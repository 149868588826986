import { Switch, Route } from 'react-router-dom'
import { CardMediaClient } from '../pages/CardsMediaClients'
import { Clients } from '../pages/Clients'
import { CreateClients } from '../pages/Clients/Create'
import { UpdateClientsInfos } from '../pages/Clients/Update'
import { CardComparisons } from '../pages/Comparisons'
import { ForgotPassword } from '../pages/ForgotPassword'
import AboutClinic from '../pages/Settings/AboutClinic'
import { Attendance } from '../pages/Settings/Attendance'
import { CreateAttendance } from '../pages/Settings/Attendance/Create'
import { UpdateAttendance } from '../pages/Settings/Attendance/update'
import { Covenants } from '../pages/Settings/Covenants'
import { CreateCovenants } from '../pages/Settings/Covenants/Create'
import { UpdateCovenants } from '../pages/Settings/Covenants/Update'
import { Origins } from '../pages/Settings/Origins'
import { CreateOrigins } from '../pages/Settings/Origins/Create'
import { UpdateOrigins } from '../pages/Settings/Origins/update'
import { RequiredFields } from '../pages/Settings/RequiredFields'
import { Suppliers } from '../pages/Settings/Suppliers'
import { CreateSuppliers } from '../pages/Settings/Suppliers/Create'
import { Tags } from '../pages/Settings/Tags'
import { CreateTags } from '../pages/Settings/Tags/Create'
import { UpdateTags } from '../pages/Settings/Tags/update'
import Users from '../pages/Settings/Users'
import { UsersCreate } from '../pages/Settings/Users/Create'
import { UpdateUser } from '../pages/Settings/Users/Update'
import { UpdateSpecialists } from '../pages/Settings/Users/UpdateSpecialists'
import { Signin } from '../pages/Signin'
import { Singup } from '../pages/Signup'
import { RoutePrivate } from './guards/RoutePrivate'
import { RouteVisitant } from './guards/RouteVisitant'
/* Scheduling */
import { Scheduling } from '../pages/Scheduling'
import { SchedulingCreate } from '../pages/Scheduling/create'
import { SchedulingUpdate } from '../pages/Scheduling/update'
/* Finances */
import { BillsToPay } from '../pages/BillsToPay'
import { CreateAndUpdateBillsToPay } from '../pages/BillsToPay/create'
import { UpdateBillsToPay } from '../pages/BillsToPay/update'
import { BillsToReceive } from '../pages/BillsToReceive'
import { Conciliation } from '../pages/BillsToReceive/conciliation'
import { Conference } from '../pages/BillsToReceive/conference'
import { Checkout } from '../pages/Checkout/index'
import { paymentBill } from '../pages/PaymentBill/payment'
// financial analysis
import { RealizedCashFlow } from '../pages/RealizedCashFlow'
// means to receipts
import { MeansToReceipts } from '../pages/MeansToReceipts'
// to update and create
import { CreateAndUpdateMeans } from '../pages/MeansToReceipts/Create'
import { MeansInstallments } from '../pages/MeansToReceipts/installments'

import { ProductsGroup } from '../pages/ProductGroups'
import { CreateProduct } from '../pages/ProductGroups/Create'

import { ProductsItems } from '../pages/ProductItems'
import { CreateProductItem } from '../pages/ProductItems/Create'
/* Services */
import { DocumentsServices } from '../pages/DocumentsServices'
import { CreateDocumentsServices } from '../pages/DocumentsServices/Create'
import { UpdateDocumentsServices } from '../pages/DocumentsServices/update'
import { Services } from '../pages/Services'
import { CreateAndUpdateService } from '../pages/Services/Create'
import { TagsServices } from '../pages/TagsServices'
import { CreateTagsServices } from '../pages/TagsServices/Create'
import { UpdateTagsServices } from '../pages/TagsServices/update'

// Attendance Screen
import { AttendanceScreen } from '../pages/Attendance'

// recipes
import { Recipe } from '../pages/Recipe'
import { CreateRecipe } from '../pages/Recipe/Create'
import { UpdateRecipe } from '../pages/Recipe/update'

// exams
import { ExamRequest } from '../pages/ExamRequest'
import { CreateExamRequest } from '../pages/ExamRequest/Create'
import { UpdateExamRequest } from '../pages/ExamRequest/update'

// creating Forms
import { FormsList } from '../pages/CreatingForms'
import { CreateAndUpdateForms } from '../pages/CreatingForms/Create'

import { BillsToPayDocument } from '../pages/BillsToPay/updateFiles'
import { DocumentPrint } from '../pages/DocumentPrint'
import { CreateDocumentPrint } from '../pages/DocumentPrint/Create'
import { UpdateDocumentPrint } from '../pages/DocumentPrint/update'
import { MovementsItem } from '../pages/ProductItems/Movement'
import { ProductsResale } from '../pages/ProductItemsResale'
import { ProductsHistory } from '../pages/ProductsHistory'
import { ReportsTransfer } from '../pages/reportsTransfer'
import { CostCenters } from '../pages/Settings/CostCenters'
import { CreateAndUpdateCostCenters } from '../pages/Settings/CostCenters/Create'
import { PaymentSources } from '../pages/Settings/PaymentSources'
import { CreateAndUpdatePaymentSources } from '../pages/Settings/PaymentSources/Create'
import { UpdatePermissions } from '../pages/Settings/Users/UpdatePermissions'
import { TaxControl } from '../pages/TaxControl'
import { TaxControlUniq } from '../pages/TaxControl/control'
import { UnitControl } from '../pages/TaxControlUnit'
import { CreateAndUpdateUnitControl } from '../pages/TaxControlUnit/Create'
import { Budgets } from '../pages/Budgets'
import { ReportsIndications } from '../pages/Indications'
import { PageNotFound } from '../pages/PageNotFound'
import { DailyBox } from '../pages/DailyBox'
import { ConferenceDailyBox } from '../pages/DailyBox/conference'
import { ReportsFinancial } from '../pages/ReportsFinancial'
import { ReportsServicesAndProducts } from '../pages/ReportsServicesAndProducts'
import { TicketsCanceled } from '../pages/TicketsCanceled'
import { ConferenceTicketsCanceled } from '../pages/TicketsCanceled/conference'

export const Routes = () => {
  return (
    <Switch>
      {/* init home, singIn SingUp */}
      <RouteVisitant exact path="/" component={Signin} />
      <RouteVisitant path="/register" component={Singup} />
      <RouteVisitant path="/forgot-password" component={ForgotPassword} />

      {/* About Clinic */}
      <RoutePrivate path="/settings/about-clinic" component={AboutClinic} />

      {/* Users */}
      <RoutePrivate exact path="/settings/users" component={Users} />
      <RoutePrivate
        exact
        path="/settings/users/create"
        component={UsersCreate}
      />
      <RoutePrivate
        exact
        path="/settings/users/update/:id"
        component={UpdateUser}
      />
      <RoutePrivate
        exact
        path="/settings/users/config-specialists/:id"
        component={UpdateSpecialists}
      />

      <RoutePrivate
        exact
        path="/settings/users/config-permissions/:id"
        component={UpdatePermissions}
      />

      {/* Cards - Fotos e Vídeos */}
      <RoutePrivate exact path="/update/card/:id" component={CardMediaClient} />

      {/* Cards - Comparisons */}
      <RoutePrivate
        exact
        path="/update/comparisons/:id"
        component={CardComparisons}
      />

      {/* Creating forms */}
      <RoutePrivate exact path="/settings/forms" component={FormsList} />

      <RoutePrivate
        exact
        path="/settings/forms/create"
        component={CreateAndUpdateForms}
      />

      <RoutePrivate
        exact
        path="/settings/forms/update/:id"
        component={CreateAndUpdateForms}
      />

      {/* ExamRequest - SOLICITAÇÃO DE EXAME */}
      <RoutePrivate
        exact
        path="/settings/exam-request"
        component={ExamRequest}
      />
      <RoutePrivate
        exact
        path="/settings/exam-request/create"
        component={CreateExamRequest}
      />
      <RoutePrivate
        exact
        path="/settings/exam-request/update/:id"
        component={UpdateExamRequest}
      />

      {/* RECIPES - RECEITAS */}
      <RoutePrivate exact path="/settings/recipes" component={Recipe} />
      <RoutePrivate
        exact
        path="/settings/recipes/create"
        component={CreateRecipe}
      />
      <RoutePrivate
        exact
        path="/settings/recipes/update/:id"
        component={UpdateRecipe}
      />

      {/* ATENDIMENTO SCREEN */}
      <RoutePrivate exact path="/attendance/:id" component={AttendanceScreen} />

      {/* AGENDAMENTO */}
      <RoutePrivate exact path="/scheduling" component={Scheduling} />
      <RoutePrivate
        exact
        path="/scheduling/create"
        component={SchedulingCreate}
      />
      <RoutePrivate
        exact
        path="/scheduling/update/:id"
        component={SchedulingUpdate}
      />

      {/* CLIENTS / PACIENTES */}
      <RoutePrivate exact path="/clients" component={Clients} />
      <RoutePrivate exact path="/clients/create" component={CreateClients} />
      <RoutePrivate
        exact
        path="/clients/update/:id"
        component={UpdateClientsInfos}
      />

      {/* LOCAIS DE ATENDIMENTO */}
      <RoutePrivate exact path="/settings/attendance" component={Attendance} />
      <RoutePrivate
        path="/settings/attendance/create"
        component={CreateAttendance}
      />
      <RoutePrivate
        path="/settings/attendance/update/:id"
        component={UpdateAttendance}
      />

      {/* COVENANTS */}
      <RoutePrivate exact path="/settings/covenants" component={Covenants} />
      <RoutePrivate
        path="/settings/covenants/create"
        component={CreateCovenants}
      />
      <RoutePrivate
        path="/settings/covenants/update/:id"
        component={UpdateCovenants}
      />

      {/* ORIGINS */}
      <RoutePrivate exact path="/settings/origins" component={Origins} />
      <RoutePrivate path="/settings/origins/create" component={CreateOrigins} />
      <RoutePrivate
        path="/settings/origins/update/:id"
        component={UpdateOrigins}
      />

      {/* TAG CLIENTE */}
      <RoutePrivate exact path="/settings/tags" component={Tags} />
      <RoutePrivate path="/settings/tags/create" component={CreateTags} />
      <RoutePrivate path="/settings/tags/update/:id" component={UpdateTags} />

      {/* PRODUCTS */}
      <RoutePrivate exact path="/group-products" component={ProductsGroup} />
      <RoutePrivate path="/group-products/create" component={CreateProduct} />
      <RoutePrivate
        path="/group-products/update/:id"
        component={CreateProduct}
      />

      <RoutePrivate exact path="/items-resale" component={ProductsResale} />

      <RoutePrivate exact path="/items-products" component={ProductsItems} />
      <RoutePrivate
        path="/items-products/create"
        component={CreateProductItem}
      />
      <RoutePrivate
        exact
        path="/items-products/update/:id"
        component={CreateProductItem}
      />

      <RoutePrivate
        exact
        path="/items-products/stock/:id"
        component={MovementsItem}
      />

      <RoutePrivate
        exact
        path="/history-products"
        component={ProductsHistory}
      />

      {/* SERVICES */}
      <RoutePrivate exact path="/services" component={Services} />
      <RoutePrivate
        path="/services/create"
        component={CreateAndUpdateService}
      />
      <RoutePrivate
        path="/services/update/:id"
        component={CreateAndUpdateService}
      />
      {/* SERVICES DOCUMENTS */}
      <RoutePrivate
        exact
        path="/services/document-service"
        component={DocumentsServices}
      />
      <RoutePrivate
        exact
        path="/services/document-service/create"
        component={CreateDocumentsServices}
      />
      <RoutePrivate
        exact
        path="/services/document-service/update/:id"
        component={UpdateDocumentsServices}
      />
      {/* SERVICES TAGS */}
      <RoutePrivate exact path="/services/tags" component={TagsServices} />
      <RoutePrivate
        exact
        path="/services/tags/create"
        component={CreateTagsServices}
      />
      <RoutePrivate
        exact
        path="/services/tags/update/:id"
        component={UpdateTagsServices}
      />

      {/* REQUIRED FIELDS */}
      <RoutePrivate
        exact
        path="/settings/required-fields"
        component={RequiredFields}
      />

      {/* FINANCES analysis */}
      <RoutePrivate
        exact
        path="/financial/analytical/realized-cash-flow"
        component={RealizedCashFlow}
      />

      {/* FINANCES */}

      {/* Tax Control */}
      {/* tax unit control */}
      <RoutePrivate
        exact
        path="/financial/tax/control/unit"
        component={UnitControl}
      />

      {/* Daily Box */}
      <RoutePrivate exact path="/financial/daily-box" component={DailyBox} />

      {/* Daily Box */}
      <RoutePrivate
        exact
        path="/financial/daily-box/conference/:id"
        component={ConferenceDailyBox}
      />

      {/* Daily Box */}
      <RoutePrivate
        exact
        path="/financial/tickets-canceled"
        component={TicketsCanceled}
      />

      {/* Daily Box */}
      <RoutePrivate
        exact
        path="/financial/tickets-canceled/conference/:id"
        component={ConferenceTicketsCanceled}
      />

      <RoutePrivate
        exact
        path="/financial/tax/control/unit/update/:id"
        component={CreateAndUpdateUnitControl}
      />

      <RoutePrivate
        exact
        path="/financial/tax/control/unit/create"
        component={CreateAndUpdateUnitControl}
      />

      <RoutePrivate
        exact
        path="/financial/tax/control"
        component={TaxControl}
      />

      <RoutePrivate
        exact
        path="/financial/tax/control/:id"
        component={TaxControlUniq}
      />

      {/* Checkout */}
      <RoutePrivate exact path="/financial/checkout/:id" component={Checkout} />

      {/* Means to Receipts */}
      <RoutePrivate
        exact
        path="/financial/receiving-method"
        component={MeansToReceipts}
      />

      <RoutePrivate
        exact
        path="/financial/receiving-method/create"
        component={CreateAndUpdateMeans}
      />

      <RoutePrivate
        exact
        path="/financial/receiving-method/update/:id"
        component={CreateAndUpdateMeans}
      />

      <RoutePrivate
        exact
        path="/financial/receiving-method/installments/:id"
        component={MeansInstallments}
      />

      {/* PAYMENT SOURCES */}
      <RoutePrivate
        exact
        path="/financial/payment-sources"
        component={PaymentSources}
      />
      <RoutePrivate
        path="/financial/payment-sources/create"
        component={CreateAndUpdatePaymentSources}
      />
      <RoutePrivate
        path="/financial/payment-sources/update/:id"
        component={CreateAndUpdatePaymentSources}
      />

      {/* COST CENTERS */}
      <RoutePrivate
        exact
        path="/financial/cost-centers"
        component={CostCenters}
      />
      <RoutePrivate
        path="/financial/cost-centers/create"
        component={CreateAndUpdateCostCenters}
      />
      <RoutePrivate
        exact
        path="/financial/cost-centers/update/:id"
        component={CreateAndUpdateCostCenters}
      />

      {/* BIllS TO RECEIVE */}
      <RoutePrivate
        exact
        path="/financial/billy-to-receive"
        component={BillsToReceive}
      />

      {/* CONCILIATION */}
      <RoutePrivate
        exact
        path="/financial/conciliation"
        component={Conciliation}
      />

      <RoutePrivate
        exact
        path="/financial/billy-to-receive/conference/:id"
        component={Conference}
      />

      {/* BILLS TO PAY */}
      <RoutePrivate
        exact
        path="/finances/bills-to-pay"
        component={BillsToPay}
      />

      <RoutePrivate
        exact
        path="/finances/bills-to-pay/create"
        component={CreateAndUpdateBillsToPay}
      />

      <RoutePrivate
        exact
        path="/finances/bills-to-pay/files/:id"
        component={BillsToPayDocument}
      />

      <RoutePrivate
        exact
        path="/finances/bills-to-pay/update/:id"
        component={UpdateBillsToPay}
      />

      <RoutePrivate
        exact
        path="/finances/bills-to-pay/payment/:id"
        component={paymentBill}
      />

      {/* SUPPLIERS */}
      <RoutePrivate exact path="/financial/suppliers" component={Suppliers} />
      <RoutePrivate
        path="/financial/suppliers/create"
        component={CreateSuppliers}
      />
      <RoutePrivate
        path="/financial/suppliers/update/:id"
        component={CreateSuppliers}
      />

      {/* DocumentPrintConfig */}

      <RoutePrivate exact path="/settings/template" component={DocumentPrint} />
      <RoutePrivate
        exact
        path="/settings/template/create"
        component={CreateDocumentPrint}
      />
      <RoutePrivate
        exact
        path="/settings/template/update/:id"
        component={UpdateDocumentPrint}
      />

      {/* Reports transfer */}
      <RoutePrivate
        exact
        path="/settings/reports/transfer"
        component={ReportsTransfer}
      />

      {/* Reports financial */}
      <RoutePrivate
        exact
        path="/settings/reports/financial"
        component={ReportsFinancial}
      />

      {/* Reports services and products */}
      <RoutePrivate
        exact
        path="/settings/reports/services-and-products"
        component={ReportsServicesAndProducts}
      />

      {/* Budgets */}
      {/* Reports indications */}
      <RoutePrivate
        exact
        path="/settings/reports/indications"
        component={ReportsIndications}
      />

      {/* Budgets */}
      <RoutePrivate exact path="/budgets/create" component={Budgets} />

      {/* Page 404 */}
      <Route path="*" component={PageNotFound} />
    </Switch>
  )
}
