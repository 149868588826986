/* eslint-disable camelcase */
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  Textarea,
  useBreakpointValue,
  useMediaQuery,
  useToast
} from '@chakra-ui/react'
import { FC, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { CardImage } from '../../components/Cards/cardImage'
import { CardVideo } from '../../components/Cards/cardVideo'
import { FileList } from '../../components/FileList'
import { Input } from '../../components/Form/Input'
import { ModalConfirmExclude } from '../../components/Modal/confirmExclude'
import { ModalMediaPlayer } from '../../components/Modal/modalMediaPlayer'
import { ModalRegister } from '../../components/Modal/modalRegister'
import { ModalUploadFiles } from '../../components/Modal/modalUploadFiles'
import { ModalWebcam } from '../../components/Modal/modalWebcam'
import { CardContainer } from '../../components/molecules/CardContainer'
import { Upload } from '../../components/Upload'
import { WebcamCapture } from '../../components/Webcam/webcamGallery'
import { WebcamStreamCapture } from '../../components/Webcam/webcamStream'
import { useDocument } from '../../hooks/useDocument'
import { LayoutDefault } from '../../layouts/Default'
import { apiAuth } from '../../services/apiAuth'

type FormData = {
  description: string
}

type ParamsProps = {
  id: string
}

interface GalleryCard {
  date: string
  description: string | null
  gallery_patient: number
  id: string
  name: string
  patient_name: string
  quantity_images: number
  quantity_videos: number
}

interface GalleryCardInfo {
  date: string | null
  description: string | null
  file: string
  id: number
  thumbnail: string | null
  name: string | null
}

type GalleryCardData = GalleryCard | null

type GalleryCardImageData = Array<GalleryCardInfo> | null

type GalleryCardVideoData = Array<GalleryCardInfo> | null

export const CardMediaClient: FC = () => {
  const [t] = useTranslation('pageCardsMedia')
  const toast = useToast()
  const { id } = useParams<ParamsProps>()
  const { uploadedFiles, setUpLoaderUrl, handlerClearListFiles } = useDocument()
  const history = useHistory()
  const data: any = history.location
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const [isLargerThan1025] = useMediaQuery('(min-width: 1025px)')
  const { register, handleSubmit, formState } = useForm<FormData>({})
  const { errors } = formState

  // state data
  const [galleryCardInfo, setGalleryCardInfo] = useState<GalleryCardData>(null)
  const [galleryCardImages, setGalleryCardImages] =
    useState<GalleryCardImageData>(null)
  const [galleryCardVideos, setGalleryCardVideos] =
    useState<GalleryCardVideoData>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingImage, setIsLoadingImage] = useState(false)
  const [isLoadingVideo, setIsLoadingVideo] = useState(false)

  // state update Card Name and Date
  const [cardName, setCardName] = useState('')
  const [cardDate, setCardDate] = useState('')
  const [cardDescription, setCardDescription] = useState('')

  // state modal excludes
  const [isOpen, setIsOpen] = useState(false)

  // state modal update
  const [isOpenEdit, setIsOpenEdit] = useState(false)
  const [nameUpdate, setNameUpdate] = useState('')
  const [dateUpdate, setDateUpdate] = useState('')
  const [descriptionUpdate, setDescriptionUpdate] = useState('')
  const [endPointUpdate, setEndPointUpdate] = useState('')

  // state modal uploadsFiles
  const [isOpenModalUpload, setIsOpenModalUpload] = useState(false)
  const [isPhotoUpload, setIsPhotoUpload] = useState(true)
  const [sizeUploadInfo, setSizeUploadInfo] = useState(25)
  const [sizeUpload, setSizeUpload] = useState(5242880 * 5)

  // states exclude photos or videos
  const [endPoint, setEndPoint] = useState('')
  const [typeExclude, setTypeExclude] = useState('')

  // states modal média view
  const [isOpenModalMedia, setIsOpenModalMedia] = useState(false)
  const [isPhoto, setIsPhoto] = useState(true)
  const [urlMedia, setUrlMedia] = useState('')

  // states modal webcam
  const [isOpenModalWebcam, setIsOpenModalWebcam] = useState(false)
  const [isWebcamVideo, setIsWebcamVideo] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    setIsLoadingImage(true)
    setIsLoadingVideo(true)
    apiAuth
      .get<GalleryCard>(`clinics/gallery-cards/${id}/`)
      .then((response) => {
        setGalleryCardInfo(response.data)
        setCardName(response.data.name || '')
        setCardDate(response.data.date || '')
        setCardDescription(response.data.description || '')
      })
      .finally(() => {
        setIsLoading(false)
      })

    apiAuth
      .get(`clinics/gallery-cards/${id}/images/`)
      .then((response) => {
        setGalleryCardImages(response.data)
      })
      .finally(() => {
        setIsLoadingImage(false)
      })

    apiAuth
      .get(`clinics/gallery-cards/${id}/videos/`)
      .then((response) => {
        setGalleryCardVideos(response.data)
        console.log(response.data)
      })
      .finally(() => {
        setIsLoadingVideo(false)
      })
  }, [id])

  // modal states functions and controls url, size from api
  const onClose = () => {
    setIsOpen(false)
  }

  const onOpen = () => {
    setIsOpen(true)
  }

  const onCloseEdit = () => {
    setIsOpenEdit(false)
    setEndPointUpdate('')
    setDescriptionUpdate('')
    setNameUpdate('')
    setDateUpdate('')
  }

  const onOpenEdit = () => {
    setIsOpenEdit(true)
  }

  const onOpenModalUpload = (isPhotoChange: boolean) => {
    if (isPhotoChange) {
      setIsPhotoUpload(true)
      setUpLoaderUrl(`clinics/gallery-cards/${id}/images/`)
      setSizeUploadInfo(5 * 5)
      setSizeUpload(5242880 * 5)
    } else {
      setIsPhotoUpload(false)
      setUpLoaderUrl(`clinics/gallery-cards/${id}/videos/`)
      setSizeUploadInfo(5 * 8)
      setSizeUpload(5242880 * 8)
    }

    setIsOpenModalUpload(true)
  }

  const onCloseModalUpload = () => {
    setIsOpenModalUpload(false)
    handlerClearListFiles()
  }

  const onCloseModalMedia = () => {
    setIsOpenModalMedia(false)
    setIsPhoto(true)
    setUrlMedia('')
  }

  const onOpenModalWebcam = (isOpenVideo: boolean) => {
    if (isOpenVideo) {
      setIsWebcamVideo(true)
    } else {
      setIsWebcamVideo(false)
    }

    setIsOpenModalWebcam(true)
  }

  const onCloseModalWebcam = () => {
    setIsOpenModalWebcam(false)
  }

  // functions updates values infos, uploads, changes
  const updateCardsInfos = () => {
    apiAuth.get(`clinics/gallery-cards/${id}/`).then((response) => {
      setGalleryCardInfo(response.data)
      console.log(response.data)
    })
  }

  const updateImages = () => {
    apiAuth.get(`clinics/gallery-cards/${id}/images/`).then((response) => {
      setGalleryCardImages(response.data)
    })
  }

  const updateVideos = () => {
    apiAuth.get(`clinics/gallery-cards/${id}/videos/`).then((response) => {
      setGalleryCardVideos(response.data)
    })
  }

  // function delete file
  const handlerDeleteFileItem = async (url: string, type: string) => {
    try {
      const { status } = await apiAuth.delete(url)

      if (status === 204) {
        toast({
          position: 'top-right',
          duration: 3000,
          status: 'success',
          description: `${t('toastExclude.success.message')}`
        })
      }
    } catch (error: any) {
      toast({
        position: 'top-right',
        duration: 3000,
        status: 'error',
        title: 'Error!',
        description: `${t('toastExclude.error.message')}`
      })
    }

    // update cards
    if (type === 'image') {
      updateImages()
    }

    if (type === 'video') {
      updateVideos()
    }

    // close modal
    onClose()
  }

  // update data in changes
  useEffect(() => {
    const subscription = () => {
      const updateCardsImages = () => {
        apiAuth.get(`clinics/gallery-cards/${id}/images/`).then((response) => {
          if (response.data.length > 0) {
            setGalleryCardImages(response.data)
          }
        })
      }

      const updateCardsVideos = () => {
        apiAuth.get(`clinics/gallery-cards/${id}/videos/`).then((response) => {
          setGalleryCardVideos(response.data)
        })
      }

      if (uploadedFiles.length > 0) {
        if (isPhotoUpload) {
          updateCardsImages()
        } else {
          updateCardsVideos()
        }
      }
    }

    return () => subscription()
  }, [isPhotoUpload, uploadedFiles, id])

  // update info Cards
  const handlerUpdateCard: SubmitHandler<FormData> = async (values, event) => {
    event?.preventDefault()

    const payload = {
      name: cardName,
      date: cardDate,
      description: cardDescription === '' ? null : cardDescription,
      gallery_patient: data.state.id
    }

    try {
      const { data: objectData } = await apiAuth.put(
        `clinics/gallery-cards/${id}/`,
        payload
      )

      setCardName(objectData.name || '')
      setCardDate(objectData.date || '')
      setCardDescription(objectData.description || '')
      updateCardsInfos()

      toast({
        duration: 3000,
        status: 'success',
        description: `${t('toastUpdate.success')}`,
        position: 'top-right'
      })
    } catch (error: any) {
      toast({
        duration: 3000,
        status: 'error',
        title: `${t('toastUpdate.error')}`,
        position: 'top-right',
        description: `${error.message}`
      })
    }
  }

  const handlerUpdateFileInfos = async (url: string) => {
    const payload = {
      name: nameUpdate,
      date: dateUpdate,
      description: descriptionUpdate
    }

    if (nameUpdate === '') {
      toast({
        duration: 3000,
        status: 'error',
        description: `${t('modalEdit.toast.validation.name')}`,
        position: 'top'
      })

      return
    }

    if (dateUpdate === '') {
      toast({
        duration: 3000,
        status: 'error',
        description: `${t('modalEdit.toast.validation.date')}`,
        position: 'top'
      })

      return
    }

    if (descriptionUpdate === '') {
      toast({
        duration: 3000,
        status: 'error',
        description: `${t('modalEdit.toast.validation.description')}`,
        position: 'top'
      })

      return
    }

    try {
      const { data: objectData } = await apiAuth.put(url, payload)

      if (objectData) {
        toast({
          duration: 3000,
          status: 'success',
          description: `${t('modalEdit.toast.success')}`,
          position: 'top-right'
        })
        updateImages()
        updateVideos()
        onCloseEdit()
      }
    } catch (error: any) {
      toast({
        duration: 3000,
        status: 'error',
        title: `${t('modalEdit.toast.error')}`,
        description: `${error.response?.data?.message}`,
        position: 'bottom-right'
      })
    }
  }

  return (
    <>
      <LayoutDefault
        title={
          isWideVersion
            ? `${t('title')} - ${galleryCardInfo?.patient_name}`
            : t('mobileTitle')
        }
        urlBack={`/clients/update/${data.state.id}`}
        onSubmit={handleSubmit(handlerUpdateCard)}
      >
        <Stack w="100%" spacing="4" direction="column">
          <CardContainer title={t('container0.title')}>
            {isLoading ? (
              <Flex p="2" w="100" justifyContent="center" alignItems="center">
                <Spinner color="blue" />
              </Flex>
            ) : (
              <Flex
                flexDirection={['column', 'row']}
                alignItems="flex-end"
                justifyContent="center"
                w="100%"
              >
                <Flex direction="column" w={['100%', '50%']}>
                  <Flex
                    align="flex-start"
                    justify="flex-start"
                    flexDirection={['column', 'row']}
                  >
                    <Box mb="2" mx="2" w={['280px', '300px']}>
                      <Input
                        name="cardName"
                        label={t('container0.cardName')}
                        value={cardName}
                        isRequired
                        placeholder={t('container0.placeholder.cardName')}
                        onChange={(event) =>
                          setCardName(event.target.value.toUpperCase())
                        }
                        maxLength={15}
                      />
                    </Box>
                    <Box mb="2" mx="2" w={['280px', '300px']}>
                      <Input
                        name="cardDate"
                        isRequired
                        type="date"
                        label={t('container0.cardDate')}
                        value={cardDate}
                        onChange={(event) => setCardDate(event.target.value)}
                      />
                    </Box>
                  </Flex>
                </Flex>
                <Flex
                  mx="4"
                  my="2"
                  pl={!isWideVersion ? '6' : undefined}
                  w={['100%', '50%']}
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  flexDirection="column"
                >
                  <Input
                    name="description"
                    label={t('container0.description')}
                    maxLength={200}
                    value={cardDescription}
                    onChange={(event) => setCardDescription(event.target.value)}
                    placeholder={t('container0.placeholder.description')}
                  />
                </Flex>
              </Flex>
            )}
          </CardContainer>

          <CardContainer title={t('container1.title')}>
            {/* isWideVersion or not */}
            <SimpleGrid
              columns={isLargerThan1025 ? 4 : 3}
              spacing={4}
              minChildWidth={isWideVersion ? undefined : '320px'}
            >
              {!galleryCardImages && isLoadingImage ? (
                <Flex w="100%" justifyContent="flex-start" alignItems="center">
                  <Text fontSize="12px" color="#999" mx="4">
                    {t('loading')}
                  </Text>
                  <Spinner size="md" color="gray" />
                </Flex>
              ) : (
                galleryCardImages?.map((image) => (
                  <Flex key={image.id}>
                    <CardImage
                      onOpenExclude={() => {
                        setEndPoint(
                          `clinics/gallery-cards/${id}/images/${image.id}/`
                        )
                        setTypeExclude('image')
                        onOpen()
                      }}
                      onViewPhoto={() => {
                        setUrlMedia(image.file)
                        setIsPhoto(true)
                        setIsOpenModalMedia(true)
                      }}
                      onOpenEdit={() => {
                        setNameUpdate(image.name || '')
                        setDateUpdate(image.date || '')
                        setDescriptionUpdate(image.description || '')
                        setEndPointUpdate(
                          `clinics/gallery-cards/${id}/images/${image.id}/`
                        )
                        onOpenEdit()
                      }}
                      src={image.file}
                      alt={image.name || 'image'}
                    />
                  </Flex>
                ))
              )}
              {galleryCardImages !== null && galleryCardImages.length === 0 && (
                <Flex w="100%">
                  <Text fontSize="12px" color="#999">
                    {t('container1.noPhoto')}
                  </Text>
                </Flex>
              )}
            </SimpleGrid>
            <Flex
              w="100%"
              align="flex-start"
              justifyContent="flex-start"
              mt="4"
              gap="2"
              flexDirection={['column', 'row']}
            >
              {isWideVersion && (
                <Button
                  onClick={() => {
                    setSizeUpload(5242880 * 3)
                    onOpenModalWebcam(false)
                  }}
                  size="xs"
                  bg="blue.300"
                  color="white"
                >
                  {t('container1.bntWebcam')}
                </Button>
              )}
              <Button
                onClick={() => onOpenModalUpload(true)}
                size="xs"
                bg="blue.300"
                color="white"
              >
                {t('container1.bntPhoto')}
              </Button>
            </Flex>
          </CardContainer>

          <CardContainer title={t('container2.title')}>
            <SimpleGrid
              columns={isLargerThan1025 ? 4 : 3}
              spacing={10}
              minChildWidth={isWideVersion ? undefined : '320px'}
            >
              {!galleryCardVideos && isLoadingVideo ? (
                <Flex w="100%" justifyContent="flex-start" alignItems="center">
                  <Text fontSize="12px" color="#999" mx="4">
                    {t('loading')}
                  </Text>
                  <Spinner size="md" color="gray" />
                </Flex>
              ) : (
                galleryCardVideos?.map((video) => (
                  <Flex key={video.id}>
                    <CardVideo
                      title={video.name || ''}
                      onOpenExclude={() => {
                        setEndPoint(
                          `clinics/gallery-cards/${id}/videos/${video.id}/`
                        )
                        setTypeExclude('video')
                        onOpen()
                      }}
                      file={video.file}
                      onOpenEdit={() => {
                        setNameUpdate(video.name || '')
                        setDateUpdate(video.date || '')
                        setDescriptionUpdate(video.description || '')
                        setEndPointUpdate(
                          `clinics/gallery-cards/${id}/videos/${video.id}/`
                        )
                        onOpenEdit()
                      }}
                      src={video.thumbnail || undefined}
                      onPlayer={() => {
                        setUrlMedia(video.file)
                        setIsPhoto(false)
                        setIsOpenModalMedia(true)
                      }}
                    />
                  </Flex>
                ))
              )}

              {galleryCardVideos !== null && galleryCardVideos.length === 0 && (
                <Flex w="100%">
                  <Text fontSize="12px" color="#999">
                    {t('container2.noVideo')}
                  </Text>
                </Flex>
              )}
            </SimpleGrid>
            <Flex
              w="100%"
              align="flex-start"
              justifyContent="flex-start"
              mt="4"
              gap="2"
              flexDirection={['column', 'row']}
            >
              {isWideVersion && (
                <Button
                  onClick={() => {
                    setSizeUpload(5242880 * 8)
                    onOpenModalWebcam(true)
                  }}
                  size="xs"
                  bg="blue.300"
                  color="white"
                >
                  {t('container2.bntWebcamVideo')}
                </Button>
              )}
              <Button
                onClick={() => onOpenModalUpload(false)}
                size="xs"
                bg="blue.300"
                color="white"
              >
                {t('container2.bntVideo')}
              </Button>
            </Flex>
          </CardContainer>
          <Flex w="100%" alignItems="center" justifyContent="flex-end">
            <Button
              type="submit"
              size="md"
              fontSize="md"
              bg="blue.300"
              color="white"
              isLoading={formState.isSubmitting}
            >
              {t('btnUpdate')}
            </Button>
          </Flex>
          <Flex w="100%" h="300px" />
        </Stack>
      </LayoutDefault>
      <ModalConfirmExclude
        isOpen={isOpen}
        title={t('modalExclude.title')}
        onConfirmExclude={async () => {
          await handlerDeleteFileItem(endPoint, typeExclude)
        }}
        onClose={onClose}
      >
        <Text>{t('modalExclude.message')}</Text>
      </ModalConfirmExclude>
      <ModalRegister
        isOpen={isOpenEdit}
        title={t('modalEdit.title')}
        isUpdating
        handlerAction={async () => {
          await handlerUpdateFileInfos(endPointUpdate)
        }}
        onClose={onCloseEdit}
      >
        <Flex
          align="flex-start"
          justify="flex-start"
          flexDirection={['column', 'row']}
        >
          <Box mb="2" mx="2" w={['280px', '300px']}>
            <Input
              name="nameUpdate"
              label={t('modalEdit.name')}
              value={nameUpdate}
              onChange={(event) => setNameUpdate(event.target.value)}
              maxLength={20}
            />
          </Box>
          <Box mb="2" mx="2" w={['280px', '300px']}>
            <Input
              name="dateUpdate"
              type="date"
              label={t('modalEdit.date')}
              value={dateUpdate}
              onChange={(event) => setDateUpdate(event.target.value)}
            />
          </Box>
        </Flex>
        <Flex w="100%" p="2">
          <Textarea
            name="fileDescription"
            placeholder={t('modalEdit.description')}
            value={descriptionUpdate}
            onChange={(event) => setDescriptionUpdate(event.target.value)}
          />
        </Flex>
      </ModalRegister>
      <ModalUploadFiles
        isOpen={isOpenModalUpload}
        onClose={onCloseModalUpload}
        title={
          isPhotoUpload
            ? t('modalUploadFiles.titlePhoto')
            : t('modalUploadFiles.titleVideo')
        }
      >
        <Upload
          accept={!isPhotoUpload ? 'video/*' : undefined}
          maxInfoSize={sizeUploadInfo}
          maxSize={sizeUpload}
        />
        {!!uploadedFiles.length && <FileList files={uploadedFiles} />}
      </ModalUploadFiles>
      <ModalMediaPlayer
        src={urlMedia}
        isPhoto={isPhoto}
        isOpen={isOpenModalMedia}
        onClose={onCloseModalMedia}
      />
      <ModalWebcam
        isOpen={isOpenModalWebcam}
        onClose={onCloseModalWebcam}
        title={t('modalWebcam.title')}
      >
        <Flex
          w={isWideVersion ? '100%' : undefined}
          h={isWideVersion ? '432px' : undefined}
          alignItems="center"
          justifyContent="center"
        >
          {isWebcamVideo ? (
            <WebcamStreamCapture
              cardId={id}
              maxSize={sizeUpload}
              onUpdate={updateVideos}
            />
          ) : (
            <WebcamCapture
              cardId={id}
              maxSize={sizeUpload}
              onUpdate={updateImages}
            />
          )}
        </Flex>
      </ModalWebcam>
    </>
  )
}
