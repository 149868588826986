/* eslint-disable camelcase */
import { useQuery } from 'react-query'
import { apiAuth } from '../../services/apiAuth'

export type CostsData = {
  name: string
  observation: string
  id: number
  is_default: boolean
}

export type ResponseGetCost = {
  costs: CostsData[]
  totalCountOfPage: number
}

export const getCost = async (
  clinicsId: string = '',
  page: number,
  search: string = '',
  isActive: boolean = true
): Promise<ResponseGetCost> => {
  try {
    const { data } = await apiAuth.get(`clinics/${clinicsId}/cost-centers/`, {
      params: {
        page,
        name__icontains: search,
        is_active: isActive
      }
    })

    const totalCountOfPage = data.count
    const costs: CostsData[] = data.results

    return {
      costs,
      totalCountOfPage
    }
  } catch (error: any) {
    console.log(error)

    const costs: CostsData[] = []
    const totalCountOfPage = 0
    return {
      costs,
      totalCountOfPage
    }
  }
}

export function useCost(
  page: number,
  functionLoading: Promise<ResponseGetCost>,
  search: string,
  isActive: boolean,
  options?: {}
) {
  return useQuery(['cost', page, search, isActive], () => functionLoading, {
    staleTime: 1000 * 2, // 2 seconds
    cacheTime: 1000 * 2, // 2 seconds
    ...options
  })
}
