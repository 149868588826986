/* eslint-disable dot-notation */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react'
import {
  Box,
  Tooltip,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Text,
  Skeleton,
  Checkbox as ChakraCheckbox
} from '@chakra-ui/react'
import { FiEdit } from 'react-icons/fi'

import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  useCovenants,
  getCovenants
} from '../../../hooks/covenants/useCovenants'
import { useAuth } from '../../../hooks/auth'
import { LayoutList } from '../../../layouts/List'
import { Pagination } from '../../../components/PaginationNew'
import { NotFound } from '../../../components/molecules/NotFound'
import { PermissionComponent } from '../../../components/Permissions'

// debounce
let timer: any = null

export function Covenants() {
  const { clinicId } = useAuth()
  const [t] = useTranslation('pageConvenio')
  const skeletonRepeat = [1, 2, 3, 4, 5, 6, 7]
  const [searchState, setSearchState] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [isActive, setIsActive] = useState(true)

  const getCovenantsData = useCovenants(
    currentPage,
    getCovenants(String(clinicId), currentPage, searchState, isActive),
    searchState,
    isActive
  )

  const SearchInputCallbackValue = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget

      clearTimeout(timer)

      timer = setTimeout(() => {
        setCurrentPage(1)
        setSearchState(value)
      }, 500)
    },
    []
  )

  const ActiveCheckboxCallbackValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsActive(event.target.checked)
    },
    []
  )

  return (
    <LayoutList
      title={t('title')}
      urlNew="/settings/covenants/create"
      refetch={getCovenantsData.refetch}
      capturaChange={(event: any) => SearchInputCallbackValue(event)}
      captureActiveChange={(event: any) => ActiveCheckboxCallbackValue(event)}
      spiCode="INSURANCES"
    >
      <Box>
        <Table variant="striped" colorScheme="blue" size="sm">
          <Thead>
            <Tr>
              <Th>{t('tConvenio')}</Th>

              <Th>{t('TAcoes')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {getCovenantsData.isLoading ? (
              <>
                {skeletonRepeat.map((skeleton) => (
                  <Tr>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                  </Tr>
                ))}
              </>
            ) : (
              <>
                {getCovenantsData.data?.covenants.map((cov, index: number) => (
                  <Tr key={cov.id} maxHeight="40px">
                    <Td>
                      <Text fontSize="sm">{cov.name}</Text>
                    </Td>

                    <Td>
                      <PermissionComponent spiCode="INSURANCES" ipCode="EDIT">
                        <Link
                          to={{
                            pathname: `/settings/covenants/update/${cov.id}`,
                            state: cov
                          }}
                        >
                          <Tooltip
                            label="Editar"
                            aria-label="editar"
                            hasArrow
                            placement="top-start"
                          >
                            <Box ml="1rem" w="24px">
                              <FiEdit
                                color="#9699B0"
                                size={16}
                                style={{ cursor: 'pointer' }}
                              />
                            </Box>
                          </Tooltip>
                        </Link>
                      </PermissionComponent>
                    </Td>
                  </Tr>
                ))}
              </>
            )}
          </Tbody>
        </Table>
        {getCovenantsData.data?.totalCountOfPage === 0 &&
          !getCovenantsData.isLoading && <NotFound />}
      </Box>
      {!(
        getCovenantsData.data?.totalCountOfPage === 0 &&
        !getCovenantsData.isLoading
      ) && (
        <Pagination
          totalCountOfRegisters={getCovenantsData.data?.totalCountOfPage || 0}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      )}
    </LayoutList>
  )
}
