/* eslint-disable camelcase */
import { useQuery } from 'react-query'
import { apiAuth } from '../../services/apiAuth'

export type ExamData = {
  id: string
  name: string
  exam_detail: string
  status: number
}

export type ResponseGetExam = {
  exams: ExamData[]
  totalCountOfPage: number
}

export const getExams = async (
  clinicsId: string = '',
  page: number,
  search: string = '',
  isActive: boolean = true
): Promise<ResponseGetExam> => {
  try {
    const { data } = await apiAuth.get(`clinics/${clinicsId}/exams/`, {
      params: {
        page,
        name__icontains: search,
        is_active: isActive
      }
    })

    const totalCountOfPage = data.count
    const exams: ExamData[] = data.results
    console.log(data.results)

    return {
      exams,
      totalCountOfPage
    }
  } catch (error: any) {
    console.log(error)

    const exams: ExamData[] = []
    const totalCountOfPage = 0
    return {
      exams,
      totalCountOfPage
    }
  }
}

export function useExams(
  page: number,
  functionLoading: Promise<ResponseGetExam>,
  search: string,
  isActive: boolean,
  options?: {}
) {
  return useQuery(['exams', page, search, isActive], () => functionLoading, {
    staleTime: 1000 * 2, // 2 seconds
    cacheTime: 1000 * 2, // 2 seconds
    ...options
  })
}
