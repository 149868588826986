/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Tooltip,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Text,
  Skeleton,
  Checkbox as ChakraCheckbox
} from '@chakra-ui/react';
import React, { useEffect, useState, useCallback } from 'react';
import {  FiEdit } from 'react-icons/fi';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTags, getTags } from '../../../hooks/tags/useTags';
import { useAuth } from '../../../hooks/auth';
import { LayoutList } from '../../../layouts/List';
import { NotFound } from '../../../components/molecules/NotFound';
import { Pagination } from '../../../components/PaginationNew';
import { PermissionComponent } from '../../../components/Permissions';

// debounce
let timer: any = null

export function Tags() {
  const { clinicId } = useAuth()
  const [t] = useTranslation('pageTags');
  const [searchState, setSearchState] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [isActive, setIsActive] = useState(true)
  const skeletonRepeat = [1, 2, 3, 4, 5, 6, 7];

  const getTagsData = useTags(
    currentPage,
    getTags(
      String(clinicId),
      currentPage,
      searchState,
      isActive,
    ),
    searchState,
    isActive,
  )

  const SearchInputCallbackValue = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget

      clearTimeout(timer);

      timer = setTimeout(() => {
        setCurrentPage(1)
        setSearchState(value)
      }, 500)
  }, [])

  const ActiveCheckboxCallbackValue = useCallback((
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsActive(event.target.checked)
  }, [])

  return (
    <LayoutList
      title={t('title')}
      urlNew="/settings/tags/create"
      refetch={getTagsData.refetch}
      capturaChange={(event: any) => SearchInputCallbackValue(event)}
      captureActiveChange={(event: any) => ActiveCheckboxCallbackValue(event)}
      spiCode="CUSTOMER_TAGS"
    >
      <Box >
        <Table variant="striped" colorScheme="blue" size="sm">
          <Thead>
            <Tr>
              <Th>{t('tLocal')}</Th>

              <Th>{t('TAcoes')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {getTagsData.isLoading ? (
              <>
                {skeletonRepeat.map(skeleton => (
                  <Tr>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                  </Tr>
                ))}
              </>
            ) : (
              <>
                {getTagsData.data?.tags.map((tagItem, index) => (
                  <Tr key={tagItem.id} maxHeight="40px">
                    <Td>
                      <Text fontSize="sm">{tagItem.name}</Text>
                    </Td>


                    <Td>
                      <PermissionComponent
                        spiCode="CUSTOMER_TAGS"
                        ipCode="EDIT"
                      >
                        <Link
                          to={{
                            pathname: `/settings/tags/update/${tagItem.id}`,
                            state: tagItem,
                          }}
                        >
                          <Tooltip
                            label="Editar"
                            aria-label="editar"
                            hasArrow
                            placement="top-start"
                          >
                            <Box ml="1rem" w="24px">
                              <FiEdit
                                color="#9699B0"
                                size={16}
                                style={{ cursor: 'pointer' }}
                              />
                            </Box>
                          </Tooltip>
                        </Link>
                      </PermissionComponent>
                    </Td>
                  </Tr>
                ))}
              </>
            )}
          </Tbody>
        </Table>
        {(getTagsData.data?.totalCountOfPage === 0 && !getTagsData.isLoading) && <NotFound />}
      </Box>
      {
       !(getTagsData.data?.totalCountOfPage === 0 && !getTagsData.isLoading)  && (
        <Pagination
          totalCountOfRegisters={getTagsData.data?.totalCountOfPage || 0}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
        )
      }

    </LayoutList>
  );
}
