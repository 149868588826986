/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Tooltip,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Text,
  Skeleton
} from '@chakra-ui/react';
import {  FiEdit } from 'react-icons/fi';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getFinancialSources, useFinancialSources } from '../../../hooks/financialSources/useFinancialSources';
import { useAuth } from '../../../hooks/auth';
import { LayoutList } from '../../../layouts/List';
import { NotFound } from '../../../components/molecules/NotFound';
import { Pagination } from '../../../components/PaginationNew';
import { PermissionComponent } from '../../../components/Permissions';

// debounce
let timer: any = null;

export function PaymentSources() {
  const { clinicId } = useAuth()
  const [t] = useTranslation('pagePaymentSources')
  const [searchState, setSearchState] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [isActive, setIsActive] = useState(true)
  const skeletonRepeat = [1, 2, 3, 4, 5, 6, 7]

  const getFinancialData = useFinancialSources(
    currentPage,
    getFinancialSources(
      String(clinicId),
      currentPage,
      searchState,
      isActive,
    ),
    searchState,
    isActive,
  )

  const SearchInputCallbackValue = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget

      clearTimeout(timer);

      timer = setTimeout(() => {
        setCurrentPage(1)
        setSearchState(value)
      }, 500)
  }, [])

  const ActiveCheckboxCallbackValue = useCallback((
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsActive(event.target.checked)
  }, [])


  return (
    <LayoutList
      title={t('title')}
      urlNew="/financial/payment-sources/create"
      refetch={getFinancialData.refetch}
      capturaChange={(event: any) => SearchInputCallbackValue(event)}
      captureActiveChange={(event: any) => ActiveCheckboxCallbackValue(event)}
      spiCode="WAYS_TO_PAYMENTS"
    >
      <Box >
        <Table variant="striped" colorScheme="blue" size="sm">
          <Thead>
            <Tr>
              <Th>{t('t1')}</Th>
              <Th>{t('t2')}</Th>
              <Th>{t('t4')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {getFinancialData.isLoading ? (
              <>
                {skeletonRepeat.map(skeleton => (
                  <Tr>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                  </Tr>
                ))}
              </>
            ) : (
              <>
                {getFinancialData.data?.financial.map((source, index) => (
                  <Tr key={source.id} maxHeight="40px">
                    <Td w="40%">
                      <Text fontSize="sm">{source.name}</Text>
                    </Td>
                    <Td w="40%">
                      <Text fontSize="sm" maxWidth="90%" isTruncated>{source.description}</Text>
                    </Td>

                    <Td>
                      <PermissionComponent
                        spiCode="WAYS_TO_PAYMENTS"
                        ipCode="EDIT"
                      >
                        <Link
                          to={{
                            pathname: `/financial/payment-sources/update/${source.id}`,
                            state: source,
                          }}
                        >
                          <Tooltip
                            label="Editar"
                            aria-label="editar"
                            hasArrow
                            placement="top-start"
                          >
                            <Box ml="1rem" w="24px">
                              <FiEdit
                                color="#9699B0"
                                size={16}
                                style={{ cursor: 'pointer' }}
                              />
                            </Box>
                          </Tooltip>
                        </Link>
                      </PermissionComponent>
                    </Td>
                  </Tr>
                ))}
              </>
            )}
          </Tbody>
        </Table>
        {(getFinancialData.data?.totalCountOfPage === 0 && !getFinancialData.isLoading) && <NotFound />}
      </Box>
      {
      !(getFinancialData.data?.totalCountOfPage === 0 && !getFinancialData.isLoading) && (
          <Pagination
            totalCountOfRegisters={getFinancialData.data?.totalCountOfPage || 0}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
          />
        )
      }
    </LayoutList>
  );
}
