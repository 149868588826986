/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useEffect, useState, useCallback } from 'react'
import Select, { OptionTypeBase, Props as SelectProps } from 'react-select'
import { Text } from '@chakra-ui/react'

import countries from '../../../utils/json/countriesTwo.json'

import { Container, customStyles } from './styles'

interface Props extends SelectProps<OptionTypeBase> {
  title?: string
  defaultValueSelect?: {
    value?: string
    label?: string
    name?: string
    code?: string
  }
  name: string
  placeholder?: string
  options?: any
  medium?: boolean
  selectedCountry: (e: any) => void
  defaultDDI?: any
  handleModifiers?: any
}

export const FlagCountries = ({
  name,
  defaultValueSelect,
  title,
  placeholder,
  medium = false,
  selectedCountry,
  options,
  defaultDDI,
  handleModifiers,
  ...rest
}: Props) => {
  const [isFocused, setIsFocused] = useState(false)
  const [value, setValue] = useState<any>({} as any)
  const handleFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleBlur = useCallback(() => {
    setIsFocused(false)
  }, [])

  let optionsFlag: any = []
  countries.map((country) => {
    optionsFlag = [
      ...optionsFlag,
      {
        value: country.code.toLowerCase(),
        label: (
          <div
            style={{
              width: 34,
              height: 34,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <img src={`/flags/${country.code.toLowerCase()}.svg`} alt="" />
          </div>
        ),
        name: country.name
      }
    ]
  }, [])

  useEffect(() => {
    if (defaultDDI) {
      const defaultVa = optionsFlag.map((flag: any) => {
        return flag.name === defaultDDI && flag
      })

      setValue(defaultVa)
    } else {
      setValue(optionsFlag.filter((option: any) => option.name === 'Brasil'))
    }
  }, [defaultDDI])

  return (
    <Container isFocused={isFocused} medium={medium}>
      {!!title && (
        <Text mb="8px" fontSize="14px">
          {title}
        </Text>
      )}
      <Select
        onFocus={handleFocus}
        onBlur={handleBlur}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          setValue(e)
          selectedCountry(e)
          handleModifiers?.()
        }}
        classNamePrefix="react-select"
        options={optionsFlag}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: ' #3182CE'
          }
        })}
        styles={customStyles}
        {...rest}
      />
    </Container>
  )
}
