/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  CloseButton,
  Flex,
  Stack,
  Text,
  Textarea,
  useBreakpointValue
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { v4 as uuidV4 } from 'uuid'

import { SubmitHandler, useForm } from 'react-hook-form'

import { useTranslation } from 'react-i18next'

import { Input } from '../../components/Form/Input'
import { CardContainer } from '../../components/molecules/CardContainer'

import { ArrowBack } from '../../components/atoms/arrowBack'
import { Header } from '../../components/Header'
import { Sidebar } from '../../components/Sidebar'

import { SelectComponent } from '../../components/Form/SelectComponent'
import { Switch } from '../../components/Form/Switch'
import { TextFieldControlled } from '../../components/Form/TextFieldControlled'
import { ModalConfirmExclude } from '../../components/Modal/confirmExclude'
import { useAttendance } from './service'

type FormData = {
  isActive: boolean
  observation: string
}

type Transference = {
  id: string | number
  name: string
  isActive: boolean
  users: Array<{
    value: number
    label: string
  }>
  isTax: boolean
  isRate: boolean
  isInput: boolean
  isTransferInSight: boolean
  otherDeductions: string
  transferencePercent: string
}

export function CreateTagsServices() {
  const [t] = useTranslation('pageTagsServices')
  const attendanceService = useAttendance()
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const { register, handleSubmit, formState } = useForm<FormData>({})
  const { errors } = formState

  const [name, setName] = useState('')

  // transference
  const [transference, setTransference] = useState<Transference[]>([])
  const [indexExclude, setIndexExclude] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const handleKeyUpEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const values = event.target.value.toUpperCase()
    setName(values)
  }

  const handleCreateTagService: SubmitHandler<FormData> = async (values) => {
    await new Promise((resolve) => setTimeout(resolve, 2000))
    const { isActive, observation } = values

    attendanceService.createAttendance({
      name,
      is_active: isActive,
      observation
    })
  }

  const handleAddNewTransference = () => {
    const oldTransfer = [...transference]

    setTransference([
      ...oldTransfer,
      {
        id: uuidV4(),
        isActive: true,
        isInput: false,
        isRate: false,
        isTax: false,
        name: '',
        users: [],
        isTransferInSight: true,
        otherDeductions: '',
        transferencePercent: ''
      }
    ])
  }

  const handleRemoveNewTransference = (index: number) => {
    const allTransferer = [...transference]
    allTransferer.splice(index, 1)
    setTransference(allTransferer)
  }

  return (
    <Box overflow="hidden" position="relative">
      <Header>
        <Flex
          mx="auto"
          w="100%"
          direction="row"
          d="flex"
          my="6"
          align="center"
          justify="space-around"
        >
          <Box mx="auto">
            <Text fontSize="24px" fontWeight="600" height="100%">
              {t('create.title')}
            </Text>
          </Box>
          <Stack direction="row" align="center" justify="center">
            <ArrowBack href="/services/tags">
              <Text fontSize="md" fontWeight="bold">
                Voltar
              </Text>
            </ArrowBack>
          </Stack>
        </Flex>
      </Header>
      <Sidebar />
      <Flex
        flex="1"
        mx="auto"
        as="form"
        ml={isWideVersion ? '75px' : '2'}
        mt="15px"
        mb="8px"
        mr="2"
        bg="white"
        h="cal(100vh - 60px)"
        onSubmit={handleSubmit(handleCreateTagService)}
      >
        <Stack spacing="4" w="100%">
          <CardContainer title={t('create.container1.title')}>
            <Stack direction="row" my="2" spacing="6">
              <Box w="40%">
                <Input
                  label={t('create.container1.i1')}
                  name="name"
                  value={name}
                  onChange={handleKeyUpEvent}
                  isRequired
                  maxLength={10}
                />
              </Box>
              <Flex w="35%">
                <Box ml="auto" mr="6">
                  <Switch
                    defaultChecked
                    label={t('create.container1.s1')}
                    {...register('isActive')}
                  />
                </Box>
              </Flex>
            </Stack>
            <Text fontSize="sm" my="2">
              {t('label.observation')}
            </Text>
            <Textarea
              {...register('observation')}
              name="observation"
              placeholder={t('create.container1.placeholder')}
            />
          </CardContainer>

          <CardContainer title={t('create.container2.title')}>
            <Stack direction="column" my="2" spacing="6">
              {transference?.map((trans, index) => (
                <Stack
                  w="100%"
                  key={trans.id}
                  direction="column"
                  borderRadius="16"
                  position="relative"
                  p="4"
                  border="1px solid gray"
                >
                  <CloseButton
                    position="absolute"
                    top="2"
                    right="4"
                    _hover={{ bg: 'red.500', color: 'white' }}
                    onClick={() => {
                      setIsOpen(true)
                      setIndexExclude(index)
                    }}
                  />
                  <Stack w="100%" direction="row">
                    <Box w="400px">
                      <Input
                        name="nameOfRule"
                        label={t('create.container2.i1')}
                      />
                    </Box>
                    <Box>
                      <Switch
                        name="isActive"
                        label={t('create.container2.i2')}
                      />
                    </Box>
                    <Box flex="1">
                      <SelectComponent
                        name="users"
                        options={attendanceService.users?.map((user) => {
                          return {
                            value: user?.id,
                            label: user?.name
                          }
                        })}
                        isMulti
                        placeholder="Selecionar usuários"
                        title={t('create.container2.i3')}
                      />
                    </Box>
                  </Stack>
                  <Stack w="100%" direction="row">
                    <Box>
                      <Switch
                        name="isActive"
                        label={t('create.container2.i4')}
                      />
                    </Box>
                    <Box>
                      <Switch
                        name="isActive"
                        label={t('create.container2.i5')}
                      />
                    </Box>
                    <Box>
                      <Switch
                        name="isActive"
                        label={t('create.container2.i6')}
                      />
                    </Box>
                    <Box>
                      <Switch
                        name="isActive"
                        label={t('create.container2.i9')}
                      />
                    </Box>
                    <Box w="320px">
                      <TextFieldControlled
                        name="isPercentVariant"
                        mask="porcentagem"
                        label={t('create.container2.i7')}
                      />
                    </Box>
                    <Box w="320px">
                      <TextFieldControlled
                        name="isPercentResource"
                        mask="porcentagem"
                        label={t('create.container2.i8')}
                      />
                    </Box>
                  </Stack>
                </Stack>
              ))}
            </Stack>
            <Flex w="100%" justifyContent="flex-start">
              <Button
                bg="blue.300"
                onClick={() => handleAddNewTransference()}
                size="sm"
                color="white"
              >
                Adicionar novo repasse
              </Button>
            </Flex>
          </CardContainer>

          <Flex mt="6" w="100%" align="flex-end" justifyContent="flex-end">
            <Button
              type="submit"
              size="md"
              fontSize="md"
              bg="blue.300"
              color="#fff"
              isLoading={formState.isSubmitting}
            >
              {t('create.button')}
            </Button>
          </Flex>
          <Flex w="100%" h="300px" />
        </Stack>
      </Flex>
      <ModalConfirmExclude
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onConfirmExclude={() => {
          handleRemoveNewTransference(indexExclude)
          setIsOpen(false)
        }}
        title="Confirma exclusão de repasse"
      >
        <Text>Gostaria realmente de excluir esta regra?</Text>
      </ModalConfirmExclude>
    </Box>
  )
}
