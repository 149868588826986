/* eslint-disable dot-notation */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import { useToast } from '@chakra-ui/react'
import i18n from 'i18next'
import jwt_decode from 'jwt-decode'
import ls from 'localstorage-slim'
import { useSubscription } from 'mqtt-react-hooks'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { useHistory } from 'react-router-dom'
import { api } from '../services/api'
import { queryClient } from '../services/reactQuery'

type Currency = 'BRL' | 'USD' | 'EUR'

interface AuthState {
  token: string
}

interface SignInCredentials {
  email: string
  password: string
}

type PermissionsData = Array<{
  id: number
  system_group_id: number
  system_permission_item: {
    id: number
    code: string
  }
  item_permission: {
    id: number
    code: string
  }
}>

interface AuthContextData {
  user: object
  decodeToken: DecodeTokenData
  clinicId: string
  isSpecialist: boolean
  signIn(credentials: SignInCredentials): Promise<void>
  signOut(): void
  selectedLanguageDefault(language: string): void
  permissions: PermissionsData
  permissionFunction: (
    spiCode?: string,
    ipCode?: string,
    ignore?: boolean,
    group?: Array<string>
  ) => boolean
  currency: string
}

interface DecodeTokenData {
  exp: number
  jti: string
  token_type: string
  user_id: number
  image_url?: string
  clinic_id: number
  clinic: {
    patient_birth_date: boolean
    patient_document: boolean
    patient_origin: boolean
    timezone: string
    currency: string
    language: string
    adding_itens_to_attendance: boolean
    clinic_contacts: Array<{
      id: number
      contact: string
      ddi_country: string
      is_main: boolean
      type_of_contact: string
    }>
  }
  user_profile_id: number
  clinic_language: string
  social_name: string
  user_color?: string
  clinic_country: string
  name: string
  email: string
  is_superuser?: boolean
}

interface SpecialistAccess {
  specialist: {
    id: number
    social_name: string
  }
  agenda_permission: number
  medical_record_permission: number
  payment_checkout: boolean
  check_paid: number
  check_received: number
  control_fiscal_permission: number
}

type GetSpecialistsAccess = Array<SpecialistAccess>

export const SignOutAuth = () => {
  ls.remove('@Clinik:token')
  ls.remove('@Clinik:refreshToken')
  ls.remove('@Clinik:ID')
  ls.remove('@Clinik:Currency')
  ls.clear()
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData)

// ttl expire Date ls
export function expireDateNowAdd8hours() {
  const ttl = 28800 // seconds
  return ttl
}

export const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory()
  const toast = useToast()
  const token: string | null = ls.get('@Clinik:token')
  const [permissions, setPermissions] = useState<PermissionsData>([])

  /*
    Caso necessário buscar o acesso aos especialista
    pra fazer lógica de permissão
  */

  // const [accessSpecialists, setAccessSpecialists] =
  //   useState<GetSpecialistsAccess>([])

  // useEffect(() => {
  //   if (token) {
  //     const infoToken: DecodeTokenData = jwt_decode(token)

  //     api
  //       .get<GetSpecialistsAccess>(
  //         `users/${infoToken?.user_id}/specialist-accesses/`
  //       )
  //       .then((response) => {
  //         setAccessSpecialists(response.data)
  //       })
  //   }

  //   return () => {}
  // }, [])

  // useEffect loading permissions in refresh
  useEffect(() => {
    if (token) {
      const infoToken: DecodeTokenData = jwt_decode(token)

      api
        .get<PermissionsData>(`users/${infoToken?.user_id}/permissions/`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((response) => {
          setPermissions(response.data)
        })
        .catch((error: any) => {
          console.log(error)
        })
    }
  }, [])

  const [data, setData] = useState<AuthState | any>(() => {
    if (token) {
      return { token }
    }

    return {} as AuthState
  })
  const [count, setCount] = useState(0)
  const [decodeToken, setDecodeToken] = useState<DecodeTokenData | any>(() => {
    if (token) {
      const infoTokenDecode: DecodeTokenData = jwt_decode(token)

      return infoTokenDecode
    }

    return {} as DecodeTokenData
  })
  const [clinicId, setClinicId] = useState(() => {
    const id = ls.get<string>('@Clinik:ID', {
      decrypt: true
    })

    if (id) {
      return id
    }

    return ''
  })
  const [currency, setCurrency] = useState(() => {
    const currencyLs = ls.get<Currency>('@Clinik:Currency', {
      decrypt: true
    })

    if (currencyLs) {
      return currencyLs
    }

    return ''
  })
  const [userHash, setUserHash] = useState(() => {
    const userHashLs = ls.get<Currency>('@Clinik:userHash', {
      decrypt: true
    })

    if (userHashLs) {
      return userHashLs
    }

    return ''
  })
  const [isSpecialist, setIsSpecialist] = useState(() => {
    const userIsSpecialist = ls.get<'1' | '0'>('@Clinik:isSpecialist')

    if (userIsSpecialist === '1') {
      return true
    }

    return false
  })
  // websocket mqtt listen
  const generateUserHash = () => {
    if (userHash === '') {
      return []
    }

    return userHash
  }

  const { message: clientMessage } = useSubscription(generateUserHash())

  const setLocalstorageNewSelecetedLanguage = useCallback(
    (languagem: string) => {
      const ttl = expireDateNowAdd8hours()
      ls.set('@CLINIC:LANGUAGE_DEFAULT', languagem, {
        ttl
      })
    },
    []
  )
  const GetClinicIdLocalStorage = useCallback(() => {
    const id = ls.get<string>('@Clinik:ID', { decrypt: true })
    setClinicId(id || '')
  }, [])

  const selectedLanguageDefault = useCallback((language: string) => {
    const ttl = expireDateNowAdd8hours()
    if (language === 'en') {
      ls.set('i18nextLng', 'en', { ttl })
      setLocalstorageNewSelecetedLanguage('en')
      i18n.changeLanguage('en')
    } else if (language === 'es') {
      ls.set('i18nextLng', 'es', { ttl })
      setLocalstorageNewSelecetedLanguage('es')
      i18n.changeLanguage('es')
    } else if (language === 'pt') {
      ls.set('i18nextLng', 'pt', { ttl })
      setLocalstorageNewSelecetedLanguage('pt')
      i18n.changeLanguage('pt')
    }
  }, [])

  const checkConfictTokenLanguageAngDefaultLanguage = useCallback(() => {
    const getDefaultLanguage: string | null = ls.get('@CLINIC:LANGUAGE_DEFAULT')

    if (!!getDefaultLanguage) {
      // usar a lingua escolhida ainda na seção (pq no token esta a lingua antiga)
      if (getDefaultLanguage !== decodeToken.clinic_language) {
        selectedLanguageDefault(getDefaultLanguage)
      } else {
        selectedLanguageDefault(decodeToken.clinic_language)
      }
    }
  }, [])

  const permissionFunction = (
    spiCode?: string,
    ipCode?: string,
    ignore?: boolean,
    group?: Array<string>
  ) => {
    let isPermission = false
    let arrayGroup: Array<string> = []

    // item Component
    if (decodeToken?.is_superuser) {
      isPermission = true
    }

    if (ignore === true) {
      isPermission = true
    }

    permissions?.forEach((item) => {
      arrayGroup = [...arrayGroup, item.system_permission_item.code]
      if (item?.system_permission_item?.code === spiCode) {
        if (item?.item_permission?.code === ipCode) {
          isPermission = true
        }
      }
    })

    group !== undefined &&
      arrayGroup?.forEach((codename) => {
        group?.find((code) => {
          if (code === codename) {
            isPermission = true
          }

          return null
        })
      })

    return isPermission
  }

  const signIn = useCallback(async ({ email, password }: SignInCredentials) => {
    const ttl = expireDateNowAdd8hours()
    const response = await api.post('token/', { email, password })

    api.defaults.headers['Authorization'] = `Bearer ${response?.data?.access}`

    const { access, refresh } = response.data
    // armazenando token
    ls.set('@Clinik:token', access, { ttl })
    // armazenando refresh
    ls.set('@Clinik:refreshToken', refresh, {
      ttl
    })
    // armazenando a clinic_id
    const decode: any = jwt_decode(access)
    const id = decode?.clinic_id
    const userId = decode?.user_id
    const currencyLs = decode?.clinic?.currency
    const idHashUser = decode?.user_profile_id

    const { data: userData } = await api.get(`users/${idHashUser}/`)

    setUserHash(`/logout-user/${userData?.hash}/`)

    ls.set('@Clinik:userHash', `/logout-user/${userData?.hash}/`)

    ls.set(
      '@Clinik:isSpecialist',
      userData?.user?.isSpecialist === true ? '1' : '0',
      {
        ttl
      }
    )

    if (userData?.user?.is_specialist === true) {
      setIsSpecialist(true)
    } else {
      setIsSpecialist(false)
    }

    setCurrency(currencyLs)

    if (currencyLs) {
      ls.set<string>('@Clinik:Currency', currencyLs, {
        ttl,
        encrypt: true
      })
    }

    if (userId) {
      const { data: allPermissions } = await api.get<PermissionsData>(
        `users/${userId}/permissions/`,
        {
          headers: {
            Authorization: `Bearer ${access}`
          }
        }
      )

      setPermissions(allPermissions)
    }

    ls.set<string>('@Clinik:ID', id, {
      ttl,
      encrypt: true
    })

    // linguagem default
    const decodeTokenAux: any = jwt_decode(access)
    if (decodeTokenAux['clinic_language'] !== null) {
      selectedLanguageDefault(decodeTokenAux.clinic_language)
    }

    setData({ token: access })
    setDecodeToken(jwt_decode(access))
    setClinicId(id)
  }, [])

  const signOut = useCallback(() => {
    // remove token default headers
    api.defaults.headers['Authorization'] = undefined

    ls.remove('@Clinik:token')
    ls.remove('@Clinik:refreshToken')
    ls.remove('@CLINIC:LANGUAGE_DEFAULT')
    ls.remove('@Clinik:ID')
    ls.remove('@Clinik:Currency')
    ls.clear()
    localStorage.clear()
    selectedLanguageDefault('pt')
    // remove filter context
    setIsSpecialist(false)
    // remove filter context
    setData({})
    setDecodeToken({})
    queryClient.clear()
    history.push('/')
  }, [])

  useEffect(() => {
    if (clientMessage !== undefined) {
      signOut()
      toast({
        title: 'Permissão atualizada!',
        description: `Fazer login novamente.`,
        isClosable: true,
        duration: 3000,
        position: 'top'
      })
    }

    return () => {}
  }, [clientMessage])

  useEffect(() => {
    if (data.token) {
      try {
        setDecodeToken(jwt_decode(data.token))

        const decodeTokenAux: any = jwt_decode(data.token)

        if (decodeTokenAux['clinic_language'] !== null) {
          checkConfictTokenLanguageAngDefaultLanguage()
        }
      } catch (error: any) {
        signOut()
        toast({
          title: 'Faça novamente o Login!',
          description:
            'Sua conta foi logada em outro local de trabalho, caso aconteça novamente sem autorização devida entre em contato com o suporte',
          duration: 4000,
          position: 'top',
          status: 'warning'
        })
      }
    }

    if (clinicId) {
      GetClinicIdLocalStorage()
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{
        user: data.token,
        decodeToken,
        clinicId,
        isSpecialist,
        signIn,
        signOut,
        selectedLanguageDefault,
        permissions,
        permissionFunction,
        currency
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext)

  return context
}
