/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Tooltip,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Text,
  Skeleton,
  Button
} from '@chakra-ui/react'
import { FiTrash, FiEdit } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDnd } from '../../../hooks/useDnd'
import {
  useAttendanceSpot,
  getAttendanceSpot,
  AttendanceSpot
} from '../../../hooks/attendanceSpot/useAttendanceSpot'
import { useAuth } from '../../../hooks/auth'
import { LayoutList } from '../../../layouts/List'
import { NotFound } from '../../../components/molecules/NotFound'
import { Pagination } from '../../../components/PaginationNew'
import { Board } from '../../../components/Board'
import { apiAuth } from '../../../services/apiAuth'
import { PermissionComponent } from '../../../components/Permissions'

// debounce
let timer: any = null

export function Attendance() {
  const { clinicId } = useAuth()
  const [t] = useTranslation('pageAttendance')
  const { isOrdering, setList, list, setUrlUpdate, updateIndex } = useDnd()
  const [searchState, setSearchState] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [isActive, setIsActive] = useState(true)
  const skeletonRepeat = [1, 2, 3, 4, 5, 6, 7]

  const getAttendance = useAttendanceSpot(
    currentPage,
    getAttendanceSpot(String(clinicId), currentPage, searchState, isActive),
    searchState,
    isActive
  )

  const SearchInputCallbackValue = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget

      clearTimeout(timer)

      timer = setTimeout(() => {
        setCurrentPage(1)
        setSearchState(value)
      }, 500)
    },
    []
  )

  const ActiveCheckboxCallbackValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsActive(event.target.checked)
    },
    []
  )

  useEffect(() => {
    apiAuth
      .get(`clinics/${clinicId}/attendance-spots/`, {
        params: {
          is_active: true
        }
      })
      .then((response) => {
        setList(response.data.results)
        setUrlUpdate('clinics/attendance-spots/change-indexes/')
      })
      .catch((error: any) => {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    getAttendance.refetch()
  }, [isOrdering])

  return (
    <LayoutList
      title={t('title')}
      urlNew="/settings/attendance/create"
      isHaveOrdering
      refetch={getAttendance.refetch}
      isFetching={getAttendance.isFetching}
      placeholder={t('search')}
      capturaChange={(event: any) => SearchInputCallbackValue(event)}
      captureActiveChange={(event: any) => ActiveCheckboxCallbackValue(event)}
      spiCode="ATTENDANCES_SPOTS"
    >
      {!isOrdering ? (
        <>
          <Box>
            <Table variant="striped" colorScheme="blue" size="sm">
              <Thead>
                <Tr>
                  <Th>{t('tLocal')}</Th>

                  <Th>{t('tIsDefault')}</Th>

                  <Th>{t('tDescription')}</Th>

                  <Th>{t('TAcoes')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {getAttendance.isLoading ? (
                  <>
                    {skeletonRepeat.map((skeleton) => (
                      <Tr>
                        <Td>
                          <Skeleton height="20px" />
                        </Td>
                        <Td>
                          <Skeleton height="20px" />
                        </Td>
                        <Td>
                          <Skeleton height="20px" />
                        </Td>
                        <Td>
                          <Skeleton height="20px" />
                        </Td>
                        <Td>
                          <Skeleton height="20px" />
                        </Td>
                      </Tr>
                    ))}
                  </>
                ) : (
                  <>
                    {getAttendance.data?.spot.map(
                      (spot: AttendanceSpot, index: number) => (
                        <Tr key={spot.index} maxHeight="40px">
                          <Td>
                            <Text fontSize="sm">{spot.name}</Text>
                          </Td>

                          <Td>{index + 1}</Td>

                          <Td>
                            <Text fontSize="sm">{spot.description}</Text>
                          </Td>

                          <Td>
                            <PermissionComponent
                              spiCode="ATTENDANCES_SPOTS"
                              ipCode="EDIT"
                            >
                              <Link
                                to={{
                                  pathname: `/settings/attendance/update/${spot.id}`,
                                  state: spot
                                }}
                              >
                                <Tooltip
                                  label="Editar"
                                  aria-label="editar"
                                  hasArrow
                                  placement="top-start"
                                >
                                  <Box ml="1rem" w="24px">
                                    <FiEdit
                                      color="#9699B0"
                                      size={16}
                                      style={{ cursor: 'pointer' }}
                                    />
                                  </Box>
                                </Tooltip>
                              </Link>
                            </PermissionComponent>
                          </Td>
                        </Tr>
                      )
                    )}
                  </>
                )}
              </Tbody>
            </Table>
            {getAttendance.data?.totalCountOfPage === 0 &&
              !getAttendance.isLoading && <NotFound />}
          </Box>
          {!(
            getAttendance.data?.totalCountOfPage === 0 &&
            !getAttendance.isLoading
          ) && (
            <Pagination
              totalCountOfRegisters={getAttendance.data?.totalCountOfPage || 0}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
            />
          )}
        </>
      ) : (
        <>
          <Board title={t('title')} content={list || undefined} />
          <Box w="100%" justifyContent="flex-end" alignItems="flex-end">
            <Button
              colorScheme="blue"
              my="2"
              size="md"
              type="button"
              onClick={updateIndex}
            >
              {t('create.button')}
            </Button>
          </Box>
        </>
      )}
    </LayoutList>
  )
}
