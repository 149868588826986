/* eslint-disable camelcase */
import { useQuery } from 'react-query'
import { apiAuth } from '../../services/apiAuth'

export type OriginsData = {
  id: string
  name: string
}

export type ResponseGetOrigins = {
  origins: OriginsData[]
  totalCountOfPage: number
}

export const getOrigins = async (
  clinicsId: string = '',
  page: number,
  search: string = '',
  isActive: boolean = true
): Promise<ResponseGetOrigins> => {
  try {
    const { data } = await apiAuth.get(`clinics/${clinicsId}/origins/`, {
      params: {
        page,
        name__icontains: search,
        is_active: isActive
      }
    })

    const totalCountOfPage = data.count
    const origins: OriginsData[] = data.results

    return {
      origins,
      totalCountOfPage
    }
  } catch (error: any) {
    console.log(error)

    const origins: OriginsData[] = []
    const totalCountOfPage = 0
    return {
      origins,
      totalCountOfPage
    }
  }
}

export function useOrigins(
  page: number,
  functionLoading: Promise<ResponseGetOrigins>,
  search: string,
  isActive: boolean,
  options?: {}
) {
  return useQuery(['origins', page, search, isActive], () => functionLoading, {
    staleTime: 1000 * 2, // 2 seconds
    cacheTime: 1000 * 2, // 2 seconds
    ...options
  })
}
