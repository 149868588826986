/* eslint-disable camelcase */
import { useQuery } from 'react-query'
import { apiAuth } from '../../services/apiAuth'

export type CovenantsData = {
  id: string
  name: string
}

export type ResponseGetCovenants = {
  covenants: CovenantsData[]
  totalCountOfPage: number
}

export const getCovenants = async (
  clinicsId: string = '',
  page: number,
  search: string = '',
  isActive: boolean = true
): Promise<ResponseGetCovenants> => {
  try {
    const { data } = await apiAuth.get(
      `clinics/${clinicsId}/health-insurances/`,
      {
        params: {
          page,
          name__icontains: search,
          is_active: isActive
        }
      }
    )

    const totalCountOfPage = data.count
    const covenants: CovenantsData[] = data.results

    return {
      covenants,
      totalCountOfPage
    }
  } catch (error: any) {
    console.log(error)

    const covenants: CovenantsData[] = []
    const totalCountOfPage = 0
    return {
      covenants,
      totalCountOfPage
    }
  }
}

export function useCovenants(
  page: number,
  functionLoading: Promise<ResponseGetCovenants>,
  search: string,
  isActive: boolean,
  options?: {}
) {
  return useQuery(
    ['covenants', page, search, isActive],
    () => functionLoading,
    {
      staleTime: 1000 * 2, // 2 seconds
      cacheTime: 1000 * 2, // 2 seconds
      ...options
    }
  )
}
