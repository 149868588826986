import { ChakraProvider } from '@chakra-ui/react'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { Connector } from 'mqtt-react-hooks'
import { I18nextProvider } from 'react-i18next'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter } from 'react-router-dom'
import { Routes } from './routes'

import { DndContextProvider } from './contexts/DropAndDragContext'
import { SideBarDrawerProvider } from './contexts/SidebarDrawerContext'
import { AuthProvider } from './hooks/auth'
import StylesGlobal from './styles'
import { theme } from './styles/theme'
import { messagesGlobal } from './utils/i18n/resourcesMsg'

import { DocumentProvider } from './contexts/DocumentContext'
import { ModalFiltersProvider } from './contexts/ModalContext'
import { ReactSchedulingProvider } from './contexts/ReactScheduling/store'
import { queryClient } from './services/reactQuery'

// config i18n
i18n.use(LanguageDetector).init({
  debug: true,
  supportedLngs: ['pt', 'es', 'en'],
  fallbackLng: 'pt',
  resources: messagesGlobal,
  detection: {
    order: ['localStorage', 'cookie']
  },
  interpolation: {
    escapeValue: false
  }
})

type OptionsConfiguration = {
  port?: number // port is made into a number subsequently
  host?: string // host does NOT include port
  hostname?: string
  password?: string
  username?: string
  path?: string
  protocol?: 'wss' | 'ws' | 'mqtt' | 'mqtts' | 'tcp' | 'ssl' | 'wx' | 'wxs'
}

const mqttOptions: OptionsConfiguration = {
  password: 'Oe44qR7Ji^mh',
  username: 'mqtt',
  protocol: 'wss',
  host: 'api-hom.clinik.net',
  port: 8884
}

function App() {
  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <ChakraProvider theme={theme}>
          <Connector
            brokerUrl="wss://api-hom.clinik.net:8884"
            options={mqttOptions}
          >
            <AuthProvider>
              <QueryClientProvider contextSharing client={queryClient}>
                <DndContextProvider>
                  <SideBarDrawerProvider>
                    <ModalFiltersProvider>
                      <DocumentProvider>
                        <ReactSchedulingProvider>
                          <Routes />
                          <StylesGlobal />
                          <ReactQueryDevtools />
                        </ReactSchedulingProvider>
                      </DocumentProvider>
                    </ModalFiltersProvider>
                  </SideBarDrawerProvider>
                </DndContextProvider>
              </QueryClientProvider>
            </AuthProvider>
          </Connector>
        </ChakraProvider>
      </I18nextProvider>
    </BrowserRouter>
  )
}

export default App
