import { Stack, Box, Text, Divider, HStack } from '@chakra-ui/react'
import { PaginationItem } from './PaginationItem'

interface PaginationProps {
  totalCountOfRegisters: number
  registersPerPage?: number
  currentPage?: number
  onPageChange: (page: number) => void
}

const siblingsCount = 1

export function Pagination({
  totalCountOfRegisters = 0,
  currentPage = 1,
  onPageChange,
  registersPerPage = 100
}: PaginationProps) {
  function generatePagesArray(from: number, to: number) {
    return [...new Array(to - from)]
      .map((_, index) => {
        return from + index + 1
      })
      .filter((page) => page > 0)
  }

  const lastPage = Math.ceil(totalCountOfRegisters / registersPerPage)
  const rest = totalCountOfRegisters - (lastPage - 1) * registersPerPage

  const previousPages =
    currentPage > 1
      ? generatePagesArray(currentPage - 1 - siblingsCount, currentPage - 1)
      : []

  const nextPages =
    currentPage < lastPage
      ? generatePagesArray(
          currentPage,
          Math.min(currentPage + siblingsCount, lastPage)
        )
      : []

  return (
    <Stack
      direction={['column', 'row']}
      spacing="6"
      mt="8"
      justify="space-between"
      align="center"
    >
      <Box>
        {totalCountOfRegisters > 0 ? (
          <HStack px="4">
            <Text fontWeight="hairline" fontSize="14px">
              página {currentPage} de {lastPage}
            </Text>
            <Text fontWeight="hairline" fontSize="14px">
              | Total de registros: {totalCountOfRegisters}
            </Text>
          </HStack>
        ) : (
          <Text>-- / --</Text>
        )}
      </Box>
      <Stack direction="row" spacing="2">
        {currentPage > 1 + siblingsCount && (
          <>
            <PaginationItem onPageChange={onPageChange} numberPage={1} />
            {currentPage > 2 + siblingsCount && (
              <Text color="gray.300" width="8" textAlign="center">
                ...
              </Text>
            )}
          </>
        )}

        {previousPages.length > 0 &&
          previousPages.map((page) => {
            return (
              <PaginationItem
                onPageChange={onPageChange}
                key={page}
                numberPage={page}
              />
            )
          })}

        <PaginationItem
          onPageChange={onPageChange}
          isCurrent
          numberPage={currentPage}
        />

        {nextPages.length > 0 &&
          nextPages.map((page) => {
            return (
              <PaginationItem
                onPageChange={onPageChange}
                key={page}
                numberPage={page}
              />
            )
          })}

        {currentPage + siblingsCount < lastPage && (
          <>
            {currentPage + 1 + siblingsCount < lastPage && (
              <Text color="gray.300" width="8" textAlign="center">
                ...
              </Text>
            )}
            <PaginationItem onPageChange={onPageChange} numberPage={lastPage} />
          </>
        )}
      </Stack>
    </Stack>
  )
}
