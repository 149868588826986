/* eslint-disable camelcase */
import { useQuery } from 'react-query'
import { apiAuth } from '../../services/apiAuth'

export type TicketsCanceledData = {
  id: number
  status_payment: number
  ticket_number: string
  attendances: Array<{
    id: number
    guide: number
    specialist_name: string
    patient_name: string
  }>
  discount: number
  addition: number
  observation_financial: string
  observation_secretary: string
  total_value: number
  paid_value: number
  result_status: null | number
  generate_invoice: null | number
  created_at: string
  total_value_invoice: number | null
  items: [
    {
      id: number
      way_to_receive: {
        id: number
        name: string
        day_to_receive: number
        observation: string
        is_active: true
        way_to_receive_parcels: Array<{
          id: number
          parcel_number: number
          tax_percentage: number
          tax_absolute: number
          interest_rate_percentage: number
          interest_rate_absolule: number
          created_at: string
          updated_at: string
          is_active: boolean
          created_by: string
          updated_by: string
          way_to_receive: number
        }>
        active_parcels: 12
      }
      event_date: string
      value: number
      number_parcels: number
      parcel_value: number
      observation: null | string
      status_conferred: null | number
      date_status_conferred: string | null
    }
  ]
}

export type ResponseGetTicketsCanceled = {
  ticketsCanceled: TicketsCanceledData[]
  totalCountOfPage: number
}

export const getTicketsCanceled = async (
  page: number,
  search: string = '',
  initialDate: string = '',
  finalDate: string = '',
  generateInvoice: string[] | number[]
): Promise<ResponseGetTicketsCanceled> => {
  try {
    const { data } = await apiAuth.get(`clinics/tickets/daily-canceled/`, {
      params: {
        page,
        keyword: search,
        startDate: initialDate,
        finishDate: finalDate,
        generate_invoice: generateInvoice?.join(',')
      }
    })

    const totalCountOfPage = data?.count || 0
    const ticketsCanceled: TicketsCanceledData[] = data?.results || []

    return {
      ticketsCanceled,
      totalCountOfPage
    }
  } catch (error: any) {
    console.log(error)

    const ticketsCanceled: TicketsCanceledData[] = []
    const totalCountOfPage = 0
    return {
      ticketsCanceled,
      totalCountOfPage
    }
  }
}

export function useTicketsCanceled(
  page: number,
  functionLoading: Promise<ResponseGetTicketsCanceled>,
  search: string = '',
  initialDate: string = '',
  finalDate: string = '',
  generateInvoice: string[] | number[],
  options?: {}
) {
  return useQuery(
    ['ticketsCanceled', page, search, initialDate, finalDate, generateInvoice],
    () => functionLoading,
    {
      staleTime: 1000 * 1, // 1 minute,
      cacheTime: 1000 * 60 * 15, // 15 minutes
      ...options
    }
  )
}
