/* eslint-disable no-debugger */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-lonely-if */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Text,
  Flex,
  Button,
  Stack,
  SimpleGrid,
  Divider,
  Textarea,
  useToast,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  useDisclosure,
  Link as LinkChakra,
  Tooltip,
  Icon,
  useBreakpointValue,
  Center,
  Spinner
} from '@chakra-ui/react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { useHistory, Link, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { format } from 'date-fns'

import { useTranslation } from 'react-i18next'
import { RiAttachmentLine } from 'react-icons/ri'
import { FiEdit, FiFile, FiUploadCloud } from 'react-icons/fi'
import { AiOutlineDollarCircle, AiOutlineInfoCircle } from 'react-icons/ai'
import { useUpdate } from '../../hooks/useUpdate'

import { CardContainer } from '../../components/molecules/CardContainer'
import { ModalConfirmExclude } from '../../components/Modal/confirmExclude'
import { Input } from '../../components/Form/Input'

import { ArrowBack } from '../../components/atoms/arrowBack'
import { Sidebar } from '../../components/Sidebar'
import { Header } from '../../components/Header'

import { useLogicPaymentMethods } from './logic'
import { RemoveBtnIcon } from '../../components/atoms/removeBtnIcon'
import { TextFieldControlled } from '../../components/Form/TextFieldControlled'
import { InputCurrency } from '../../components/Form/InputCurrency'
import { Select } from '../../components/Form/Select'
import { SelectComponent } from '../../components/Form/SelectComponent'
import Dropzone from './Dropzone'
import { PermissionComponent } from '../../components/Permissions'
import { useService } from './service'

import { queryClient } from '../../services/reactQuery'
import { remaskCaractersAll } from '../../utils/fns/removeCaracters'
import { useAuth } from '../../hooks/auth'
import { formatBrOrDollarDefault } from '../../utils/fns/formatBrOrDollarDefault'
import { ModalArrivedTime } from '../../components/Modal/confirmArrivedTime'
import { apiAuth } from '../../services/apiAuth'

type FormData = {
  observation: string
}

type PaymentMethodsData = {
  paymentDate: string
  amount: string
  paymentMethod: string
  balance: string
  isRequired: boolean
}

type SelectType = {
  value: string
  label: string
}

interface ParamsProps {
  id: string
}

export function paymentBill() {
  const [t] = useTranslation('pagePaymentBill')
  const { permissionFunction, currency, clinicId } = useAuth()
  const logic = useLogicPaymentMethods()
  const history = useHistory()
  const { id } = useParams<ParamsProps>()
  // const { state }: any = history.location
  const [state, setState] = useState<any>(null)
  const service = useService()
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { UpdatePosting } = useUpdate()
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const [redirect, setRedirect] = useState(false)
  const updatePayable = useMutation(
    async (payload: any) => {
      await UpdatePosting(payload, state?.id, redirect)
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries('billsToPay')
      },
      onSuccess: () => {
        queryClient.invalidateQueries('billsToPay')
      },
      onError: () => {
        // Invalidate Cache
        queryClient.invalidateQueries('billsToPay')

        // Message error
        toast({
          title: 'Cadastro não realizado',
          description: 'Ouve um erro de comunicação com o servidor',
          status: 'error',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })
      }
    }
  )
  const defaultOptionsCategory = [
    { value: '25', label: `${t('options.category.25')}` },
    { value: '26', label: `${t('options.category.26')}` },
    { value: '27', label: `${t('options.category.27')}` },
    { value: '28', label: `${t('options.category.28')}` },
    { value: '29', label: `${t('options.category.29')}` },
    { value: '30', label: `${t('options.category.30')}` },
    { value: '31', label: `${t('options.category.31')}` },
    { value: '32', label: `${t('options.category.32')}` },
    { value: '34', label: `${t('options.category.34')}` }
  ]
  const defaultOptionsStatus = [
    { value: 1, label: `${t('options.status.1')}` },
    { value: 2, label: `${t('options.status.2')}` },
    { value: 3, label: `${t('options.status.3')}` },
    { value: 4, label: `${t('options.status.4')}` }
  ]
  const optionsStatusNoInactive = [
    { value: 1, label: `${t('options.status.1')}` },
    { value: 2, label: `${t('options.status.2')}` },
    { value: 3, label: `${t('options.status.3')}` }
  ]
  const { register, handleSubmit, formState } = useForm<FormData>({})
  const [isFinalValue, setIsFinalValue] = useState(false)
  const [status, setStatus] = useState(0)
  const [payment_date, setPaymentDate] = useState(false)
  const [payment_date_format, setPaymentDateFormat] = useState<any>(null)
  const [indexDoc, setIndexDoc] = useState(0)
  const [isActive, setIsActive] = useState(true)
  // values supplier_posting and costOfCenter
  const [supPosting, setSupPosting] = useState<SelectType | null>(null)
  const [costPosting, setCostPosting] = useState<SelectType | null>(null)
  const [isInactive, setIsInactive] = useState(false)
  // edit name document
  const [editName, setEditName] = useState('')
  const [idEdit, setIdEdit] = useState(0)
  const [isOpenEdit, setIsOpenEdit] = useState(false)

  const { errors } = formState

  useEffect(() => {
    // format data for state value
    const formatData = (myBills: any) => {
      const newCompetenceString = remaskCaractersAll(myBills?.competence_date)
      const newDueDateString = remaskCaractersAll(myBills?.due_date)

      // date invalid for app
      const newPaymentDate = remaskCaractersAll(
        myBills?.payment_date || '11112000'
      )
      // logic Get Format Date for body
      const vc = newCompetenceString.split('')
      const dayVc = Number(`${0}${5}`)
      const monthVc = Number(`${vc[4]}${vc[5]}`) - 1
      const yearVc = Number(`${vc[0]}${vc[1]}${vc[2]}${vc[3]}`)
      const dateVc = new Date(yearVc, monthVc, dayVc)
      const FormatMonthVc = String(format(dateVc, 'MM/yyyy'))

      // logic Get Format Date for body
      const vd = newDueDateString.split('')
      const dayVd = Number(`${vd[6]}${vd[7]}`)
      const monthVd = Number(`${vd[4]}${vd[5]}`) - 1
      const yearVd = Number(`${vd[0]}${vd[1]}${vd[2]}${vd[3]}`)
      const dateVd = new Date(yearVd, monthVd, dayVd)
      const FormatMonthVd = String(format(dateVd, 'dd/MM/yyyy'))
      // logic Get Format Date for body format
      const vp = newPaymentDate.split('')
      const dayVp = Number(`${vp[6]}${vp[7]}`)
      const monthVp = Number(`${vp[4]}${vp[5]}`) - 1
      const yearVp = Number(`${vp[0]}${vp[1]}${vp[2]}${vp[3]}`)
      const dateVp = new Date(yearVp, monthVp, dayVp)
      const FormatMonthVp = String(format(dateVp, 'dd/MM/yyyy'))

      const posting_methods = myBills?.posting_methods.map(
        (posting_method: any) => {
          const newPaymentDateMethod = remaskCaractersAll(
            posting_method.payment_date || '11112000'
          )
          // logic Get Format Date for body format
          const vpm = newPaymentDateMethod.split('')
          const dayVpm = Number(`${vpm[6]}${vpm[7]}`)
          const monthVpm = Number(`${vpm[4]}${vpm[5]}`) - 1
          const yearVpm = Number(`${vpm[0]}${vpm[1]}${vpm[2]}${vpm[3]}`)
          const dateVpm = new Date(yearVpm, monthVpm, dayVpm)
          const FormatMonthVpm = String(format(dateVpm, 'yyyy-MM-dd'))

          return {
            paymentDate: FormatMonthVpm,
            amount: formatBrOrDollarDefault(posting_method.price || ''),
            paymentMethod: String(posting_method.payment_source),
            balance: formatBrOrDollarDefault(posting_method.debt || '')
          }
        }
      )

      const attachments = myBills?.attachments.map((attachment: any) => {
        return {
          id: String(attachment.id),
          document: {
            file: attachment.file,
            file_format: attachment.file_format,
            description: attachment.description,
            alt: attachment.alt,
            posting_document: attachment.posting_document
          }
        }
      })

      return {
        id: myBills?.id,
        name: myBills?.name,
        code: myBills?.code,
        price: formatBrOrDollarDefault(myBills?.price),
        nominal_price: formatBrOrDollarDefault(myBills?.nominal_price),
        observation: myBills?.observation,
        pos: String(
          `${myBills?.share_number} de ${myBills?.total_of_postings}`
        ),
        competence_date: FormatMonthVc,
        due_date: myBills?.due_date,
        due_date_view: FormatMonthVd,
        payment_date: FormatMonthVp,
        share_number: myBills?.share_number,
        total_of_postings: myBills?.total_of_postings,
        addition_price: formatBrOrDollarDefault(myBills?.addition_price || ''),
        discount_price: formatBrOrDollarDefault(myBills?.discount_price || ''),
        status: myBills?.status,
        posting_methods,
        category: myBills?.category,
        supplier_posting: myBills?.supplier_posting,
        cost_center: myBills?.cost_center,
        postings: myBills?.total_of_postings,
        posting_clinic: myBills?.posting_clinic,
        posting_group: myBills?.posting_group,
        attachments,
        created_at: myBills?.created_at,
        updated_at: myBills?.updated_at,
        created_by: myBills?.created_by,
        updated_by: myBills?.updated_by
      }
    }

    const getPosting = async () => {
      const { data } = await apiAuth.get(`clinics/postings/${id}/`)
      const dataState = formatData(data)

      setState(dataState)
    }
    getPosting()

    return () => {}
  }, [id])

  // verify permission inactive
  useEffect(() => {
    const permission = permissionFunction('BILLS_TO_PAY', 'INACTIVATE')

    setIsInactive(permission)
  }, [])

  useEffect(() => {
    if (state) {
      logic.setPrice(state?.nominal_price)
      logic.setNominalPrice(state?.nominal_price)
      setStatus(state?.status)

      setSupPosting({
        value: state?.supplier_posting?.id || '',
        label: state?.supplier_posting?.trading_name || ''
      })

      setCostPosting({
        value: state?.cost_center?.id || '',
        label: state?.cost_center?.name || ''
      })

      if (state?.posting_methods.length > 0) {
        logic.setPaymentMethod(state?.posting_methods)
      } else {
        logic.setPaymentMethod([
          {
            paymentDate: format(new Date(), 'yyyy-MM-dd'),
            amount: '',
            paymentMethod: '',
            balance: state?.nominal_price,
            isRequired: false
          }
        ])
      }

      if (state?.attachments?.length > 0) {
        logic.setDocuments(state?.attachments)
      } else {
        logic.setDocuments([{ id: 1, document: {} }])
      }

      if (state?.discount_price) {
        const valueDiscount = Number(remaskCaractersAll(state?.discount_price))

        if (valueDiscount > 0) {
          logic.setDiscountPrice(state?.discount_price)
        }
      }
      if (state?.addition_price) {
        const valueAdditionPrice = Number(
          remaskCaractersAll(state?.addition_price)
        )

        if (valueAdditionPrice > 0) {
          logic.setAdditionPrice(state?.addition_price)
        }
      }

      setIsFinalValue(true)

      if (isFinalValue === true) {
        logic.setPrice(state?.price)
      }
    }
  }, [isFinalValue, state])

  useEffect(() => {
    if (status === 4) {
      setIsActive(false)
      setPaymentDate(false)
    } else {
      setIsActive(true)
      if (status !== 1) {
        setPaymentDate(false)
        if (status === 2) {
          setPaymentDate(true)
        }
      } else {
        setPaymentDate(false)
      }
    }
  }, [status])

  useEffect(() => {
    // invalid Date app state if payment_date true and status 2 or 3
    if (state) {
      if (state?.payment_date !== '31/07/1112') {
        const newPaymentDate = remaskCaractersAll(state?.payment_date)
        // logic Get Format Date for body
        const vp = newPaymentDate?.split('')
        const dayVp = Number(`${vp[0]}${vp[1]}`)
        const monthVp = Number(`${vp[2]}${vp[3]}`) - 1
        const yearVp = Number(`${vp[4]}${vp[5]}${vp[6]}${vp[7]}`)
        const dateVp = new Date(yearVp, monthVp, dayVp)
        const FormatPaymentDate = String(format(dateVp, 'yyyy-MM-dd'))
        setPaymentDate(true)
        setPaymentDateFormat(FormatPaymentDate)
      } else {
        setPaymentDateFormat(null)
        setPaymentDate(false)
      }
    }
  }, [state])

  // handler change new name document
  const handlerUpdateNameDocument = async () => {
    try {
      const { data } = await apiAuth.post(
        `clinics/documents-posting/${idEdit}/rename/`,
        {
          new_name: editName || undefined
        }
      )

      if (data.id !== undefined) {
        toast({
          title: 'Alteração realizado com sucesso!',
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top-right'
        })

        const values = [...logic.documents]
        values[indexDoc].id = data.id
        values[indexDoc].document = data

        logic.setDocuments(values)
      }
    } catch (error: any) {
      toast({
        title: 'Não é possível atualizar',
        description:
          'Para poder atualizar um documento, acesse novamente esta área',
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top-right'
      })
    }
  }

  // balance string
  const returnBalanceValueString = () => {
    let balanceValue: number = 0

    const nominalPrice = Number(remaskCaractersAll(state?.nominal_price)) / 100
    const discountPrice = Number(remaskCaractersAll(logic.discountPrice)) / 100
    const additionPrice = Number(remaskCaractersAll(logic.additionPrice)) / 100

    balanceValue = nominalPrice - discountPrice + additionPrice

    logic.paymentMethods?.forEach((payment) => {
      balanceValue -= Number(remaskCaractersAll(payment.amount)) / 100
    })

    const result = formatBrOrDollarDefault(balanceValue.toFixed(2))

    return result
  }

  // balance number
  const returnBalanceValueNumber = () => {
    let balanceValue: number = 0

    const nominalPrice = Number(remaskCaractersAll(state?.nominal_price)) / 100
    const discountPrice = Number(remaskCaractersAll(logic.discountPrice)) / 100
    const additionPrice = Number(remaskCaractersAll(logic.additionPrice)) / 100

    balanceValue = nominalPrice - discountPrice + additionPrice

    logic.paymentMethods?.forEach((payment) => {
      balanceValue -= Number(remaskCaractersAll(payment.amount)) / 100
    })

    return balanceValue
  }

  const handlePaymentBills: SubmitHandler<FormData> = async ({
    observation
  }) => {
    // Format paymentMethods
    const posting_methods = logic.paymentMethods.map(
      (item: PaymentMethodsData, index: number) => {
        if (logic.paymentMethods[index].paymentMethod !== '') {
          return {
            is_active: true,
            price: Number(remaskCaractersAll(item.amount)) / 100,
            payment_date: item.paymentDate,
            payment_source: Number(item.paymentMethod)
          }
        }

        return { invalid: true }
      }
    )

    // Format dates
    const formatCompetence_date = remaskCaractersAll(state?.competence_date)
    // logic Get Format Date for body
    const vc = formatCompetence_date.split('')
    const dayVc = Number(`${0}${5}`)
    const monthVc = Number(`${vc[0]}${vc[1]}`) - 1
    const yearVc = Number(`${vc[2]}${vc[3]}${vc[4]}${vc[5]}`)
    const dateVc = new Date(yearVc, monthVc, dayVc)
    const FormatCompetence = String(format(dateVc, 'yyyy-MM-dd'))

    const price = Number(Number(remaskCaractersAll(logic.price)) / 100)
    const nominal_price = Number(
      Number(remaskCaractersAll(state?.nominal_price)) / 100
    )
    const addition_price = Number(
      Number(remaskCaractersAll(logic.additionPrice)) / 100
    )
    const discount_price = Number(
      Number(remaskCaractersAll(logic.discountPrice)) / 100
    )

    const payable = {
      name: state?.name,
      share_number: state?.share_number,
      code: state?.code,
      is_active: isActive,
      price,
      nominal_price,
      observation,
      competence_date: FormatCompetence,
      due_date: state?.due_date,
      payment_date: payment_date_format === '' ? null : payment_date_format,
      total_of_postings: state?.total_of_postings,
      addition_price,
      discount_price,
      status,
      posting_methods:
        posting_methods[0].invalid === true ? [] : posting_methods,
      category: {
        id: state?.category?.id
      },
      supplier_posting: {
        id: state?.supplier_posting?.id
      },
      cost_center: {
        id: state?.cost_center?.id
      }
    }

    updatePayable.mutateAsync(payable)
  }

  if (state === null) {
    return (
      <Box overflow="hidden" position="relative">
        <Header>
          <Flex
            mx="auto"
            w="100%"
            direction="row"
            d="flex"
            my="6"
            align="center"
            justify="space-around"
          >
            <Box mx="auto">
              <Text fontSize="24px" fontWeight="600" height="100%">
                {t('create.title')}
              </Text>
            </Box>
            <Stack direction="row" align="center" justify="center">
              <ArrowBack href="/finances/bills-to-pay">
                <Text fontSize="md" fontWeight="bold">
                  Voltar
                </Text>
              </ArrowBack>
            </Stack>
          </Flex>
        </Header>
        <Sidebar />
        <Flex
          flex="1"
          mx="auto"
          as="form"
          ml={isWideVersion ? '75px' : '2'}
          mt="15px"
          mb="8px"
          mr="2"
          bg="white"
          h="cal(100vh - 60px)"
          onSubmit={handleSubmit(handlePaymentBills)}
        >
          <Flex w="100vw" h="100vh" align="center" justify="center">
            <Spinner color="blue" />
          </Flex>
        </Flex>
      </Box>
    )
  }

  return (
    <>
      <Box overflow="hidden" position="relative">
        <Header>
          <Flex
            mx="auto"
            w="100%"
            direction="row"
            d="flex"
            my="6"
            align="center"
            justify="space-around"
          >
            <Box mx="auto">
              <Text fontSize="24px" fontWeight="600" height="100%">
                {t('create.title')}
              </Text>
            </Box>
            <Stack direction="row" align="center" justify="center">
              <ArrowBack href="/finances/bills-to-pay">
                <Text fontSize="md" fontWeight="bold">
                  Voltar
                </Text>
              </ArrowBack>
            </Stack>
          </Flex>
        </Header>
        <Sidebar />
        <Flex
          flex="1"
          mx="auto"
          as="form"
          ml={isWideVersion ? '75px' : '2'}
          mt="15px"
          mb="8px"
          mr="2"
          bg="white"
          h="cal(100vh - 60px)"
          onSubmit={handleSubmit(handlePaymentBills)}
        >
          <Stack spacing="4">
            <CardContainer title={t('create.container1.title')}>
              <Stack position="absolute" direction="row" right="4" top="4">
                <PermissionComponent spiCode="BILLS_TO_PAY" ipCode="EDIT">
                  <Flex flexDirection="row" align="center" justify="center">
                    <Link
                      to={{
                        pathname: `/finances/bills-to-pay/update/${state?.id}`,
                        state
                      }}
                    >
                      <Tooltip
                        label={t('updateTitle')}
                        aria-label={t('updateTitle')}
                        hasArrow
                        placement="top-start"
                      >
                        <Box ml="1rem" w="24px">
                          <FiEdit
                            color="#9699B0"
                            size={16}
                            style={{ cursor: 'pointer' }}
                          />
                        </Box>
                      </Tooltip>
                    </Link>
                    <Link
                      to={{
                        pathname: `/finances/bills-to-pay/files/${state?.id}`,
                        state
                      }}
                    >
                      <Tooltip
                        label={t('file')}
                        aria-label={t('updateTitle')}
                        hasArrow
                        placement="top-start"
                      >
                        <Box ml="1rem" w="24px">
                          <FiFile
                            color="#9699B0"
                            size={16}
                            style={{ cursor: 'pointer' }}
                          />
                        </Box>
                      </Tooltip>
                    </Link>
                    {/*                   <Link
                    to={{
                      pathname: `/finances/bills-to-pay/payment/${state?.id}`,
                      state
                    }}
                  >
                    <Tooltip
                      label={t('payment')}
                      aria-label={t('payment')}
                      hasArrow
                      placement="top-start"
                    >
                      <Box ml="1rem" w="24px">
                        <AiOutlineDollarCircle
                          color="#9699B0"
                          size={20}
                          style={{ cursor: 'pointer' }}
                        />
                      </Box>
                    </Tooltip>
                  </Link> */}
                  </Flex>
                </PermissionComponent>
              </Stack>
              <SimpleGrid minChildWidth="320px">
                <Stack direction="row" my="2" spacing="6">
                  <Box w="320px">
                    <Input
                      label={t('create.container1.i1')}
                      defaultValue={state?.name}
                      name="name"
                      isDisabled
                      placeholder={t('create.container1.placeholder.name')}
                    />
                  </Box>
                  <Box w="320px">
                    <SelectComponent
                      options={service.newSuppliersFormats}
                      value={supPosting}
                      name="supplier"
                      isDisabled
                      title={t('create.container1.i2')}
                    />
                  </Box>
                  <Box w="320px">
                    <Select
                      options={defaultOptionsCategory}
                      defaultValue={String(state?.category?.id)}
                      name="category"
                      placeholder={t('create.container1.placeholder.category')}
                      isDisabled
                      label={t('create.container1.i3')}
                    />
                  </Box>
                  <Box w="320px">
                    <SelectComponent
                      options={service.newCostsFormats}
                      value={costPosting}
                      isDisabled
                      name="cost"
                      title={t('create.container1.i4')}
                    />
                  </Box>
                </Stack>
              </SimpleGrid>
              <Flex />
            </CardContainer>

            <CardContainer title={t('create.container3.title')}>
              <SimpleGrid minChildWidth="320px">
                <Stack direction="row" my="2" spacing="6">
                  <Box w="100px">
                    <Input
                      w="100px"
                      label={t('create.container3.i1')}
                      defaultValue={state?.code}
                      name="code"
                      isDisabled
                    />
                  </Box>
                  <Box w="100px">
                    <Input
                      w="100px"
                      label={t('create.container3.i2')}
                      defaultValue={state?.pos}
                      name="share_number"
                      isDisabled
                    />
                  </Box>
                  <Box w="300px">
                    <Input
                      name="competence_date"
                      defaultValue={state?.competence_date}
                      label={t('create.container3.i3')}
                      isDisabled
                    />
                  </Box>
                  <Box w="300px">
                    <Input
                      defaultValue={state?.due_date_view}
                      name="due_date"
                      label={t('create.container3.i4')}
                      isDisabled
                    />
                  </Box>
                  <Box w="300px">
                    <Input
                      value={payment_date_format}
                      onChange={(event) =>
                        setPaymentDateFormat(event.target.value)
                      }
                      isRequired={payment_date}
                      type="date"
                      name="paymentDateFormat"
                      label={t('create.container4.i3')}
                    />
                  </Box>
                  <Box w="300px">
                    <Select
                      options={
                        isInactive
                          ? defaultOptionsStatus
                          : optionsStatusNoInactive
                      }
                      placeholder={t('create.container3.placeholder.status')}
                      name="status"
                      value={status}
                      onChange={(event) =>
                        setStatus(Number(event.target.value))
                      }
                      label={t('create.container3.i5')}
                    />
                  </Box>
                </Stack>
              </SimpleGrid>
              <SimpleGrid minChildWidth="320px">
                <Stack direction="row" my="2" spacing="6">
                  <Box w="320px">
                    <InputCurrency
                      name="cost"
                      label={t('create.container3.i6')}
                      defaultValue={state?.nominal_price}
                      isDisabled
                    />
                  </Box>
                  <Box w="320px">
                    <TextFieldControlled
                      label={t('create.container3.i7')}
                      mask={currency === 'BRL' ? 'monetary' : 'monetaryDollar'}
                      name="discount_price"
                      initialValue={logic.discountPrice}
                      defaultValue={state?.discount_price || ''}
                      onKeyUp={(event) => {
                        logic.setDiscountPrice(event.currentTarget.value)
                        logic.changeDiscountPrice(event)
                      }}
                    />
                  </Box>
                  <Box w="320px">
                    <TextFieldControlled
                      label={t('create.container3.i8')}
                      name="addition_price"
                      defaultValue={state?.addition_price || ''}
                      mask={currency === 'BRL' ? 'monetary' : 'monetaryDollar'}
                      initialValue={logic.additionPrice}
                      onKeyUp={(event) => {
                        logic.setAdditionPrice(event.currentTarget.value)
                        logic.changeAdditionPrice(event)
                      }}
                    />
                  </Box>
                  <Box w="320px">
                    <TextFieldControlled
                      name="price"
                      label={t('create.container3.i9')}
                      mask={currency === 'BRL' ? 'monetary' : 'monetaryDollar'}
                      isDisabled
                      isInvalid={logic.isInvalid.isInvalid}
                      message={logic.isInvalid.message}
                      value={logic.price}
                    />
                  </Box>
                  <Box w="320px">
                    <TextFieldControlled
                      name="balanceFinal"
                      label={t('create.container3.i10')}
                      isDisabled
                      value={returnBalanceValueString()}
                    />
                  </Box>
                </Stack>
              </SimpleGrid>
              <Flex flexDirection="column" mx="auto" w="100%">
                <Text my="2" fontSize="sm">
                  {t('create.container3.i11')}
                </Text>
                <Textarea
                  placeholder={t('create.container3.observation')}
                  {...register('observation')}
                  defaultValue={state?.observation}
                />
              </Flex>
            </CardContainer>

            <CardContainer title={t('create.container4.title')}>
              <Table>
                <Thead>
                  <Tr>
                    <Th>{t('create.container4.i1')}</Th>
                    <Th>{t('create.container4.i2')}</Th>
                    <Th>{t('create.container4.i3')}</Th>
                    {logic.paymentMethods.length > 1 && (
                      <Th>{t('create.container4.i5')}</Th>
                    )}
                  </Tr>
                </Thead>
                <Tbody>
                  {logic.paymentMethods.map(
                    (payment: PaymentMethodsData, index: number) => (
                      <Tr key={payment.paymentMethod}>
                        <Td>
                          <Select
                            isRequired={payment.isRequired}
                            options={service.newPaymentMethods}
                            placeholder={t(
                              'create.container4.placeholder.payment'
                            )}
                            name="paymentMethod"
                            value={logic.paymentMethods[index].paymentMethod}
                            onChange={(event) => {
                              logic.handleSelectOptionPaymentMethods(
                                event,
                                index,
                                'paymentMethod'
                              )
                            }}
                          />
                        </Td>
                        <Td>
                          <TextFieldControlled
                            mask={
                              currency === 'BRL' ? 'monetary' : 'monetaryDollar'
                            }
                            name="amount"
                            initialValue={payment.amount}
                            onKeyUp={(event) => {
                              logic.handleInputChangeAmountPaymentMethods(
                                event,
                                index
                              )
                              logic.handleInputChangeAmountCalculation(
                                event,
                                index
                              )
                            }}
                          />
                        </Td>
                        <Td>
                          <Input
                            name="paymentDate"
                            type="date"
                            value={payment.paymentDate}
                            onChange={(event) =>
                              logic.handleInputChange(event, index)
                            }
                          />
                        </Td>
                        {logic.paymentMethods.length > 1 && (
                          <Td>
                            <RemoveBtnIcon
                              onClick={() =>
                                logic.onRemoveFieldPaymentMethodsDateIndex(
                                  index
                                )
                              }
                            />
                          </Td>
                        )}
                      </Tr>
                    )
                  )}
                </Tbody>
                <Box mt="5">
                  <Button
                    size="md"
                    fontSize="sm"
                    bg="green.300"
                    color="#fff"
                    isDisabled={returnBalanceValueNumber() <= 0}
                    onClick={() =>
                      logic.handleAddFieldPaymentMethodsDateIndex()
                    }
                  >
                    {t('create.container4.btnLabel')}
                  </Button>
                </Box>
              </Table>
            </CardContainer>
            {/*             <CardContainer title={t('create.container5.title')}>
              {logic.documents.map((doc: any, index: number) => (
                <>
                  <Stack>
                    <Flex justify="space-between">
                      {doc?.document?.posting_document && (
                        <Flex mx="6" d="flex" align="center" justify="center">
                          <Icon mx="2" as={RiAttachmentLine} />
                          <Text mx="2">{doc?.document?.alt}</Text>
                          <Link
                            mx="2"
                            href={doc?.document?.file}
                            target="_blank"
                            d="flex"
                            alignItems="center"
                            justifyContent="center"
                            p="2"
                            bg="green.300"
                            borderRadius={8}
                            color="white"
                            transition="0.2s ease-in"
                            _hover={{
                              bg: 'green.400'
                            }}
                          >
                            Download
                          </Link>
                        </Flex>
                      )}
                      {!doc.document.posting_document && (
                        <Flex direction="column">
                          <Dropzone
                            getImg={(e: any) => {
                              logic.handleUploadDocument(e, index)
                            }}
                            handleSetImg={doc.document}
                            idPost={state?.id}
                          />
                        </Flex>
                      )}
                      <Flex justify="space-between">
                        {logic.documents[index].document.file && (
                          <Stack direction="row">
                            <Button
                              size="xs"
                              bg="transparent"
                              onClick={() => {
                                setIndexDoc(index)
                                setIdEdit(logic.documents[index]?.id)
                                setEditName(
                                  logic.documents[index].document?.alt
                                )
                                setIsOpenEdit(true)
                              }}
                            >
                              <FiEdit />
                            </Button>
                            <Button
                              type="button"
                              colorScheme="red"
                              size="xs"
                              mb="2"
                              onClick={() => {
                                setIndexDoc(index)
                                onOpen()
                              }}
                            >
                              X
                            </Button>
                          </Stack>
                        )}
                      </Flex>
                    </Flex>
                  </Stack>
                  <Divider my="6" />
                </>
              ))}

              <Box mt="5">
                <Button
                  size="md"
                  fontSize="sm"
                  bg="green.300"
                  color="#fff"
                  isLoading={formState?.isSubmitting}
                  onClick={() => logic.handleAddFieldDocumentsPaymentMethods()}
                >
                  {t('create.container5.btnLabel')}
                </Button>
              </Box>
            </CardContainer> */}

            <Flex
              mt="6"
              w="100%"
              align="flex-end"
              justifyContent="flex-end"
              gap={2}
            >
              <Button
                type="submit"
                size="md"
                fontSize="md"
                bg="blue.300"
                color="#fff"
                isLoading={formState?.isSubmitting}
                onClick={() => {
                  setRedirect(false)
                }}
              >
                {t('create.buttonNoRedirect')}
              </Button>
              <Button
                type="submit"
                size="md"
                fontSize="md"
                bg="blue.300"
                color="#fff"
                isLoading={formState?.isSubmitting}
                onClick={() => {
                  setRedirect(true)
                }}
              >
                {t('create.buttonRedirect')}
              </Button>
            </Flex>
          </Stack>
        </Flex>
      </Box>
      <ModalConfirmExclude
        isOpen={isOpen}
        title="Confirma Exclusão:"
        onConfirmExclude={() => {
          logic.handleExcludedDocumentByIndexId(indexDoc)
          logic.onRemoveFieldDocumentsPaymentMethods(indexDoc)
          onClose()
        }}
        onClose={onClose}
      >
        <Text>Tem certeza que quer excluir este documento?</Text>
      </ModalConfirmExclude>
      <ModalArrivedTime
        isOpen={isOpenEdit}
        title="Edição de arquivo:"
        onUpdate={async () => {
          await handlerUpdateNameDocument()
          setIsOpenEdit(false)
        }}
        onClose={() => setIsOpenEdit(false)}
      >
        <Input
          name="editNameDocument"
          label="Nome"
          value={editName}
          onChange={(event) => setEditName(event.target.value)}
        />
      </ModalArrivedTime>
    </>
  )
}
