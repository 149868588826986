/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import { useToast } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../hooks/auth'
import { useDocument } from '../../hooks/useDocument'
import { apiAuth } from '../../services/apiAuth'

export type PatientsData = {
  id?: number
  name: string
  document_type?: string
  document_number: string
  sex?: number
  alerts: string
  observation: string
  is_activated: string
  ocuppation: string
  marital_status: string
  emails: string[]
  address: {
    city: string
  }
  contacts_patient: {
    contact: string
  }
  patient_tags: number[]
  patient_origins: number[]
}

export type TagsData = {
  id: number
  name: string
}

export type OriginsData = {
  id: number
  name: string
}

export type CovenantsData = {
  id: number
  name: string
}

export type ClientsData = {
  id: number
  name: string
}

type SpecialistsData = {
  id: number
  social_name: string
  user: {
    id: number
    name: string
    email: string
    contact: string
  }
}

const BASE_URL = 'clinics/patients/'

export const usePatients = () => {
  // terceiros
  const { clinicId } = useAuth()
  const { setImgSrc } = useDocument()
  const router = useHistory()
  const toast = useToast()

  // state
  const [tagsServices, setTagsServices] = useState<TagsData[]>([])
  const [originsServices, setOriginsServices] = useState<OriginsData[]>([])
  const [covenantsServices, setCovenants] = useState<CovenantsData[]>([])
  const [isLoadingSpecialists, setIsLoadingSpecialists] = useState(false)
  const [specialists, setSpecialists] = useState<SpecialistsData[]>([])

  const getSpecialists = useCallback(() => {
    try {
      setIsLoadingSpecialists(true)
      apiAuth
        .get(`users/`, {
          params: {
            user__is_active: true,
            is_specialist: true
          }
        })
        .then((response) => {
          setSpecialists(response.data.results)
          setIsLoadingSpecialists(false)
        })
    } catch (err: any) {
      console.log(err)
      setIsLoadingSpecialists(false)
    }
  }, [])

  async function getClientById(id: string) {
    try {
      const { data } = await apiAuth.get(`clinics/patients/${id}/`)

      return data
    } catch (error: any) {
      return {
        error: true,
        errorMessage: error.response?.data?.message
      }
    }
  }

  // função para criar um conveio
  async function createPatients(payload: any) {
    try {
      const { data } = await apiAuth.post(BASE_URL, payload)

      return data.id
    } catch (error: any) {
      toast({
        title: 'Aconteceu um erro!',
        description: `${error.message}`,
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })

      return undefined
    }
  }

  // função para editar um conveio
  async function updatePatients(
    payload: any,
    id: string,
    isRedirect?: boolean
  ) {
    try {
      const { data } = await apiAuth.put(`${BASE_URL}${id}/`, payload)

      if (data.id !== undefined) {
        toast({
          title: 'Atualização realizado com sucesso!',
          description: isRedirect ? 'Estamos lhe redirecionando.' : '',
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })

        if (isRedirect === true) {
          setTimeout(() => {
            router.push('/clients')
            // image base64
            setImgSrc('')
          }, 2000)
        }
      }
    } catch (error: any) {
      toast({
        title: 'Aconteceu um error!',
        description: `${error.message}`,
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })
    }
  }

  const getTagsServices = useCallback(async () => {
    try {
      const { data } = await apiAuth.get(`clinics/${clinicId}/tags/`, {
        params: {
          is_active: true
        }
      })

      const { results } = data

      setTagsServices(results)
    } catch (error: any) {
      console.log(error)
    }
  }, [clinicId])

  const getOriginsServices = useCallback(async () => {
    try {
      const { data } = await apiAuth.get(`clinics/${clinicId}/origins/`, {
        params: {
          is_active: true
        }
      })

      const { results } = data

      setOriginsServices(results)
    } catch (error: any) {
      console.log(error)
    }
  }, [clinicId])

  const getCovenantsServices = useCallback(async () => {
    try {
      const { data } = await apiAuth.get(
        `clinics/${clinicId}/health-insurances/`,
        {
          params: {
            is_active: true
          }
        }
      )

      const { results } = data

      setCovenants(results)
    } catch (error: any) {
      console.log(error)
    }
  }, [clinicId])

  const getClientsServices = useCallback(async (search?: string) => {
    try {
      const { data } = await apiAuth.get(`clinics/patients/`, {
        params: {
          is_active: true,
          noPaginate: 1,
          name__icontains: search || ''
        }
      })

      const { results } = data

      const formatReturn = results?.map((clients: any) => {
        return {
          value: clients.id,
          label: clients?.name_document || clients?.name || clients?.social_name
        }
      })

      return formatReturn
    } catch (error: any) {
      return []
    }
  }, [])

  useEffect(() => {
    getTagsServices()
    getOriginsServices()
    getCovenantsServices()
    getClientsServices()
    getSpecialists()

    return () => {}
  }, [])

  // export
  return {
    createPatients,
    specialists,
    tagsServices,
    isLoadingSpecialists,
    getClientsServices,
    getClientById,
    originsServices,
    updatePatients,
    covenantsServices
  }
}
