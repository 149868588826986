import React, { createContext, ReactNode, useState, useEffect } from 'react'
import produce from 'immer'
import { apiAuth } from '../services/apiAuth'
import { queryClient } from '../services/reactQuery'

interface DndProviderProps {
  children: ReactNode
}

interface ListData {
  id?: string
  name?: string
  index?: number
}

interface Options {
  pos: number
  label: string
  value: string | number
}

interface Fields {
  id: string
  name: string
  isRequired: boolean
  placeholder: string
  type: string
  value: string
  defaultValue: string
  label: string
  maxLength?: number
  options?: Options[]
  error?: boolean
}

interface GroupFields {
  id: string
  name: string
  fields: Array<Fields>
  isVisible: boolean
}

type ListArrayData = Array<ListData>

type DndData = {
  list: ListArrayData
  setList: React.Dispatch<React.SetStateAction<any[]>>
  Move: (fromList: number, from: number, to: number) => void
  MoveForm: (fromList: number, toList: number, from: number, to: number) => void
  MoveFormGroup: (fromList: number, from: number, to: number) => void
  listForms: GroupFields[]
  setListForms: React.Dispatch<React.SetStateAction<GroupFields[]>>
  setIsOrdering: React.Dispatch<React.SetStateAction<boolean>>
  isOrdering: boolean
  setUrlUpdate: React.Dispatch<React.SetStateAction<string>>
  updateIndex: () => void
  groupFieldsValues: GroupFields[]
  setGroupFieldsValues: React.Dispatch<React.SetStateAction<GroupFields[]>>
}

export const DndContext = createContext({} as DndData)

export function DndContextProvider({ children }: DndProviderProps) {
  const [list, setList] = useState<ListArrayData>([])
  const [isOrdering, setIsOrdering] = useState(false)
  const [urlUpdate, setUrlUpdate] = useState('')
  // create forms context state
  const [listForms, setListForms] = useState<GroupFields[]>([])
  const [groupFieldsValues, setGroupFieldsValues] = useState<GroupFields[]>([])

  function Move(fromList: number, from: number, to: number) {
    setList(
      produce(list, (draft) => {
        const dragged = draft[from]

        draft.splice(from, 1)
        draft.splice(to, fromList, dragged)
      })
    )
  }

  function MoveForm(
    fromList: number,
    toList: number,
    from: number,
    to: number
  ) {
    setListForms(
      produce(listForms, (draft) => {
        const dragged = draft[fromList].fields[from]

        draft[fromList].fields.splice(from, 1)
        draft[toList].fields.splice(to, 0, dragged)
      })
    )
  }

  function MoveFormGroup(fromList: number, from: number, to: number) {
    setListForms(
      produce(listForms, (draft) => {
        const dragged = draft[from]

        draft.splice(from, 1)
        draft.splice(to, fromList, dragged)
      })
    )
  }

  async function updateIndex() {
    if (list.length === 0) {
      return
    }

    if (urlUpdate === '') {
      return
    }

    const listBody = list.map((item, index: number) => {
      const data = {
        index
      }

      return {
        ...item,
        ...data
      }
    })

    try {
      const { status } = await apiAuth.put(urlUpdate, listBody)

      if (status === 200) {
        queryClient.invalidateQueries('AttendanceSpot')
        await queryClient.refetchQueries('AttendanceSpot')
        setIsOrdering(false)
      }
    } catch (err: any) {
      console.log(err?.message)
    }
  }

  return (
    <DndContext.Provider
      value={{
        list,
        setList,
        Move,
        setIsOrdering,
        MoveForm,
        setListForms,
        MoveFormGroup,
        listForms,
        isOrdering,
        setUrlUpdate,
        updateIndex,
        setGroupFieldsValues,
        groupFieldsValues
      }}
    >
      {children}
    </DndContext.Provider>
  )
}
