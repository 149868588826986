/* eslint-disable react-hooks/exhaustive-deps */
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  HStack,
  Skeleton,
  Stack,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useToast,
  VStack
} from '@chakra-ui/react'
import { addMonths, format, subMonths } from 'date-fns'
import React, { FC, useCallback, useState } from 'react'
import { v4 as uuidV4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import { AiFillLock, AiFillUnlock } from 'react-icons/ai'
import { FiDollarSign, FiEdit } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { Input } from '../../components/Form/Input'
import { TextFieldControlled } from '../../components/Form/TextFieldControlled'
import { ModalArrivedTime } from '../../components/Modal/confirmArrivedTime'
import { CardContainer } from '../../components/molecules/CardContainer'
import { NotFound } from '../../components/molecules/NotFound'
import { MultiSelectMenuFilter } from '../../components/MultiSelectFilters'
import {
  getTicketsCanceled,
  useTicketsCanceled
} from '../../hooks/ticketsCanceled/useTicketsCanceled'
import { useModal } from '../../hooks/useModal'
import { LayoutBlank } from '../../layouts/blank'
import { apiAuth } from '../../services/apiAuth'
import { formatBrOrDollarDefault } from '../../utils/fns/formatBrOrDollarDefault'
import { useService } from './service'
import { ModalConfirmExclude } from '../../components/Modal/confirmExclude'
import { Pagination } from '../../components/PaginationNew'

// debounce
let timer: any = null

export const TicketsCanceled: FC = () => {
  const [t] = useTranslation('pageTicketsCanceled')
  const toast = useToast()
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const services = useService()
  const {
    LocaleApp,
    dateFinalTicketsCanceled,
    dateInitialTicketsCanceled,
    searchDailyBox,
    setSearchDailyBox,
    setDateFinalTicketsCanceled,
    setDateInitialTicketsCanceled,
    generateInvoiceFilterTicketsCanceled,
    setGenerateInvoiceFilterTicketsCanceled
  } = useModal()
  const [currentPage, setCurrentPage] = useState(1)
  const skeletonRepeat = [1, 2, 3, 4, 5, 6, 7]
  const statusGenerateInvoice = [
    { id: '1', name: `${t('optionsFilter.1')}` },
    { id: '2', name: `${t('optionsFilter.2')}` },
    { id: 'null', name: `${t('optionsFilter.null')}` }
  ]

  // local filters
  const [inicialDate, setInicialDate] = useState(dateInitialTicketsCanceled)
  const [finalDate, setFinalDate] = useState(dateFinalTicketsCanceled)
  const [generateInvoice, setGenerateInvoice] = useState(
    generateInvoiceFilterTicketsCanceled
  )

  const dateDefault = new Date(
    Number(inicialDate?.substring(0, 4)),
    Number(inicialDate?.substring(5, 7)) - 1,
    Number(inicialDate?.substring(8, 10))
  )

  const maxDate = addMonths(dateDefault, 13)

  const getDailyBoxData = useTicketsCanceled(
    currentPage,
    getTicketsCanceled(
      currentPage,
      searchDailyBox,
      dateInitialTicketsCanceled,
      dateFinalTicketsCanceled,
      generateInvoiceFilterTicketsCanceled
    ),
    searchDailyBox,
    dateInitialTicketsCanceled,
    dateFinalTicketsCanceled,
    generateInvoiceFilterTicketsCanceled
  )

  const SearchInputCallbackValue = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget

      clearTimeout(timer)

      timer = setTimeout(() => {
        setCurrentPage(1)
        setSearchDailyBox(value)
      }, 500)
    },
    []
  )

  // apply filters date in context date search field
  const handlerApplyFiltersDate = () => {
    let throwError = false

    if (inicialDate === '') {
      toast({
        title: 'Data inicial obrigatória',
        position: 'top-right',
        status: 'error'
      })

      throwError = true
    }

    if (finalDate === '') {
      toast({
        title: 'Data final obrigatória',
        position: 'top-right',
        status: 'error'
      })

      throwError = true
    }

    if (throwError) {
      toast({
        title: 'Revise os valores de filtro.',
        position: 'top-right',
        status: 'error'
      })

      return
    }

    setDateInitialTicketsCanceled(inicialDate)
    setDateFinalTicketsCanceled(finalDate)
    setGenerateInvoiceFilterTicketsCanceled(generateInvoice)
  }

  return (
    <>
      <LayoutBlank
        title={t('title')}
        placeholder={t('search')}
        defaultSearch={searchDailyBox}
        capturaChange={(event) => SearchInputCallbackValue(event)}
        noRenderIsActive
      >
        <Flex
          mx="auto"
          w="100%"
          direction="row"
          mb="2"
          p="4"
          justify="space-around"
        >
          <HStack>
            <Flex align="center" px="2" mx="2">
              <Flex mx="8px" align="baseline" justify="baseline">
                <TextFieldControlled
                  textAlign="center"
                  type="date"
                  value={inicialDate}
                  onChange={(event) => {
                    setInicialDate(event.target.value)
                    setFinalDate('')
                  }}
                  name="date"
                />
              </Flex>
              <Divider w="20px" />
              <Flex mx="8px" align="baseline" justify="baseline">
                <TextFieldControlled
                  textAlign="center"
                  type="date"
                  min={inicialDate}
                  disabled={inicialDate === ''}
                  max={format(maxDate, 'yyyy-MM-dd')}
                  value={finalDate}
                  onChange={(event) => setFinalDate(event.target.value)}
                  name="date"
                />
              </Flex>
            </Flex>
            <MultiSelectMenuFilter
              label="Recibo"
              options={statusGenerateInvoice}
              backChange={(values) => {
                setGenerateInvoice(values)
              }}
              render={() => {
                const statusRender: any = generateInvoiceFilterTicketsCanceled
                return statusRender
              }}
            />
            <Flex mx="8px" align="baseline" justify="baseline">
              <Button
                color="white"
                bg="blue.300"
                onClick={() => handlerApplyFiltersDate()}
              >
                Aplicar
              </Button>
            </Flex>
          </HStack>
        </Flex>
        <Stack w="100%" spacing="4" direction={['row', 'column']}>
          <CardContainer title={t('container3.title')}>
            <Table variant="striped" colorScheme="blue" size="sm">
              <Thead>
                <Tr>
                  <Th fontSize="xs">{t('t1')}</Th>
                  <Th fontSize="xs">{t('t9')}</Th>
                  <Th fontSize="xs">{t('t2')}</Th>
                  <Th fontSize="xs">{t('t6')}</Th>
                  <Th fontSize="xs">{t('t7')}</Th>
                  <Th fontSize="xs">{t('t8')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {getDailyBoxData?.isLoading ? (
                  <>
                    {skeletonRepeat.map((skeleton) => (
                      <Tr key={skeleton}>
                        <Td>
                          <Skeleton height="20px" />
                        </Td>
                        <Td>
                          <Skeleton height="20px" />
                        </Td>
                        <Td>
                          <Skeleton height="20px" />
                        </Td>
                        <Td>
                          <Skeleton height="20px" />
                        </Td>
                        <Td>
                          <Skeleton height="20px" />
                        </Td>
                        <Td>
                          <Skeleton height="20px" />
                        </Td>
                        <Td>
                          <Skeleton height="20px" />
                        </Td>
                        <Td>
                          <Skeleton height="20px" />
                        </Td>
                        <Td>
                          <Skeleton height="20px" />
                        </Td>
                      </Tr>
                    ))}
                  </>
                ) : (
                  <>
                    {getDailyBoxData?.data?.ticketsCanceled.map((item) => (
                      <Tr key={`${item?.id}${uuidV4()}`}>
                        <Td w="150px">
                          <Text>{item?.ticket_number}</Text>
                        </Td>
                        <Td w="150px">
                          {item?.created_at !== undefined ? (
                            <Text>
                              {format(
                                new Date(
                                  Number(item?.created_at?.substring(0, 4)),
                                  Number(item?.created_at?.substring(5, 7)) - 1,
                                  Number(item?.created_at?.substring(8, 10))
                                ),
                                'dd/MM/yyyy'
                              )}
                            </Text>
                          ) : (
                            <Text>--/--/----</Text>
                          )}
                        </Td>
                        <Td textAlign="right">
                          <Text fontSize="sm" fontWeight="hairline">
                            {formatBrOrDollarDefault(
                              item?.total_value.toFixed(2) || '0.00'
                            ) || '---'}
                          </Text>
                        </Td>
                        {item?.generate_invoice === 1 && (
                          <Td textAlign="center">Sim</Td>
                        )}
                        {item?.generate_invoice === 2 && (
                          <Td textAlign="center">Não</Td>
                        )}
                        {!item?.generate_invoice && (
                          <Td textAlign="center">---</Td>
                        )}
                        <Td textAlign="right">
                          <Text>
                            {formatBrOrDollarDefault(
                              String(
                                item?.total_value_invoice !== null &&
                                  item?.total_value_invoice * 100
                              ) || ''
                            ) || '---'}
                          </Text>
                        </Td>
                        <Td>
                          <Link
                            to={{
                              pathname: `/financial/tickets-canceled/conference/${item?.attendances[0]?.id}`,
                              state: item
                            }}
                          >
                            <Tooltip
                              label="Editar"
                              aria-label="editar"
                              hasArrow
                              placement="top-start"
                            >
                              <Box w="24px">
                                <FiEdit
                                  color="#9699B0"
                                  size={16}
                                  style={{ cursor: 'pointer' }}
                                />
                              </Box>
                            </Tooltip>
                          </Link>
                        </Td>
                      </Tr>
                    ))}
                  </>
                )}
              </Tbody>
            </Table>
            {getDailyBoxData?.data?.ticketsCanceled?.length === 0 &&
              !getDailyBoxData?.isLoading && <NotFound />}
            {!(
              getDailyBoxData.data?.totalCountOfPage === 0 &&
              !getDailyBoxData.isLoading
            ) && (
              <Pagination
                totalCountOfRegisters={
                  getDailyBoxData.data?.totalCountOfPage || 0
                }
                currentPage={currentPage}
                onPageChange={setCurrentPage}
              />
            )}
          </CardContainer>
        </Stack>
      </LayoutBlank>
    </>
  )
}
