/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react'
import { useAuth } from '../../hooks/auth'
import { apiAuth } from '../../services/apiAuth'

type SuppliersData = {
  id: string
  trading_name: string
}

type CostOfCenterData = {
  id: string
  name: string
}

type PaymentMethodsData = {
  id: string
  name: string
}

type SupplierData = SuppliersData | null

type CostData = CostOfCenterData | null

export const useService = () => {
  // Clinic Id Get decode hook
  const { clinicId } = useAuth()

  // State
  const [suppliers, setSuppliers] = useState<SuppliersData[]>([])
  const [costOfCenter, setCostOfCenter] = useState<CostOfCenterData[]>([])
  const [paymentsForm, setPaymentForm] = useState<PaymentMethodsData[]>([])
  const [recoverSuppliers, setRecoverSuppliers] = useState<SupplierData>(null)
  const [recCostOfCenter, setRecCostOfCenter] = useState<CostData>(null)

  const getCostsByClinicId = useCallback(async () => {
    try {
      const { data } = await apiAuth.get(`clinics/${clinicId}/cost-centers/`, {
        params: {
          is_active: true
        }
      })

      setCostOfCenter(data.results)
    } catch (error) {
      console.log('Error getting Cost', error)
    }
  }, [])

  const getSuppliersByClinicId = useCallback(async () => {
    try {
      const { data } = await apiAuth.get(`clinics/${clinicId}/suppliers/`, {
        params: {
          is_active: true
        }
      })

      setSuppliers(data.results)
    } catch (error) {
      console.log('Error getting Suppliers', error)
    }
  }, [])

  const getPaymentFormByClinicId = useCallback(async () => {
    try {
      const { data } = await apiAuth.get(
        `clinics/${clinicId}/payment-sources/`,
        {
          params: {
            is_active: true
          }
        }
      )

      setPaymentForm(data.results)
    } catch (error) {
      console.log('Error getting Suppliers', error)
    }
  }, [])

  const getSupplierById = useCallback(async (id: string) => {
    try {
      const { data } = await apiAuth.get(`clinics/suppliers/${id}/`)

      setRecoverSuppliers(data)
    } catch (error) {
      console.log('Error getting Suppliers', error)
    }
  }, [])

  const getCostById = useCallback(async (id: string) => {
    try {
      const { data } = await apiAuth.get(`clinics/cost-centers/${id}/`)

      setRecCostOfCenter(data)
    } catch (error) {
      console.log('Error getting cost', error)
    }
  }, [])

  useEffect(() => {
    getCostsByClinicId()
    getSuppliersByClinicId()
    getPaymentFormByClinicId()
  }, [])

  // Get Value of my select Supplier
  const newSuppliersFormats = suppliers.map((supplier: SuppliersData) => {
    return {
      value: String(supplier.id),
      label: supplier.trading_name
    }
  })

  // Get Value of my select Cost
  const newCostsFormats = costOfCenter.map((cost: CostOfCenterData) => {
    return {
      value: String(cost.id),
      label: cost.name
    }
  })

  // Get Value of my select Cost
  const newPaymentMethods = paymentsForm.map((payment: PaymentMethodsData) => {
    return {
      value: String(payment.id),
      label: payment.name
    }
  })

  return {
    newSuppliersFormats,
    newCostsFormats,
    newPaymentMethods,
    getSupplierById,
    getCostById,
    recoverSuppliers,
    recCostOfCenter
  }
}
