/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Center,
  Flex,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast
} from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import format from 'date-fns/format'
import { FiEdit } from 'react-icons/fi'
import FileSaver from 'file-saver'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ModalArrivedTime } from '../../components/Modal/confirmArrivedTime'
import { NotFound } from '../../components/molecules/NotFound'
import { MultiSelectMenuFilter } from '../../components/MultiSelectFilters'
import { Pagination } from '../../components/PaginationNew'
import { PermissionComponent } from '../../components/Permissions'
import { useAuth } from '../../hooks/auth'
import {
  getServices,
  ServicesData,
  useServices
} from '../../hooks/services/useServices'
import { useModal } from '../../hooks/useModal'
import { LayoutList } from '../../layouts/List'
import { formatBrOrDollarDefault } from '../../utils/fns/formatBrOrDollarDefault'
import { useService } from './service'
import { apiAuth } from '../../services/apiAuth'

// debounce
let timer: any = null

export function Services() {
  const { clinicId } = useAuth()
  const services = useService()
  const toast = useToast()
  const {
    isOpenServices,
    searchState,
    setSearchState,
    handlerCloseModal,
    servicesFilter,
    setServicesFilter,
    setIsActiveFilterServices
  } = useModal()
  const [t] = useTranslation('pageServices')
  const skeletonRepeat = [1, 2, 3, 4, 5, 6, 7]
  //  state
  const [currentPage, setCurrentPage] = useState(1)
  const [isActive, setIsActive] = useState(true)

  const getServiceData = useServices(
    currentPage,
    getServices(
      String(clinicId),
      currentPage,
      searchState,
      isActive,
      servicesFilter
    ),
    searchState,
    isActive,
    servicesFilter
  )

  const SearchInputCallbackValue = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget

      clearTimeout(timer)

      timer = setTimeout(() => {
        setCurrentPage(1)
        setSearchState(value)
      }, 500)
    },
    []
  )

  const ActiveCheckboxCallbackValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsActive(event.target.checked)
    },
    []
  )

  const handlerCloseModalServices = () => {
    handlerCloseModal('Services')
  }

  // verify filters
  useEffect(() => {
    const verifyActiveFilter = () => {
      if (searchState !== '') {
        return true
      }

      if (servicesFilter?.length > 0) {
        return true
      }

      return false
    }

    setIsActiveFilterServices(verifyActiveFilter)

    return () => {}
  }, [searchState, servicesFilter])

  const handlerGeneratedExcel = async () => {
    try {
      const { data } = await apiAuth.get(`clinics/services/export/`, {
        params: {
          keyword: searchState,
          is_active: isActive,
          ordering: 'name',
          groups: servicesFilter.join(',')
        },
        responseType: 'blob'
      })

      FileSaver(data, `${t('title')} ${format(new Date(), 'dd-MM-yyyy')}.xlsx`)
    } catch (error: any) {
      toast({
        position: 'top',
        duration: 3000,
        title: `Não foi possível gerar arquivo`
      })
    }
  }

  return (
    <LayoutList
      title={t('title')}
      urlNew="/services/create"
      refetch={getServiceData.refetch}
      isFetching={getServiceData.isFetching}
      placeholder={t('placeholder.search')}
      isHaveFilter
      nameFilter="Services"
      defaultSearch={searchState}
      capturaChange={(event: any) => SearchInputCallbackValue(event)}
      captureActiveChange={(event: any) => ActiveCheckboxCallbackValue(event)}
      spiCode="SERVICES"
      generateExcel={handlerGeneratedExcel}
      isHaveData={
        getServiceData?.data?.services !== undefined &&
        getServiceData?.data?.services?.length > 0
      }
    >
      <Box>
        <Table variant="striped" colorScheme="blue" size="sm">
          <Thead>
            <Tr>
              <Th>
                <Flex align="center" justify="center">
                  {t('t1')}
                </Flex>
              </Th>
              <Th>
                <Flex align="center" justify="center">
                  {t('t2')}
                </Flex>
              </Th>
              <Th>
                <Flex align="center" justify="center">
                  {t('t3')}
                </Flex>
              </Th>
              <Th>
                <Flex align="center" justify="center">
                  {t('t4')}
                </Flex>
              </Th>
              <Th>
                <Flex align="center" justify="center">
                  {t('t5')}
                </Flex>
              </Th>
              <Th>
                <Flex align="center" justify="center">
                  {t('t6')}
                </Flex>
              </Th>
              <Th>
                <Flex align="center" justify="center">
                  {t('t7')}
                </Flex>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {getServiceData.isLoading ? (
              <>
                {skeletonRepeat.map((skeleton) => (
                  <Tr>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                  </Tr>
                ))}
              </>
            ) : (
              <>
                {getServiceData.data?.services.map(
                  (service: ServicesData, index: number) => (
                    <Tr key={service.id} maxHeight="40px">
                      <Td>
                        <Text fontSize="sm">{service.name}</Text>
                      </Td>

                      <Td>
                        {service.service_sessions.length === 1 ? (
                          <Flex align="center" justify="center">
                            <Text fontSize="sm">{t('section')}</Text>
                          </Flex>
                        ) : (
                          <Flex align="center" justify="center">
                            <Text fontSize="sm">
                              {service.service_sessions.length}
                            </Text>
                          </Flex>
                        )}
                      </Td>

                      <Td>
                        <Text textAlign="right" fontSize="sm">
                          {service.price === '0.00'
                            ? `${t('free')}`
                            : formatBrOrDollarDefault(String(service.price))}
                        </Text>
                      </Td>

                      <Td>
                        {service.days_to_come_back === 0 ? (
                          <Flex align="center" justify="center">
                            <Text fontSize="sm">{t('notReturn')}</Text>
                          </Flex>
                        ) : (
                          <Flex align="center" justify="center">
                            <Text fontSize="sm">
                              {service.days_to_come_back}
                            </Text>
                          </Flex>
                        )}
                      </Td>

                      <Td>
                        {service.days_to_maturity === 0 ? (
                          <Flex align="center" justify="center">
                            <Text fontSize="sm">{t('undefined')}</Text>
                          </Flex>
                        ) : (
                          <Flex align="center" justify="center">
                            <Text fontSize="sm">
                              {service.days_to_maturity}
                            </Text>
                          </Flex>
                        )}
                      </Td>

                      <Td>{service?.tag_service?.name}</Td>

                      <Td>
                        <PermissionComponent spiCode="SERVICES" ipCode="EDIT">
                          <Link
                            to={{
                              pathname: `/services/update/${service.id}`,
                              state: service
                            }}
                          >
                            <Tooltip
                              label="Editar"
                              aria-label="editar"
                              hasArrow
                              placement="top-start"
                            >
                              <Box ml="1rem" w="24px">
                                <FiEdit
                                  color="#9699B0"
                                  size={16}
                                  style={{ cursor: 'pointer' }}
                                />
                              </Box>
                            </Tooltip>
                          </Link>
                        </PermissionComponent>
                      </Td>
                    </Tr>
                  )
                )}
              </>
            )}
          </Tbody>
        </Table>
        {getServiceData.data?.totalCountOfPage === 0 &&
          !getServiceData.isLoading && <NotFound />}
      </Box>
      {!(
        getServiceData.data?.totalCountOfPage === 0 && !getServiceData.isLoading
      ) && (
        <Pagination
          totalCountOfRegisters={getServiceData.data?.totalCountOfPage || 0}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      )}
      <ModalArrivedTime
        title={t('filters.title')}
        isOpen={isOpenServices}
        onClose={handlerCloseModalServices}
        onUpdate={() => {}}
        notRenderFooter
      >
        <Stack w="100%" p="4" dir="column" h="450px">
          <Center>
            <Text fontWeight="bold" fontSize="lg">
              {t('filters.groupSearch')}
            </Text>
          </Center>
          <Flex w="100%" justifyContent="flex-start">
            <MultiSelectMenuFilter
              label={t('filters.group')}
              options={services.tagsServices.map((group) => {
                return {
                  id: String(group.id),
                  name: group.name
                }
              })}
              backChange={(values) => {
                setServicesFilter(values)
              }}
              render={() => {
                const backValuesRender: any = servicesFilter
                return backValuesRender
              }}
            />
          </Flex>
        </Stack>
      </ModalArrivedTime>
    </LayoutList>
  )
}
