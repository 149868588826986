/* eslint-disable camelcase */
import { useQuery } from 'react-query'
import { apiAuth } from '../../services/apiAuth'

export type Conciliation = {
  id: number
  due_date: string
  interest_rate_absolule: null | number
  interest_rate_percentage: null | number
  parcel_number: number
  status_conciliation: number
  status_receivement: number
  tax_absolute: null | number
  tax_percentage: null | number
  net_value: number
  value_interest_rate_percentage: null | number
  value_tax_percentage: null | number
  value: number
  client: Array<{ id: number; name: string; social_name: string | null }>
  total_parcels: number
  ticket: {
    addition: number | null
    discount: number | null
    observation_financial: string | null
    observation_secretary: string | null
    paid_value: number | null
    result_status: number | null
    status_payment: number | null
    ticket_number: number | null
    total_value: number
  }
  way_to_receive: {
    active_parcels: number
    day_to_receive: number
    id: number
    is_active: boolean
    name: string
    observation: string | null
  }
}

export type ResponseGetConciliation = {
  conciliation: Conciliation[]
  totalCountOfPage: number
}

export const getConciliation = async (
  page: number,
  search: string = '',
  startAt: string = '',
  endAt: string = '',
  startAtSchedule: string = '',
  endAtSchedule: string = '',
  waysToReceive: String[] | any = [],
  status: String[] | any = [],
  statusReceive: String[] | any = []
): Promise<ResponseGetConciliation> => {
  try {
    const { data } = await apiAuth.get<Array<Conciliation>>(
      `clinics/tickets/conciliation/`,
      {
        params: {
          page,
          keyword: search !== '' ? search : undefined,
          startDate: startAt !== '' ? startAt : undefined,
          finishDate: endAt !== '' ? endAt : undefined,
          scheduledStartDate:
            startAtSchedule !== '' ? `${startAtSchedule}T00:00` : '',
          scheduledFinishDate:
            endAtSchedule !== '' ? `${endAtSchedule}T23:59` : '',
          wayToReceiveId: waysToReceive.join(','),
          statusConciliation: status.join(','),
          statusReceivement: statusReceive.join(',')
        }
      }
    )

    const totalCountOfPage = data?.length || 0
    const conciliation: Conciliation[] = data || []

    return {
      conciliation,
      totalCountOfPage
    }
  } catch (error: any) {
    console.log(error)

    const conciliation: Conciliation[] = []
    const totalCountOfPage = 0
    return {
      conciliation,
      totalCountOfPage
    }
  }
}

export function useConciliation(
  page: number,
  functionLoading: Promise<ResponseGetConciliation>,
  search: string,
  startAt: string = '',
  endAt: string = '',
  startAtSchedule: string = '',
  endAtSchedule: string = '',
  specialistId: String[] | String = [],
  status: String[] | String = [],
  statusReceive: String[] | String = [],
  options?: {}
) {
  return useQuery(
    [
      'conciliation',
      page,
      search,
      startAt,
      endAt,
      startAtSchedule,
      endAtSchedule,
      specialistId,
      status,
      statusReceive
    ],
    () => functionLoading,
    {
      staleTime: 1000 * 1, // 1 seconds
      cacheTime: 1000 * 60 * 15, // 5 minutes
      ...options
    }
  )
}
