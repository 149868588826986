import ls from 'localstorage-slim'
import { remaskCaractersAll } from './removeCaracters'
import formatBr from '../newMask/formatMonetary'
import formatUSA from '../newMask/formatMonetaryDollar'

type Currency = 'BRL' | 'USD' | 'EUR'

export const formatBrOrDollarDefault = (value: string) => {
  const currency = ls.get<Currency>('@Clinik:Currency', {
    decrypt: true
  })

  if (!value) {
    return ''
  }

  const valueNumber = Number(value)
  const formatValue = remaskCaractersAll(value)

  if (valueNumber >= 0) {
    if (currency === 'BRL') {
      const formatValueBr = formatBr(formatValue)

      return formatValueBr
    }

    const formatValueUSA = formatUSA(formatValue)

    return formatValueUSA
  }

  if (valueNumber < 0) {
    if (currency === 'BRL') {
      const formatValueBr = formatBr(formatValue)

      return `-${formatValueBr}`
    }

    const formatValueUSA = formatUSA(formatValue)

    return `-${formatValueUSA}`
  }

  if (currency === 'BRL') {
    const formatValueBr = formatBr(formatValue)

    return formatValueBr
  }

  const formatValueUSA = formatUSA(formatValue)

  return formatValueUSA
}
