/* eslint-disable array-callback-return */
import {
  Button,
  Flex,
  Link as LinkChakra,
  Stack,
  Text,
  useToast
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { ArrowBack } from '../../components/atoms/arrowBack'
import { Logo } from '../../components/atoms/Logo'
import { Input } from '../../components/Form/Input'
import { InputPhone } from '../../components/Form/InputPhone'
import { api } from '../../services/api'

type SignUpFormData = {
  name: string
  email: string
  password: string
  passwordConfirmation: string
  nameClinic: string
  phone: string
}

interface Country {
  sigla: string
  code: string
  name: string
}

const singInFormSchema = yup.object().shape({
  name: yup.string().required('Nome obrigatório'),
  nameClinic: yup.string().required('Nome da clínica é obrigatório'),
  email: yup.string().required('E-mail obrigatório').email('E-mail inválido'),
  phone: yup.string().required('Telefone obrigatório'),
  password: yup
    .string()
    .required('Senha obrigatória')
    .min(6, 'No mínimo 6 caracteres'),
  passwordConfirmation: yup
    .string()
    .required('Senha obrigatório')
    .oneOf([null, yup.ref('password')], 'As senha precisam ser iguais')
})

export const Singup = () => {
  const toast = useToast()
  const navigation = useHistory()
  const { register, handleSubmit, formState } = useForm<SignUpFormData>({
    resolver: yupResolver(singInFormSchema)
  })
  const [language, setLanguage] = useState('pt')

  const { errors } = formState

  const backCountries = (country: Country) => {
    console.log(country)

    switch (country.name) {
      case 'Brasil':
        setLanguage('pt')
        break

      case 'Spain':
        setLanguage('es')
        break

      case 'United States of America':
        setLanguage('en')
        break

      case 'Mexico':
        setLanguage('es')
        break

      default:
        setLanguage('pt')
        break
    }
  }

  const handleRegister: SubmitHandler<SignUpFormData> = useCallback(
    async (values) => {
      await new Promise((resolve) => setTimeout(resolve, 2000))

      const { email, password, name, phone, nameClinic } = values

      const payload = {
        user: {
          name,
          email,
          password
        },
        name_clinic: nameClinic,
        language,
        telephone: phone,
        user_contacts: []
      }

      try {
        await api.post('users/', payload)

        toast({
          title: 'Cadastro realizado com sucesso!',
          description: 'Estamos redirecionando você para o login',
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })

        setTimeout(() => {
          navigation.push('/')
        }, 3000)
      } catch (e: any) {
        if (e.message.indexOf('500') === -1) {
          toast({
            title: 'Não foi possível cadastrar!',
            description: 'Tende novamente mais tarde.',
            status: 'error',
            isClosable: true,
            duration: 3000,
            position: 'top'
          })
        } else {
          toast({
            title: 'Não foi possível cadastrar!',
            description: `${e.response?.data?.errorMessage}`,
            status: 'error',
            isClosable: true,
            duration: 3000,
            position: 'top'
          })
        }
      }
    },
    [language, toast, navigation]
  )

  return (
    <Flex w="100vw" minHeight="100vh" align="center" justify="center">
      <Flex direction="column" width="100%" maxWidth={480} my="4">
        <Flex marginBottom="4" p={[8, 0]} paddingBottom="0">
          <ArrowBack href="/" />
        </Flex>
        <Flex
          as="form"
          width="100%"
          maxWidth={480}
          p="8"
          border={['none', '1px']}
          borderColor={['none', 'gray.100']}
          borderRadius={[0, 8]}
          flexDir="column"
          onSubmit={handleSubmit(handleRegister)}
        >
          <Logo />
          <Flex my="2" w="100%" alignItems="center" justifyContent="center">
            <Text mb="16px" fontSize="16px" fontWeight="bold">
              Criação de clínica e usuário
            </Text>
          </Flex>
          <Text my="2" fontSize="14px" fontWeight="bold">
            Nome da clínica:
          </Text>
          <Input
            type="text"
            size="md"
            {...register('nameClinic')}
            error={errors.nameClinic}
          />
          <Text mt="8" mb="2" fontSize="14px" fontWeight="bold">
            Dados do usuário:
          </Text>
          <Stack>
            <Input
              label="Nome"
              type="text"
              size="md"
              error={errors.name}
              {...register('name')}
            />
            <Input
              label="E-mail"
              type="email"
              size="md"
              error={errors.email}
              {...register('email')}
            />
            <Stack direction="row">
              <InputPhone
                label="Telefone"
                type="text"
                backCountries={backCountries}
                size="md"
                error={errors.phone}
                {...register('phone')}
              />
            </Stack>
            <Input
              label="Senha"
              type="password"
              size="md"
              {...register('password')}
              error={errors.password}
            />
            <Input
              label="Confirmar senha"
              type="password"
              size="md"
              {...register('passwordConfirmation')}
              error={errors.passwordConfirmation}
            />
          </Stack>
          <Button
            type="submit"
            mt="6"
            colorScheme="blue"
            size="lg"
            isLoading={formState.isSubmitting}
          >
            Cadastrar
          </Button>
          <Flex my="8" align="center" justify="center">
            <Text>
              Já possuo uma conta!
              <LinkChakra as={Link} to="/" color="pink.500" marginLeft="2">
                Login
              </LinkChakra>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
