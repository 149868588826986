import { ChevronLeftIcon } from '@chakra-ui/icons'
import { Flex } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { useHistory } from 'react-router-dom'
import { useDnd } from '../../hooks/useDnd'
import { useDocument } from '../../hooks/useDocument'

interface ArrowBackProps {
  href: string
  goBack?: boolean
  children?: ReactNode
}

export const ArrowBack = ({ href = '', goBack, children }: ArrowBackProps) => {
  const navigate = useHistory()
  const { setImgSrc, setUploadedFiles } = useDocument()
  const { setListForms } = useDnd()

  return (
    <Flex
      color="gray.100"
      _hover={{ color: 'gray.500' }}
      cursor="pointer"
      onClick={() => {
        if (goBack) {
          navigate.goBack()
        } else {
          navigate.push(href)
        }
        setImgSrc('')
        setUploadedFiles([])
        setListForms([])
      }}
      align="center"
      justify="center"
    >
      <ChevronLeftIcon w={10} h={10} />
      {children}
    </Flex>
  )
}
