/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable operator-assignment */
/* eslint-disable camelcase */
import {
  Box,
  Button,
  Center,
  CloseButton,
  Flex,
  Spinner,
  Stack,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast
} from '@chakra-ui/react'
import format from 'date-fns/format'
import React, { FC, ReactNode, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AiFillLock, AiFillUnlock } from 'react-icons/ai'
import { useHistory, useParams } from 'react-router-dom'
import { v4 as uuidV4 } from 'uuid'
import { useService } from './service'
import { Input } from '../../components/Form/Input'
import { SelectComponent } from '../../components/Form/SelectComponent'
import { SelectComponentAsync } from '../../components/Form/SelectComponentAsync'
import { Switch } from '../../components/Form/Switch'
import { TextFieldControlled } from '../../components/Form/TextFieldControlled'
import { ModalArrivedTime } from '../../components/Modal/confirmArrivedTime'
import { CardContainer } from '../../components/molecules/CardContainer'
import { PermissionComponent } from '../../components/Permissions'
import { useAuth } from '../../hooks/auth'
import { LayoutDefault } from '../../layouts/Default'
import { apiAuth } from '../../services/apiAuth'
import { formatBrOrDollarDefault } from '../../utils/fns/formatBrOrDollarDefault'
import { remaskCaractersAll } from '../../utils/fns/removeCaracters'
import { Select } from '../../components/Form/Select'

type FormData = {}

type paramsProps = {
  id: string
}

type Attendance = {
  id: number
  guide: number
  patient_id: number
  scheduled_arrival_time: Date
  scheduled_initial_time: string
  scheduled_final_time: string
  real_arrival_time: string | null
  real_initial_time: string | null
  real_final_time: string | null
  status: number
  professional_id: number
  observation: string | null
  observation_payment: string | null
  value_to_income: number | null
  invoice_number: number | null
  invoice_date: string | null
  block_guide: boolean
  is_active: boolean
  specialist_name: string | null
  patient_name: string | null
  patient_social_name: string | null
  patient_birth_date: string | null
  patient_alerts: string | null
  attendance_items_exclude?: Array<number>
  is_conferred: boolean
  attendance_items: Array<{
    cost_center_id: number | null
    discount_currency: string
    discount_percentage: string
    addition: string
    id: number
    initial_price: number
    service_id: number
    service_name: string
    total_session: number
  }>
}

type Item = {
  event_date: string
  id: number | string
  number_parcels: number
  observation: string | null
  date_status_conferred?: string | null
  parcel_value: number
  value: number
  way_to_receive: {
    id: number
    name: string
  }
  status_conferred: number
}

type KeyOptions =
  | 'initial_price'
  | 'discount_percentage'
  | 'discount_currency'
  | 'addition'
  | 'final_price'

type PaymentOptions = Array<{
  id: string | number
  dateEvent: string
  wayToReceive: {
    id: number
    name: string
  }
  value: string
  numberParcels: number
  valueParcels: string
  observation: string | undefined
  statusConference?: ReactNode
  status_conference?: number
}>

type Invoices = {
  created_at: string
  event_date: string
  file: null | string
  id: number | string
  identifier: string
  patient: {
    id: number
    name: string
  }
  tax_unit: {
    id: number
    name: string
  }
  value: number
}

type Ticket = {
  attendances: Array<Attendance>
  invoices: Array<Invoices>
  items: Array<Item>
  status_payment: number | null
  ticket_number: string | null
  discount: number | null
  addition: number | null
  observation_financial?: string
  observation_secretary?: string
}

type Notes = {
  id: string | number
  value: string
  noteNumber: string
  dateEvent: string
  client: {
    id: number
    name: string
  } | null
  unit: {
    id: number
    name: string
  } | null
}

export const ConferenceDailyBox: FC = () => {
  // hooks
  const toast = useToast()
  const { currency } = useAuth()
  const { id } = useParams<paramsProps>()
  const [t] = useTranslation('pageDailyBox')
  const history = useHistory()
  const { handleSubmit } = useForm<FormData>({})
  const services = useService()
  const { state }: any = history.location
  // init states
  const [attendances, setAttendances] = useState<Attendance[]>([])
  const [ticket, setTicket] = useState<Ticket | null>(null)
  const [isFinishedLoading, setIsFinishedLoading] = useState(false)
  const [ticketDiscount, setTicketDiscount] = useState('')
  const [ticketAddition, setTicketAddition] = useState('')
  const [ticketObservation, setTicketObservation] = useState('')
  const [ticketObservationFinancial, setTicketObservationFinancial] =
    useState('')

  // modal cancel ticket
  const [isOpenTicketCancel, setIsOpenTicketCancel] = useState(false)
  const [wayToReceives, setWayToReceives] = useState<PaymentOptions>([])

  // modal confirme conference
  const [isLoading, setIsLoading] = useState(false)

  // control tax units
  const [isMakerTaxNote, setIsMakerTaxNote] = useState<string | undefined>(
    undefined
  )
  const [notes, setNotes] = useState<Notes[]>([
    {
      id: uuidV4(),
      client: null,
      dateEvent: '',
      noteNumber: '',
      unit: null,
      value: ''
    }
  ])

  useEffect(() => {
    if (state?.generate_invoice) {
      setIsMakerTaxNote(String(state?.generate_invoice))
    }
  }, [state])

  // defaultValues status conference way
  const defaultValuesStatusCOnference = [
    {
      value: 1,
      label: (
        <Flex gap={2} alignItems="center" justifyContent="flex-start">
          <Box borderRadius="full" w="25px" h="25px" bg="#c71717f4" />
          <Text>{t('pageConference.statusConference.1')}</Text>
        </Flex>
      ),
      name: `${t('pageConference.statusConference.1')}`
    },
    {
      value: 2,
      label: (
        <Flex gap={2} alignItems="center" justifyContent="flex-start">
          <Box borderRadius="full" w="25px" h="25px" bg="#56c20ff4" />
          <Text>{t('pageConference.statusConference.2')}</Text>
        </Flex>
      ),
      name: `${t('pageConference.statusConference.2')}`
    },
    {
      value: 3,
      label: (
        <Flex gap={2} alignItems="center" justifyContent="flex-start">
          <Box borderRadius="full" w="25px" h="25px" bg="#fd7011f0" />
          <Text>{t('pageConference.statusConference.3')}</Text>
        </Flex>
      ),
      name: `${t('pageConference.statusConference.3')}`
    },
    {
      value: 4,
      label: (
        <Flex gap={2} alignItems="center" justifyContent="flex-start">
          <Box borderRadius="full" w="25px" h="25px" bg="#192facdc" />
          <Text>{t('pageConference.statusConference.4')}</Text>
        </Flex>
      ),
      name: `${t('pageConference.statusConference.4')}`
    }
  ]
  // options invoice
  const optionsInvoices = [
    { value: '1', label: `${t('options.1')}` },
    { value: '2', label: `${t('options.2')}` }
  ]

  // get values page
  useEffect(() => {
    const loadingData = async () => {
      const { data } = await apiAuth.get<Ticket>(
        `clinics/attendances/${id}/tickets/`
      )
      if (data?.ticket_number === '') {
        if (data?.attendances?.length === 0) {
          apiAuth
            .get<Attendance>(`clinics/attendances/${id}/`)
            .then((response) => {
              const attendance_items = response.data.attendance_items.map(
                (item) => {
                  return {
                    cost_center_id: item.cost_center_id,
                    id: item.id,
                    service_id: item.service_id,
                    initial_price: item.initial_price,
                    total_session: item.total_session,
                    service_name: item.service_name,
                    discount_percentage: formatBrOrDollarDefault(
                      String(Number(item.discount_percentage) * 100)
                    ),
                    discount_currency: formatBrOrDollarDefault(
                      String(Number(item.discount_currency) * 100)
                    ),
                    addition: formatBrOrDollarDefault(
                      String(Number(item.addition) * 100)
                    )
                  }
                }
              )

              const addData = {
                attendance_items
              }

              const attendance = [{ ...response.data, ...addData }]

              setAttendances(attendance)
            })
            .finally(() => {
              setIsFinishedLoading(true)
            })
        }
      } else {
        if (data?.ticket_number !== '') {
          setTicket(data)
        }
        // formatar os valores de attendances_itens
        const formatAttendance = data?.attendances?.map((attendance) => {
          const attendance_items = attendance.attendance_items.map((item) => {
            return {
              cost_center_id: item.cost_center_id,
              id: item.id,
              service_id: item.service_id,
              initial_price: item.initial_price,
              total_session: item.total_session,
              service_name: item.service_name,
              discount_percentage: formatBrOrDollarDefault(
                String(Number(item.discount_percentage) * 100)
              ),
              discount_currency: formatBrOrDollarDefault(
                String(Number(item.discount_currency) * 100)
              ),
              addition: formatBrOrDollarDefault(
                String(Number(item.addition) * 100)
              )
            }
          })

          const addData = {
            attendance_items
          }

          return { ...attendance, ...addData }
        })
        // formatar os valores de items
        const formatItems = data?.items?.map((item) => {
          return {
            id: item.id,
            dateEvent: item.event_date.substring(0, 10),
            wayToReceive: {
              id: item.way_to_receive.id,
              name: item.way_to_receive.name
            },
            value: formatBrOrDollarDefault(item.value.toFixed(2)),
            numberParcels: item?.number_parcels,
            valueParcels: formatBrOrDollarDefault(item.parcel_value.toFixed(2)),
            observation: item?.observation || undefined,
            status_conference: item?.status_conferred || undefined,
            statusConference:
              item?.status_conferred !== null
                ? defaultValuesStatusCOnference[item?.status_conferred - 1 || 0]
                : undefined
          }
        })
        setAttendances(formatAttendance)
        setWayToReceives(formatItems)
        setTicketObservation(data?.observation_secretary || '')
        setTicketDiscount(
          formatBrOrDollarDefault(data?.discount?.toFixed(2) || '')
        )
        setTicketAddition(
          formatBrOrDollarDefault(data?.addition?.toFixed(2) || '')
        )
        setTicketObservationFinancial(data?.observation_financial || '')
        setIsFinishedLoading(true)

        if (data?.invoices) {
          const formatInvoices = data?.invoices?.map((invoice) => {
            return {
              id: invoice.id || uuidV4(),
              value: formatBrOrDollarDefault(invoice?.value?.toFixed(2) || ''),
              noteNumber: invoice?.identifier || '',
              dateEvent: invoice?.event_date?.substring(0, 10),
              client:
                {
                  id: invoice?.patient?.id,
                  name: invoice?.patient?.name
                } || null,
              unit:
                {
                  id: invoice?.tax_unit?.id,
                  name: invoice?.tax_unit?.name
                } || null
            }
          })

          setNotes(formatInvoices)
        }
      }
    }

    loadingData()
    setTimeout(() => {
      setIsLoading(true)
    }, 2000)

    return () => {}
  }, [id])

  // logic calculations
  // discountPercent
  const discountPercentagemInPrice = (
    initialValue: number,
    discountPercent: string | null
  ) => {
    const discountCurrency =
      Number(remaskCaractersAll(discountPercent || '0')) / 100
    const valueMultiple = initialValue * discountCurrency
    const valueFinal = valueMultiple / 100
    const valueFixed = valueFinal.toFixed(2)
    const formatCurrency = formatBrOrDollarDefault(valueFixed)

    return formatCurrency
  }

  const finalPriceServiceLine = (
    initialValue: number,
    discountPercent: string | null,
    discountCurrency: string | null,
    addition: string | null,
    isMask?: boolean
  ) => {
    const valueDiscountPercent =
      (initialValue * Number(remaskCaractersAll(discountPercent || '') || 0)) /
      10000
    const valueDiscountCurrency =
      Number(remaskCaractersAll(discountCurrency || '')) / 100
    const valueAddition = Number(remaskCaractersAll(addition || '')) / 100

    const finalPrice =
      initialValue -
      valueDiscountPercent -
      valueDiscountCurrency +
      valueAddition

    if (isMask) {
      return formatBrOrDollarDefault(finalPrice.toFixed(2))
    }

    return finalPrice.toFixed(2)
  }

  // all addition receive key
  const additionReduceTotal = (index: number, key: KeyOptions) => {
    const arraySelect = attendances[index].attendance_items
    const formatArraySelect = arraySelect.map((item) => {
      const percent =
        (item.initial_price *
          Number(remaskCaractersAll(item.discount_percentage || '') || 0)) /
        10000

      return {
        initial_price: item.initial_price,
        discount_percentage: percent,
        discount_currency:
          Number(remaskCaractersAll(item.discount_currency || '')) / 100,
        addition: Number(remaskCaractersAll(item.addition || '')) / 100,
        final_price: Number(
          finalPriceServiceLine(
            item.initial_price,
            item.discount_percentage,
            item.discount_currency,
            item.addition
          )
        )
      }
    })

    return formatArraySelect.reduce(
      (accumulator, item) => accumulator + (item[key] || 0),
      0
    )
  }

  // return Total value in guides
  const totalValueAllGuides = () => {
    let total: number = 0
    attendances.forEach((attendance, index) => {
      const finalValueGuide = additionReduceTotal(index, 'final_price')
      total = total + finalValueGuide
    })

    const valueFixed = total.toFixed(2)
    const formatCurrency = formatBrOrDollarDefault(valueFixed)

    return formatCurrency
  }

  // return prevision to pay
  const returnPrevisionPaymentGuides = () => {
    const totalValueGuidesNumber =
      Number(remaskCaractersAll(totalValueAllGuides())) / 100
    const discount = Number(remaskCaractersAll(ticketDiscount)) / 100
    const addition = Number(remaskCaractersAll(ticketAddition)) / 100

    const valueToPay = totalValueGuidesNumber - discount + addition

    const valueFixed = valueToPay.toFixed(2)
    const formatCurrency = formatBrOrDollarDefault(valueFixed)

    return formatCurrency
  }

  // calculate way_to_receives for pay and pay partial
  const returnsTheAmountAlreadyPaid = () => {
    let total: number = 0

    wayToReceives.forEach((way) => {
      const valueTotalWay = Number(remaskCaractersAll(way.value)) / 100

      total = total + valueTotalWay
    })

    const valueFixed = total.toFixed(2)
    const formatCurrency = formatBrOrDollarDefault(valueFixed)

    return formatCurrency
  }

  // returns Remains to be paid
  const returnsTheAmountThatRemainsToBePaid = () => {
    let valueRemainsToBePaid: number = 0

    const valuePaid =
      Number(remaskCaractersAll(returnsTheAmountAlreadyPaid())) / 100
    const valueTotalTicket =
      Number(remaskCaractersAll(returnPrevisionPaymentGuides())) / 100

    valueRemainsToBePaid = valueTotalTicket - valuePaid

    const valueFixed = valueRemainsToBePaid.toFixed(2)
    const formatCurrency = formatBrOrDollarDefault(valueFixed)

    return formatCurrency
  }

  // jxs status item ticket
  const returnJsxElementStatusTicket = () => {
    const balance =
      Number(remaskCaractersAll(returnsTheAmountThatRemainsToBePaid())) / 100
    const valuePaid =
      Number(remaskCaractersAll(returnsTheAmountAlreadyPaid())) / 100
    const valueTotalTicket =
      Number(remaskCaractersAll(returnPrevisionPaymentGuides())) / 100

    if (valuePaid === 0 && ticket?.status_payment === 5) {
      return (
        <Tag
          size="sm"
          bg="#141412"
          color="white"
          fontSize="14px"
          fontWeight="bold"
        >
          {t('pageConference.statusTicket.5')}
        </Tag>
      )
    }

    if (valuePaid === 0) {
      return (
        <Tag
          size="sm"
          bg="#c71717f4"
          color="white"
          fontSize="14px"
          fontWeight="bold"
        >
          {t('pageConference.statusTicket.1')}
        </Tag>
      )
    }

    if (balance === 0) {
      return (
        <Tag
          size="sm"
          bg="#56c20ff4"
          color="white"
          fontSize="14px"
          fontWeight="bold"
        >
          {t('pageConference.statusTicket.2')}
        </Tag>
      )
    }

    if (valuePaid > 0 && valuePaid < valueTotalTicket) {
      return (
        <Tag
          size="sm"
          bg="#fd7011f0"
          color="white"
          fontSize="14px"
          fontWeight="bold"
        >
          {t('pageConference.statusTicket.3')}
        </Tag>
      )
    }

    if (valuePaid > valueTotalTicket) {
      return (
        <Tag
          size="sm"
          bg="#192facdc"
          color="white"
          fontSize="14px"
          fontWeight="bold"
        >
          {t('pageConference.statusTicket.4')}
        </Tag>
      )
    }

    return null
  }

  // update observation financial
  useEffect(() => {
    const observation_financial = ticketObservationFinancial

    const payload = {
      observation_financial
    }

    if (ticket) {
      apiAuth.patch(`clinics/attendances/${id}/tickets/`, payload)
    }
    return () => {}
  }, [ticketObservationFinancial])

  useEffect(() => {
    const formatAttendance = () => {
      const attendancesFormatted = attendances.map((attendance) => {
        const attendance_items_exclude =
          attendance?.attendance_items_exclude || []
        const attendance_items = attendance.attendance_items.map((item) => {
          return {
            cost_center_id: item.cost_center_id,
            discount_percentage:
              Number(remaskCaractersAll(item.discount_percentage)) / 100,
            discount_currency:
              Number(remaskCaractersAll(item.discount_currency)) / 100,
            addition: Number(remaskCaractersAll(item.addition)) / 100,
            total_session: item.total_session,
            service_name: item.service_name,
            initial_price: item.initial_price,
            service_id: item.service_id,
            id: item.id
          }
        })

        const data = {
          attendance_items,
          attendance_items_exclude
        }

        return { ...attendance, ...data }
      })

      return attendancesFormatted
    }

    const dataTotal = formatAttendance()

    dataTotal.forEach((payload) => {
      apiAuth.put(`clinics/attendances/${payload.id}/`, payload)
    })

    return () => {}
  }, [attendances])

  // save infos items (paid)
  useEffect(() => {
    if (isLoading) {
      const formatWayToReceiveItems = () => {
        const formatWays = wayToReceives.map((way) => {
          return {
            way_to_receive: {
              id: way.wayToReceive.id
            },
            id: way.id,
            event_date: `${way.dateEvent}T00:00:00`,
            number_parcels: way.numberParcels,
            value: Number(remaskCaractersAll(way.value)) / 100,
            observation: way.observation,
            status_conferred: way.status_conference || null,
            date_status_conferred: format(
              new Date(),
              "yyyy-MM-dd'T'HH':'mm':'ss"
            )
          }
        })

        return formatWays
      }

      const items = formatWayToReceiveItems()

      const payload = {
        items
      }

      if (ticket) {
        apiAuth.patch(`clinics/attendances/${id}/tickets/`, payload)
      }
    }
  }, [wayToReceives])

  // update block and conference guide
  const handlerChangeConferenceGuide = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const values = [...attendances]

    values[index].block_guide = event.target.checked
    values[index].is_conferred = event.target.checked

    setAttendances(values)
  }

  // update block and conference way
  const handlerChangeConferenceWay = (event: any, index: number) => {
    const values = [...wayToReceives]

    values[index].statusConference = event
    values[index].status_conference = event?.value || 1

    setWayToReceives(values)
  }

  // cancel ticket
  const handlerCancelTicket = async () => {
    try {
      const { data } = await apiAuth.patch<Ticket>(
        `clinics/attendances/${id}/tickets/`,
        {
          status_payment: 5
        }
      )

      if (data?.ticket_number === '') {
        if (data?.attendances?.length === 0) {
          apiAuth
            .get<Attendance>(`clinics/attendances/${id}/`)
            .then((response) => {
              const attendance_items = response.data.attendance_items.map(
                (item) => {
                  return {
                    cost_center_id: item.cost_center_id,
                    id: item.id,
                    service_id: item.service_id,
                    initial_price: item.initial_price,
                    total_session: item.total_session,
                    service_name: item.service_name,
                    discount_percentage: formatBrOrDollarDefault(
                      String(Number(item.discount_percentage) * 100)
                    ),
                    discount_currency: formatBrOrDollarDefault(
                      String(Number(item.discount_currency) * 100)
                    ),
                    addition: formatBrOrDollarDefault(
                      String(Number(item.addition) * 100)
                    )
                  }
                }
              )

              const addData = {
                attendance_items
              }

              const attendance = [{ ...response.data, ...addData }]

              setAttendances(attendance)
            })
            .finally(() => {
              setIsFinishedLoading(true)
            })
        }
      } else {
        if (data?.ticket_number !== '') {
          setTicket(data)
        }
        // formatar os valores de attendances_itens
        const formatAttendance = data?.attendances?.map((attendance) => {
          const attendance_items = attendance.attendance_items.map((item) => {
            return {
              cost_center_id: item.cost_center_id,
              id: item.id,
              service_id: item.service_id,
              initial_price: item.initial_price,
              total_session: item.total_session,
              service_name: item.service_name,
              discount_percentage: formatBrOrDollarDefault(
                String(Number(item.discount_percentage) * 100)
              ),
              discount_currency: formatBrOrDollarDefault(
                String(Number(item.discount_currency) * 100)
              ),
              addition: formatBrOrDollarDefault(
                String(Number(item.addition) * 100)
              )
            }
          })

          const addData = {
            attendance_items
          }

          return { ...attendance, ...addData }
        })
        // formatar os valores de items
        const formatItems = data?.items?.map((item) => {
          return {
            id: item.id,
            dateEvent: item.event_date.substring(0, 10),
            wayToReceive: {
              id: item.way_to_receive.id,
              name: item.way_to_receive.name
            },
            value: formatBrOrDollarDefault(item.value.toFixed(2)),
            numberParcels: item?.number_parcels,
            valueParcels: formatBrOrDollarDefault(item.parcel_value.toFixed(2)),
            observation: item?.observation || undefined,
            status_conference: item?.status_conferred || undefined,
            statusConference:
              item?.status_conferred !== null
                ? defaultValuesStatusCOnference[item?.status_conferred - 1 || 0]
                : undefined
          }
        })
        setAttendances(formatAttendance)
        setWayToReceives(formatItems)
        setTicketObservation(data?.observation_secretary || '')
        setTicketDiscount(
          formatBrOrDollarDefault(String(Number(data?.discount) * 100))
        )
        setTicketAddition(
          formatBrOrDollarDefault(String(Number(data?.addition) * 100))
        )
        setTicketObservationFinancial(data?.observation_financial || '')
        setIsFinishedLoading(true)
      }
    } catch (error: any) {
      toast({
        position: 'top-right',
        status: 'error',
        title: 'Error!',
        description: 'Não foi possível realizar ação'
      })
    }
  }

  // control tax units functions
  const handlerAddNote = () => {
    setNotes([
      ...notes,
      {
        id: uuidV4(),
        client: null,
        dateEvent: '',
        noteNumber: '',
        unit: null,
        value: ''
      }
    ])
  }

  const handlerRemoveNote = (index: number) => {
    const allNotes = [...notes]
    allNotes.splice(index, 1)
    setNotes(allNotes)
  }

  if (!isFinishedLoading) {
    return (
      <LayoutDefault urlBack="/scheduling" title={`${t('title')}`}>
        <Center h="100vh" w="100vw">
          <Spinner color="blue.300" size="lg" />
        </Center>
      </LayoutDefault>
    )
  }

  // format notes
  const formatNotesForApi = () => {
    const formattedNotes = notes.map((not) => {
      return {
        value: Number(remaskCaractersAll(not.value)) / 100,
        tax_unit: {
          id: not.unit?.id
        },
        identifier: not?.noteNumber,
        ticket: Number(id),
        patient: { id: not?.client?.id },
        event_date: `${not?.dateEvent}T00:00:00`
      }
    })

    return formattedNotes
  }

  // submit event form
  const handleSubmitSaveInvoices: SubmitHandler<FormData> = async (
    values,
    event
  ) => {
    event?.preventDefault()

    const invoices = formatNotesForApi()

    if (isMakerTaxNote !== '1') {
      const payload = {
        generate_invoice: isMakerTaxNote === '' ? null : isMakerTaxNote,
        invoices: []
      }

      await apiAuth.patch(`clinics/tickets/${state?.id}/`, payload)

      toast({
        status: 'success',
        description: 'Informação de nota fiscal atualizada'
      })
    }

    if (isMakerTaxNote === '1') {
      try {
        const payload = {
          generate_invoice: isMakerTaxNote,
          invoices
        }

        await apiAuth.patch(`clinics/tickets/${state?.id}/`, payload)

        toast({
          status: 'success',
          description:
            'Dados atualizados para esse ticket, e notas fiscais com sucesso'
        })
      } catch (error: any) {
        toast({
          status: 'error',
          description: 'Não foi possível atualizar, tente novamente'
        })
      }
    }
  }

  // reset status received item
  const handleResetMeansOfReceiveStatus = () => {
    const values = [...wayToReceives]

    const data = {
      status_conference: undefined,
      statusConference: null
    }

    const newValues = values.map((item) => {
      return { ...item, ...data }
    })

    setWayToReceives(newValues)
  }

  return (
    <>
      <LayoutDefault
        urlBack="/financial/daily-box"
        title={`${t('pageConference.title')}`}
        onSubmit={handleSubmit(handleSubmitSaveInvoices)}
      >
        <Stack justify="space-evenly" spacing="2" w="100%">
          {attendances?.map((attendance, index) => (
            <CardContainer
              title={`${t('pageConference.guide')}#${attendance?.guide} - ${
                attendance.patient_name
              } - ${format(
                new Date(attendance.scheduled_arrival_time),
                'dd/MM/yyyy'
              )} - ${t('pageConference.specialist')}${
                attendance?.specialist_name
              }`}
              key={attendance.id}
            >
              <Stack direction="row" position="absolute" top="4" right="4">
                <PermissionComponent ipCode="EDIT" spiCode="BILLS_TO_RECEIVE">
                  <Switch
                    size="sm"
                    isChecked={attendance?.block_guide}
                    name="conferenceGuide"
                    label={t('pageConference.block')}
                    onChange={(event) => {
                      handlerChangeConferenceGuide(event, index)
                    }}
                  />
                </PermissionComponent>
                {attendance?.block_guide ? (
                  <Tooltip
                    label={t('block')}
                    aria-label={t('block')}
                    hasArrow
                    placement="top-start"
                  >
                    <Box>
                      <AiFillLock size={20} color="red" />
                    </Box>
                  </Tooltip>
                ) : (
                  <Tooltip
                    label={t('noBlock')}
                    aria-label={t('noBlock')}
                    hasArrow
                    placement="top-start"
                  >
                    <Box>
                      <AiFillUnlock size={20} color="green" />
                    </Box>
                  </Tooltip>
                )}
              </Stack>
              <Flex my="2" gap={2} flexDir="column">
                <Text fontSize="14px">
                  {t('pageConference.observationPayment')}
                </Text>
                <Textarea
                  placeholder={t(
                    'pageConference.placeholderObservationPayment'
                  )}
                  name="paymentObservation"
                  value={attendance?.observation_payment || ''}
                  disabled
                />
              </Flex>
              <Table>
                <Thead>
                  <Tr>
                    <Th w="200px">{t('pageConference.serviceName')}</Th>
                    <Th textAlign="right">
                      {t('pageConference.initialValue')}
                    </Th>
                    <Th textAlign="right">
                      {t('pageConference.percentDiscount')}
                    </Th>
                    <Th textAlign="right">
                      {t('pageConference.valueDiscount')}
                    </Th>
                    <Th textAlign="right">{t('pageConference.discount')}</Th>
                    <Th textAlign="right">{t('pageConference.addition')}</Th>
                    <Th textAlign="right">{t('pageConference.finalValue')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {attendance?.attendance_items?.map((item, indexService) => (
                    <Tr key={item?.id}>
                      <Td>
                        <Text fontSize="xs" fontWeight="bold">
                          {item.service_name}
                        </Text>
                      </Td>
                      <Td>
                        <Box>
                          <TextFieldControlled
                            name="flashRate"
                            mask={
                              currency === 'BRL' ? 'monetary' : 'monetaryDollar'
                            }
                            maxLength={10}
                            disabled
                            value={formatBrOrDollarDefault(
                              String(item.initial_price.toFixed(2))
                            )}
                          />
                        </Box>
                      </Td>
                      <Td>
                        <Box>
                          <TextFieldControlled
                            max={100}
                            min={0.01}
                            name="percentRate"
                            mask="porcentagem"
                            disabled
                            initialValue={formatBrOrDollarDefault(
                              String(
                                attendances[index].attendance_items[
                                  indexService
                                ].discount_percentage
                              )
                            )}
                          />
                        </Box>
                      </Td>
                      <Td textAlign="right">
                        <Box textAlign="right">
                          <Text textAlign="right" fontWeight="hairline">
                            {discountPercentagemInPrice(
                              item.initial_price,
                              item.discount_percentage
                            )}
                          </Text>
                        </Box>
                      </Td>
                      <Td>
                        <Box>
                          <TextFieldControlled
                            name="discount"
                            mask={
                              currency === 'BRL' ? 'monetary' : 'monetaryDollar'
                            }
                            initialValue={formatBrOrDollarDefault(
                              String(
                                attendances[index].attendance_items[
                                  indexService
                                ].discount_currency
                              )
                            )}
                            disabled
                          />
                        </Box>
                      </Td>
                      <Td>
                        <Box>
                          <TextFieldControlled
                            name="addition"
                            mask={
                              currency === 'BRL' ? 'monetary' : 'monetaryDollar'
                            }
                            initialValue={formatBrOrDollarDefault(
                              String(
                                attendances[index].attendance_items[
                                  indexService
                                ].addition
                              )
                            )}
                            disabled
                          />
                        </Box>
                      </Td>
                      <Td w="200px" textAlign="right">
                        <Stack
                          direction="row"
                          spacing="6"
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          <Text fontWeight="bold" textAlign="right">
                            {finalPriceServiceLine(
                              item.initial_price,
                              item.discount_percentage,
                              item.discount_currency,
                              item.addition,
                              true
                            )}
                          </Text>
                        </Stack>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <Table>
                <Tr>
                  <Th textAlign="left" w="220px">
                    {t('totalGuide')}
                  </Th>
                  <Th textAlign="right" fontSize="16px">
                    {formatBrOrDollarDefault(
                      additionReduceTotal(index, 'initial_price').toFixed(2)
                    )}
                  </Th>
                  <Th>
                    <Box w="180px" />
                  </Th>
                  <Th textAlign="left" w="160px" fontSize="16px">
                    {formatBrOrDollarDefault(
                      additionReduceTotal(index, 'discount_percentage').toFixed(
                        2
                      )
                    )}
                  </Th>
                  <Th textAlign="left" w="162px" fontSize="16px">
                    {formatBrOrDollarDefault(
                      additionReduceTotal(index, 'discount_currency').toFixed(2)
                    )}
                  </Th>
                  <Th textAlign="left" w="162px" fontSize="16px">
                    {formatBrOrDollarDefault(
                      additionReduceTotal(index, 'addition').toFixed(2)
                    )}
                  </Th>
                  <Th textAlign="right" w="145px" fontSize="16px">
                    {formatBrOrDollarDefault(
                      additionReduceTotal(index, 'final_price').toFixed(2)
                    )}
                  </Th>
                </Tr>
              </Table>
            </CardContainer>
          ))}
          {!ticket ? (
            <Flex
              w="100%"
              justifyContent="center"
              p="4"
              borderRadius={8}
              border="1px solid gray"
            >
              <Text fontFamily="heading" fontWeight="bold">
                Nâo existe ticket para essa guia.
              </Text>
            </Flex>
          ) : (
            <CardContainer title={t('pageConference.ticket')}>
              <Text position="absolute" top="4" left="100px">
                {`${ticket?.ticket_number}`}
                {/* {`#${ticket.ticket_number} - ${format(
                  new Date(
                    Number(ticket?.created_at?.substring(0, 4)),
                    Number(ticket?.created_at?.substring(5, 7)) - 1,
                    Number(ticket?.created_at?.substring(8, 10))
                  ),
                  'dd/MM/yyyy'
                )}`} */}
              </Text>
              {wayToReceives.length === 0 && ticket.status_payment === 1 && (
                <Button
                  position="absolute"
                  top="4"
                  size="sm"
                  bg="red.500"
                  color="white"
                  left="160px"
                  onClick={() => setIsOpenTicketCancel(true)}
                >
                  {t('pageConference.cancel')}
                </Button>
              )}
              <Table>
                <Thead>
                  <Tr>
                    <Th w="200px" textAlign="right">
                      {t('pageConference.totalGuides')}
                    </Th>
                    <Th w="180px" textAlign="right">
                      {t('pageConference.discountTicket')}
                    </Th>
                    <Th w="180px" textAlign="right">
                      {t('pageConference.additionTicket')}
                    </Th>
                    <Th textAlign="right">{t('pageConference.payable')}</Th>
                    <Th textAlign="right">{t('pageConference.paid')}</Th>
                    <Th textAlign="right">
                      {t('pageConference.balanceToPay')}
                    </Th>
                    <Th>{t('pageConference.status')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Td textAlign="right">
                    <Text textAlign="right" fontWeight="bold">
                      {totalValueAllGuides()}
                    </Text>
                  </Td>
                  <Td>
                    <TextFieldControlled
                      name="discountTicket"
                      disabled
                      mask={currency === 'BRL' ? 'monetary' : 'monetaryDollar'}
                      inputOnChange={(event) =>
                        setTicketDiscount(event.currentTarget.value)
                      }
                      initialValue={ticketDiscount}
                    />
                  </Td>
                  <Td>
                    <TextFieldControlled
                      name="additionTicket"
                      disabled
                      mask={currency === 'BRL' ? 'monetary' : 'monetaryDollar'}
                      inputOnChange={(event) =>
                        setTicketAddition(event.currentTarget.value)
                      }
                      initialValue={ticketAddition}
                    />
                  </Td>
                  <Td textAlign="right">{returnPrevisionPaymentGuides()}</Td>
                  <Td textAlign="right">{returnsTheAmountAlreadyPaid()}</Td>
                  <Td textAlign="right">
                    {returnsTheAmountThatRemainsToBePaid()}
                  </Td>
                  <Td>{returnJsxElementStatusTicket()}</Td>
                </Tbody>
              </Table>
              <Flex my="2" gap={2} flexDir="column">
                <Text fontSize="14px">
                  {t('pageConference.observationTicket')}
                </Text>
                <Textarea
                  placeholder={t('pageConference.placeholderObservationTicket')}
                  name="paymentObservation"
                  value={ticketObservation}
                  disabled
                  onChange={(event) => setTicketObservation(event.target.value)}
                />
              </Flex>
            </CardContainer>
          )}
          {ticket && (
            <CardContainer title={t('pageConference.items.title')}>
              <Stack direction="row" position="absolute" top="4" right="4">
                <Button
                  size="sm"
                  bg="blue.300"
                  color="white"
                  onClick={() => handleResetMeansOfReceiveStatus()}
                >
                  {t('pageConference.reset')}
                </Button>
              </Stack>
              <Table>
                <Thead>
                  <Tr>
                    <Th textAlign="left">
                      {t('pageConference.items.dateEvent')}
                    </Th>
                    <Th textAlign="left">
                      {t('pageConference.items.wayToPayment')}
                    </Th>
                    <Th textAlign="right">{t('pageConference.items.value')}</Th>
                    <Th textAlign="center">
                      {t('pageConference.items.numberParcels')}
                    </Th>
                    <Th textAlign="right">
                      {t('pageConference.items.amountParcel')}
                    </Th>
                    <Th textAlign="left" w="280px">
                      {t('pageConference.items.actions')}
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {wayToReceives?.map((way, index) => (
                    <Tr key={way.id}>
                      <Td>
                        {format(
                          new Date(
                            Number(way.dateEvent.substring(0, 4)),
                            Number(way.dateEvent.substring(5, 7)) - 1,
                            Number(way.dateEvent.substring(8, 10))
                          ),
                          'dd/MM/yyyy'
                        )}
                      </Td>
                      <Td>{way.wayToReceive.name}</Td>
                      <Td textAlign="right">{way.value}</Td>
                      <Td textAlign="center">{way.numberParcels}</Td>
                      <Td textAlign="right">{way.valueParcels}</Td>
                      <Td>
                        <PermissionComponent
                          ipCode="EDIT"
                          spiCode="BILLS_TO_RECEIVE"
                        >
                          <SelectComponent
                            name="conferenceWay"
                            title={t('pageConference.conference')}
                            options={defaultValuesStatusCOnference}
                            value={way.statusConference}
                            placeholder={t('pageConference.placeholderStatus')}
                            defaultValue={1}
                            onChange={(event: any) => {
                              handlerChangeConferenceWay(event, index)
                            }}
                          />
                        </PermissionComponent>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <Flex my="2" gap={2} flexDir="column">
                <PermissionComponent ipCode="EDIT" spiCode="BILLS_TO_RECEIVE">
                  <Text fontSize="14px">
                    {t('pageConference.observationTicketFinancial')}
                  </Text>
                  <Textarea
                    placeholder={t('pageConference.placeholderTicketFinancial')}
                    name="paymentObservation"
                    value={ticketObservationFinancial}
                    onChange={(event) =>
                      setTicketObservationFinancial(event.target.value)
                    }
                  />
                </PermissionComponent>
              </Flex>
            </CardContainer>
          )}
          {ticket && (
            <CardContainer title={t('pageTaxControl.tax.title')}>
              <Box position="absolute" top="4" right="4">
                <Tooltip label="Sim ou não?" aria-label="Sim ou não?">
                  <Box>
                    <Select
                      name="isTaxControl"
                      value={isMakerTaxNote}
                      placeholder="Selecione sim ou não"
                      options={optionsInvoices}
                      onChange={(event) =>
                        setIsMakerTaxNote(event.target.value)
                      }
                    />
                  </Box>
                </Tooltip>
              </Box>
              {isMakerTaxNote === '1' && (
                <>
                  <Table>
                    <Thead>
                      <Tr>
                        <Th textAlign="left">
                          {t('pageTaxControl.tax.dateEvent')}
                        </Th>
                        <Th textAlign="left">
                          {t('pageTaxControl.tax.patient')}
                        </Th>
                        <Th textAlign="left">{t('pageTaxControl.tax.unit')}</Th>
                        <Th textAlign="right">
                          {t('pageTaxControl.tax.value')}
                        </Th>
                        <Th textAlign="right">
                          {t('pageTaxControl.tax.numberTax')}
                        </Th>
                        {notes.length > 1 && (
                          <Th textAlign="right">
                            {t('pageTaxControl.tax.actions')}
                          </Th>
                        )}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {notes?.map((note, index) => (
                        <Tr key={note.id}>
                          <Td w="100px">
                            <Input
                              isRequired
                              name="dateEvent"
                              type="date"
                              value={note.dateEvent}
                              onChange={(event) => {
                                const allNotes = [...notes]
                                allNotes[index].dateEvent = event.target.value

                                setNotes(allNotes)
                              }}
                            />
                          </Td>
                          <Td w="400px">
                            <SelectComponentAsync
                              name="client"
                              loadOptions={(values: string) =>
                                services.getClients(values)
                              }
                              isRequired
                              value={{
                                value: note?.client?.id,
                                label: note?.client?.name
                              }}
                              onChange={(event: any) => {
                                const allNotes = [...notes]
                                allNotes[index].client = {
                                  id: event?.value,
                                  name: event?.label
                                }

                                setNotes(allNotes)
                              }}
                            />
                          </Td>
                          <Td w="250px">
                            <SelectComponent
                              name="unit"
                              options={services.taxUnits.map((tax) => {
                                return {
                                  value: tax?.id,
                                  label: tax?.name
                                }
                              })}
                              isRequired
                              value={{
                                value: note?.unit?.id,
                                label: note?.unit?.name
                              }}
                              onChange={(event: any) => {
                                const allNotes = [...notes]
                                allNotes[index].unit = {
                                  id: event?.value,
                                  name: event?.label
                                }

                                setNotes(allNotes)
                              }}
                            />
                          </Td>
                          <Td w="210px">
                            <TextFieldControlled
                              name="value"
                              initialValue={note.value}
                              mask={
                                currency === 'BRL'
                                  ? 'monetary'
                                  : 'monetaryDollar'
                              }
                              isRequired
                              inputOnChange={(event) => {
                                const allNotes = [...notes]
                                allNotes[index].value =
                                  event.currentTarget.value

                                setNotes(allNotes)
                              }}
                            />
                          </Td>
                          <Td w="250px">
                            <TextFieldControlled
                              textAlign="left"
                              name="noteNumber"
                              maxLength={100}
                              placeholder=""
                              initialValue={note.noteNumber}
                              isRequired
                              inputOnChange={(event) => {
                                const allNotes = [...notes]
                                allNotes[index].noteNumber =
                                  event.currentTarget.value

                                setNotes(allNotes)
                              }}
                            />
                          </Td>
                          {notes?.length > 1 && (
                            <Stack
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <CloseButton
                                m="2"
                                onClick={() => handlerRemoveNote(index)}
                              />
                            </Stack>
                          )}
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                  <Flex my="2" w="100%" justifyContent="flex-start">
                    <Button
                      size="sm"
                      color="white"
                      bg="blue.300"
                      onClick={() => handlerAddNote()}
                    >
                      Adicionar Nota
                    </Button>
                  </Flex>
                </>
              )}
            </CardContainer>
          )}
          <Flex
            w="100%"
            justifyContent="flex-end"
            onClick={() => {
              handleSubmit(handleSubmitSaveInvoices)
            }}
          >
            <Button type="submit" bg="blue.300" color="white">
              {t('save')}
            </Button>
          </Flex>
          <Stack w="100%" h="300px" />
        </Stack>
      </LayoutDefault>
      <ModalArrivedTime
        isOpen={isOpenTicketCancel}
        onUpdate={async () => {
          await handlerCancelTicket()
          setIsOpenTicketCancel(false)
        }}
        onClose={() => setIsOpenTicketCancel(false)}
        title={t('pageConference.cancelTicketTitle')}
      >
        {t('pageConference.cancelTicketDescription')}
      </ModalArrivedTime>
    </>
  )
}
