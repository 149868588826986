import { cloneElement, ReactElement } from 'react'
import { Link, LinkProps, useHistory } from 'react-router-dom'

interface ActiveLinkProps extends LinkProps {
  children: ReactElement
  shouldMatchExactHref?: boolean
}

export function ActiveLink({
  children,
  shouldMatchExactHref = false,
  ...rest
}: ActiveLinkProps) {
  const { location } = useHistory()

  let isActive = false

  if (shouldMatchExactHref && location.pathname === rest.to) {
    isActive = true
  }

  if (!shouldMatchExactHref && location.pathname.startsWith(String(rest.to))) {
    isActive = true
  }

  return (
    <Link {...rest}>
      {cloneElement(children, {
        color: isActive ? 'blue.500' : 'gray.400'
      })}
    </Link>
  )
}
