/* eslint-disable camelcase */
import { useQuery } from 'react-query'
import { apiAuth } from '../../services/apiAuth'

export type DocumentsData = {
  id: string
  name: string
  document: string
  actions: number
  description: string
}

export type ResponseGetDocuments = {
  documents: DocumentsData[]
  totalCountOfPage: number
}

export const getDocuments = async (
  clinicsId: string = '',
  page: number,
  search: string = '',
  isActive: boolean = true
): Promise<ResponseGetDocuments> => {
  try {
    const { data } = await apiAuth.get(
      `clinics/${clinicsId}/documents-service/`,
      {
        params: {
          page,
          name__icontains: search,
          is_active: isActive
        }
      }
    )

    const totalCountOfPage = data.count
    const documents: DocumentsData[] = data.results
    console.log(data.results)

    return {
      documents,
      totalCountOfPage
    }
  } catch (error: any) {
    console.log(error)

    const documents: DocumentsData[] = []
    const totalCountOfPage = 0
    return {
      documents,
      totalCountOfPage
    }
  }
}

export function useDocumentsServices(
  page: number,
  functionLoading: Promise<ResponseGetDocuments>,
  search: string,
  isActive: boolean,
  options?: {}
) {
  return useQuery(
    ['tagsServices', page, search, isActive],
    () => functionLoading,
    {
      staleTime: 1000 * 2, // 2 seconds
      cacheTime: 1000 * 2, // 2 seconds
      ...options
    }
  )
}
