/* eslint-disable no-alert */
/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-duplicates */
/* eslint-disable camelcase */
import React, { FC, useEffect, useState } from 'react'
import {
  Text,
  Flex,
  Box,
  Stack,
  Divider,
  List,
  ListItem,
  Center,
  Spinner
} from '@chakra-ui/react'
import FullCalendar, {
  DateSelectArg,
  DatesSetArg,
  EventClickArg,
  EventContentArg,
  EventHoveringArg
} from '@fullcalendar/react'
import pt from '@fullcalendar/core/locales/pt-br'
import es from '@fullcalendar/core/locales/es-us'
import en from '@fullcalendar/core/locales/en-au'
import bootstrap5Plugin from '@fullcalendar/bootstrap5'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../hooks/auth'
import { apiAuth } from '../../services/apiAuth'

interface Scheduling {
  id: number
  guide: number
  patient_id: number
  scheduled_arrival_time: string
  scheduled_initial_time: string
  scheduled_final_time: string
  real_arrival_time: string
  real_initial_time: string
  real_final_time: string
  status: number
  professional_id: number
  attendance_spot_id: number
  observation: string
  observation_payment: string
  value_to_income: number
  invoice_number: number | null
  invoice_date: string | null
  block_guide: boolean
  is_active: boolean
  specialist_name: string | null
  patient_name: string | null
  specialist_color: string | null
  patient_social_name: string | null
  attendance_items_exclude?: Array<number>
  ticket_status_payment?: number | null
  attendance_items: Array<{
    id: number
    service_name: string
    service_id: number
  }>
  attendance_spot: {
    id: number
    index: number
    name: string
    is_active: boolean
  }
}

type Services = Array<{
  id: number
  service_name: string
  service_id: number
}>

interface EventsScheduling {
  start: string
  end: string
  title: string
  update: string
}

interface ViewDayTimelineProps {
  initialDate: string
  data: Array<Scheduling> | undefined
  setStartAt: React.Dispatch<React.SetStateAction<string>>
  setEndAt: React.Dispatch<React.SetStateAction<string>>
  specialistId: String | undefined
}

type ViewCalendar = 'timeGridDay' | 'timeGridWeek' | 'dayGridMonth' | string

// logic click and double click let variables and date select handler
let clicks: number = 0
let timer: any = setTimeout(() => {}, 750)

export const ViewDayTimeline: FC<ViewDayTimelineProps> = ({
  initialDate,
  data,
  setEndAt,
  setStartAt,
  specialistId
}) => {
  const { decodeToken } = useAuth()
  const [date, setDate] = useState(initialDate)
  const [dateClick, setDateClick] = useState('')
  const [events, setEvents] = useState<EventsScheduling[] | undefined>([])
  const [typeView, setTypeView] = useState<ViewCalendar>('timeGridDay')
  const [showPopover, setShowPopover] = useState(false)
  const [guide, setGuide] = useState(useState(''))
  const [patientName, setPatientName] = useState('')
  const [specialistName, setSpecialistName] = useState('')
  const [services, setServices] = useState<Services>([])
  const [statusPayment, setStatusPayment] = useState<number | null | undefined>(
    null
  )
  const [color, setColor] = useState('blue.300')
  const [isLoadingInfos, setIsLoadingInfos] = useState(false)
  const locale = navigator.language
  const history = useHistory()

  // reset logic handler click
  const reset = () => {
    clicks = 0
    timer = setTimeout(() => {}, 750)
    setDateClick('')
  }

  useEffect(() => {
    if (typeView === 'timeGridDay') {
      const arrayEvent = data?.map((event) => {
        return {
          idAttendance: event?.id,
          start: event?.scheduled_arrival_time,
          end: event?.scheduled_final_time,
          title: `#${event?.guide || ''} - ${
            event?.patient_name || event?.patient_social_name
          }`,
          update: `/scheduling/update/${event?.id}`,
          patient_name: event?.patient_name,
          specialist_name: event?.specialist_name,
          color: event?.specialist_color || undefined,
          specialist_color: event?.specialist_color || undefined
        }
      })
      setEvents(arrayEvent)
    } else {
      const arrayEvent = data?.map((event) => {
        return {
          idAttendance: event?.id,
          start: event?.scheduled_arrival_time,
          end: event?.scheduled_final_time,
          title: `${event?.patient_social_name || event?.patient_name}`,
          update: `/scheduling/update/${event?.id}`,
          guide: event?.guide,
          color: event?.specialist_color || undefined,
          specialist_color: event?.specialist_color || undefined
        }
      })
      setEvents(arrayEvent)
    }

    const newDate = initialDate

    setDate(newDate)
  }, [data, initialDate, typeView])

  const datesSet = (arg: DatesSetArg) => {
    setStartAt(arg.startStr)
    setEndAt(arg.endStr)
  }

  const handlerDataClick = (arg: DateClickArg) => {
    // handlerNumberClicks
    clicks++
    setDateClick(arg.dateStr)

    // logic
    if (clicks === 1) {
      timer = setTimeout(() => {
        reset()
      }, 750)
    }

    if (clicks === 2) {
      timer = setTimeout(() => {
        history.push('/scheduling/create', {
          id: specialistId,
          selectedDate: dateClick
        })
        reset()
      }, 750)
    }
  }

  const EventClick = (arg: EventClickArg) => {
    history.push(arg.event._def.extendedProps.update)
  }

  const select = (arg: DateSelectArg) => {
    console.log(arg)
  }

  const changeContentView = (arg: EventContentArg) => {
    if (typeView !== arg.view.type) {
      setTypeView(arg.view.type)
    }
  }

  const eventHoveringItem = async (arg: EventHoveringArg) => {
    setColor(arg?.event?._def?.extendedProps?.specialist_color)
    setShowPopover(true)
    setIsLoadingInfos(true)
    const id = arg?.event?._def?.extendedProps?.idAttendance

    clearTimeout(timer)

    timer = setTimeout(async () => {
      try {
        const { data: attendanceInfos } = await apiAuth.get(
          `clinics/attendances/${id}/`,
          {
            params: {
              onlyFields:
                'guide,ticket_status_payment,attendance_items,patient_name,patient_social_name,specialist_name'
            }
          }
        )

        setGuide(attendanceInfos?.guide)
        setPatientName(
          attendanceInfos?.patient_name || attendanceInfos?.patient_social_name
        )
        setSpecialistName(attendanceInfos?.specialist_name)
        setStatusPayment(attendanceInfos?.ticket_status_payment)
        setServices(attendanceInfos?.attendance_items)
      } catch (error: any) {
        console.log(error)
      } finally {
        setIsLoadingInfos(false)
      }
    }, 650)
  }

  const eventHoveringItemLeave = (arg: EventHoveringArg) => {
    setShowPopover(false)
  }

  return (
    <>
      <FullCalendar
        plugins={[
          dayGridPlugin,
          bootstrap5Plugin,
          timeGridPlugin,
          interactionPlugin
        ]}
        eventContent={changeContentView}
        eventMouseEnter={eventHoveringItem}
        eventMouseLeave={eventHoveringItemLeave}
        locales={[pt, es, en]}
        nowIndicator
        dateClick={handlerDataClick}
        timeZone={decodeToken?.clinic?.timezone}
        navLinks
        height="auto"
        select={select}
        datesSet={datesSet}
        timeZoneParam={decodeToken?.clinic?.timezone}
        locale={decodeToken?.clinic?.language === 'pt' ? 'pt-BR' : locale}
        allDaySlot={false}
        eventClick={EventClick}
        firstDay={1}
        initialDate={date}
        initialView="timeGridDay"
        headerToolbar={{
          center: 'title',
          left: `prev,next today`,
          right: 'timeGridDay,timeGridWeek,dayGridMonth'
        }}
        themeSystem="bootstrap5"
        events={events}
      />
      {showPopover && (
        <Flex
          position="fixed"
          zIndex={999}
          right="12"
          bottom="8"
          p="4"
          bg={color !== undefined ? color : 'Highlight'}
          boxShadow="0px 4px 10px rgba(0, 0, 0, 0.32)"
          borderRadius={16}
          color="white"
          flexDirection="column"
        >
          <Box>
            <Text fontWeight="bold" fontFamily="heading" textAlign="center">
              Informações sobre a agenda
            </Text>
          </Box>
          {isLoadingInfos ? (
            <Center gap="4" p="6" px="4">
              <Text color="white">Carregando informações...</Text>
              <Spinner size="md" color="white" />
            </Center>
          ) : (
            <Stack spacing="2" direction="column" w="100%">
              <Stack
                direction="row"
                my="2"
                spacing="6"
                justifyContent="space-between"
                w="100%"
              >
                <Text>Guia:</Text>
                <Text>{guide}</Text>
              </Stack>
              <Stack
                direction="row"
                my="2"
                spacing="6"
                justifyContent="space-between"
                w="100%"
              >
                <Text>Paciente:</Text>
                <Text>{patientName}</Text>
              </Stack>
              <Stack
                direction="row"
                my="2"
                spacing="6"
                justifyContent="space-between"
                w="100%"
              >
                <Text>Especialista:</Text>
                <Text>{specialistName}</Text>
              </Stack>
              <Stack
                direction="row"
                my="2"
                spacing="6"
                justifyContent="space-between"
                w="100%"
              >
                <Text>Status de Pagamento:</Text>
                {statusPayment === 1 && <Text>Pendente</Text>}
                {statusPayment === 2 && <Text>Pago</Text>}
                {statusPayment === 3 && <Text>Parcial</Text>}
                {statusPayment === 4 && <Text>Excedente</Text>}
                {!statusPayment && <Text>Ticket não gerado</Text>}
              </Stack>
              <Divider />
              <Stack
                direction="row"
                my="2"
                spacing="6"
                w="100%"
                justifyContent="space-between"
              >
                <Text>Serviços</Text>
                <List>
                  {services?.map((item) => (
                    <ListItem textAlign="right" key={item?.id}>
                      {item.service_name}
                    </ListItem>
                  ))}
                </List>
              </Stack>
            </Stack>
          )}
        </Flex>
      )}
    </>
  )
}
