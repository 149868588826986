/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Tooltip,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Text,
  Skeleton,
  Flex,
  Checkbox as ChakraCheckbox
} from '@chakra-ui/react'
import React, { useState, useCallback } from 'react'
import { FiEdit } from 'react-icons/fi'

import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  getWaysReceives,
  useWaysReceives
} from '../../hooks/waysReceives/useWays'
import { useAuth } from '../../hooks/auth'
import { LayoutList } from '../../layouts/List'
import { NotFound } from '../../components/molecules/NotFound'
import { Pagination } from '../../components/PaginationNew'
import { PermissionComponent } from '../../components/Permissions'

// debounce
let timer: any = null

export function MeansToReceipts() {
  const { clinicId } = useAuth()
  const [t] = useTranslation('pageMeansToReceipts')
  const [searchState, setSearchState] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [isActive, setIsActive] = useState(true)
  const skeletonRepeat = [1, 2, 3, 4, 5, 6, 7]

  const getMeans = useWaysReceives(
    currentPage,
    getWaysReceives(String(clinicId), currentPage, searchState, isActive),
    searchState,
    isActive
  )

  const SearchInputCallbackValue = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget

      clearTimeout(timer)

      timer = setTimeout(() => {
        setCurrentPage(1)
        setSearchState(value)
      }, 500)
    },
    []
  )

  const ActiveCheckboxCallbackValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsActive(event.target.checked)
    },
    []
  )

  return (
    <LayoutList
      title={t('title')}
      urlNew="/financial/receiving-method/create"
      refetch={getMeans.refetch}
      placeholder={t('search')}
      capturaChange={(event: any) => SearchInputCallbackValue(event)}
      captureActiveChange={(event: any) => ActiveCheckboxCallbackValue(event)}
      spiCode="WAYS_TO_RECEIVE"
    >
      <Box>
        <Table variant="striped" colorScheme="blue" size="sm">
          <Thead>
            <Tr>
              <Th>{t('t1')}</Th>
              <Th>{t('t2')}</Th>
              <Th>{t('t3')}</Th>
              <Th>{t('t4')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {getMeans.isLoading ? (
              <>
                {skeletonRepeat.map((skeleton) => (
                  <Tr>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                  </Tr>
                ))}
              </>
            ) : (
              <>
                {getMeans.data?.waysReceives.map((ways) => (
                  <Tr key={ways.id} maxHeight="40px">
                    <Td>
                      <Tooltip
                        aria-label={ways?.observation || ''}
                        label={ways?.observation}
                      >
                        <Text _hover={{ cursor: 'default' }} fontSize="sm">
                          {ways.name}
                        </Text>
                      </Tooltip>
                    </Td>

                    <Td>
                      <Text fontSize="sm">{ways.active_parcels}</Text>
                    </Td>

                    <Td>
                      <Text fontSize="sm">
                        {ways?.day_to_receive ? ways.day_to_receive : '0'}
                      </Text>
                    </Td>

                    <Td>
                      <PermissionComponent
                        spiCode="WAYS_TO_RECEIVE"
                        ipCode="EDIT"
                      >
                        <Flex gap="2" dir="row">
                          <Link
                            to={{
                              pathname: `/financial/receiving-method/update/${ways.id}`,
                              state: ways
                            }}
                          >
                            <Tooltip
                              label="Editar"
                              aria-label="editar"
                              hasArrow
                              placement="top-start"
                            >
                              <Box ml="1rem" w="24px">
                                <FiEdit
                                  color="#9699B0"
                                  size={16}
                                  style={{ cursor: 'pointer' }}
                                />
                              </Box>
                            </Tooltip>
                          </Link>
                          <Link
                            to={{
                              pathname: `/financial/receiving-method/installments/${ways.id}`,
                              state: ways
                            }}
                          >
                            <Tooltip
                              label="Parcelas"
                              aria-label="Parcelas"
                              hasArrow
                              placement="top-start"
                            >
                              <Box ml="1rem" w="24px">
                                <Text
                                  color="gray.500"
                                  fontSize="18"
                                  fontWeight="bold"
                                >
                                  P
                                </Text>
                              </Box>
                            </Tooltip>
                          </Link>
                        </Flex>
                      </PermissionComponent>
                    </Td>
                  </Tr>
                ))}
              </>
            )}
          </Tbody>
        </Table>
        {getMeans.data?.totalCountOfPage === 0 && !getMeans.isLoading && (
          <NotFound />
        )}
      </Box>
      {!(getMeans.data?.totalCountOfPage === 0 && !getMeans.isLoading) && (
        <Pagination
          totalCountOfRegisters={getMeans.data?.totalCountOfPage || 0}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      )}
    </LayoutList>
  )
}
