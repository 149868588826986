/* eslint-disable camelcase */
import { useQuery } from 'react-query'
import { apiAuth } from '../../services/apiAuth'

export type TemplatesData = {
  id: string
  name: string
  status: number
}

export type ResponseGetTemplates = {
  templates: TemplatesData[]
  totalCountOfPage: number
}

export const getTemplates = async (
  clinicsId: string = '',
  page: number,
  search: string = '',
  isActive: boolean = true
): Promise<ResponseGetTemplates> => {
  try {
    const { data } = await apiAuth.get(
      `clinics/prescription-email-templates/`,
      {
        params: {
          page,
          keyword: search,
          is_active: isActive
        }
      }
    )

    const totalCountOfPage = data.count
    const templates: TemplatesData[] = data.results

    return {
      templates,
      totalCountOfPage
    }
  } catch (error: any) {
    const templates: TemplatesData[] = []
    const totalCountOfPage = 0
    return {
      templates,
      totalCountOfPage
    }
  }
}

export function useTemplates(
  page: number,
  functionLoading: Promise<ResponseGetTemplates>,
  search: string,
  isActive: boolean,
  options?: {}
) {
  return useQuery(
    ['templates', page, search, isActive],
    () => functionLoading,
    {
      staleTime: 1000 * 2, // 2 seconds
      cacheTime: 1000 * 2, // 2 seconds
      ...options
    }
  )
}
