import {
  Box,
  Flex,
  Icon,
  Stack,
  Text,
  useBreakpointValue
} from '@chakra-ui/react'
import { FormEventHandler, ReactNode } from 'react'
import { FiHelpCircle } from 'react-icons/fi'

import { ArrowBack } from '../components/atoms/arrowBack'
import { Header } from '../components/Header'
import { Sidebar } from '../components/Sidebar'
import { Timer } from '../components/Timer'

interface ListProps {
  title: string
  children: ReactNode
  IconHeader?: ReactNode
  urlBack: string
  titleBack?: string
  onSubmit?: FormEventHandler<HTMLDivElement> | undefined
  isTimer?: boolean
  timer?: number
  noRenderHelp?: boolean
  goBack?: boolean
}

export function LayoutDefault({
  title,
  children,
  urlBack,
  onSubmit,
  IconHeader,
  titleBack,
  isTimer,
  timer,
  noRenderHelp,
  goBack
}: ListProps) {
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })

  return (
    <Box overflow="hidden" position="relative">
      <Header>
        <Flex
          mx="auto"
          w="100%"
          direction="row"
          d="flex"
          my="6"
          align="center"
          justify="space-around"
        >
          <Flex mx="auto" flexDirection="row" alignItems="center">
            {IconHeader && IconHeader}
            <Flex
              alignItems="center"
              justifyContent="center"
              flexDirection="row"
            >
              <Text fontSize="18px" mx="2" fontWeight="600" height="100%">
                {title}
              </Text>
              {!noRenderHelp && <Icon as={FiHelpCircle} />}
            </Flex>
            {isTimer && (
              <>
                <Text mx="2">|</Text>
                <Timer timerInit={timer} />
              </>
            )}
          </Flex>
          <Stack direction="row" align="center" justify="center">
            <ArrowBack href={urlBack} goBack={goBack}>
              <Text fontSize="md" fontWeight="bold">
                {titleBack || 'Voltar'}
              </Text>
            </ArrowBack>
          </Stack>
        </Flex>
      </Header>
      <Sidebar />
      <Flex
        flex="1"
        mx="auto"
        as="form"
        ml={isWideVersion ? '75px' : '2'}
        mt="15px"
        mb="8px"
        mr="4"
        bg="white"
        h="cal(100vh - 60px)"
        onSubmit={onSubmit}
      >
        {children}
      </Flex>
    </Box>
  )
}
