/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react'
import { useAuth } from '../../hooks/auth'
import { apiAuth } from '../../services/apiAuth'

export type SuppliersData = {
  id: string
  trading_name: string
}

type CostOfCenterData = {
  id: string
  name: string
  is_default?: boolean
}

type SupplierData = SuppliersData | null

export const useService = () => {
  // Clinic Id Get decode hook
  const { clinicId } = useAuth()

  // State
  const [suppliers, setSuppliers] = useState<SuppliersData[]>([])
  const [recoverSuppliers, setRecoverSuppliers] = useState<SupplierData>(null)
  const [costOfCenter, setCostOfCenter] = useState<CostOfCenterData[]>([])

  const getCostsByClinicId = useCallback(async () => {
    try {
      const { data } = await apiAuth.get(`clinics/${clinicId}/cost-centers/`, {
        params: {
          is_active: true
        }
      })

      setCostOfCenter(data.results)
    } catch (error) {
      console.log('Error getting Cost', error)
    }
  }, [])

  const getSuppliersByClinicId = useCallback(async () => {
    try {
      const { data } = await apiAuth.get(`clinics/${clinicId}/suppliers/`, {
        params: {
          is_active: true
        }
      })

      setSuppliers(data.results)
    } catch (error) {
      console.log('Error getting Suppliers', error)
    }
  }, [])

  const getSupplierById = useCallback(async (id: string) => {
    try {
      const { data } = await apiAuth.get(`clinics/suppliers/${id}/`)

      setRecoverSuppliers(data)
    } catch (error) {
      console.log('Error getting Suppliers', error)
    }
  }, [])

  useEffect(() => {
    const loadingInfos = async () => {
      await getCostsByClinicId()
      await getSuppliersByClinicId()
    }

    loadingInfos()
    return () => {}
  }, [])

  // Get Value of my select Supplier
  const newSuppliersFormats = suppliers.map((supplier: SuppliersData) => {
    return {
      value: String(supplier.id),
      label: supplier.trading_name
    }
  })

  // Get Value of my select Cost
  const newCostsFormats = costOfCenter.map((cost: CostOfCenterData) => {
    return {
      value: String(cost.id),
      label: cost.name,
      is_default: cost.is_default
    }
  })

  return {
    newSuppliersFormats,
    newCostsFormats,
    setSuppliers,
    getSupplierById,
    recoverSuppliers,
    suppliers
  }
}
