import { ElementType, ReactNode } from 'react'
import {
  Link as ChakraLink,
  Icon,
  Text,
  LinkProps as ChakraLinkProps
} from '@chakra-ui/react'
import { ActiveLink } from './ActiveLink'

interface NavLinkProps extends ChakraLinkProps {
  icon?: ElementType
  children: ReactNode
  to: string
}

export function NavLink({ icon, children, to, ...rest }: NavLinkProps) {
  return (
    <ActiveLink to={to}>
      <ChakraLink display="flex" align="center" {...rest}>
        {!!icon && <Icon as={icon} fontSize="18px" mt="2px" />}
        <Text ml="4" fontSize="14px">
          {children}
        </Text>
      </ChakraLink>
    </ActiveLink>
  )
}
