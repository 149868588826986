/* eslint-disable no-case-declarations */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-debugger */
/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  List,
  ListIcon,
  ListItem,
  Radio as ChakraRadio,
  RadioGroup,
  SimpleGrid,
  Spinner,
  Stack,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useToast,
  VStack
} from '@chakra-ui/react'
import format from 'date-fns/format'
import parserElement from 'html-react-parser'
import lodash, { filter } from 'lodash'
import { useSubscription } from 'mqtt-react-hooks'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AiFillLock, AiFillUnlock } from 'react-icons/ai'
import { BiErrorCircle } from 'react-icons/bi'
import { FcInfo } from 'react-icons/fc'
import { FiDollarSign, FiEdit, FiInfo, FiPrinter, FiUser } from 'react-icons/fi'
import { MdSettingsAccessibility } from 'react-icons/md'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Card } from '../../components/Cards/card'
import { Input } from '../../components/Form/Input'
import { ModalCreateNewCard } from '../../components/Modal/confirmNewCard'
import { CardContainer } from '../../components/molecules/CardContainer'
import { WebcamCapture } from '../../components/Webcam'
import { ImageComponent } from '../../components/Webcam/ImageComponent'
import { StateOptions } from '../../utils/json/states'
import Dropzone from './Dropzone'

import { AddRecordUniq } from '../../components/AddRecordingUniq'
import { ArrowBack } from '../../components/atoms/arrowBack'
import { InputPhone2 } from '../../components/Form/InputPhone2'
import { Select } from '../../components/Form/Select'
import { SelectComponent } from '../../components/Form/SelectComponent'
import { SelectComponentAsync } from '../../components/Form/SelectComponentAsync'
import { Switch } from '../../components/Form/Switch'
import { TextFieldControlled } from '../../components/Form/TextFieldControlled'
import { Header } from '../../components/Header'
import { ModalWebcam } from '../../components/Modal/modalWebcam'
import { NotFound } from '../../components/molecules/NotFound'
import { MultiSelectMenuFilter } from '../../components/MultiSelectFilters'
import { Pagination } from '../../components/PaginationNew'
import { PrintButton } from '../../components/PrintButton'
import { Sidebar } from '../../components/Sidebar'
import { useAuth } from '../../hooks/auth'
import { useDocument } from '../../hooks/useDocument'
import { useModal } from '../../hooks/useModal'
import { apiAuth } from '../../services/apiAuth'
import { remaskCaractersAll } from '../../utils/fns/removeCaracters'
import { mask } from '../../utils/mask'
import { useAddress } from '../../utils/services/useAddress'
import { useCustomers } from './logic'
import { usePatients } from './service'
import { ModalArrivedTime } from '../../components/Modal/confirmArrivedTime'
import { formatBrOrDollarDefault } from '../../utils/fns/formatBrOrDollarDefault'

// form values
type FormData = {
  occupation: string
  is_active: string
  alerts: string
  observation: string
  state: string
  cep: string
}

// indications service data
type ServicesData = Array<{
  value: number
  label: string
}>

type GetResponseDefaultData = Array<{
  name: string
  id: number
}>

// client
export type FormClientData = {
  name: string
  nascimento: string
  idade: string
}

type ContactsData = {
  contact: string
  ddi_country: string
  id: string
  description: string
}

interface MedicalRecords {
  attendance_id: number
  start_date: string
  finish_date: string
  exams?: Array<{
    id?: number
    name: string
    information: string
  }>
  forms?: Array<{
    information: string | null
    name: string
    questions?: Array<{
      answer: string
      is_multiple: boolean
      is_required: boolean
      options?: string
      question: string
    }>
  }>
  indications?: Array<{
    id: number
    indication_date: string | null
    observation: string
    service: {
      id: number
    }
    service_name: string
    status: number
  }>
  prescriptions?: Array<{
    id?: number
    name: string
    information: string
  }>
  services_not_performed:
    | []
    | Array<{
        service_id: number
        service_name: string
        description: string
        service: {
          id: number
          name: string
          observation: string
          is_active: boolean
          price: string
          days_to_come_back: 0
          days_to_maturity: 0
          protocol: string
          service_sessions: Array<{}>
        }
      }>
  services_performed:
    | []
    | Array<{
        service_id: number
        service_name: string
        total_session: number
      }>
  sessions_not_performed:
    | []
    | Array<{
        id: number
        session_number: number
        status: number
        description: string | null
        attendance_id: number
        service_id: number
        service_name: string
        total_session: number
      }>
  sessions_performed:
    | []
    | Array<{
        id: number
        session_number: number
        status: number
        description: string | null
        attendance_id: number
        service_id: number
        service_name: string
        total_session: number
      }>
  specialist: {
    id: number
    social_name: string
  }
}

type GetMedicalRecordsData = {
  results: Array<MedicalRecords>
}

type MedicalRecordsData = Array<MedicalRecords>

interface ParamsProps {
  id: string
}

type EmailsData = {
  id: string
  email: string
  description: string
}

interface Client {
  id: string
  name: string
  is_active: boolean
  image: string
  birth_date: string
  social_name: string
  sex: number
  document_type: number
  document_number: string
  observation: string
  alerts: string
  patient_tags: Array<number>
  patient_origins: Array<number>
  role: string
  address: {
    zip_code: string
    street: string
    district: string
    city: string
    country: string
    state: string
    number: string
    observation: string
  }
  created_by: {}
  update_by: {}
}

type ClientData = Client | null

type ActionsWs =
  | 'cancel-attendance'
  | 'finish-attendance'
  | 'allow-start-attendance'
  | 'logout-user'
  | undefined

interface Attendance {
  id: number
  guide: number
  patient_id: number
  edit_status: 1 | 2 | 3
  hash: string
  allowed_start_attendance: boolean
  block_checkout: boolean
  status_by_system: boolean
  scheduled_arrival_time: string
  scheduled_initial_time: string
  scheduled_final_time: string
  real_arrival_time: string
  real_initial_time: string
  real_final_time: string
  status: number
  professional_id: number
  attendance_spot_id: number
  observation: string
  observation_payment: string
  value_to_income: number
  patient_number?: string
  invoice_number: number | null
  invoice_date: string | null
  block_guide: boolean
  is_active: boolean
  specialist_name: string | null
  patient_name: string | null
  sessions: Array<{
    id: number
    session_number: number
    status: number
    description: string | null
    attendance_id: number
    service_id: number
    service_name: string
    total_session: number
  }>
  sessions_not_performed: Array<{}>
  indications: Array<{}>
  patient_social_name: string | null
  attendance_items_exclude?: Array<number>
  specialist_color: null | string
  ticket_status_payment?: number | null
  attendance_items: Array<{
    id: number
    service_name: string
    service_id: number
    total_session: number
  }>
  attendance_spot: {
    id: number
    index: number
    name: string
    is_active: boolean
  }
}

type AttendanceData = Array<Attendance>

type GetAttendances = {
  results: Array<Attendance>
}

interface GalleryCard {
  id: string
  date: string
  name: string
  quantity_images: number
  quantity_videos: number
}

type GalleryCardData = Array<GalleryCard> | null

type Template = {
  id: number
  name: string
}

type Templates = Array<Template>

type IndicationsDataInfo = Array<{
  id: number
  service: {
    id: number
    name: string
  }
  service_name: string
  observation: string | null
  status: number
  indication_date: string | null
  specialist_name: string
  medical_record_finish_date: string
  updated_at: string
  updated_by_name: string | null
}>

type BudgetsDataInfo = Array<{
  id: number
  code: number
  due_date: string
  created_by: {
    id: number
    social_name: string
  }
  specialist: {
    id: number
    name: string
    social_name: string
  } | null
  patient: {
    id: number
    name: string
    social_name: string
  }
  created_at: string
  total: number
  items: [
    {
      id: number
      service: {
        id: number
        name: string
      }
      service_name: string
      sessions: number
      quantity: number
      unit_value: number
      discount_percent: number
      discount_absolute: number
      subtotal: number
    }
  ]
}>

// debounce
const timer: any = null

type LocationData = {
  isBackAttendance?: boolean
  id?: number | string | null
  state?: Attendance
  name?: string
}

type FiltersIndicationsData = {
  specialistIds: Array<string | number>
  status: Array<string | number>
  orderBy: string
}

type FiltersBudgetsData = {
  specialistIds: Array<string | number>
  date: string
}

export function UpdateClientsInfos() {
  const [t] = useTranslation('pageClients')
  const toast = useToast()
  const { decodeToken, clinicId } = useAuth()
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const { imgSrc, setImgSrc } = useDocument()
  const params = useParams<ParamsProps>()
  const {
    calculateAge,
    healthInsurance,
    handleInputConvenio,
    handleSelectConvenio,
    addFieldsConvenio,
    removeFieldsConvenio,
    patientRelatives,
    addFieldsRelatives,
    removeFieldsRelatives,
    handleSelectedRelativesNames,
    handleSelectRelativesFamily,
    handleSelectedTags,
    handleSelectedOrigins,
    setPatientsTags,
    setPatientsOrigins,
    patientsTags,
    patientsOrigins,
    setHealthInsurance,
    setPatientRelatives
  } = useCustomers()
  const { tabIndexClient, setTabIndexClient, LocaleApp } = useModal()
  const today = format(new Date(), 'yyyy-MM-dd', {
    locale: LocaleApp
  })
  const address = useAddress()
  const history = useHistory<LocationData>()
  const clientService = usePatients()
  const tagsUpdate = clientService.tagsServices
  const optionsFamily = [
    {
      value: 1,
      label: `${t('pageClients.PageNovoCliente.containerFour.seletedPar.pai')}`
    },
    {
      value: 2,
      label: `${t('pageClients.PageNovoCliente.containerFour.seletedPar.mae')}`
    },
    {
      value: 3,
      label: `${t('pageClients.PageNovoCliente.containerFour.seletedPar.irma')}`
    },
    {
      value: 4,
      label: `${t('pageClients.PageNovoCliente.containerFour.seletedPar.tio')}`
    },
    {
      value: 5,
      label: `${t(
        'pageClients.PageNovoCliente.containerFour.seletedPar.outros'
      )}`
    }
  ]
  const originsUpdate = clientService.originsServices
  const covenantsUpdate = clientService.covenantsServices
  // states page
  const [localMethod, setLocal] = useState('')
  const [state, setState] = useState<ClientData>(null)
  const { state: oldLocation } = history.location

  // Form
  const { register, handleSubmit, formState } = useForm<FormData>({})
  const { errors } = formState

  // states cadastro
  const [ageUser, setAgeUser] = useState('')
  const [is_active, setIsActive] = useState(false)
  const [valueDocumentSelect, setValueDocumentSelect] = useState('')
  const [typeDocument, setTypeDocument] = useState<any>()
  const [numberDocument, setNumberDocument] = useState('')
  const [numbers, setNumbers] = useState<ContactsData[]>([
    {
      contact: '',
      ddi_country:
        decodeToken?.clinic?.clinic_contacts[0]?.ddi_country || '+55',
      id: '',
      description: ''
    }
  ])
  const [name, setName] = useState('')
  const [sex, setSex] = useState('')
  const [birth_date, setBirthDate] = useState('')
  const [socialName, setSocialName] = useState('')
  const [redirect, setRedirect] = useState(false)
  const [emails, setEmails] = useState<EmailsData[]>([
    {
      id: lodash.uniqueId(),
      email: '',
      description: ''
    }
  ])
  // address state
  const [numberAddress, setNumberAddress] = useState('')
  const [cepAddress, setCepAddress] = useState('')
  const [complement, setComplement] = useState('')

  // states attendance list components
  const statusOptionsDefault = [
    { value: 1, label: `${t('attendance.status.1')}` },
    { value: 2, label: `${t('attendance.status.2')}` },
    { value: 3, label: `${t('attendance.status.3')}` },
    { value: 4, label: `${t('attendance.status.4')}` },
    { value: 5, label: `${t('attendance.status.5')}` },
    { value: 6, label: `${t('attendance.status.6')}` },
    { value: 7, label: `${t('attendance.status.7')}` }
  ]
  const statusOptionsIndications = [
    { value: 1, label: `${t('indications.status.1')}` },
    { value: 2, label: `${t('indications.status.2')}` },
    { value: 3, label: `${t('indications.status.3')}` },
    { value: 4, label: `${t('indications.status.4')}` },
    { value: 5, label: `${t('indications.status.5')}` }
  ]
  const statusOptionsIndicationsEdit = [
    { value: 1, label: `${t('indications.status.1')}` },
    { value: 4, label: `${t('indications.status.4')}` },
    { value: 5, label: `${t('indications.status.5')}` }
  ]
  const [specialistIds, setSpecialistIds] = useState<String[]>([])
  const [statusParam, setStatusParam] = useState<String[]>([])
  const [attendance, setAttendance] = useState<AttendanceData>([])
  const [isLoadingAttendance, setIsLoadingAttendance] = useState(false)
  // medical records filters
  // types options default
  const [medicalRecords, setMedicalRecords] = useState<MedicalRecordsData>([])
  const [arrayOpenTabs, setArrayOpenTabs] = useState<Array<number> | undefined>(
    undefined
  )
  const TypesMedicalData = [
    { value: 'forms', label: `${t('recording.types.1')}` },
    { value: 'prescriptions', label: `${t('recording.types.2')}` },
    { value: 'exams', label: `${t('recording.types.3')}` },
    { value: 'indications', label: `${t('recording.types.4')}` }
  ]
  const [medicalRecordsSpecialist, setMedicalRecordsSpecialist] = useState<
    string[]
  >([])
  const [medicalTypeDataFilter, setMedicalTypeDataFilter] = useState<string[]>([
    'forms',
    'prescriptions',
    'exams',
    'indications'
  ])
  const [medicalStartDate, setMedicalStartDate] = useState<string>('')
  const [medicalEndDate, setMedicalEndDate] = useState<string>('')
  const [medicalKeyword, setMedicalKeyword] = useState<string>('')
  // print templates list
  const [templates, setTemplates] = useState<Templates>([])
  // state services for indications add
  const [servicesIndications, setServicesIndications] = useState<ServicesData>(
    []
  )

  // gallery state
  // view mode gallery photos and videos
  const [galleryCard, setGalleryCard] = useState<GalleryCardData>(null)
  const [galleryIsLoading, setGalleryIsLoading] = useState(false)
  const [galleryError, setGalleryError] = useState(false)

  // comparisons and gallery create
  const [newCardIsOpen, setNewCardIsOpen] = useState(false)
  const [typeCard, setTypeCard] = useState('')
  const [dateCard, setDateCard] = useState('')
  const [nameCard, setNameCard] = useState('')
  // states modal
  const [isOpen, setIsOpen] = useState(false)

  // states indications
  const [indicationsData, setIndicationsData] = useState<IndicationsDataInfo>(
    []
  )
  const [totalOfRegisterIndication, setTotalOfRegisterIndication] = useState(0)
  const [isLoadingIndications, setIsLoadingIndications] = useState(false)
  const [currentPageIndication, setCurrentPageIndication] = useState(1)
  const [idIndication, setIdIndication] = useState<number | null>(null)
  const [idServiceIndication, setIdServiceIndication] = useState<number | null>(
    null
  )
  const [observationInd, setObservationInd] = useState<string | null>('')
  const [dateIndication, setDateIndication] = useState('')
  const [statusChangeIndication, setStatusChangeIndication] = useState(1)
  const [isOpenEditIndication, setIsOpenEditIndication] = useState(false)
  const [statusIndication, setStatusIndication] = useState<any>([])
  const [specialistsIndication, setSpecialistsIndication] = useState<any>([])
  const [listByIndication, setListByIndication] = useState('indication_date')
  const [orderByIndication, setOrderByIndication] = useState('')
  const [filtersIndication, setFiltersIndication] =
    useState<FiltersIndicationsData | null>({
      orderBy: 'indication_date',
      specialistIds: [],
      status: []
    })

  // states budgets
  const [budgetsData, setBudgetsData] = useState<BudgetsDataInfo>([])
  const [totalOfRegisterBudgets, setTotalOfRegisterBudgets] = useState(0)
  const [isLoadingBudgets, setIsLoadingBudgets] = useState(false)
  const [currentPageBudgets, setCurrentPageBudgets] = useState(1)
  const [specialistsBudgets, setSpecialistsBudgets] = useState<any>([])
  const [dateBudgets, setDateBudgets] = useState('')
  const [filtersBudgets, setFiltersBudgets] =
    useState<FiltersBudgetsData | null>({
      specialistIds: [],
      date: ''
    })

  const generateArrayMqttObserver = () => {
    if (attendance !== undefined) {
      if (attendance !== null) {
        const allArrayAllow = attendance.map((item) => {
          return `/attendances/${item?.hash}/allow-start-attendance/`
        })
        const allArrayFinish = attendance.map((item) => {
          return `/attendances/${item?.hash}/finish-attendance/`
        })

        const allArrayCancel = attendance.map((item) => {
          return `/attendances/${item?.hash}/cancel-attendance/`
        })

        const allArray = [
          ...allArrayFinish,
          ...allArrayAllow,
          ...allArrayCancel
        ]

        return allArray
      }
    }

    return []
  }

  // websocket mqtt listen
  const { message: clientMessage } = useSubscription(
    generateArrayMqttObserver()
  )
  // function getInfo for view client
  const GetClient = async () => {
    const { getClientById } = clientService

    const data: ClientData = await getClientById(params.id)

    setState(data)
  }

  // tabIndex control get cards Gallery Info
  const getCardsTimeline = async () => {
    setGalleryIsLoading(true)

    const { data } = await apiAuth.get(`clinics/gallery-cards/`, {
      params: {
        patient_id: params.id
      }
    })

    if (data.results !== undefined) {
      setGalleryCard(data.results)
      console.log(data)
    } else {
      setGalleryCard(null)
      setGalleryError(true)
    }

    setGalleryIsLoading(false)
  }

  // create new Card function
  const handlerCreateNewCard = async () => {
    if (nameCard === '') {
      toast({
        duration: 3000,
        title: t('gallery.cardCreate.error.title'),
        position: 'top',
        status: 'error',
        description: t('gallery.cardCreate.error.message')
      })

      return
    }

    const payload = {
      gallery_patient: params.id,
      name: nameCard,
      date: dateCard
    }

    if (typeCard === 'gallery') {
      try {
        const { data } = await apiAuth.post(`clinics/gallery-cards/`, payload)

        if (data.id !== undefined) {
          toast({
            duration: 3000,
            title: t('gallery.cardCreate.success.title'),
            position: 'top',
            status: 'success',
            description: t('gallery.cardCreate.success.message')
          })
          setDateCard(
            format(new Date(), 'yyyy-MM-dd', {
              locale: LocaleApp
            })
          )
          getCardsTimeline()
          setNameCard(
            format(new Date(), 'dd/MM/yyyy', {
              locale: LocaleApp
            })
          )
          setNewCardIsOpen(false)
        }
      } catch (error: any) {
        toast({
          duration: 3000,
          title: t('Error!'),
          position: 'top',
          status: 'error',
          description: `${error.response?.data?.message}`
        })
      }
    }

    if (typeCard === 'comparisons') {
      try {
        const { data } = await apiAuth.post(`clinics/comparisons/`, payload)

        if (data.id !== undefined) {
          toast({
            duration: 3000,
            title: t('gallery.cardCreate.success.title'),
            position: 'top',
            status: 'success',
            description: t('gallery.cardCreate.success.message')
          })
          setDateCard(
            format(new Date(), 'yyyy-MM-dd', {
              locale: LocaleApp
            })
          )
          setNameCard(
            format(new Date(), 'dd/MM/yyyy', {
              locale: LocaleApp
            })
          )
          getCardsTimeline()
          setNewCardIsOpen(false)
        }
      } catch (error: any) {
        toast({
          duration: 3000,
          title: t('Error!'),
          position: 'top',
          status: 'error',
          description: `${error.response?.data?.message}`
        })
      }
    }

    setNameCard(
      format(new Date(), 'dd/MM/yyyy', {
        locale: LocaleApp
      })
    )
  }

  // attendances return infos ticket
  // jxs status item ticket
  const returnJsxElementStatusTicket = (
    statusPayment: number | null | undefined
  ) => {
    if (statusPayment === 1) {
      return (
        <Tooltip
          label={t('attendance.statusTicket.1')}
          aria-label={t('attendance.statusTicket.1')}
          hasArrow
          placement="top-start"
        >
          <Box ml="1rem" w="24px">
            <FiDollarSign
              color="#c71717f4"
              size={16}
              style={{ cursor: 'default' }}
            />
          </Box>
        </Tooltip>
      )
    }

    if (statusPayment === 2) {
      return (
        <Tooltip
          label={t('attendance.statusTicket.2')}
          aria-label={t('attendance.statusTicket.2')}
          hasArrow
          placement="top-start"
        >
          <Box ml="1rem" w="24px">
            <FiDollarSign
              color="#56c20ff4"
              size={16}
              style={{ cursor: 'default' }}
            />
          </Box>
        </Tooltip>
      )
    }

    if (statusPayment === 3) {
      return (
        <Tooltip
          label={t('attendance.statusTicket.3')}
          aria-label={t('attendance.statusTicket.3')}
          hasArrow
          placement="top-start"
        >
          <Box ml="1rem" w="24px">
            <FiDollarSign
              color="#fd7011f0"
              size={16}
              style={{ cursor: 'default' }}
            />
          </Box>
        </Tooltip>
      )
    }

    if (statusPayment === 4) {
      return (
        <Tooltip
          label={t('attendance.statusTicket.4')}
          aria-label={t('attendance.statusTicket.4')}
          hasArrow
          placement="top-start"
        >
          <Box ml="1rem" w="24px">
            <FiDollarSign
              color="#192facdc"
              size={16}
              style={{ cursor: 'default' }}
            />
          </Box>
        </Tooltip>
      )
    }

    return (
      <Tooltip
        label={t('attendance.statusTicket.null')}
        aria-label={t('attendance.statusTicket.null')}
        hasArrow
        placement="top-start"
      >
        <Box ml="1rem" w="24px">
          <FiDollarSign
            color="#9699B0"
            size={16}
            style={{ cursor: 'default' }}
          />
        </Box>
      </Tooltip>
    )
  }

  const verifyStatusAttendanceButton = (statusAtt: number) => {
    let verify = false

    if (statusAtt === 3) {
      return verify
    }

    if (statusAtt === 4) {
      return verify
    }

    if (statusAtt === 6) {
      return verify
    }

    if (statusAtt === 7) {
      return verify
    }

    verify = true
    return verify
  }

  // websocket receive message
  useEffect(() => {
    const topic = clientMessage?.topic.split('/')
    const hash = topic?.[2]
    const action = topic?.[1]

    switch (action as ActionsWs) {
      case 'allow-start-attendance':
        const allAttendance = [...attendance]

        const newData = {
          allowed_start_attendance: true
        }

        const newAttendanceValue = allAttendance.map((att) => {
          return att.hash === hash ? { ...att, ...newData } : att
        })

        setAttendance(newAttendanceValue)
        break
      case 'cancel-attendance':
        const oldAttendance = [...attendance]

        const newDataCancel = {
          allowed_start_attendance: false,
          status: 3,
          status_by_system: true
        }

        const newAttendance = oldAttendance.map((att) => {
          return att.hash === hash ? { ...att, ...newDataCancel } : att
        })

        setAttendance(newAttendance)
        break
      case 'finish-attendance':
        const AttOlds = [...attendance]

        const newDataFinish = {
          allowed_start_attendance: false,
          status: 7,
          status_by_system: true
        }

        const newAttFinish = AttOlds.map((att) => {
          return att.hash === hash ? { ...att, ...newDataFinish } : att
        })

        setAttendance(newAttFinish)
        break

      default:
        break
    }
  }, [clientMessage])

  // get infos and set today inputs
  useEffect(() => {
    GetClient()
    setDateCard(today)
  }, [])

  // get templates for print
  // buscar no component pai para evitar várias chamadas pra pegar esse valor
  useEffect(() => {
    const getDataTemplates = async () => {
      const { data } = await apiAuth.get<Templates>(
        `clinics/prescription-email-templates/`,
        {
          params: {
            noPaginate: 1,
            is_active: true
          }
        }
      )

      setTemplates(data)
    }

    getDataTemplates()

    return () => {}
  }, [])

  // Get values for update
  useEffect(() => {
    if (state?.name !== undefined) {
      setName(state.name)
    }

    if (state?.is_active) {
      setIsActive(state.is_active)
    }

    if (state?.image !== undefined) {
      if (state?.image === null) {
        setImgSrc('')
      } else {
        setImgSrc(state.image)
      }
    }

    if (state?.birth_date !== undefined) {
      if (state.birth_date !== null) {
        setBirthDate(state.birth_date)
        const formatDate = format(new Date(state.birth_date), 'dd/MM/yyyy', {
          locale: LocaleApp
        })
        const age = calculateAge(formatDate)
        setAgeUser(String(age))
      }
    }

    if (state?.social_name !== undefined) {
      setSocialName(state.social_name)
    }

    if (state?.sex !== undefined) {
      setSex(String(state.sex))
    }

    if (state?.document_type !== undefined) {
      setValueDocumentSelect(String(state.document_type))
    } else {
      setValueDocumentSelect('')
    }

    if (
      state?.document_number !== undefined &&
      state?.document_number !== null
    ) {
      setNumberDocument(String(state?.document_number))
    } else {
      setNumberDocument('')
    }
  }, [state])

  useEffect(() => {
    apiAuth.get(`clinics/patients/${params.id}/emails/`).then((response) => {
      if (response?.data?.length !== undefined) {
        if (response?.data?.length > 0) {
          setEmails(response.data)
        }
      }
    })

    apiAuth
      .get(`clinics/patients/${params.id}/health-insurances/`)
      .then((response) => {
        if (response?.data?.length !== undefined) {
          if (response?.data?.length > 0) {
            const formatHeal = response.data.map((heal: any) => {
              return {
                healthInsurance: {
                  value: heal.health_insurance.id,
                  label: heal.health_insurance.name
                },
                healthInsuranceNumber: heal?.number || ''
              }
            })

            setHealthInsurance(formatHeal)
          }
        }
      })

    apiAuth.get(`clinics/patients/${params.id}/parents/`).then((response) => {
      if (response?.data !== undefined) {
        if (response?.data?.length !== undefined) {
          if (response?.data?.length > 0) {
            const formatParents = response.data.map((item: any) => {
              if (item?.parent_degree === 1) {
                return {
                  patientRelatives: {
                    value: item?.parent?.id,
                    label: item?.parent?.name
                  },
                  patientDegree: item?.parent_degree
                }
              }
              if (item?.parent_degree === 2) {
                return {
                  patientRelatives: {
                    value: item?.parent?.id,
                    label: item?.parent?.name
                  },
                  patientDegree: item?.parent_degree
                }
              }
              if (item?.parent_degree === 3) {
                return {
                  patientRelatives: {
                    value: item?.parent?.id,
                    label: item?.parent?.name
                  },
                  patientDegree: item?.parent_degree
                }
              }
              if (item?.parent_degree === 4) {
                return {
                  patientRelatives: {
                    value: item?.parent?.id,
                    label: item?.parent?.name
                  },
                  patientDegree: item?.parent_degree
                }
              }
              return {
                patientRelatives: {
                  value: item?.parent?.id,
                  label: item?.parent?.name
                },
                patientDegree: item?.parent_degree
              }
            })

            setPatientRelatives(formatParents)
          }
        }
      }
    })

    apiAuth
      .get(`clinics/patients/${params.id}/telephones/`)
      .then((response) => {
        if (response?.data?.length !== undefined) {
          if (response?.data?.length > 0) {
            const getNumbersFormat = response.data.map((number: any) => {
              return {
                contact: number?.number || '',
                ddi_country: number?.ddi_country || '',
                id: number?.id || lodash.uniqueId(),
                description: number?.description || ''
              }
            })

            setNumbers(getNumbersFormat)
          }
        }
      })

    apiAuth.get(`clinics/patients/${params.id}/addresses/`).then((response) => {
      const getAddress = response?.data?.[0] || {}

      if (getAddress?.number) {
        setNumberAddress(getAddress.number)
      }
      if (getAddress?.complement) {
        setComplement(getAddress?.complement)
      }
      if (getAddress?.zip_code) {
        const zipCode = remaskCaractersAll(getAddress?.zip_code)
        setCepAddress(zipCode)
      }
      address.setCep(getAddress?.zip_code || '')
      address.setAddress(getAddress?.street || '')
      address.setBairro(getAddress?.district || '')
      address.setMunicipio(getAddress?.city || '')
      address.setSeledtedBR(true)
      address.optionsContries.map((contry: any) => {
        if (getAddress?.country === contry.label) {
          address.setMunicipio(getAddress?.city || '')
          if (contry !== null) {
            address.setSeletedCountry(contry)
          } else {
            address.setSeletedCountry('')
          }
        }
      })
      StateOptions.map((ufAux: any) => {
        if (getAddress?.state === ufAux?.label) {
          address.setSelectedState(ufAux || '')
          address.setUfDefault(ufAux || '')
        }

        if (getAddress?.state === ufAux?.sigla) {
          address.setSelectedState(ufAux || '')
          address.setUfDefault(ufAux || '')
        }
      })
    })
  }, [params.id])

  // Get values for update
  useEffect(() => {
    if (state === null) {
      return
    }

    if (state.patient_tags !== undefined) {
      if (state?.patient_tags.length > 0) {
        const tagsDefault = state.patient_tags
        const tagsValues = tagsUpdate
        const tagsSelect = tagsDefault.map((item) =>
          tagsValues.find((tag) => tag.id === item)
        )

        if (tagsSelect[0] !== undefined) {
          setPatientsTags(
            tagsSelect.map((tag: any) => {
              return {
                value: String(tag.id),
                label: String(tag.name)
              }
            })
          )
        }
      }
    }
  }, [tagsUpdate, state])

  // get values services by indications
  useEffect(() => {
    apiAuth
      .get<GetResponseDefaultData>(`clinics/${clinicId}/services/`, {
        params: {
          is_active: true,
          noPaginate: 1
        }
      })
      .then((response) => {
        const formattedServices = response.data.map((service) => {
          return {
            value: service.id,
            label: service.name
          }
        })

        setServicesIndications(formattedServices)
      })

    return () => {}
  }, [clinicId])

  // Get values for update
  useEffect(() => {
    if (state === null) {
      return
    }

    if (state?.patient_origins !== undefined) {
      if (state?.patient_origins.length > 0) {
        const originsDefault = state.patient_origins
        const originsValues = originsUpdate
        const originsSelect = originsDefault.map((item) =>
          originsValues.find((tag) => tag.id === item)
        )

        if (originsSelect[0] !== undefined) {
          setPatientsOrigins(
            originsSelect.map((tag: any) => {
              return {
                value: String(tag.id),
                label: String(tag.name)
              }
            })
          )
        }
      }
    }
  }, [originsUpdate, state])

  // Open modal new Card
  function handleCreateNewCardOpenModal() {
    setNewCardIsOpen(true)
  }

  // Close modal new Card
  function handleCreateNewCardCloseModal() {
    setNewCardIsOpen(false)
  }

  // format tags, origens
  function formatTagsServices() {
    if (patientsTags.length > 0) {
      const serviceTags = patientsTags.map((tag: any) => {
        return Number(tag.value)
      })

      return serviceTags
    }

    return patientsTags
  }

  function formatOriginsServices() {
    if (patientsOrigins.length > 0) {
      const serviceOrigins = patientsOrigins.map((origin: any) => {
        return Number(origin.value)
      })

      return serviceOrigins
    }

    return patientsOrigins
  }

  function formatNumbers() {
    if (numbers[0].contact === '') {
      return undefined
    }

    const telephones = numbers.map((item, index) => {
      return {
        ddi_country: item?.ddi_country === '' ? null : item?.ddi_country,
        number: item?.contact === '' ? null : item?.contact,
        index,
        description: item?.description === '' ? null : item?.description
      }
    })

    return telephones
  }

  function formatEmails() {
    if (emails[0].email === '') {
      return undefined
    }

    const emailsFormat = emails.map((email, index) => {
      return {
        email: email.email === '' ? null : email.email,
        index,
        description: email.description === '' ? null : email.description
      }
    })

    return emailsFormat
  }

  function formatHealthInsurance() {
    const healthInsuranceAllSelectReturn = healthInsurance.map((heal) => {
      if (heal.healthInsurance !== null) {
        return {
          health_insurance: {
            id: heal.healthInsurance?.value
          },
          number:
            heal.healthInsuranceNumber !== ''
              ? heal.healthInsuranceNumber
              : null
        }
      }

      return false
    })

    const health = healthInsuranceAllSelectReturn.filter(Boolean)

    return health
  }

  function formatPatientRelatives() {
    const patientRelativesAllSelectReturn = patientRelatives.map((patient) => {
      if (patient.patientRelatives !== null) {
        return {
          parent: {
            id: patient.patientRelatives.value
          },
          parent_degree: Number(patient.patientDegree || '5')
        }
      }

      return false
    })

    const parent = patientRelativesAllSelectReturn.filter(Boolean)

    return parent
  }

  // is required
  function isRequiredReturnForForm(obj: any | null | string) {
    let isRequired = false

    if (obj !== null) {
      isRequired = true
      return isRequired
    }

    if (obj === '') {
      isRequired = true
      return isRequired
    }

    return isRequired
  }

  // NUMBERS FUNCTIONS
  function handleAddNumbers() {
    setNumbers([
      ...numbers,
      {
        contact: '',
        ddi_country: '+55',
        id: '',
        description: ''
      }
    ])
  }

  function handleRemoveNumbers(index: number) {
    const values = [...numbers]
    values.splice(index, 1)
    setNumbers(values)
  }

  function handleChangeInputNumbers(
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    const values = [...numbers]

    if (values[index].ddi_country === '+55') {
      values[index].contact = mask(e.target.value, '(99) 99999-9999')
    } else {
      values[index].contact = mask(e.target.value, '9999999999999999')
    }

    setNumbers(values)
  }

  function handlerChangeDescriptionContact(
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const allNumbers = [...numbers]

    allNumbers[index].description = event.target.value

    setNumbers(allNumbers)
  }

  // EMAILS FUNCTIONS
  function handlerChangeEmail(
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const allEmails = [...emails]

    allEmails[index].email = event.target.value

    setEmails(allEmails)
  }

  function handlerChangeEmailDescription(
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const allEmails = [...emails]

    allEmails[index].description = event.target.value

    setEmails(allEmails)
  }

  function handleGetMethod() {
    const local = history.location.pathname

    if (local.indexOf('create') !== -1) {
      setLocal('create')
    } else {
      setLocal('update')
    }
  }

  // cal age
  function handleDefinedAge(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.value.length === 10) {
      const age = calculateAge(
        format(new Date(e.target.value), 'dd/MM/yyyy', { locale: LocaleApp })
      )
      setAgeUser(String(age))
    } else if (e.target.value.length < 10) {
      setAgeUser('')
    }
  }

  // Close modal
  const handleCloseModal = () => {
    setIsOpen(false)
  }

  // change Mask for input document value
  useEffect(() => {
    switch (valueDocumentSelect) {
      case '1':
        setTypeDocument('cpf')
        break

      case '2':
        setTypeDocument('cnpj')
        break

      case '0':
        setTypeDocument('def')
        break

      default:
        break
    }
  }, [valueDocumentSelect])

  const handleUpdateClient: SubmitHandler<FormData> = async (values) => {
    await new Promise((resolve) => setTimeout(resolve, 2000))

    const payloadTelephones = formatNumbers()

    const payloadEmails = formatEmails()

    const payloadParent = formatPatientRelatives()

    const payloadHeal = formatHealthInsurance()

    const payloadAddress = [
      {
        zip_code: address.cep || state?.address?.zip_code || undefined,
        street: address.address || state?.address?.street || undefined,
        number: numberAddress || state?.address?.number || undefined,
        district: address.bairro || state?.address?.district || undefined,
        city: address.municipio || state?.address?.city || undefined,
        state:
          address.ufDefault.label ||
          address?.seletedState.label ||
          values.state ||
          undefined,
        country:
          address.seletedCountry.label || state?.address?.country || undefined,
        complement: complement || state?.address?.observation || undefined,
        index: 0
      }
    ]

    const patient_tags = formatTagsServices()
    const patient_origins = formatOriginsServices()
    const stringValue = imgSrc !== '' ? imgSrc.substring(0, 4) : ''

    const payload = {
      name,
      social_name: socialName,
      birth_date: birth_date === '' ? null : birth_date,
      document_number: numberDocument === '' ? null : numberDocument,
      document_type: Number(valueDocumentSelect),
      sex: Number(sex) || undefined,
      observation: values.observation || undefined,
      is_active,
      alerts: values.alerts || undefined,
      ocuppation: values.occupation || undefined,
      patient_tags,
      patient_origins,
      image: stringValue === 'http' ? undefined : imgSrc
    }

    try {
      await apiAuth.post(
        `clinics/patients/${state?.id}/telephones/`,
        payloadTelephones
      )

      await apiAuth.post(
        `clinics/patients/${state?.id}/health-insurances/`,
        payloadHeal
      )

      await apiAuth.post(
        `clinics/patients/${state?.id}/parents/`,
        payloadParent
      )

      await apiAuth.post(`clinics/patients/${state?.id}/emails/`, payloadEmails)

      await apiAuth.post(
        `clinics/patients/${state?.id}/addresses/`,
        payloadAddress
      )

      if (localMethod === 'create') {
        await clientService.createPatients(payload)
      } else {
        await clientService.updatePatients(payload, state?.id || '', redirect)
      }
    } catch (error: any) {
      console.log(error)
      toast({
        status: 'error',
        description: `${error.response?.data?.message}` || 'Error',
        duration: 3000,
        title: `${t('Error!')}`
      })
    }
  }

  // get filters specialist
  const getFiltersSpecialist = (values: String[]) => {
    setSpecialistIds(values)
  }

  // return hours format
  const hoursFormat = (myDate: string) => {
    if (myDate === null) {
      return ''
    }

    const formatDate = myDate.substring(11, 16)
    return formatDate
  }

  // return date format
  const dateFormat = (myDate: string) => {
    if (myDate === null) {
      return ''
    }

    const formatDate = format(new Date(myDate), 'dd/MM/yyyy', {
      locale: LocaleApp
    })

    return formatDate
  }

  // attendances get all attendances
  useEffect(() => {
    setIsLoadingAttendance(true)
    apiAuth
      .get<GetAttendances>(`clinics/attendances/`, {
        params: {
          is_active: true,
          patientId: params.id,
          orderBy: '-scheduled_initial_time',
          specialistId: specialistIds.join(','),
          status: statusParam.join(',')
        }
      })
      .then((response) => {
        setAttendance(response.data.results)
      })
      .finally(() => {
        setIsLoadingAttendance(false)
      })

    return () => {}
  }, [specialistIds, statusParam])

  // medical records get all function
  const getAllMedicalRecordsValues = () => {
    apiAuth
      .get<GetMedicalRecordsData>(
        `clinics/patients/${params.id}/medical-records/`,
        {
          params: {
            start_date:
              medicalStartDate !== '' ? `${medicalStartDate}T00:00:00` : '',
            finish_date:
              medicalEndDate !== '' ? `${medicalEndDate}T23:59:59` : '',
            keyword: medicalKeyword,
            fields: `${medicalTypeDataFilter.join(
              ','
            )},start_date,finish_date,services_performed,services_not_performed,specialist,attendance_id`,
            specialistId: medicalRecordsSpecialist.join(',')
          }
        }
      )
      .then((response) => {
        setMedicalRecords(response.data.results)
      })
  }

  // medical records get all
  useEffect(() => {
    apiAuth
      .get<GetMedicalRecordsData>(
        `clinics/patients/${params.id}/medical-records/`,
        {
          params: {
            start_date:
              medicalStartDate !== '' ? `${medicalStartDate}T00:00:00` : '',
            finish_date:
              medicalEndDate !== '' ? `${medicalEndDate}T23:59:59` : '',
            keyword: medicalKeyword,
            fields: `${medicalTypeDataFilter.join(
              ','
            )},finish_date,services_performed,services_not_performed,specialist,attendance_id`,
            specialistId: medicalRecordsSpecialist.join(',')
          }
        }
      )
      .then((response) => {
        setMedicalRecords(response.data.results)
      })
  }, [
    medicalRecordsSpecialist,
    medicalTypeDataFilter,
    medicalStartDate,
    medicalEndDate,
    medicalKeyword
  ])

  // handler init attendance
  async function handlerInitAttendance(index: number, id: number | string) {
    const myAttendance = attendance[index]

    myAttendance.status = 6
    myAttendance.real_initial_time = format(
      new Date(),
      "yyyy-MM-dd'T'HH':'mm':'ss",
      {
        locale: LocaleApp
      }
    )

    const data = myAttendance

    const payload = {
      start_date: format(new Date(), "yyyy-MM-dd'T'HH':'mm':'ss", {
        locale: LocaleApp
      })
    }

    try {
      await apiAuth.put(`clinics/attendances/${attendance[index].id}/`, data)
      await apiAuth.patch(
        `clinics/attendances/${attendance[index].id}/medical-records/`,
        payload
      )

      history.push(`/attendance/${id}`)
    } catch (error: any) {
      console.log(error)
    }
  }

  // handler click tab index indication and function get all ind
  async function getAllIndicationsList() {
    setIsLoadingIndications(true)

    try {
      const { data } = await apiAuth.get(
        `clinics/patients/${params?.id}/indications/`,
        {
          params: {
            is_active: true,
            specialist_ids: filtersIndication?.specialistIds?.join(',') || '',
            status: filtersIndication?.status?.join(',') || '',
            order_by: filtersIndication?.orderBy || ''
          }
        }
      )

      if (data?.count) {
        setTotalOfRegisterIndication(data?.count)
      }

      if (data?.results) {
        setIndicationsData(data?.results)
      }

      setIsLoadingIndications(false)
    } catch (error: any) {
      console.log(error)
    }

    setIsLoadingIndications(false)
  }

  // handler click tab index indication and function get all ind
  async function getAllBudgetsList() {
    setIsLoadingBudgets(true)

    try {
      const { data } = await apiAuth.get(`clinics/quotes/`, {
        params: {
          patient_id: params?.id,
          specialist_id: filtersBudgets?.specialistIds?.join(',') || '',
          created_at: filtersBudgets?.date || ''
        }
      })

      if (data !== undefined) {
        console.log(data)
      }

      if (data?.count) {
        setTotalOfRegisterBudgets(data?.count)
      }

      if (data?.results) {
        setBudgetsData(data?.results)
      }

      console.log(data)

      setIsLoadingBudgets(false)
    } catch (error: any) {
      console.log(error)
    }

    setIsLoadingBudgets(false)
  }

  useEffect(() => {
    if (tabIndexClient === 0) {
      if (state?.image !== undefined) {
        if (state.image !== null) {
          setImgSrc(state.image)
        }
      }
    } else {
      setImgSrc('')
    }

    if (tabIndexClient === 4) {
      getCardsTimeline()
      setNameCard(
        format(new Date(), 'dd/MM/yyyy', {
          locale: LocaleApp
        })
      )
    }

    if (tabIndexClient === 3) {
      getAllIndicationsList()
    }

    if (tabIndexClient === 4) {
      getAllBudgetsList()
    }
  }, [tabIndexClient])

  useEffect(() => {
    // handler click tab index indication and function get all ind
    async function getAllIndicationsListEffect() {
      setIsLoadingIndications(true)

      try {
        const { data } = await apiAuth.get(
          `clinics/patients/${params?.id}/indications/`,
          {
            params: {
              is_active: true,
              specialist_ids: filtersIndication?.specialistIds?.join(',') || '',
              status: filtersIndication?.status?.join(',') || '',
              order_by: filtersIndication?.orderBy || ''
            }
          }
        )

        if (data?.count) {
          setTotalOfRegisterIndication(data?.count)
        }

        if (data?.results) {
          setIndicationsData(data?.results)
        }

        setIsLoadingIndications(false)
      } catch (error: any) {
        console.log(error)
      }

      setIsLoadingIndications(false)
    }

    getAllIndicationsListEffect()
  }, [filtersIndication])

  useEffect(() => {
    async function getAllBudgetsListEffect() {
      setIsLoadingBudgets(true)

      try {
        const { data } = await apiAuth.get(`clinics/quotes/`, {
          params: {
            patient_id: params?.id,
            specialist_id: filtersBudgets?.specialistIds?.join(',') || '',
            created_at: filtersBudgets?.date || ''
          }
        })

        if (data !== undefined) {
          console.log(data)
        }

        if (data?.count) {
          setTotalOfRegisterBudgets(data?.count)
        }

        if (data?.results) {
          setBudgetsData(data?.results)
        }

        setIsLoadingBudgets(false)
      } catch (error: any) {
        console.log(error)
      }

      setIsLoadingBudgets(false)
    }

    getAllBudgetsListEffect()
  }, [filtersBudgets])

  useEffect(() => {
    handleGetMethod()
  }, [history.location.pathname])

  useEffect(() => {
    if (decodeToken === undefined) {
      return
    }

    if (decodeToken.clinic_country === 'Brasil') {
      address.setSeledtedBR(true)
    }

    const country = [decodeToken.clinic_country]
    const ArrayCountry = address.optionsContries

    const SelectedDefault = ArrayCountry.map((count: any) =>
      country.find((cou) => {
        if (cou === count.value) {
          address.setSeletedCountry(count)
        }
      })
    )
  }, [decodeToken])

  // functions maker element html to element React
  const htmlToElement = (html: string) => {
    const Element = parserElement(html || '', {
      trim: true
    })

    return Element === '' ? null : Element
  }

  // functions medical record update states
  // start
  const handlerUpdateStartDateMedicalRecording = (date: string) => {
    setMedicalStartDate(date)
  }
  // end
  const handlerUpdateEndDateMedicalRecording = (date: string) => {
    setMedicalEndDate(date)
  }

  const handlerSelectSpecialistMedicalRecording = (values: string[]) => {
    setMedicalRecordsSpecialist(values)
  }

  // function navigate card
  const handlerClickInCardToUpdate = (id: string | number) => {
    const dataState = {
      id: state?.id
    }

    history.push(`/update/card/${id}`, dataState)
  }

  // function expanded recording
  const handlerClickButtonView = () => {
    if (arrayOpenTabs?.length === undefined) {
      const arrayNumbers = medicalRecords.map((group, index) => {
        return index
      })
      setArrayOpenTabs(arrayNumbers)
    } else {
      setArrayOpenTabs(undefined)
    }
  }

  // function return boolean value for button add medical record
  const returnValidateViewButtonAddMedicalRecord = (finishDate: string) => {
    const result =
      Math.abs(new Date(finishDate).getTime() - new Date().getTime()) / 3600000
    if (result <= 24) {
      return true
    }

    return false
  }

  if (state === null) {
    return (
      <Box overflow="hidden" position="relative">
        <Header>
          <Flex
            mx="auto"
            w="100%"
            direction="row"
            d="flex"
            my="6"
            align="center"
            justify="space-around"
          >
            <Box mx="auto">
              <Text fontSize="24px" fontWeight="600" height="100%">
                {localMethod === 'create'
                  ? t('create.title')
                  : `${t('updateTitle')}`}
              </Text>
            </Box>
            <Stack direction="row" align="center" justify="center">
              <ArrowBack href="/clients">
                <Text fontSize="md" fontWeight="bold">
                  {oldLocation?.isBackAttendance === true
                    ? `${t('backAttendance')}`
                    : `${t(`back`)}`}
                </Text>
              </ArrowBack>
            </Stack>
          </Flex>
        </Header>
        <Sidebar />
        <>
          <Flex
            flex="1"
            align="center"
            justify="center"
            mx="auto"
            as="form"
            ml="75px"
            mt="15px"
            mb="8px"
            mr="2"
            bg="white"
            h="cal(100vh - 60px)"
          >
            <Flex w="100vw" h="100vh" align="center" justify="center">
              <Spinner color="blue" />
            </Flex>
          </Flex>
        </>
      </Box>
    )
  }

  // handler change indication in tab indication
  const handlerUpdateIndication = async () => {
    const payload = {
      indication_date: `${dateIndication}T00:00:00`,
      observation: observationInd !== '' ? observationInd : null,
      status: statusChangeIndication,
      service: {
        id: idServiceIndication
      }
    }

    try {
      const { data } = await apiAuth.put(
        `/clinics/medical-record-indications/${idIndication}/`,
        payload
      )

      if (data) {
        toast({
          duration: 3000,
          variant: 'solid',
          title: 'Atualização realizada',
          status: 'success',
          description: 'Atualizando a lista de indicações'
        })

        await getAllIndicationsList()

        setIsOpenEditIndication(false)
      }
    } catch (error: any) {
      toast({
        duration: 3000,
        variant: 'solid',
        title: 'Não foi possível atualizar',
        status: 'error'
      })
    }
  }

  return (
    <Box overflow="hidden" position="relative">
      <Header>
        <Flex
          mx="auto"
          w="100%"
          direction="row"
          d="flex"
          my="6"
          align="center"
          justify="space-around"
        >
          <Flex mx="auto" flexDirection="row">
            <Flex mx="auto" align="center" justify="center">
              <Text mx="2" fontSize="24px" fontWeight="600" height="100%">
                {localMethod === 'create' ? t('create.title') : ``}
              </Text>
              <FiUser />
              {isWideVersion ? (
                <Text
                  align="end"
                  mx="2"
                  fontSize="18px"
                >{`${state?.name}`}</Text>
              ) : null}
            </Flex>
          </Flex>
          <Stack direction="row" align="center" justify="center">
            <ArrowBack href="/client" goBack>
              <Text fontSize="md" fontWeight="bold">
                {oldLocation?.isBackAttendance === true
                  ? `${t('backAttendance')}`
                  : `${t(`back`)}`}
              </Text>
            </ArrowBack>
          </Stack>
        </Flex>
      </Header>
      <Sidebar />
      <>
        <Flex
          flex="1"
          mx="auto"
          as="form"
          ml={isWideVersion ? '75px' : '2'}
          mt="15px"
          mb="8px"
          mr="2"
          bg="white"
          h="cal(100vh - 60px)"
          onSubmit={handleSubmit(handleUpdateClient)}
        >
          <Tabs
            onChange={(event) => setTabIndexClient(event)}
            isFitted
            defaultIndex={tabIndexClient}
            variant="soft-rounded"
            colorScheme="blue"
            w="100%"
          >
            <TabList mb="1em">
              <Tab>{t('tabs.1')}</Tab>
              <Tab>{t('tabs.2')}</Tab>
              <Tab>{t('tabs.3')}</Tab>
              <Tab>{t('tabs.4')}</Tab>
              <Tab>{t('tabs.5')}</Tab>
              {/* <Tab isDisabled _hover={{ cursor: 'not-allowed' }}>
                {t('tabs.6')}
              </Tab> */}
            </TabList>
            <TabPanels>
              <TabPanel>
                <Stack spacing="4" w="100%">
                  <CardContainer
                    title={t(
                      'pageClients.PageNovoCliente.containerOne.titleContainer'
                    )}
                  >
                    <Box position="absolute" top="4" right="4">
                      <Tooltip
                        label={t(
                          'pageClients.PageNovoCliente.containerOne.isActive'
                        )}
                        aria-label={t(
                          'pageClients.PageNovoCliente.containerOne.isActive'
                        )}
                      >
                        <Box>
                          <Switch
                            name="is_active"
                            isChecked={is_active}
                            onChange={(event) =>
                              setIsActive(event.target.checked)
                            }
                          />
                        </Box>
                      </Tooltip>
                    </Box>
                    <Flex flexDirection="row">
                      <Flex flexDirection="column">
                        {/* Image for user */}
                        {imgSrc ? <ImageComponent /> : <Dropzone />}
                        {/* Open Modal Webcam */}
                        <Button
                          size="md"
                          fontSize="md"
                          bg="blue.300"
                          color="#fff"
                          onClick={() => setIsOpen(true)}
                        >
                          {t('pageClients.PageNovoCliente.btnWebcam')}
                        </Button>
                      </Flex>
                      <Flex
                        w="100%"
                        ml="6"
                        flexDirection="column"
                        justify="space-around"
                      >
                        <SimpleGrid minChildWidth="320px">
                          <Stack direction="row" my="2" spacing="6">
                            <Box w="50%">
                              <Input
                                label={t(
                                  'pageClients.PageNovoCliente.containerOne.iNome'
                                )}
                                isRequired
                                name="name"
                                value={name}
                                onChange={(event) =>
                                  setName(event.target.value.toUpperCase())
                                }
                              />
                            </Box>
                            <Box w="240px">
                              <Input
                                label={t(
                                  'pageClients.PageNovoCliente.containerOne.iSocialName'
                                )}
                                isRequired
                                name="socialName"
                                value={socialName}
                                maxLength={20}
                                onChange={(event) =>
                                  setSocialName(
                                    event.target.value.toUpperCase()
                                  )
                                }
                              />
                            </Box>
                            <Box w="200px">
                              <Input
                                label={t(
                                  'pageClients.PageNovoCliente.containerOne.iDataNasc'
                                )}
                                type="date"
                                handleChange={(e) => {
                                  setBirthDate(e.target.value)
                                  handleDefinedAge(e)
                                }}
                                isRequired={
                                  decodeToken?.clinic?.patient_birth_date
                                }
                                name="birth_date"
                                value={birth_date}
                                defaultValue={state?.birth_date}
                              />
                            </Box>
                          </Stack>
                        </SimpleGrid>

                        <Stack
                          direction="row"
                          my="2"
                          spacing="6"
                          w="100%"
                          justify="space-between"
                        >
                          <Box w="100px">
                            <Input
                              label={t(
                                'pageClients.PageNovoCliente.containerOne.iIdade'
                              )}
                              disabled
                              value={ageUser}
                              name="age"
                            />
                          </Box>
                          <Box w="30%">
                            <Select
                              label={t(
                                'pageClients.PageNovoCliente.containerOne.iTDoc'
                              )}
                              placeholder="Ex: CPF"
                              isRequired={decodeToken?.clinic?.patient_document}
                              value={valueDocumentSelect}
                              onChange={(
                                event: React.ChangeEvent<HTMLSelectElement>
                              ) => setValueDocumentSelect(event.target.value)}
                              options={[
                                {
                                  value: '1',
                                  label: `${t(
                                    'pageClients.PageNovoCliente.containerOne.TiposDoc.cpf'
                                  )}`
                                },
                                // {
                                //   value: '2',
                                //   label: `${t(
                                //     'pageClients.PageNovoCliente.containerOne.TiposDoc.cnpj'
                                //   )}`
                                // },
                                {
                                  value: '0',
                                  label: `${t(
                                    'pageClients.PageNovoCliente.containerOne.TiposDoc.outros'
                                  )}`
                                }
                              ]}
                              name="document_type"
                            />
                          </Box>
                          <Box w="30%">
                            <TextFieldControlled
                              label={t(
                                'pageClients.PageNovoCliente.containerOne.iDoc'
                              )}
                              mask={typeDocument}
                              textAlign="left"
                              isRequired={decodeToken?.clinic?.patient_document}
                              placeholder={t(
                                'pageClients.PageNovoCliente.containerOne.iDoc'
                              )}
                              name="document_number"
                              initialValue={numberDocument}
                              onKeyUp={(
                                event: React.KeyboardEvent<HTMLInputElement>
                              ) => {
                                setNumberDocument(event.currentTarget.value)
                              }}
                            />
                          </Box>
                          <Flex
                            p="4"
                            border="1px solid #d1d1d6"
                            flexDirection="column"
                            borderRadius={8}
                          >
                            <Flex
                              align="flex-start"
                              justify="flex-start"
                              mb="4"
                            >
                              <Text>
                                {t(
                                  'pageClients.PageNovoCliente.containerOne.iSexo'
                                )}
                              </Text>
                            </Flex>
                            <RadioGroup
                              value={sex}
                              onChange={(event) => setSex(event)}
                              defaultValue={sex}
                            >
                              <Stack spacing={4} direction="row">
                                <ChakraRadio value="1">
                                  {t(
                                    'pageClients.PageNovoCliente.containerOne.TiposSexo.m'
                                  )}
                                </ChakraRadio>
                                <ChakraRadio value="2">
                                  {t(
                                    'pageClients.PageNovoCliente.containerOne.TiposSexo.f'
                                  )}
                                </ChakraRadio>
                              </Stack>
                            </RadioGroup>
                          </Flex>
                        </Stack>
                      </Flex>
                    </Flex>

                    <Stack direction="row" my="2" spacing="6">
                      <Box w="50%">
                        <Input
                          label={t(
                            'pageClients.PageNovoCliente.containerOne.iTrabalho'
                          )}
                          {...register('occupation')}
                          defaultValue={state?.role}
                          error={errors.occupation}
                        />
                      </Box>
                      <Box w="50%">
                        <SelectComponent
                          title="Origens"
                          options={originsUpdate.map((origin) => {
                            return {
                              value: origin.id,
                              label: origin.name
                            }
                          })}
                          placeholder={t('placeholder.origins')}
                          isRequired={decodeToken?.clinic?.patient_origin}
                          isMulti
                          value={patientsOrigins}
                          onChange={(event: any) =>
                            handleSelectedOrigins(event)
                          }
                          name="origins"
                          noOptionsText="Nenhuma origem encontrada"
                        />
                      </Box>
                    </Stack>

                    <Stack direction="row" my="2" spacing="6">
                      <Box w="100%">
                        <SelectComponent
                          title="Tags"
                          options={tagsUpdate.map((tag) => {
                            return {
                              value: tag.id,
                              label: tag.name
                            }
                          })}
                          isMulti
                          placeholder={t('placeholder.tags')}
                          value={patientsTags}
                          onChange={(event: any) => handleSelectedTags(event)}
                          name="tags"
                          noOptionsText="Nenhuma tag encontrada"
                        />
                      </Box>
                    </Stack>

                    <Stack direction="row" my="2" spacing="6">
                      <Box w="100%">
                        <Text fontSize="sm" my="2">
                          {t(
                            'pageClients.PageNovoCliente.containerOne.iObsMedical'
                          )}
                        </Text>
                        <Textarea
                          {...register('alerts')}
                          defaultValue={state?.alerts}
                          placeholder={t('placeholder.observationMedical')}
                        />
                      </Box>
                      <Box w="100%">
                        <Text fontSize="sm" my="2">
                          {t('pageClients.PageNovoCliente.containerOne.iObs')}
                        </Text>
                        <Textarea
                          {...register('observation')}
                          placeholder={t('placeholder.observation')}
                          defaultValue={state?.observation}
                        />
                      </Box>
                    </Stack>
                  </CardContainer>

                  <CardContainer
                    title={t('pageClients.PageNovoCliente.containerTwo.title')}
                  >
                    <Stack my="2" spacing="6">
                      {numbers.map((number, index: number) => (
                        <Stack direction="row" key={`number${index}`}>
                          <Stack direction="row" w="100%">
                            <InputPhone2
                              label={`${t(
                                'pageClients.PageNovoCliente.containerTwo.tele'
                              )} ${index + 1}`}
                              defaultValues={{
                                ddi: numbers[index].ddi_country
                              }}
                              type="text"
                              size="md"
                              name="number"
                              getValues={(value) => {
                                const values = [...numbers]
                                values[index].ddi_country = value?.ddi_country
                                setNumbers(values)
                              }}
                              value={numbers[index].contact}
                              onChange={(e) =>
                                handleChangeInputNumbers(index, e)
                              }
                            />
                            {numbers[index].contact.length > 5 && (
                              <Input
                                name="descriptionContact"
                                value={numbers[index].description}
                                label={t(
                                  'pageClients.PageNovoCliente.containerTwo.description'
                                )}
                                onChange={(event) =>
                                  handlerChangeDescriptionContact(index, event)
                                }
                              />
                            )}
                            {/*                       <Flex
                        pt="4"
                        direction="column"
                        align="center"
                        justify="center"
                      >
                        {numbers.length > 1 && (
                          <Box>
                            <Button
                              type="button"
                              colorScheme="red"
                              size="xs"
                              onClick={() => handleRemoveNumbers(index)}
                              mb="2"
                            >
                              -
                            </Button>
                          </Box>
                        )}
                        <Box>
                          <Button
                            bg="green.400"
                            color="#fff"
                            _hover={{
                              bg: 'green.400',
                              filter: 'brightness(0.7)'
                            }}
                            _active={{
                              bg: 'green.400',
                              filter: 'brightness(0.7)'
                            }}
                            type="button"
                            size="xs"
                            onClick={() => handleAddNumbers()}
                          >
                            +
                          </Button>
                        </Box>
                      </Flex> */}
                          </Stack>
                        </Stack>
                      ))}
                    </Stack>
                  </CardContainer>

                  <CardContainer
                    title={t(
                      'pageClients.PageNovoCliente.containerEmail.title'
                    )}
                  >
                    <Stack direction="row" my="2" spacing="6">
                      <Input
                        label={t(
                          'pageClients.PageNovoCliente.containerEmail.email'
                        )}
                        name="email"
                        type="email"
                        size="md"
                        value={emails[0].email}
                        onChange={(event) => handlerChangeEmail(0, event)}
                      />
                      {emails[0].email.length > 5 && (
                        <Input
                          label={t(
                            'pageClients.PageNovoCliente.containerEmail.description'
                          )}
                          name="descriptionEmail"
                          value={emails[0].description}
                          onChange={(event) =>
                            handlerChangeEmailDescription(0, event)
                          }
                        />
                      )}
                    </Stack>
                  </CardContainer>

                  <CardContainer
                    title={t(
                      'pageClients.PageNovoCliente.containerThree.title'
                    )}
                  >
                    <Stack direction="row" my="2" spacing="2">
                      <Box w="30%">
                        <SelectComponent
                          title={t(
                            'pageClients.PageNovoCliente.containerThree.iPais'
                          )}
                          placeholder="Selecione um país"
                          options={address.optionsContries}
                          name="pais"
                          value={address.seletedCountry}
                          onChange={(e: any) =>
                            address.handleBRSelectedAndShowStates(e)
                          }
                        />
                      </Box>
                      <Box>
                        <Input
                          label={t(
                            'pageClients.PageNovoCliente.containerThree.iCEP'
                          )}
                          type="number"
                          name="cep"
                          isDisabled={address.loadingCompletAddress}
                          value={cepAddress}
                          onChange={(e) => {
                            address.handleCompletAddress(e.target.value)
                            if (e.currentTarget.value.length <= 8) {
                              setCepAddress(e.currentTarget.value)
                            }
                          }}
                        />
                      </Box>
                      <Box w="40%">
                        <Input
                          label={t(
                            'pageClients.PageNovoCliente.containerThree.iRua'
                          )}
                          name="street"
                          value={address.address}
                          onChange={(e) => address.setAddress(e.target.value)}
                        />
                      </Box>
                      <Box>
                        <Input
                          label={t(
                            'pageClients.PageNovoCliente.containerThree.iNumero'
                          )}
                          type="number"
                          name="numberAddress"
                          defaultValue={state?.address?.number}
                          onChange={(e) => setNumberAddress(e.target.value)}
                          value={numberAddress}
                        />
                      </Box>
                    </Stack>
                    <Stack direction="row" my="2" spacing="2">
                      <Box w="30%">
                        <Input
                          label={t(
                            'pageClients.PageNovoCliente.containerThree.iComplemento'
                          )}
                          value={complement}
                          name="complementAddress"
                          onChange={(e) => setComplement(e.target.value)}
                        />
                      </Box>
                      <Box>
                        <Input
                          label={t(
                            'pageClients.PageNovoCliente.containerThree.iBairro'
                          )}
                          name="neighborhood"
                          value={address.bairro}
                          onChange={(e) => address.setBairro(e.target.value)}
                        />
                      </Box>
                      <Box w="30%">
                        <Input
                          label={t(
                            'pageClients.PageNovoCliente.containerThree.iCidade'
                          )}
                          name="city"
                          value={address.municipio}
                          onChange={(e) => address.setMunicipio(e.target.value)}
                        />
                      </Box>
                      {address.selectedBR ? (
                        <Box w="30%">
                          <SelectComponent
                            title={t(
                              'pageClients.PageNovoCliente.containerThree.iEstado'
                            )}
                            placeholder="Selecione um estado"
                            options={address.ufs}
                            name="estado"
                            value={address.seletedState}
                            onChange={(e: any) => address.setSelectedState(e)}
                          />
                        </Box>
                      ) : (
                        <Box w="30%">
                          <Input
                            label={t(
                              'pageClients.PageNovoCliente.containerThree.iEstado'
                            )}
                            defaultValue={state?.address?.state}
                            {...register('state')}
                            error={errors.state}
                          />
                        </Box>
                      )}
                    </Stack>
                  </CardContainer>

                  <CardContainer
                    title={t('pageClients.PageNovoCliente.containerFive.title')}
                  >
                    <Stack spacing="6">
                      {healthInsurance.map((health, index: number) => (
                        <Stack
                          w="100%"
                          direction="row"
                          my="2"
                          spacing="6"
                          key={`health${index}`}
                        >
                          <Box w="40%">
                            <SelectComponent
                              title={t(
                                'pageClients.PageNovoCliente.containerFive.iConvenios'
                              )}
                              placeholder={t('placeholder.covenants')}
                              options={covenantsUpdate.map((con) => {
                                return {
                                  value: con.id,
                                  label: con.name
                                }
                              })}
                              value={health.healthInsurance}
                              onChange={(e: any) =>
                                handleSelectConvenio(index, e)
                              }
                              name="health_insurance"
                            />
                          </Box>
                          <Stack direction="row" spacing="4" flex="1">
                            <Box w="100%">
                              <Input
                                label={t(
                                  'pageClients.PageNovoCliente.containerFive.iNumero'
                                )}
                                isRequired={isRequiredReturnForForm(
                                  health.healthInsurance
                                )}
                                placeholder={t('placeholder.covenantsNumber')}
                                value={health.healthInsuranceNumber || ''}
                                onChange={(e) => handleInputConvenio(index, e)}
                                name="health_insurance_number"
                              />
                            </Box>
                            <Flex
                              pt="4"
                              direction="column"
                              align="center"
                              justify="center"
                            >
                              {healthInsurance.length > 1 && (
                                <Box>
                                  <Button
                                    type="button"
                                    colorScheme="red"
                                    size="xs"
                                    onClick={() => removeFieldsConvenio(index)}
                                    mb="2"
                                  >
                                    -
                                  </Button>
                                </Box>
                              )}
                              <Box>
                                <Button
                                  bg="green.400"
                                  color="#fff"
                                  _hover={{
                                    bg: 'green.400',
                                    filter: 'brightness(0.7)'
                                  }}
                                  _active={{
                                    bg: 'green.400',
                                    filter: 'brightness(0.7)'
                                  }}
                                  type="button"
                                  size="xs"
                                  onClick={() => addFieldsConvenio()}
                                >
                                  +
                                </Button>
                              </Box>
                            </Flex>
                          </Stack>
                        </Stack>
                      ))}
                    </Stack>
                  </CardContainer>

                  <CardContainer
                    title={t('pageClients.PageNovoCliente.containerFour.title')}
                  >
                    <Stack spacing="6">
                      {patientRelatives.map((patient, index: number) => (
                        <Stack
                          w="100%"
                          direction="row"
                          my="2"
                          spacing="6"
                          key={`patient${index}`}
                        >
                          <Box w="60%">
                            <SelectComponentAsync
                              title={t(
                                'pageClients.PageNovoCliente.containerFour.iNome'
                              )}
                              placeholder={t('placeholder.family')}
                              loadOptions={(values: string) =>
                                clientService.getClientsServices(values)
                              }
                              value={patient.patientRelatives}
                              onChange={(e: any) =>
                                handleSelectedRelativesNames(index, e)
                              }
                              name="patient_relatives"
                            />
                          </Box>
                          <Stack direction="row" spacing="4" flex="1">
                            <Box w="100%">
                              <Select
                                label={t(
                                  'pageClients.PageNovoCliente.containerFour.iGrauP'
                                )}
                                placeholder="Ex: Pai"
                                options={optionsFamily}
                                value={patient.patientDegree}
                                isRequired={isRequiredReturnForForm(
                                  patient.patientRelatives
                                )}
                                onChange={(e: any) =>
                                  handleSelectRelativesFamily(index, e)
                                }
                                name="patient_degree"
                              />
                            </Box>
                            <Flex
                              pt="4"
                              direction="column"
                              align="center"
                              justify="center"
                            >
                              {patientRelatives.length > 1 && (
                                <Box>
                                  <Button
                                    type="button"
                                    colorScheme="red"
                                    size="xs"
                                    onClick={() => removeFieldsRelatives(index)}
                                    mb="2"
                                  >
                                    -
                                  </Button>
                                </Box>
                              )}
                              <Box>
                                <Button
                                  bg="green.400"
                                  color="#fff"
                                  _hover={{
                                    bg: 'green.400',
                                    filter: 'brightness(0.7)'
                                  }}
                                  _active={{
                                    bg: 'green.400',
                                    filter: 'brightness(0.7)'
                                  }}
                                  type="button"
                                  size="xs"
                                  onClick={() => addFieldsRelatives()}
                                >
                                  +
                                </Button>
                              </Box>
                            </Flex>
                          </Stack>
                        </Stack>
                      ))}
                    </Stack>
                  </CardContainer>

                  <Flex
                    mt="6"
                    w="100%"
                    align="flex-end"
                    justifyContent="flex-end"
                    gap={2}
                  >
                    <Button
                      type="submit"
                      size="md"
                      fontSize="md"
                      bg="blue.300"
                      color="#fff"
                      isLoading={formState.isSubmitting}
                      onClick={() => {
                        setRedirect(false)
                      }}
                    >
                      {t('pageClients.PageNovoCliente.continue')}
                    </Button>
                    <Button
                      type="submit"
                      size="md"
                      fontSize="md"
                      bg="blue.300"
                      color="#fff"
                      isLoading={formState.isSubmitting}
                      onClick={() => {
                        setRedirect(true)
                      }}
                    >
                      {t('pageClients.PageNovoCliente.buttonSave')}
                    </Button>
                  </Flex>

                  <Stack w="100%" my="2" h="300px" />
                </Stack>
              </TabPanel>
              <TabPanel>
                <>
                  <Flex flex="1" flexDirection="column">
                    <Flex
                      w="100%"
                      borderBlockEnd="1px solid #e4e4e4"
                      mb="2"
                      pb="2"
                      align="flex-start"
                      justify="space-between"
                      mx="auto"
                    >
                      <Flex flexDirection="row" w="80%" alignItems="end">
                        {/* {isWideVersion ? (
                          <Heading size="md" mx="2">
                            {t('attendance.title')}
                          </Heading>
                        ) : (
                          <Heading size="md" mx="2">
                            {t('attendance.title')}
                          </Heading>
                        )} */}
                        <MultiSelectMenuFilter
                          label="Especialistas"
                          options={clientService.specialists.map(
                            (specialist) => {
                              return {
                                id: String(specialist?.user?.id),
                                name:
                                  specialist?.social_name ||
                                  specialist.user.name
                              }
                            }
                          )}
                          backChange={(values: any) => {
                            getFiltersSpecialist(values)
                          }}
                        />

                        <MultiSelectMenuFilter
                          label="Status"
                          options={statusOptionsDefault.map((itemStatus) => {
                            return {
                              id: String(itemStatus.value),
                              name: itemStatus.label
                            }
                          })}
                          backChange={(values: any) => {
                            setStatusParam(values)
                          }}
                        />
                        <Link
                          to={{
                            pathname: '/scheduling/create',
                            state: {
                              client: {
                                value: state?.id,
                                label: state?.name
                              },
                              today: format(new Date(), 'yyyy-MM-dd', {
                                locale: LocaleApp
                              })
                            }
                          }}
                        >
                          <Button
                            size="md"
                            bg="blue.300"
                            mx="2"
                            mb="2px"
                            color="white"
                          >
                            Nova Agenda
                          </Button>
                        </Link>
                      </Flex>
                      <Flex flexDirection="row">
                        <Button
                          mx="2"
                          colorScheme="blue"
                          isDisabled
                          size="sm"
                          onClick={() => {}}
                        >
                          <FiPrinter size={20} />
                        </Button>
                      </Flex>
                    </Flex>
                    {/* content attendances */}
                    <Flex flex="1" h="100vh" flexDirection="column">
                      {isLoadingAttendance ? (
                        <Center w="100%" p="2">
                          <Spinner size="md" color="blue.300" />
                        </Center>
                      ) : (
                        <Table variant="striped" colorScheme="blue" size="sm">
                          <Thead>
                            <Tr>
                              <Th>{t('attendance.table.t1')}</Th>
                              <Th>{t('attendance.table.t2')}</Th>
                              <Th>{t('attendance.table.t3')}</Th>
                              <Th>{t('attendance.table.t4')}</Th>
                              {specialistIds.length === 1 ? null : (
                                <Th>{t('attendance.table.t7')}</Th>
                              )}
                              <Th>{t('attendance.table.t8')}</Th>
                              <Th>{t('attendance.table.t9')}</Th>
                              <Th>{t('attendance.table.t10')}</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {attendance?.map((item, index: number) => (
                              <Tr key={String(item?.id)}>
                                <Td>
                                  <Text fontSize="sm">
                                    {dateFormat(item?.scheduled_arrival_time)}
                                  </Text>
                                </Td>
                                <Td>
                                  <Text fontSize="sm">
                                    {hoursFormat(item?.scheduled_arrival_time)}
                                  </Text>
                                </Td>
                                <Td>
                                  <Text fontSize="sm">
                                    {hoursFormat(item?.real_arrival_time)}
                                  </Text>
                                </Td>
                                {item?.status && (
                                  <>
                                    <Td>
                                      <Flex gap="2" flexDir="row">
                                        {item?.status === 1 && (
                                          <Text fontSize="small">
                                            {t('attendance.status.1')}
                                          </Text>
                                        )}
                                        {item?.status === 2 && (
                                          <Text fontSize="small">
                                            {t('attendance.status.2')}
                                          </Text>
                                        )}
                                        {item?.status === 3 && (
                                          <Text fontSize="small">
                                            {t('attendance.status.3')}
                                          </Text>
                                        )}
                                        {item?.status === 4 && (
                                          <Text fontSize="small">
                                            {t('attendance.status.4')}
                                          </Text>
                                        )}
                                        {item?.status === 5 && (
                                          <Text fontSize="small">
                                            {t('attendance.status.5')}
                                          </Text>
                                        )}
                                        {item?.status === 6 && (
                                          <Text fontSize="small">
                                            {t('attendance.status.6')}
                                          </Text>
                                        )}
                                        {item?.status === 7 && (
                                          <Text fontSize="small">
                                            {t('attendance.status.7')}
                                          </Text>
                                        )}
                                        {item?.status_by_system && (
                                          <Tooltip
                                            label={t('bySystem')}
                                            aria-label={t('bySystem')}
                                            hasArrow
                                            placement="auto-end"
                                          >
                                            <Box>
                                              <Flex
                                                rounded="full"
                                                w="14px"
                                                alignItems="center"
                                                justifyContent="center"
                                                h="14px"
                                                bg="blue.300"
                                                color="white"
                                              >
                                                <Text>S</Text>
                                              </Flex>
                                            </Box>
                                          </Tooltip>
                                        )}
                                      </Flex>
                                    </Td>
                                  </>
                                )}
                                {specialistIds.length === 1 ? null : (
                                  <Td>
                                    <Text fontSize="smaller">
                                      {item?.specialist_name}
                                    </Text>
                                  </Td>
                                )}
                                <Td>
                                  {item?.attendance_items?.map((service) => (
                                    <Text
                                      key={service.id}
                                      fontSize="12px"
                                      fontWeight="semibold"
                                    >
                                      {`${service?.service_name} - 1/${service?.total_session}`}
                                    </Text>
                                  ))}
                                  {item?.sessions !== undefined &&
                                    item?.sessions?.map((sec) => (
                                      <Tooltip
                                        key={sec.id}
                                        label={`${sec?.service_name} - ${sec?.session_number}/${sec?.total_session}`}
                                        aria-label={`${sec?.service_name} - ${sec?.session_number}/${sec?.total_session}`}
                                        hasArrow
                                        placement="top-start"
                                      >
                                        <Text
                                          noOfLines={1}
                                          fontSize="12px"
                                          fontWeight="semibold"
                                          _hover={{
                                            cursor: 'default'
                                          }}
                                        >
                                          {`${sec?.service_name} - ${sec?.session_number}/${sec?.total_session}`}
                                        </Text>
                                      </Tooltip>
                                    ))}
                                </Td>
                                <Td>
                                  <Stack direction="row">
                                    {item?.observation ? (
                                      <Tooltip
                                        label={item?.observation || ''}
                                        aria-label={item?.observation || ''}
                                        hasArrow
                                        placement="auto-end"
                                      >
                                        <Box>
                                          <FcInfo size={20} />
                                        </Box>
                                      </Tooltip>
                                    ) : (
                                      <Box w="20px" />
                                    )}
                                    {returnJsxElementStatusTicket(
                                      item?.ticket_status_payment
                                    )}
                                    {item?.block_guide ? (
                                      <Tooltip
                                        label={t('attendance.block')}
                                        aria-label={t('attendance.block')}
                                        hasArrow
                                        placement="top-start"
                                      >
                                        <Box>
                                          <AiFillLock size={20} color="red" />
                                        </Box>
                                      </Tooltip>
                                    ) : (
                                      <Tooltip
                                        label={t('attendance.noBlock')}
                                        aria-label={t('attendance.noBlock')}
                                        hasArrow
                                        placement="top-start"
                                      >
                                        <Box>
                                          <AiFillUnlock
                                            size={20}
                                            color="green"
                                          />
                                        </Box>
                                      </Tooltip>
                                    )}
                                  </Stack>
                                </Td>
                                <Td>
                                  {Number(decodeToken.user_id) ===
                                    item?.professional_id &&
                                    verifyStatusAttendanceButton(
                                      item?.status
                                    ) &&
                                    item?.allowed_start_attendance === true && (
                                      <Button
                                        size="md"
                                        h="22px"
                                        fontSize="small"
                                        colorScheme="blue"
                                        onClick={() => {
                                          handlerInitAttendance(index, item?.id)
                                        }}
                                      >
                                        {t('attendance.btnInit')}
                                      </Button>
                                    )}
                                  {Number(decodeToken.user_id) ===
                                    item?.professional_id &&
                                    item?.status === 6 && (
                                      <Button
                                        size="md"
                                        h="22px"
                                        fontSize="small"
                                        colorScheme="blue"
                                        onClick={() => {
                                          history.push(
                                            `/attendance/${item?.id}`,
                                            {
                                              state: item
                                            }
                                          )
                                        }}
                                      >
                                        {t('attendance.btnContinue')}
                                      </Button>
                                    )}
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      )}
                      {attendance?.length === 0 && !isLoadingAttendance && (
                        <Flex
                          w="100%"
                          p="2"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Text fontSize="12px" fontWeight="hairline">
                            {t('attendance.none')}
                          </Text>
                        </Flex>
                      )}
                      <Flex w="100%" h="400px" />
                    </Flex>
                  </Flex>
                </>
              </TabPanel>
              <TabPanel>
                <>
                  <Flex flex="1" flexDirection="column">
                    <Flex
                      w="100%"
                      borderBlockEnd="1px solid #e4e4e4"
                      mb="2"
                      pb="2"
                      align="flex-start"
                      justify="space-between"
                      mx="auto"
                    >
                      <Flex flexDirection="row" w="90%">
                        <Stack direction="row">
                          <Box w="162px">
                            <Input
                              placeholder={t('recording.filters.startDate')}
                              type="date"
                              value={medicalStartDate}
                              onChange={(event) => {
                                handlerUpdateStartDateMedicalRecording(
                                  event.target.value
                                )
                              }}
                              name="filterRecording"
                            />
                          </Box>
                          <Box w="162px">
                            <Input
                              placeholder={t('recording.filters.endDate')}
                              value={medicalEndDate}
                              onChange={(event) => {
                                handlerUpdateEndDateMedicalRecording(
                                  event.target.value
                                )
                              }}
                              type="date"
                              name="filterRecording"
                            />
                          </Box>
                          <Box w="162px">
                            <Input
                              placeholder={t('recording.filters.keyword')}
                              name="keywordMedicalRecord"
                              value={medicalKeyword}
                              onChange={(event) =>
                                setMedicalKeyword(event.target.value)
                              }
                            />
                          </Box>
                          <MultiSelectMenuFilter
                            label={t('recording.filters.specialist')}
                            options={clientService.specialists.map(
                              (specialist) => {
                                return {
                                  id: String(specialist?.user?.id),
                                  name:
                                    specialist?.social_name ||
                                    specialist.user.name
                                }
                              }
                            )}
                            backChange={(values: any) => {
                              handlerSelectSpecialistMedicalRecording(values)
                            }}
                          />

                          <MultiSelectMenuFilter
                            label={t('recording.filters.types')}
                            isDefaultFull
                            options={TypesMedicalData.map((item) => {
                              return {
                                id: String(item?.value),
                                name: item?.label
                              }
                            })}
                            render={() => {
                              const defaultTypes = medicalTypeDataFilter

                              return defaultTypes
                            }}
                            backChange={(values: any) => {
                              setMedicalTypeDataFilter(values)
                            }}
                          />
                        </Stack>
                        <Flex>
                          <Button
                            colorScheme="blue"
                            mx="2"
                            onClick={() => {
                              handlerClickButtonView()
                            }}
                          >
                            {arrayOpenTabs?.length === undefined
                              ? t('recording.expanded')
                              : t('recording.recall')}
                          </Button>
                        </Flex>
                      </Flex>
                    </Flex>
                    {/* content recording  */}
                    <Flex flex="1" h="100vh" flexDirection="column">
                      <>
                        <Flex w="100%" flexDirection="column" gap={2}>
                          <Stack
                            direction={['column', 'row']}
                            w="100%"
                            textAlign="left"
                          >
                            <Box w="120px">
                              <Text textAlign="center" mx="10">
                                {t('recording.table.t1')}
                              </Text>
                            </Box>
                            <Box w="480px">
                              <Text textAlign="center" mx="10">
                                {t('recording.table.t2')}
                              </Text>
                            </Box>
                            <Box w="23%">
                              <Text mx="10">{t('recording.table.t3')}</Text>
                            </Box>
                            <Box w="300px" textAlign="right">
                              <Text mx="10">{t('recording.table.t4')}</Text>
                            </Box>
                          </Stack>
                          <Accordion
                            index={arrayOpenTabs}
                            allowMultiple
                            w="100%"
                            position="relative"
                          >
                            {medicalRecords?.map((record, index) => (
                              <AccordionItem
                                key={record.attendance_id || `record${index}`}
                              >
                                {({ isExpanded }) => (
                                  <>
                                    <h2>
                                      <AccordionButton
                                        _expanded={{
                                          bg: 'blue.500',
                                          color: 'white'
                                        }}
                                        justifyContent="space-around"
                                        alignItems="center"
                                      >
                                        <Flex
                                          flex="1"
                                          dir="row"
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <Text
                                            fontSize="14px"
                                            fontWeight="hairline"
                                            mx="2"
                                          >
                                            {dateFormat(record?.finish_date)}
                                          </Text>
                                          <Text
                                            fontSize="14px"
                                            fontWeight="hairline"
                                            mx="140px"
                                          >
                                            {record?.specialist?.social_name}
                                          </Text>
                                          <List
                                            spacing={2}
                                            w="350px"
                                            pl="30px"
                                            textAlign="left"
                                            justifyContent="flex-start"
                                          >
                                            {record?.services_performed?.map(
                                              (service) => (
                                                <ListItem
                                                  key={service?.service_id}
                                                >
                                                  <Tooltip
                                                    label={service.service_name}
                                                    aria-label={
                                                      service.service_name
                                                    }
                                                  >
                                                    <Text
                                                      fontSize="sm"
                                                      fontWeight="hairline"
                                                      noOfLines={1}
                                                      _hover={{
                                                        cursor: 'default'
                                                      }}
                                                    >
                                                      {`${service?.service_name} - 1/${service?.total_session}`}
                                                    </Text>
                                                  </Tooltip>
                                                </ListItem>
                                              )
                                            )}
                                            {record?.services_performed
                                              ?.length === 0 &&
                                              record?.sessions_performed
                                                ?.length && <Text>---</Text>}
                                          </List>
                                          <Flex
                                            gap={2}
                                            flexDirection="row"
                                            mr="6"
                                          >
                                            <Box>
                                              <Text
                                                fontSize="12"
                                                fontWeight="hairline"
                                              >
                                                {t('recording.forms')}
                                              </Text>
                                              <Text
                                                fontSize="14px"
                                                fontWeight="hairline"
                                                mx="2"
                                              >
                                                {record?.forms?.length || 0}
                                              </Text>
                                            </Box>
                                            <Box>
                                              <Text
                                                fontSize="12"
                                                fontWeight="hairline"
                                              >
                                                {t('recording.prescriptions')}
                                              </Text>
                                              <Text
                                                fontSize="14px"
                                                fontWeight="hairline"
                                                mx="2"
                                              >
                                                {record?.prescriptions
                                                  ?.length || 0}
                                              </Text>
                                            </Box>
                                            <Box>
                                              <Text
                                                fontSize="12"
                                                fontWeight="hairline"
                                              >
                                                {t('recording.exams')}
                                              </Text>
                                              <Text
                                                fontSize="14px"
                                                fontWeight="hairline"
                                                mx="2"
                                              >
                                                {record?.exams?.length || 0}
                                              </Text>
                                            </Box>
                                            <Box>
                                              <Text
                                                fontSize="12"
                                                fontWeight="hairline"
                                              >
                                                {t('recording.indications')}
                                              </Text>
                                              <Text
                                                fontSize="14px"
                                                fontWeight="hairline"
                                                mx="2"
                                              >
                                                {record?.indications?.length ||
                                                  0}
                                              </Text>
                                            </Box>
                                          </Flex>
                                        </Flex>
                                        {isExpanded ? (
                                          <MinusIcon fontSize="12px" />
                                        ) : (
                                          <AddIcon fontSize="12px" />
                                        )}
                                      </AccordionButton>
                                    </h2>
                                    <AccordionPanel
                                      borderRadius="4px"
                                      border="1px solid #d3d3d3"
                                      position="relative"
                                    >
                                      <Flex my="2" justifyContent="flex-end">
                                        <Box>
                                          {Number(decodeToken.user_id) ===
                                            record?.specialist?.id &&
                                            returnValidateViewButtonAddMedicalRecord(
                                              record.finish_date
                                            ) &&
                                            decodeToken?.clinic
                                              ?.adding_itens_to_attendance ===
                                              true && (
                                              <AddRecordUniq
                                                id={record?.attendance_id}
                                                updateListRecording={
                                                  getAllMedicalRecordsValues
                                                }
                                                servicesList={
                                                  servicesIndications
                                                }
                                              />
                                            )}
                                        </Box>
                                      </Flex>
                                      <Flex
                                        flex="1"
                                        alignItems="flex-start"
                                        justifyContent="flex-start"
                                        flexDirection="column"
                                        p="2"
                                      >
                                        {record?.services_not_performed
                                          ?.length > 0 && (
                                          <Text
                                            fontSize="lg"
                                            fontWeight="bold"
                                            fontFamily="heading"
                                            mb="2"
                                          >
                                            {t('recording.noServices')}
                                          </Text>
                                        )}
                                        <Flex w="100%">
                                          <List spacing={2}>
                                            {record?.services_not_performed?.map(
                                              (service) => (
                                                <ListItem
                                                  key={service?.service_id}
                                                >
                                                  <Flex flexDirection="row">
                                                    <ListIcon
                                                      as={BiErrorCircle}
                                                      color="red.500"
                                                    />
                                                    <Text
                                                      fontSize="sm"
                                                      fontWeight="bold"
                                                    >
                                                      {`${
                                                        service?.service_name
                                                      } - 1/${
                                                        service?.service
                                                          ?.service_sessions
                                                          ?.length || ''
                                                      }`}
                                                    </Text>
                                                    <Text
                                                      mx="2"
                                                      fontSize="sm"
                                                      fontWeight="hairline"
                                                    >
                                                      {service?.description}
                                                    </Text>
                                                  </Flex>
                                                </ListItem>
                                              )
                                            )}
                                          </List>
                                        </Flex>
                                        {record?.forms?.map(
                                          (form, indexForm) => (
                                            <Flex
                                              p="2"
                                              key={indexForm}
                                              my="2"
                                              borderRadius={8}
                                              border="1px solid gray"
                                              flexDirection="column"
                                              w="100%"
                                            >
                                              <Text
                                                fontSize="lg"
                                                fontWeight="bold"
                                                fontFamily="heading"
                                                mb="2"
                                                key={`${form?.name}${indexForm}`}
                                              >
                                                {t('recording.form')}
                                              </Text>
                                              <Flex
                                                w="100%"
                                                flexDirection="column"
                                                gap={2}
                                              >
                                                <Text>
                                                  {form?.name === 'Blank'
                                                    ? ''
                                                    : `${form?.name}`}
                                                </Text>
                                                {htmlToElement(
                                                  form?.information || ''
                                                )}
                                                {form?.questions?.map(
                                                  (question) => (
                                                    <Box
                                                      key={question?.question}
                                                    >
                                                      <Text>
                                                        {question?.question}
                                                      </Text>
                                                      <Text
                                                        mx="4"
                                                        fontSize="14px"
                                                        fontWeight="hairline"
                                                      >
                                                        {t('recording.answer')}
                                                        {question?.answer}
                                                      </Text>
                                                    </Box>
                                                  )
                                                )}
                                              </Flex>
                                            </Flex>
                                          )
                                        )}
                                        {record?.prescriptions?.map(
                                          (prescription, indexPres) => (
                                            <Flex
                                              p="2"
                                              my="2"
                                              key={prescription.id || indexPres}
                                              borderRadius={8}
                                              position="relative"
                                              border="1px solid gray"
                                              flexDirection="column"
                                              w="100%"
                                            >
                                              <Box
                                                position="absolute"
                                                top="2"
                                                right="2"
                                              >
                                                <PrintButton
                                                  id={
                                                    prescription?.id ||
                                                    prescription?.name
                                                  }
                                                  typePrint="prescription"
                                                  templates={templates}
                                                  patientId={params.id}
                                                />
                                              </Box>
                                              <Text
                                                fontSize="lg"
                                                fontWeight="bold"
                                                fontFamily="heading"
                                                mb="2"
                                                key={`${prescription?.name}${indexPres}`}
                                              >
                                                {t('recording.prescription')}
                                              </Text>
                                              <Flex
                                                w="100%"
                                                flexDirection="column"
                                                gap={2}
                                              >
                                                {prescription?.name === 'Blank'
                                                  ? ''
                                                  : `${prescription?.name}`}
                                                {htmlToElement(
                                                  prescription?.information ||
                                                    ''
                                                )}
                                              </Flex>
                                            </Flex>
                                          )
                                        )}
                                        {record?.exams?.map(
                                          (exam, indexExam) => (
                                            <Flex
                                              p="2"
                                              my="2"
                                              key={exam.id || indexExam}
                                              borderRadius={8}
                                              position="relative"
                                              border="1px solid gray"
                                              flexDirection="column"
                                              w="100%"
                                            >
                                              <Box
                                                position="absolute"
                                                top="2"
                                                right="2"
                                              >
                                                <PrintButton
                                                  id={exam?.id || exam?.name}
                                                  typePrint="exam"
                                                  templates={templates}
                                                  patientId={params.id}
                                                />
                                              </Box>
                                              <Text
                                                fontSize="lg"
                                                fontWeight="bold"
                                                fontFamily="heading"
                                                mb="2"
                                                key={`${exam?.name}${indexExam}`}
                                              >
                                                {t('recording.exam')}
                                              </Text>
                                              <Flex
                                                w="100%"
                                                flexDirection="column"
                                                gap={2}
                                              >
                                                {exam?.name === 'Blank'
                                                  ? ''
                                                  : `${exam?.name}`}
                                                {htmlToElement(
                                                  exam?.information || ''
                                                )}
                                              </Flex>
                                            </Flex>
                                          )
                                        )}
                                        {record?.indications &&
                                          record?.indications?.length > 0 && (
                                            <List
                                              p="2"
                                              w="100%"
                                              border="1px solid gray"
                                              rounded="md"
                                              mt="2"
                                              position="relative"
                                              spacing={2}
                                            >
                                              <Text
                                                fontSize="lg"
                                                fontWeight="bold"
                                                fontFamily="heading"
                                                mb="2"
                                              >
                                                {t('recording.indications')}
                                              </Text>
                                              <Box
                                                position="absolute"
                                                top="2"
                                                right="2"
                                              >
                                                <PrintButton
                                                  id={record?.attendance_id}
                                                  typePrint="indication"
                                                  templates={templates}
                                                  patientId={params.id}
                                                />
                                              </Box>
                                              {record?.indications
                                                ?.sort((a, b) => {
                                                  return (
                                                    new Date(
                                                      a?.indication_date || ''
                                                    ).getTime() -
                                                    new Date(
                                                      b?.indication_date || ''
                                                    ).getTime()
                                                  )
                                                })
                                                .map((ind, indexInd) => (
                                                  <ListItem
                                                    w="100%"
                                                    key={ind?.id}
                                                  >
                                                    <Flex
                                                      w="100%"
                                                      textAlign="left"
                                                      flexDirection="column"
                                                    >
                                                      <Flex
                                                        gap="2"
                                                        flexDir="row"
                                                      >
                                                        <ListIcon
                                                          as={
                                                            MdSettingsAccessibility
                                                          }
                                                          color="gray"
                                                        />
                                                        <Text
                                                          fontSize="sm"
                                                          fontWeight="bold"
                                                        >
                                                          {ind?.service_name}
                                                        </Text>
                                                        {ind?.indication_date && (
                                                          <Text
                                                            fontSize="sm"
                                                            fontWeight="bold"
                                                          >
                                                            {`- ${format(
                                                              new Date(
                                                                Number(
                                                                  ind?.indication_date.substring(
                                                                    0,
                                                                    4
                                                                  )
                                                                ),
                                                                Number(
                                                                  ind?.indication_date.substring(
                                                                    5,
                                                                    7
                                                                  )
                                                                ) - 1,
                                                                Number(
                                                                  ind?.indication_date.substring(
                                                                    8,
                                                                    10
                                                                  )
                                                                )
                                                              ),
                                                              'dd/MM/yyyy'
                                                            )}`}
                                                          </Text>
                                                        )}
                                                      </Flex>
                                                      <Text
                                                        mx="2"
                                                        ml="8"
                                                        fontSize="sm"
                                                        fontWeight="hairline"
                                                      >
                                                        {ind?.observation}
                                                      </Text>
                                                    </Flex>
                                                  </ListItem>
                                                ))}
                                            </List>
                                          )}
                                      </Flex>
                                    </AccordionPanel>
                                  </>
                                )}
                              </AccordionItem>
                            ))}
                            {medicalRecords?.length === 0 && (
                              <Flex
                                w="100%"
                                p="2"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Text fontSize="12px" fontWeight="hairline">
                                  {t('recording.none')}
                                </Text>
                              </Flex>
                            )}
                          </Accordion>
                        </Flex>
                      </>
                      <Flex w="100%" h="400px" />
                    </Flex>
                  </Flex>
                </>
              </TabPanel>
              <TabPanel>
                <>
                  <Flex flex="1" flexDirection="column">
                    <Flex
                      w="100%"
                      borderBlockEnd="1px solid #e4e4e4"
                      mb="2"
                      pb="2"
                      alignItems="flex-end"
                      mx="auto"
                    >
                      <Flex flexDirection="row" alignItems="flex-end">
                        <Stack direction="row" alignItems="flex-end">
                          <Box w="250px">
                            <Select
                              name="listBy"
                              options={[
                                {
                                  value: 'indication_date',
                                  label: `${t('indications.table.t1')}`
                                },
                                {
                                  value: 'medical_record__finish_date',
                                  label: `${t('indications.table.t2')}`
                                }
                              ]}
                              value={listByIndication}
                              onChange={(e) =>
                                setListByIndication(e.target.value)
                              }
                              label="Listar por:"
                              mb="0"
                            />
                          </Box>
                          <Box w="250px">
                            <Select
                              name="orderer"
                              options={[
                                { value: '', label: 'Crescente' },
                                { value: '-', label: 'Decrescente' }
                              ]}
                              label="Ordenar por:"
                              mb="0"
                              value={orderByIndication}
                              onChange={(e) =>
                                setOrderByIndication(e.target.value)
                              }
                            />
                          </Box>
                          <MultiSelectMenuFilter
                            label="Especialistas"
                            options={clientService.specialists.map(
                              (specialist) => {
                                return {
                                  id: String(specialist?.user?.id),
                                  name:
                                    specialist?.social_name ||
                                    specialist.user.name
                                }
                              }
                            )}
                            backChange={(values: any) => {
                              setSpecialistsIndication(values)
                            }}
                          />
                          <MultiSelectMenuFilter
                            label="Status"
                            options={statusOptionsIndications.map(
                              (itemStatus) => {
                                return {
                                  id: String(itemStatus.value),
                                  name: itemStatus.label
                                }
                              }
                            )}
                            backChange={(values: any) => {
                              setStatusIndication(values)
                            }}
                          />

                          <Button
                            size="md"
                            bg="blue.300"
                            color="white"
                            isLoading={isLoadingIndications}
                            onClick={() => {
                              const filters = {
                                specialistIds: specialistsIndication,
                                status: statusIndication,
                                orderBy: `${orderByIndication}${listByIndication}`
                              }

                              setFiltersIndication(filters)
                            }}
                          >
                            Aplicar
                          </Button>
                        </Stack>
                      </Flex>
                    </Flex>
                    {/* content indications */}
                    <Flex flex="1" h="100vh" flexDirection="column">
                      {isLoadingIndications ? (
                        <Center w="100%" p="2">
                          <Spinner size="md" color="blue.300" />
                        </Center>
                      ) : (
                        <>
                          <Table variant="striped" colorScheme="blue" size="sm">
                            <Thead>
                              <Tr>
                                <Th>{t('indications.table.t1')}</Th>
                                <Th>{t('indications.table.t2')}</Th>
                                {specialistIds.length === 1 ? null : (
                                  <Th w="200px">{t('indications.table.t3')}</Th>
                                )}
                                <Th>{t('indications.table.t4')}</Th>
                                <Th>{t('indications.table.t7')}</Th>
                                <Th>{t('indications.table.t8')}</Th>
                                <Th>{t('indications.table.t9')}</Th>
                                <Th>{t('indications.table.t10')}</Th>
                                <Th>{t('indications.table.t11')}</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {indicationsData?.length > 0 &&
                                !isLoadingIndications &&
                                indicationsData?.map((ind, indexInd) => (
                                  <Tr key={`${ind.service_name}${indexInd}`}>
                                    <Td>
                                      {ind?.indication_date ? (
                                        format(
                                          new Date(
                                            Number(
                                              ind?.indication_date.substring(
                                                0,
                                                4
                                              )
                                            ),
                                            Number(
                                              ind?.indication_date.substring(
                                                5,
                                                7
                                              )
                                            ) - 1,
                                            Number(
                                              ind?.indication_date.substring(
                                                8,
                                                10
                                              )
                                            )
                                          ),
                                          'dd/MM/yyyy',
                                          {
                                            locale: LocaleApp
                                          }
                                        )
                                      ) : (
                                        <Text>--/--/----</Text>
                                      )}
                                    </Td>
                                    <Td>
                                      {ind?.medical_record_finish_date ? (
                                        format(
                                          new Date(
                                            ind?.medical_record_finish_date
                                          ),
                                          'dd/MM/yyyy',
                                          {
                                            locale: LocaleApp
                                          }
                                        )
                                      ) : (
                                        <Text>--/--/----</Text>
                                      )}
                                    </Td>
                                    {specialistIds.length === 1 ? null : (
                                      <Td>
                                        <Text>{ind?.specialist_name}</Text>
                                      </Td>
                                    )}
                                    <Td>
                                      <Tooltip
                                        aria-label={ind?.service_name}
                                        label={ind?.service_name}
                                      >
                                        <Box>
                                          <Text
                                            noOfLines={1}
                                            _hover={{ cursor: 'default' }}
                                          >
                                            {ind?.service_name}
                                          </Text>
                                        </Box>
                                      </Tooltip>
                                    </Td>
                                    <Td>
                                      <Tooltip
                                        aria-label={ind?.observation || ''}
                                        label={ind?.observation || ''}
                                      >
                                        <Box>
                                          <Text
                                            noOfLines={1}
                                            _hover={{ cursor: 'default' }}
                                          >
                                            {ind?.observation}
                                          </Text>
                                        </Box>
                                      </Tooltip>
                                    </Td>
                                    <Td>
                                      {ind?.status === 1 && (
                                        <Text>{t('indications.status.1')}</Text>
                                      )}
                                      {ind?.status === 2 && (
                                        <Text>{t('indications.status.2')}</Text>
                                      )}
                                      {ind?.status === 3 && (
                                        <Text>{t('indications.status.3')}</Text>
                                      )}
                                      {ind?.status === 4 && (
                                        <Text>{t('indications.status.4')}</Text>
                                      )}
                                      {ind?.status === 5 && (
                                        <Text>{t('indications.status.5')}</Text>
                                      )}
                                    </Td>
                                    <Td>
                                      {ind?.updated_at ? (
                                        format(
                                          new Date(ind?.updated_at),
                                          'dd/MM/yyyy'
                                        )
                                      ) : (
                                        <Text>--/--/----</Text>
                                      )}
                                    </Td>
                                    <Td>
                                      <Text>
                                        {ind?.updated_by_name || '---'}
                                      </Text>
                                    </Td>
                                    <Td>
                                      {ind?.status === 1 && (
                                        <Tooltip
                                          aria-label="Editar"
                                          label="Editar"
                                        >
                                          <Box>
                                            <FiEdit
                                              onClick={() => {
                                                setObservationInd(
                                                  ind?.observation || ''
                                                )
                                                setIdIndication(ind?.id)
                                                setIdServiceIndication(
                                                  ind?.service?.id
                                                )
                                                setDateIndication(
                                                  ind?.indication_date?.substring(
                                                    0,
                                                    10
                                                  ) ||
                                                    format(
                                                      new Date(),
                                                      'dd/MM/yyyy',
                                                      {
                                                        locale: LocaleApp
                                                      }
                                                    )
                                                )
                                                setStatusChangeIndication(
                                                  ind?.status
                                                )
                                                setIsOpenEditIndication(true)
                                              }}
                                              color="#9699B0"
                                              size={16}
                                              style={{ cursor: 'pointer' }}
                                            />
                                          </Box>
                                        </Tooltip>
                                      )}
                                    </Td>
                                  </Tr>
                                ))}
                            </Tbody>
                          </Table>
                          {isLoadingIndications && (
                            <Flex
                              justifyContent="center"
                              alignItems="center"
                              w="100%"
                              p="8"
                            >
                              <Spinner size="md" color="blue.500" />
                            </Flex>
                          )}
                          {totalOfRegisterIndication === 0 &&
                            !isLoadingIndications && <NotFound />}
                          {totalOfRegisterIndication !== 0 &&
                            !isLoadingIndications && (
                              <Pagination
                                totalCountOfRegisters={
                                  totalOfRegisterIndication
                                }
                                currentPage={currentPageIndication}
                                onPageChange={setCurrentPageIndication}
                              />
                            )}
                        </>
                      )}
                      <Flex w="100%" h="400px" />
                    </Flex>
                  </Flex>
                  <ModalArrivedTime
                    isOpen={isOpenEditIndication}
                    isLoading={isLoadingIndications}
                    title="Edição de indicação"
                    onClose={() => setIsOpenEditIndication(false)}
                    onUpdate={async () => {
                      await handlerUpdateIndication()
                    }}
                  >
                    <Stack direction="column" w="100%">
                      <Input
                        name="dateIndication"
                        value={dateIndication}
                        isRequired
                        label={t('indications.table.t1')}
                        onChange={(e) => {
                          if (e.target.value !== '') {
                            setDateIndication(e.target.value)
                          }
                        }}
                        type="date"
                        min={format(new Date(), 'yyyy-MM-dd')}
                      />
                      <Flex gap="2" flexDir="column">
                        <Text fontSize="14px">Comentário</Text>
                        <Textarea
                          name="observationInd"
                          value={observationInd || ''}
                          onChange={(e) => setObservationInd(e.target.value)}
                        />
                      </Flex>
                      <Select
                        label="Status"
                        name="newStatusInd"
                        value={statusChangeIndication}
                        options={statusOptionsIndicationsEdit}
                        onChange={(e) =>
                          setStatusChangeIndication(Number(e.target.value))
                        }
                      />
                      <Stack direction="row">
                        <FiInfo />
                        <Text fontSize="xs" fontWeight="hairline">
                          Ao alterar o status para rejeitado ou cancelado, não é
                          possível desfazer a alteração ou editar o item
                          novamente.
                        </Text>
                      </Stack>
                    </Stack>
                  </ModalArrivedTime>
                </>
              </TabPanel>
              <TabPanel>
                <>
                  <Flex flex="1" flexDirection="column">
                    <Flex
                      w="100%"
                      borderBlockEnd="1px solid #e4e4e4"
                      mb="2"
                      pb="2"
                      alignItems="flex-end"
                      mx="auto"
                    >
                      <Flex w="100%" flexDirection="row" alignItems="flex-end">
                        <Stack w="100%" direction="row" alignItems="flex-end">
                          <Box w="320px">
                            <Input
                              name="dateBudgets"
                              type="date"
                              label={t('budgets.labels.searchDate')}
                              value={dateBudgets}
                              onChange={(event) =>
                                setDateBudgets(event.target.value)
                              }
                            />
                          </Box>

                          <MultiSelectMenuFilter
                            label="Especialistas"
                            options={clientService.specialists.map(
                              (specialist) => {
                                return {
                                  id: String(specialist?.user?.id),
                                  name:
                                    specialist?.social_name ||
                                    specialist.user.name
                                }
                              }
                            )}
                            backChange={(values: any) => {
                              setSpecialistsBudgets(values)
                            }}
                          />

                          <Button
                            size="md"
                            bg="blue.300"
                            color="white"
                            isLoading={isLoadingBudgets}
                            onClick={() => {
                              setFiltersBudgets({
                                date: dateBudgets,
                                specialistIds: specialistsBudgets
                              })
                            }}
                          >
                            Aplicar
                          </Button>
                          <Button
                            size="md"
                            bg="blue.300"
                            color="white"
                            isLoading={isLoadingBudgets}
                            onClick={() => {
                              history.push('/budgets/create', {
                                id: params.id,
                                name: state?.name
                              })
                            }}
                          >
                            {t('budgets.newBudgets')}
                          </Button>
                        </Stack>
                      </Flex>
                    </Flex>
                    {/* content budgets */}
                    <Flex flex="1" h="100vh" flexDirection="column">
                      {isLoadingBudgets ? (
                        <Center w="100%" p="2">
                          <Spinner size="md" color="blue.300" />
                        </Center>
                      ) : (
                        <>
                          <Table variant="striped" colorScheme="blue" size="sm">
                            <Thead>
                              <Tr>
                                <Th>{t('budgets.table.t1')}</Th>
                                <Th>{t('budgets.table.t2')}</Th>
                                <Th>{t('budgets.table.t3')}</Th>
                                {specialistIds.length === 1 ? null : (
                                  <Th w="200px">{t('budgets.table.t4')}</Th>
                                )}
                                <Th>{t('budgets.table.t7')}</Th>
                                <Th>{t('budgets.table.t8')}</Th>
                                <Th>{t('budgets.table.t9')}</Th>
                                <Th>{t('budgets.table.t10')}</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {budgetsData?.map((bud) => (
                                <Tr key={bud.id || bud?.code}>
                                  <Td>{bud?.code}</Td>
                                  <Td>
                                    {format(
                                      new Date(bud?.created_at),
                                      'dd/MM/yyy'
                                    )}
                                  </Td>
                                  <Td>
                                    {bud.due_date ? (
                                      format(
                                        new Date(
                                          Number(bud.due_date.substring(0, 4)),
                                          Number(bud.due_date.substring(5, 7)) -
                                            1,
                                          Number(bud.due_date.substring(8, 10))
                                        ),
                                        'dd/MM/yyyy',
                                        {
                                          locale: LocaleApp
                                        }
                                      )
                                    ) : (
                                      <Text>--/--/----</Text>
                                    )}
                                  </Td>
                                  <Td>
                                    {bud?.specialist
                                      ? bud?.specialist?.social_name
                                      : '---'}
                                  </Td>
                                  <Td w="230px">
                                    {bud?.items !== undefined &&
                                      bud?.items?.map((service) => (
                                        <Tooltip
                                          key={service.id}
                                          label={service.service_name || ''}
                                          aria-label={
                                            service.service_name || ''
                                          }
                                          hasArrow
                                          placement="top-start"
                                        >
                                          <Text
                                            noOfLines={1}
                                            key={service.id}
                                            fontSize="12px"
                                            fontWeight="semibold"
                                            _hover={{
                                              cursor: 'default'
                                            }}
                                          >
                                            {service?.service_name}
                                          </Text>
                                        </Tooltip>
                                      ))}
                                  </Td>
                                  <Td textAlign="right">
                                    {formatBrOrDollarDefault(
                                      bud?.total?.toFixed(2)
                                    )}
                                  </Td>
                                  <Td>{bud?.created_by?.social_name}</Td>
                                  <Td
                                    alignItems="center"
                                    justifyContent="center"
                                    textAlign="center"
                                  >
                                    <Box>
                                      <PrintButton
                                        id={bud?.id}
                                        patientId={params?.id}
                                        isIconButton
                                        templates={templates}
                                        typePrint="quotes"
                                        nameDocument={state?.social_name}
                                        code={bud?.code}
                                      />
                                    </Box>
                                  </Td>
                                </Tr>
                              ))}
                            </Tbody>
                          </Table>
                          {isLoadingBudgets && (
                            <Flex
                              justifyContent="center"
                              alignItems="center"
                              w="100%"
                              p="8"
                            >
                              <Spinner size="md" color="blue.500" />
                            </Flex>
                          )}
                          {totalOfRegisterBudgets === 0 &&
                            !isLoadingBudgets && <NotFound />}
                          {totalOfRegisterBudgets !== 0 &&
                            !isLoadingBudgets && (
                              <Pagination
                                totalCountOfRegisters={totalOfRegisterBudgets}
                                currentPage={currentPageBudgets}
                                onPageChange={setCurrentPageBudgets}
                              />
                            )}
                        </>
                      )}
                      <Flex w="100%" h="400px" />
                    </Flex>
                  </Flex>
                </>
              </TabPanel>
              <TabPanel>
                <>
                  <Flex flex="1" flexDirection="column">
                    {/* control */}
                    <Flex
                      w="100%"
                      borderBlockEnd="1px solid #e4e4e4"
                      mb="2"
                      pb="2"
                      align="flex-start"
                      justify="space-between"
                      mx="auto"
                    >
                      <Flex flexDirection="row">
                        {isWideVersion ? (
                          <Heading size="md">{t('gallery.title')}</Heading>
                        ) : (
                          <Heading size="md">
                            {t('gallery.titleMobile')}
                          </Heading>
                        )}
                      </Flex>
                      <Flex flexDirection="row">
                        <Button
                          mx="2"
                          colorScheme="blue"
                          size="sm"
                          onClick={() => {
                            setTypeCard('gallery')
                            handleCreateNewCardOpenModal()
                          }}
                        >
                          {t('btnNew')}
                        </Button>
                      </Flex>
                    </Flex>
                    {/* content imagens / videos */}
                    <Center flex="1" p="2" h="100vh">
                      {galleryCard && !galleryIsLoading && !galleryError && (
                        <VStack w="100%">
                          {galleryCard.map((card) => (
                            <Flex w="100%" key={card.id}>
                              <Card
                                onClick={() =>
                                  handlerClickInCardToUpdate(card.id)
                                }
                                isMedia
                                date={card.date}
                                title={card.name}
                                photosQuantity={card.quantity_images}
                                mediaQuantity={card.quantity_videos}
                              />
                            </Flex>
                          ))}
                        </VStack>
                      )}
                      {!galleryCard && !galleryIsLoading && galleryError && (
                        <Text fontSize="12px" color="#999">
                          {t('gallery.galleryError')}
                        </Text>
                      )}
                      {galleryIsLoading && (
                        <Flex direction="row">
                          <Text mx="4" fontSize="12px" color="#999">
                            {t('gallery.isLoading')}
                          </Text>
                          <Spinner size="md" color="blue.300" />
                        </Flex>
                      )}
                    </Center>
                  </Flex>
                  <ModalCreateNewCard
                    isOpen={newCardIsOpen}
                    onClose={() => handleCreateNewCardCloseModal()}
                    onCreate={() => {
                      handlerCreateNewCard()
                    }}
                    title={t('modalCreateCard.title')}
                  >
                    <Flex w="320px" flexDirection="column" p="2" mx="auto">
                      <VStack spacing="6">
                        <TextFieldControlled
                          name="dateCard"
                          type="date"
                          initialValue={dateCard}
                          textAlign="left"
                          isRequired
                          onChange={(event) => setDateCard(event.target.value)}
                          label={t('modalCreateCard.dateCard')}
                        />
                        <TextFieldControlled
                          name="nameCard"
                          initialValue={nameCard}
                          textAlign="left"
                          placeholder=""
                          isRequired
                          onChange={(event) =>
                            setNameCard(event.target.value.toUpperCase())
                          }
                          label={t('modalCreateCard.nameCard')}
                        />
                      </VStack>
                    </Flex>
                  </ModalCreateNewCard>
                </>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
        <ModalWebcam
          title={t('modal.title')}
          isOpen={isOpen}
          onClose={handleCloseModal}
        >
          <Flex mx="2px" h="500px">
            <WebcamCapture />
          </Flex>
        </ModalWebcam>
      </>
    </Box>
  )
}
