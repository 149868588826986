/* eslint-disable no-debugger */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable radix */
import React, { createContext, ReactNode, useState } from 'react'
import { DropEvent, FileRejection } from 'react-dropzone'
import { useToast } from '@chakra-ui/react'
import { format } from 'date-fns'
import { uniqueId } from 'lodash'
import { useTranslation } from 'react-i18next'
import filesize from 'filesize'
import { apiAuth } from '../services/apiAuth'

interface DocumentProviderProps {
  children: ReactNode
}

type ProgressData = React.Dispatch<React.SetStateAction<number>>
type idData = React.Dispatch<React.SetStateAction<string>>
type urlData = React.Dispatch<React.SetStateAction<string | null>>
type uploadedData = React.Dispatch<React.SetStateAction<boolean>>
type errorData = React.Dispatch<React.SetStateAction<boolean>>

type FilesProps = Array<File>

export type UploadFilesData = {
  file: File
  id: string
  name: string
  readableSize: string
  preview: string
  progress: number
  uploaded: boolean
  error: boolean
  url: string | null
  uploadedDate: string | null
  description: string
}

type DocumentData = {
  imgSrc: string
  upLoaderUrl: string
  setImgSrc: React.Dispatch<React.SetStateAction<string>>
  setUpLoaderUrl: React.Dispatch<React.SetStateAction<string>>
  handlerUploadButtonFiles: () => void
  handlerRemoveImage: () => void
  onDropRejected: (files: FileRejection[], event: DropEvent) => void
  isUploadedFile: boolean
  processUpload: (
    fileUpload: UploadFilesData,
    onProgress: ProgressData,
    onUpdateId: idData,
    onUpdateUrl: urlData,
    onUploaded: uploadedData,
    onError: errorData
  ) => void
  handlerClearListFiles: () => void
  handlerUpload: (files: FilesProps, event: DropEvent) => void
  uploadedFiles: UploadFilesData[]
  setUploadedFiles: React.Dispatch<UploadFilesData[]>
}

export const DocumentContext = createContext({} as DocumentData)

export function DocumentProvider({ children }: DocumentProviderProps) {
  // hook terces
  const [t] = useTranslation('documentContext')
  const toast = useToast()
  // image profile
  const [imgSrc, setImgSrc] = useState('')
  // upload states
  const [uploadedFiles, setUploadedFiles] = useState<UploadFilesData[]>([])
  const [upLoaderUrl, setUpLoaderUrl] = useState('')
  const [isUploadedFile, setIsUploadedFile] = useState(false)

  const processUpload = (
    fileUpload: UploadFilesData,
    onProgress: ProgressData,
    onUpdateId: idData,
    onUpdateUrl: urlData,
    onUploaded: uploadedData,
    onError: errorData
  ) => {
    const data = new FormData()

    data.append('file', fileUpload.file)
    data.append('name', fileUpload.name)
    data.append('date', fileUpload.uploadedDate || '')
    data.append('description', fileUpload.description)

    apiAuth
      .post(upLoaderUrl, data, {
        onUploadProgress: (event: ProgressEvent) => {
          const progress = parseInt(
            String(Math.round((event.loaded * 100) / event.total))
          )

          onProgress(progress)
        }
      })
      .then((response) => {
        console.log(response.data)
        onUpdateId(response.data.id)
        onUpdateUrl(response.data.file)
        onUploaded(true)
      })
      .catch((error: any) => {
        onError(true)
        console.log(error.response?.data?.message)
      })
  }

  const handlerUpload = (files: FilesProps, event: DropEvent) => {
    const filesUploadedArray = files.map((file) => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
      uploadedDate: format(new Date(), 'yyyy-MM-dd'),
      description: `${file.name}, ${file.lastModified}`
    }))

    setUploadedFiles(uploadedFiles.concat(filesUploadedArray))
  }

  const onDropRejected = (
    fileRejections: FileRejection[],
    event: DropEvent
  ) => {
    if (event) {
      if (fileRejections.length === 1) {
        toast({
          status: 'error',
          duration: 3000,
          position: 'bottom-right',
          title: 'Error!',
          description: `${t('documentContext.dropzoneReject')}`
        })
      } else {
        toast({
          status: 'error',
          duration: 3000,
          position: 'bottom-right',
          title: 'Error!',
          description: `${t('documentContext.dropzoneMultipleReject')} ${
            fileRejections.length
          }`
        })
      }
    }
  }

  // upload files
  const handlerUploadButtonFiles = () => {
    if (uploadedFiles.length === 0) {
      return
    }

    setIsUploadedFile(true)
    setIsUploadedFile(false)
  }

  // function remove Image
  const handlerRemoveImage = () => {
    setImgSrc('')
  }

  // function remove UploadsFiles
  const handlerClearListFiles = () => {
    setUploadedFiles([])
  }

  return (
    <DocumentContext.Provider
      value={{
        imgSrc,
        setImgSrc,
        handlerRemoveImage,
        processUpload,
        handlerUpload,
        uploadedFiles,
        setUploadedFiles,
        upLoaderUrl,
        setUpLoaderUrl,
        handlerUploadButtonFiles,
        handlerClearListFiles,
        isUploadedFile,
        onDropRejected
      }}
    >
      {children}
    </DocumentContext.Provider>
  )
}
