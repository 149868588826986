/* eslint-disable react/no-children-prop */
/*  eslint no-param-reassign: ["error", { "props": false }] */
import React, {
  useState,
  useEffect,
  forwardRef,
  ElementType,
  ForwardRefRenderFunction,
  useCallback
} from 'react'
import ls from 'localstorage-slim'
import { FiEye, FiEyeOff } from 'react-icons/fi'
import {
  FormControl,
  FormLabel,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  FormErrorMessage,
  InputRightElement,
  Icon,
  Stack,
  Box,
  Flex
} from '@chakra-ui/react'
import { FieldError } from 'react-hook-form'
import Mask from '../../../utils/newMask'

import * as S from './styles'

export type isDefaultType = 'default' | 'small'
export type StateTypes = 'default' | 'danger' | 'success' | 'octopus'
type MasksTypes =
  | 'cep'
  | 'cnpj'
  | 'cpf'
  | 'phone'
  | 'date'
  | 'time'
  | 'card'
  | 'monetary'
  | 'number'
  | 'monetaryDollar'
  | 'monetaryEuro'
  | 'double'
  | 'porcentagem'
  | 'def'

type Currency = 'BRL' | 'USD' | 'EUR'

interface InputProps extends ChakraInputProps {
  name: string
  label?: string
  mask?: MasksTypes
  error?: FieldError
  message?: string
  type?: string
  disabled?: boolean
  isRequired?: boolean
  iconRight?: ElementType
  isInvalid?: boolean
  _active?: any
  size?: string
  defaultValue?: any
  initialValue?: string
  isPassword?: boolean
  inputOnChange?: (value: React.FormEvent<HTMLInputElement>) => void
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    initialValue,
    name,
    isPassword,
    isRequired,
    error,
    mask = 'def',
    label,
    disabled,
    message,
    size = 'md',
    iconRight,
    isInvalid = false,
    inputOnChange,
    ...rest
  },
  ref
) => {
  const currency = ls.get<Currency>('@Clinik:Currency', {
    decrypt: true
  })
  const [valueState, setValue] = useState(initialValue)
  const [isShowPassword, setIsShowPassword] = useState(false)
  const [typeState, setType] = useState('text')

  const changeInputValue = (event: React.FormEvent<HTMLInputElement>) => {
    if (mask) {
      event.currentTarget.value = Mask[mask](event.currentTarget.value)
    }

    setValue(event.currentTarget.value)

    !!inputOnChange && inputOnChange(event)
  }

  const onClickVisibilit = useCallback(() => {
    const val = !isShowPassword

    setIsShowPassword(val)

    if (val) return setType('text')

    return setType('password')
  }, [isShowPassword])

  useEffect(() => {
    if (isPassword) {
      setType('password')
    } else {
      setType('text')
    }
  }, [isPassword])

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  // control maxLength
  const defaultMaxLength = () => {
    switch (mask) {
      case 'number':
        return 50
      case 'monetary':
        return 20
      case 'monetaryDollar':
        return 20
      default:
        return 200
    }
  }

  const length = defaultMaxLength()

  return (
    <Stack direction="row" w="100%" spacing="2">
      <FormControl
        isInvalid={!!error || isInvalid}
        isRequired={isRequired}
        bgColor="white"
      >
        {!!label && (
          <FormLabel fontSize="14px" htmlFor={name}>
            {label}
          </FormLabel>
        )}
        <Flex w="100%" align="center">
          <ChakraInput
            w="100%"
            borderRadius="none"
            name={name}
            id={name}
            focusBorderColor="blue.400"
            bgColor="white"
            borderColor="gray.100"
            autoComplete="off"
            maxLength={length}
            borderWidth="1px"
            variant="filled"
            textAlign="right"
            disabled={disabled}
            placeholder={mask !== 'def' && currency === 'BRL' ? '0,00' : '0.00'}
            type={typeState}
            _hover={{
              bgColor: 'blue.50'
            }}
            ref={ref}
            value={valueState}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              changeInputValue(e)
            }
            {...rest}
          />
          {!!iconRight && (
            <InputRightElement
              mt={size === 'md' ? '0px' : '4px'}
              children={
                <Icon
                  as={iconRight}
                  fontSize={size === 'md' ? '20px' : '24px'}
                  color="#9699B0"
                />
              }
            />
          )}

          {isPassword && (
            <S.ButtonPassword onClick={onClickVisibilit}>
              {isShowPassword ? <FiEyeOff size={20} /> : <FiEye size={20} />}
            </S.ButtonPassword>
          )}
        </Flex>
        {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
        {!!isInvalid && <FormErrorMessage>{message}</FormErrorMessage>}
      </FormControl>
    </Stack>
  )
}

export const TextFieldControlled = forwardRef(InputBase)
