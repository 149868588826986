/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  SimpleGrid,
  Skeleton,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useMediaQuery,
  useToast
} from '@chakra-ui/react'
import ls from 'localstorage-slim'
import React, { useCallback, useEffect, useState } from 'react'
import FileSaver from 'file-saver'
import { AiOutlineDollarCircle, AiOutlineInfoCircle } from 'react-icons/ai'
import { FiEdit, FiFile } from 'react-icons/fi'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import format from 'date-fns/format'
import { ModalFilters } from '../../components/Modal'
import { NotFound } from '../../components/molecules/NotFound'
import { Pagination } from '../../components/PaginationNew'
import { LayoutList } from '../../layouts/List'

import { Select } from '../../components/Form/Select'
import { TextFieldControlled } from '../../components/Form/TextFieldControlled'
import { PermissionComponent } from '../../components/Permissions'
import { useAuth } from '../../hooks/auth'
import {
  getBillsToPay,
  useBillsToPay
} from '../../hooks/billsToPay/useBillsToPay'
import { useModal } from '../../hooks/useModal'
import { handlerExportXlsx } from '../../utils/fns/exportXlsx'
import { remaskCaractersAll } from '../../utils/fns/removeCaracters'
import { MultiSelectMenuFilter } from '../../components/MultiSelectFilters'
import { useService } from './service'
import { apiAuth } from '../../services/apiAuth'

// debounce
let timer: any = null

type Language = 'pt' | 'en' | 'es'

export const BillsToPay: React.FC = () => {
  const [t] = useTranslation('pageBillsToPay')
  const [mediaQuery1024] = useMediaQuery('(min-width: 1200px)')
  const { clinicId } = useAuth()
  const services = useService()
  const {
    dueDateFinal,
    dueDateInitial,
    paymentDateInitial,
    setTabIndex,
    tabIndex,
    paymentDateFinal,
    code,
    competenceDate,
    order,
    isActiveFilterBillsToPay,
    name,
    status,
    cost,
    category,
    handlerApplyFiltersBillsToPay,
    handlerRemoveFilters,
    handlerRemoveFiltersDefaultSearchBox,
    isOpenBillsToPay,
    handlerCloseModal
  } = useModal()
  const toast = useToast()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchState, setSearchState] = useState('')
  const [isActive, setIsActive] = useState(true)
  const skeletonRepeat = [1, 2, 3, 4, 5, 6, 7]
  const defaultOptionsStatus = [
    { value: '1', label: `${t('status.1')}` },
    { value: '2', label: `${t('status.2')}` },
    { value: '3', label: `${t('status.3')}` },
    { value: '4', label: `${t('status.4')}` }
  ]
  const defaultOptionsMonth = [
    { value: '01', label: `${t('month.1')}` },
    { value: '02', label: `${t('month.2')}` },
    { value: '03', label: `${t('month.3')}` },
    { value: '04', label: `${t('month.4')}` },
    { value: '05', label: `${t('month.5')}` },
    { value: '06', label: `${t('month.6')}` },
    { value: '07', label: `${t('month.7')}` },
    { value: '08', label: `${t('month.8')}` },
    { value: '09', label: `${t('month.9')}` },
    { value: '10', label: `${t('month.10')}` },
    { value: '11', label: `${t('month.11')}` },
    { value: '12', label: `${t('month.12')}` }
  ]
  const defaultOptionsOrder = [
    { value: '1', label: `${t('order.1')}` },
    { value: '2', label: `${t('order.2')}` },
    { value: '3', label: `${t('order.3')}` },
    { value: '4', label: `${t('order.4')}` }
  ]
  const defaultOptionsCategory = [
    { value: '25', label: `${t('options.category.25')}` },
    { value: '26', label: `${t('options.category.26')}` },
    { value: '27', label: `${t('options.category.27')}` },
    { value: '28', label: `${t('options.category.28')}` },
    { value: '29', label: `${t('options.category.29')}` },
    { value: '30', label: `${t('options.category.30')}` },
    { value: '31', label: `${t('options.category.31')}` },
    { value: '32', label: `${t('options.category.32')}` },
    { value: '34', label: `${t('options.category.34')}` }
  ]
  // Get current date
  const date = new Date()

  // filters
  const [codeLocal, setCode] = useState(code || '')
  const [statusLocal, setStatus] = useState<any>(status || [])
  const [nameLocal, setName] = useState(name || '')
  const [orderLocal, setOrder] = useState('')
  const [valueOrderFilter, setValueOrderFilter] = useState(order || '')
  const [dueDateInitialLocal, setDueDateInitial] = useState(
    dueDateInitial || ''
  )
  const [isResetLocal, setIsResetLocal] = useState(false)
  const [dueDateFinalLocal, setDueDateFinal] = useState(dueDateInitial || '')
  const [paymentDateInitialLocal, setPaymentDateInitial] = useState(
    paymentDateInitial || ''
  )
  const [paymentDateFinalLocal, setPaymentDateFinal] = useState(
    paymentDateFinal || ''
  )
  const [month, setMonth] = useState(String(new Date().getMonth() + 1))
  const [year, setYear] = useState(new Date().getFullYear())
  const [optionsYear, setOptionsYear] = useState<number[]>([])
  // all competence day 05 works month and year
  const [competenceDateLocal, setCompetenceDate] = useState(
    `${year}-${month}-05`
  )
  const [categoryLocal, setCategory] = useState<any>(category || [])
  const [costLocal, setCost] = useState<any>(cost || [])
  // My filters Object for function apply filter
  const filters = {
    status: statusLocal,
    code: codeLocal,
    order: valueOrderFilter,
    name: nameLocal,
    dueDateInitial: dueDateInitialLocal,
    dueDateFinal: dueDateFinalLocal,
    paymentDateInitial: paymentDateInitialLocal,
    paymentDateFinal: paymentDateFinalLocal,
    competenceDate: competenceDateLocal,
    category: categoryLocal,
    cost: costLocal
  }

  function generateYearArray(from: number, to: number) {
    return [...new Array(to - from)]
      .map((_, index) => {
        return from + index + 1
      })
      .filter((yearFilter) => yearFilter > 0)
  }

  // Generate new Array onChange
  useEffect(() => {
    if (year === 0) {
      const generateArray = generateYearArray(
        new Date().getFullYear() - 6,
        new Date().getFullYear() + 6
      )
      setOptionsYear(generateArray)
    } else {
      const generateArray = generateYearArray(year - 6, year + 6)
      setOptionsYear(generateArray)
    }
  }, [year])

  // Reset state to default for competence
  useEffect(() => {
    setCompetenceDate(`${year}-${month}-05`)

    if (year === 0) {
      setCompetenceDate('')
    }

    if (month === '') {
      setCompetenceDate('')
    }
  }, [year, month])

  // Get value order real
  useEffect(() => {
    switch (order) {
      case '':
        setValueOrderFilter('')
        break

      case '1':
        setValueOrderFilter('due_date')
        break
      case '2':
        setValueOrderFilter('-due_date')
        break
      case '3':
        setValueOrderFilter('payment_date')
        break
      case '4':
        setValueOrderFilter('-payment_date')
        break
      default:
        break
    }
  }, [order])

  const getPostings = useBillsToPay(
    currentPage,
    getBillsToPay(
      currentPage,
      searchState,
      isActive,
      dueDateInitial,
      dueDateFinal,
      paymentDateInitial,
      paymentDateFinal,
      competenceDate,
      code,
      name,
      status,
      category,
      cost,
      order
    ),
    searchState,
    isActive,
    dueDateInitial,
    dueDateFinal,
    paymentDateInitial,
    paymentDateFinal,
    competenceDate,
    code,
    name,
    status,
    category,
    cost,
    order
  )

  const handlerGeneratedExcel = async () => {
    try {
      const { data } = await apiAuth.get(`clinics/postings/export/`, {
        params: {
          keyword: name,
          due_date__gte: dueDateInitial,
          due_date__lte: dueDateFinal,
          payment_date__gte: paymentDateInitial,
          payment_date__lte: paymentDateFinal,
          competence_date: competenceDate,
          code,
          status: status?.join(','),
          category: category?.join(','),
          cost_center: cost?.join(','),
          ordering: order
        },
        responseType: 'blob'
      })

      FileSaver(data, `${t('title')} ${format(new Date(), 'dd-MM-yyyy')}.xlsx`)
    } catch (error: any) {
      toast({
        position: 'top',
        duration: 3000,
        title: `Não foi possível gerar arquivo`
      })
    }
  }

  const SearchInputCallbackValue = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget

      clearTimeout(timer)

      timer = setTimeout(() => {
        // Remove Filters for searchState Box
        if (value.length > 0) {
          handlerRemoveFiltersDefaultSearchBox()
        } else {
          handlerRemoveFilters()
        }
        setSearchState(value)
      }, 500)
    },
    []
  )

  useEffect(() => {
    if (tabIndex === 0) {
      if (!isActiveFilterBillsToPay) {
        setDueDateInitial(dueDateInitial)
        setDueDateFinal(dueDateFinal)
      }
    } else {
      setDueDateInitial('')
      setDueDateFinal('')
    }
  }, [tabIndex, handlerRemoveFilters])

  const ActiveCheckboxCallbackValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsActive(event.target.checked)
    },
    []
  )

  useEffect(() => {
    switch (tabIndex) {
      case 0:
        setCode('')
        setOrder('1')
        setStatus(['1', '2', '3'])
        setPaymentDateFinal('')
        setPaymentDateInitial('')
        setName('')
        setCompetenceDate('')
        setMonth('')
        setYear(0)
        break

      case 1:
        setCode('')
        setOrder('1')
        setStatus(['1', '2', '3'])
        setPaymentDateFinal('')
        setPaymentDateInitial('')
        setDueDateInitial('')
        setDueDateFinal('')
        setName('')
        break

      case 2:
        setCode('')
        setOrder('3')
        setStatus(['1', '2', '3'])
        setDueDateFinal('')
        setDueDateInitial('')
        setName('')
        setCompetenceDate('')
        setMonth('')
        setYear(0)

        break

      case 3:
        setCode('')
        setOrder('')
        setDueDateFinal('')
        setDueDateInitial('')
        setPaymentDateFinal('')
        setPaymentDateInitial('')
        setName('')
        setCompetenceDate('')
        setMonth('')
        setYear(0)
        setStatus([])

        break

      default:
        break
    }
  }, [tabIndex])

  useEffect(() => {
    if (isResetLocal === true) {
      setTimeout(() => {
        setIsResetLocal(false)
      }, 2000)
    }
  }, [isResetLocal])

  const handlerCloseModalBillsToPay = () => {
    handlerCloseModal('BillsToPay')
  }

  // Remove all Filters BillsToPay
  const handlerRemoveFiltersLocal = () => {
    // Filters BillsTopPay
    setStatus(['1', '2', '3'])
    setCategory([])
    setCost([])
    setCode('')
    setOrder('due_date')
    setName('')
    setDueDateInitial(
      format(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd')
    )
    setDueDateFinal(
      format(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-MM-dd')
    )
    setPaymentDateInitial('')
    setPaymentDateFinal('')
    setCompetenceDate('')
    setIsResetLocal(true)
  }

  return (
    <>
      <LayoutList
        title={t('title')}
        urlNew="/finances/bills-to-pay/create"
        isHaveFilter
        capturaChange={(event: any) => SearchInputCallbackValue(event)}
        captureActiveChange={(event: any) => ActiveCheckboxCallbackValue(event)}
        placeholder={t('placeholder.search')}
        refetch={getPostings.refetch}
        isFetching={getPostings.isFetching}
        isLoading={getPostings.isLoading}
        isViewActive={false}
        isHaveData={
          getPostings?.data?.postings && getPostings?.data?.postings.length > 0
        }
        generateExcel={handlerGeneratedExcel}
        spiCode="BILLS_TO_PAY"
        nameFilter="BillsToPay"
        isSearched={false}
      >
        <Box>
          <Table variant="striped" colorScheme="blue" size="sm">
            <Thead>
              <Tr>
                <Th w={20}>{t('t1')}</Th>
                <Th>{t('t2')}</Th>
                <Th>{t('t3')}</Th>
                <Th>{t('t4')}</Th>
                <Th>{t('t5')}</Th>
                <Th>{t('t6')}</Th>
                {!mediaQuery1024 ? null : <Th>{t('t7')}</Th>}
                <Th>{t('t8')}</Th>
                <Th>{t('t9')}</Th>
                <Th>{t('t10')}</Th>
                <Th>{t('t11')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {getPostings.isLoading ? (
                <>
                  {skeletonRepeat.map((skeleton) => (
                    <Tr key={skeleton}>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                    </Tr>
                  ))}
                </>
              ) : (
                <>
                  {getPostings.data?.postings.map((post) => (
                    <Tr key={post?.code} maxWidth="70px" maxHeight="40px">
                      <Td>
                        <Text fontSize="sm" textAlign="center">
                          {post?.code}
                        </Text>
                      </Td>

                      <Td align="left">
                        <Tooltip aria-label={post?.name} label={post?.name}>
                          <Box>
                            <Text
                              _hover={{ cursor: 'default' }}
                              noOfLines={1}
                              fontSize="sm"
                              textAlign="left"
                            >
                              {post?.name}
                            </Text>
                          </Box>
                        </Tooltip>
                      </Td>

                      <Td align="left">
                        <Tooltip
                          aria-label={post?.supplier_posting?.trading_name}
                          label={post?.supplier_posting?.trading_name}
                        >
                          <Box>
                            <Text
                              _hover={{ cursor: 'default' }}
                              noOfLines={1}
                              fontSize="sm"
                              textAlign="left"
                            >
                              {post?.supplier_posting?.trading_name}
                            </Text>
                          </Box>
                        </Tooltip>
                      </Td>

                      <Td>
                        <Text fontSize="sm" textAlign="center">
                          {`
                            ${post?.share_number}
                            ${t('of')}
                            ${post?.total_of_postings}
                          `}
                        </Text>
                      </Td>

                      <Td>
                        <Text fontSize="sm" textAlign="center">
                          {post?.competence_date}
                        </Text>
                      </Td>

                      <Td>
                        <Text fontSize="sm" textAlign="center">
                          {post?.due_date_view}
                        </Text>
                      </Td>

                      <Td>
                        <Text fontSize="sm" textAlign="center">
                          {
                            // invalid date def
                            post?.payment_date !== '31/07/1112'
                              ? post?.payment_date
                              : '-- / -- / ----'
                          }
                        </Text>
                      </Td>

                      <Td align="right">
                        <Text fontSize="sm" textAlign="right">
                          {post?.price}
                        </Text>
                      </Td>

                      <Td>
                        {post?.status === 1 && (
                          <Text fontSize="sm" textAlign="center">
                            {t('status.1')}
                          </Text>
                        )}
                        {post?.status === 2 && (
                          <Text fontSize="sm" textAlign="center">
                            {t('status.2')}
                          </Text>
                        )}
                        {post?.status === 3 && (
                          <Text fontSize="sm" textAlign="center">
                            {t('status.3')}
                          </Text>
                        )}
                        {post?.status === 4 && (
                          <Text fontSize="sm" textAlign="center">
                            {t('status.4')}
                          </Text>
                        )}
                      </Td>

                      <Td align="center" justifyContent="center">
                        {post?.attachments.length > 0 ? (
                          <Flex align="center" justify="center">
                            <Text>{post?.attachments.length}</Text>
                          </Flex>
                        ) : (
                          <Flex align="center" justify="center">
                            <Text>0</Text>
                          </Flex>
                        )}
                      </Td>

                      <Td align="center" justifyContent="center">
                        <PermissionComponent
                          spiCode="BILLS_TO_PAY"
                          ipCode="EDIT"
                        >
                          <Flex
                            flexDirection="row"
                            align="center"
                            justify="center"
                          >
                            <Link
                              to={{
                                pathname: `/finances/bills-to-pay/update/${post?.id}`,
                                state: post
                              }}
                            >
                              <Tooltip
                                label={t('updateTitle')}
                                aria-label={t('updateTitle')}
                                hasArrow
                                placement="top-start"
                              >
                                <Box ml="1rem" w="24px">
                                  <FiEdit
                                    color="#9699B0"
                                    size={16}
                                    style={{ cursor: 'pointer' }}
                                  />
                                </Box>
                              </Tooltip>
                            </Link>
                            <Link
                              to={{
                                pathname: `/finances/bills-to-pay/files/${post?.id}`,
                                state: post
                              }}
                            >
                              <Tooltip
                                label={t('file')}
                                aria-label={t('updateTitle')}
                                hasArrow
                                placement="top-start"
                              >
                                <Box ml="1rem" w="24px">
                                  <FiFile
                                    color="#9699B0"
                                    size={16}
                                    style={{ cursor: 'pointer' }}
                                  />
                                </Box>
                              </Tooltip>
                            </Link>
                            <Link
                              to={{
                                pathname: `/finances/bills-to-pay/payment/${post?.id}`,
                                state: post
                              }}
                            >
                              <Tooltip
                                label={t('payment')}
                                aria-label={t('payment')}
                                hasArrow
                                placement="top-start"
                              >
                                <Box ml="1rem" w="24px">
                                  <AiOutlineDollarCircle
                                    color="#9699B0"
                                    size={20}
                                    style={{ cursor: 'pointer' }}
                                  />
                                </Box>
                              </Tooltip>
                            </Link>
                          </Flex>
                        </PermissionComponent>
                      </Td>
                    </Tr>
                  ))}
                </>
              )}
            </Tbody>
          </Table>
          {getPostings.data?.totalCountOfPage === 0 &&
            !getPostings.isLoading && <NotFound />}
        </Box>
        {!(
          getPostings.data?.totalCountOfPage === 0 && !getPostings.isLoading
        ) && (
          <Pagination
            totalCountOfRegisters={getPostings.data?.totalCountOfPage || 0}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
          />
        )}
      </LayoutList>
      <ModalFilters
        onClose={() => {
          handlerCloseModalBillsToPay()
        }}
        isHaveDefault
        title="Filtros - contas a pagar"
        isOpen={isOpenBillsToPay}
        applyFilter={() => {
          handlerApplyFiltersBillsToPay(filters)
          handlerCloseModalBillsToPay()
          setCurrentPage(1)
        }}
        removeFilter={() => {
          handlerRemoveFiltersLocal()
          setCurrentPage(1)
        }}
      >
        <Tabs
          isFitted
          variant="enclosed"
          defaultIndex={tabIndex}
          onChange={(index) => setTabIndex(index)}
        >
          <TabList>
            <Tab>Por vencimento</Tab>
            <Tab>Por competência</Tab>
            <Tab>Por pagamento</Tab>
            <Tab>Por código</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <SimpleGrid
                d="flex"
                mx="auto"
                minChildWidth="320px"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                spacing={1}
              >
                <Flex
                  d="flex"
                  flexDirection="row"
                  align="center"
                  justifyContent="center"
                  mx="auto"
                >
                  <Box mx="4">
                    <Select
                      name="order"
                      borderRadius={8}
                      options={defaultOptionsOrder}
                      label={t('order.label')}
                      textAlign="left"
                      value={orderLocal}
                      onChange={(
                        event: React.ChangeEvent<HTMLSelectElement>
                      ) => {
                        setOrder(event.target.value)
                      }}
                    />
                  </Box>
                  <Popover isLazy>
                    <PopoverTrigger>
                      <Button
                        bg="transparent"
                        _hover={{
                          background: 'transparent'
                        }}
                      >
                        <AiOutlineInfoCircle size={26} />
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverHeader fontWeight="semibold">
                        Informações
                      </PopoverHeader>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverBody>
                        <Text>
                          Aqui você pode selecionar a data inicial e a data
                          final de vencimento fazendo um período para seus
                          filtros por padrão temos o período do Mês atual.
                        </Text>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                  <Box mx="4">
                    <MultiSelectMenuFilter
                      options={defaultOptionsStatus?.map((stat) => {
                        return {
                          id: stat?.value,
                          name: stat?.label
                        }
                      })}
                      label="Status"
                      isReset={isResetLocal}
                      backChange={(values) => {
                        setStatus(values)
                      }}
                      valueReset={['1', '2', '3']}
                      render={() => {
                        const backStatus: any = statusLocal

                        return backStatus
                      }}
                    />
                  </Box>
                  <Box mx="4">
                    <MultiSelectMenuFilter
                      options={defaultOptionsCategory?.map((cat) => {
                        return {
                          id: cat?.value,
                          name: cat?.label
                        }
                      })}
                      label="Categorias"
                      isReset={isResetLocal}
                      backChange={(values) => {
                        setCategory(values)
                      }}
                      render={() => {
                        const backStatus = categoryLocal

                        return backStatus
                      }}
                    />
                  </Box>
                  <Box mx="4">
                    <MultiSelectMenuFilter
                      options={services?.newCostsFormats?.map((c) => {
                        return {
                          id: c?.value,
                          name: c?.label
                        }
                      })}
                      label="Centro de custos"
                      isReset={isResetLocal}
                      backChange={(values) => {
                        setCost(values)
                      }}
                      render={() => {
                        const backStatus = costLocal

                        return backStatus
                      }}
                    />
                  </Box>
                </Flex>
                <Divider mt="2" />
                <Center d="flex" flexDirection="column">
                  <Flex d="flex" flexDirection="row" my="4">
                    <Box mx="4">
                      <TextFieldControlled
                        name="code"
                        borderRadius={8}
                        type="date"
                        label="Data Inicial:"
                        textAlign="left"
                        placeholder="Selecione a data"
                        initialValue={dueDateInitialLocal}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setDueDateInitial(event.currentTarget.value)
                        }}
                      />
                    </Box>
                    <Box mx="4">
                      <TextFieldControlled
                        name="code"
                        borderRadius={8}
                        type="date"
                        label="Data Final:"
                        textAlign="left"
                        placeholder="Selecione a data"
                        initialValue={dueDateFinalLocal}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setDueDateFinal(event.currentTarget.value)
                        }}
                      />
                    </Box>
                  </Flex>
                  <Flex mx="auto" my="4">
                    <Box mx="4">
                      <TextFieldControlled
                        name="name"
                        borderRadius={8}
                        label="Nome da Conta:"
                        textAlign="left"
                        placeholder="Pesquisar por nome"
                        initialValue={nameLocal}
                        onKeyUp={(
                          event: React.KeyboardEvent<HTMLInputElement>
                        ) => {
                          setName(event.currentTarget.value)
                        }}
                      />
                    </Box>
                  </Flex>
                </Center>
              </SimpleGrid>
            </TabPanel>
            <TabPanel>
              <SimpleGrid
                d="flex"
                mx="auto"
                minChildWidth="320px"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                spacing={1}
              >
                <Flex
                  d="flex"
                  flexDirection="row"
                  align="center"
                  justifyContent="center"
                  mx="auto"
                  mb="2"
                >
                  <Box mx="4">
                    <Select
                      name="order"
                      borderRadius={8}
                      options={defaultOptionsOrder}
                      label={t('order.label')}
                      textAlign="left"
                      value={orderLocal}
                      onChange={(
                        event: React.ChangeEvent<HTMLSelectElement>
                      ) => {
                        setOrder(event.target.value)
                      }}
                    />
                  </Box>
                  <Popover isLazy>
                    <PopoverTrigger>
                      <Button
                        bg="transparent"
                        _hover={{
                          background: 'transparent'
                        }}
                      >
                        <AiOutlineInfoCircle size={26} />
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverHeader fontWeight="semibold">
                        Informações
                      </PopoverHeader>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverBody>
                        <Text>
                          Aqui você pode selecionar o mês (competência) para
                          seus filtros e o ano desejado
                        </Text>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                  <Box mx="4">
                    <MultiSelectMenuFilter
                      options={defaultOptionsStatus?.map((stat) => {
                        return {
                          id: stat?.value,
                          name: stat?.label
                        }
                      })}
                      label="Status"
                      isReset={isResetLocal}
                      backChange={(values) => {
                        setStatus(values)
                      }}
                      valueReset={['1', '2', '3']}
                      render={() => {
                        const backStatus: any = statusLocal

                        return backStatus
                      }}
                    />
                  </Box>
                  <Box mx="4">
                    <MultiSelectMenuFilter
                      options={defaultOptionsCategory?.map((cat) => {
                        return {
                          id: cat?.value,
                          name: cat?.label
                        }
                      })}
                      label="Categorias"
                      isReset={isResetLocal}
                      backChange={(values) => {
                        setCategory(values)
                      }}
                      render={() => {
                        const backStatus = categoryLocal

                        return backStatus
                      }}
                    />
                  </Box>
                  <Box mx="4">
                    <MultiSelectMenuFilter
                      options={services?.newCostsFormats?.map((c) => {
                        return {
                          id: c?.value,
                          name: c?.label
                        }
                      })}
                      label="Centro de custos"
                      isReset={isResetLocal}
                      backChange={(values) => {
                        setCost(values)
                      }}
                      render={() => {
                        const backStatus = costLocal

                        return backStatus
                      }}
                    />
                  </Box>
                </Flex>
                <Divider />
                <Center d="flex" flexDirection="column">
                  <Flex mx="auto" my="4">
                    <Box mx="4">
                      <Select
                        name="name"
                        borderRadius={8}
                        options={defaultOptionsMonth}
                        label="Mês:"
                        textAlign="left"
                        placeholder="Selecione o mês"
                        value={month}
                        onChange={(
                          event: React.ChangeEvent<HTMLSelectElement>
                        ) => {
                          setMonth(event.target.value)
                        }}
                      />
                    </Box>
                    <Box mx="4">
                      <Select
                        name="year"
                        borderRadius={8}
                        options={[]}
                        label="Ano:"
                        placeholder="Selecione o ano"
                        value={year}
                        onChange={(
                          event: React.ChangeEvent<HTMLSelectElement>
                        ) => {
                          setYear(Number(event.target.value))
                        }}
                      >
                        {optionsYear.map((item) => {
                          return (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          )
                        })}
                      </Select>
                    </Box>
                  </Flex>
                  <Flex mx="auto" my="4">
                    <Box mx="4">
                      <TextFieldControlled
                        name="name"
                        borderRadius={8}
                        label="Nome da Conta:"
                        textAlign="left"
                        placeholder="Pesquisar por nome"
                        initialValue={nameLocal}
                        onKeyUp={(
                          event: React.KeyboardEvent<HTMLInputElement>
                        ) => {
                          setName(event.currentTarget.value)
                        }}
                      />
                    </Box>
                  </Flex>
                </Center>
              </SimpleGrid>
            </TabPanel>
            <TabPanel>
              <SimpleGrid
                d="flex"
                mx="auto"
                minChildWidth="320px"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                spacing={1}
              >
                <Flex
                  d="flex"
                  flexDirection="row"
                  align="center"
                  justifyContent="center"
                  mx="auto"
                  mb="2"
                >
                  <Box mx="4">
                    <Select
                      name="order"
                      borderRadius={8}
                      options={defaultOptionsOrder}
                      label={t('order.label')}
                      textAlign="left"
                      value={order}
                      onChange={(
                        event: React.ChangeEvent<HTMLSelectElement>
                      ) => {
                        setOrder(event.target.value)
                      }}
                    />
                  </Box>
                  <Popover isLazy>
                    <PopoverTrigger>
                      <Button
                        bg="transparent"
                        _hover={{
                          background: 'transparent'
                        }}
                      >
                        <AiOutlineInfoCircle size={26} />
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverHeader fontWeight="semibold">
                        Informações
                      </PopoverHeader>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverBody>
                        <Text>
                          Aqui você pode selecionar a data inicial e a data
                          final de pagamento fazendo um período para seus
                          filtros
                        </Text>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                  <Box mx="4">
                    <MultiSelectMenuFilter
                      options={defaultOptionsStatus?.map((stat) => {
                        return {
                          id: stat?.value,
                          name: stat?.label
                        }
                      })}
                      label="Status"
                      isReset={isResetLocal}
                      backChange={(values) => {
                        setStatus(values)
                      }}
                      valueReset={['1', '2', '3']}
                      render={() => {
                        const backStatus: any = statusLocal

                        return backStatus
                      }}
                    />
                  </Box>
                  <Box mx="4">
                    <MultiSelectMenuFilter
                      options={defaultOptionsCategory?.map((cat) => {
                        return {
                          id: cat?.value,
                          name: cat?.label
                        }
                      })}
                      label="Categorias"
                      isReset={isResetLocal}
                      backChange={(values) => {
                        setCategory(values)
                      }}
                      render={() => {
                        const backStatus = categoryLocal

                        return backStatus
                      }}
                    />
                  </Box>
                  <Box mx="4">
                    <MultiSelectMenuFilter
                      options={services?.newCostsFormats?.map((c) => {
                        return {
                          id: c?.value,
                          name: c?.label
                        }
                      })}
                      label="Centro de custos"
                      isReset={isResetLocal}
                      backChange={(values) => {
                        setCost(values)
                      }}
                      render={() => {
                        const backStatus = costLocal

                        return backStatus
                      }}
                    />
                  </Box>
                </Flex>
                <Divider />
                <Center d="flex" flexDirection="column">
                  <Flex d="flex" flexDirection="row" m="4">
                    <Box mx="4">
                      <TextFieldControlled
                        name="code"
                        borderRadius={8}
                        type="date"
                        label="Data Inicial:"
                        textAlign="left"
                        placeholder="Selecione a data"
                        initialValue={paymentDateInitialLocal}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setPaymentDateInitial(event.target.value)
                        }}
                      />
                    </Box>
                    <Box mx="4">
                      <TextFieldControlled
                        name="code"
                        borderRadius={8}
                        type="date"
                        label="Data Final:"
                        textAlign="left"
                        placeholder="Selecione a data"
                        initialValue={paymentDateFinalLocal}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setPaymentDateFinal(event.target.value)
                        }}
                      />
                    </Box>
                  </Flex>
                  <Flex mx="auto" my="4">
                    <Box mx="4">
                      <TextFieldControlled
                        name="name"
                        borderRadius={8}
                        label="Nome da Conta:"
                        textAlign="left"
                        placeholder="Pesquisar por nome"
                        initialValue={nameLocal}
                        onKeyUp={(
                          event: React.KeyboardEvent<HTMLInputElement>
                        ) => {
                          setName(event.currentTarget.value)
                        }}
                      />
                    </Box>
                  </Flex>
                </Center>
              </SimpleGrid>
            </TabPanel>
            <TabPanel>
              <SimpleGrid
                d="flex"
                mx="auto"
                my="4"
                minChildWidth="320px"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                spacing={1}
              >
                <Flex
                  d="flex"
                  flexDirection="row"
                  align="center"
                  justifyContent="center"
                  mx="auto"
                  mb="2"
                >
                  <Popover isLazy>
                    <PopoverTrigger>
                      <Button
                        bg="transparent"
                        _hover={{
                          background: 'transparent'
                        }}
                      >
                        <AiOutlineInfoCircle size={26} />
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverHeader fontWeight="semibold">
                        Informações
                      </PopoverHeader>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverBody>
                        <Text>
                          Inserir o código do lançamento para o filtro!
                        </Text>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </Flex>
                <Divider />
                <Center my="4" d="flex" flexDirection="column">
                  <Flex d="flex" flexDirection="row" m="4">
                    <Box mx="4">
                      <TextFieldControlled
                        name="code"
                        borderRadius={8}
                        label="Código :"
                        textAlign="left"
                        placeholder="Pesquisar por código"
                        initialValue={codeLocal}
                        onKeyUp={(
                          event: React.KeyboardEvent<HTMLInputElement>
                        ) => {
                          setCode(event.currentTarget.value)
                        }}
                      />
                    </Box>
                  </Flex>
                </Center>
              </SimpleGrid>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </ModalFilters>
    </>
  )
}
