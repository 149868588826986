/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import {
  Box,
  Button,
  Flex,
  Stack,
  Text,
  Textarea,
  useBreakpointValue
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import { Input } from '../../components/Form/Input'
import { CardContainer } from '../../components/molecules/CardContainer'

import { ArrowBack } from '../../components/atoms/arrowBack'
import { Header } from '../../components/Header'
import { Sidebar } from '../../components/Sidebar'

import { Switch } from '../../components/Form/Switch'
import { PermissionComponent } from '../../components/Permissions'
import { useAttendance } from './service'

type FormData = {
  isActive: boolean
  observation: string
}

export function UpdateTagsServices() {
  const [t] = useTranslation('pageTagsServices')
  const attendanceService = useAttendance()
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const { register, handleSubmit, formState } = useForm<FormData>({})
  const { errors } = formState
  const [name, setName] = useState('')

  const router = useHistory()
  const { state }: any = router.location

  useEffect(() => {
    if (state?.name !== undefined) {
      setName(state.name)
    }
  }, [])

  const handleUpdateTagService: SubmitHandler<FormData> = async (values) => {
    await new Promise((resolve) => setTimeout(resolve, 2000))
    const { isActive, observation } = values

    attendanceService.updateAttendance({
      name: name || state.name,
      id: state.id,
      is_active: isActive,
      observation
    })
  }

  return (
    <Box overflow="hidden" position="relative">
      <Header>
        <Flex
          mx="auto"
          w="100%"
          direction="row"
          d="flex"
          my="6"
          align="center"
          justify="space-around"
        >
          <Box mx="auto">
            <Text fontSize="24px" fontWeight="600" height="100%">
              {t('updateTitle')}
            </Text>
          </Box>
          <Stack direction="row" align="center" justify="center">
            <ArrowBack href="/services/tags">
              <Text fontSize="md" fontWeight="bold">
                Voltar
              </Text>
            </ArrowBack>
          </Stack>
        </Flex>
      </Header>
      <Sidebar />

      <Flex
        flex="1"
        mx="auto"
        as="form"
        ml={isWideVersion ? '75px' : '2'}
        mt="15px"
        mb="8px"
        mr="2"
        bg="white"
        h="cal(100vh - 60px)"
        onSubmit={handleSubmit(handleUpdateTagService)}
      >
        <Stack spacing="4" w="100%">
          <CardContainer title={t('create.container1.title')}>
            <Stack direction="row" my="2" spacing="6">
              <Box w="65%">
                <Input
                  label={t('create.container1.i1')}
                  name="name"
                  value={name}
                  isRequired
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setName(event.target.value.toUpperCase())
                  }
                  maxLength={10}
                />
              </Box>
              <Flex w="35%">
                <PermissionComponent
                  spiCode="SERVICE_GROUPS"
                  ipCode="INACTIVATE"
                >
                  <Box ml="auto" mr="6">
                    <Switch
                      defaultChecked={state.is_active}
                      label={t('create.container1.s1')}
                      {...register('isActive')}
                    />
                  </Box>
                </PermissionComponent>
              </Flex>
            </Stack>
            <Text fontSize="sm" my="2">
              {t('label.observation')}
            </Text>
            <Textarea
              {...register('observation')}
              name="observation"
              defaultValue={state?.observation}
              placeholder={t('create.container1.placeholder')}
            />
          </CardContainer>

          <Flex mt="6" w="100%" align="flex-end" justifyContent="flex-end">
            <Button
              type="submit"
              size="md"
              fontSize="md"
              bg="blue.300"
              color="#fff"
              isLoading={formState.isSubmitting}
            >
              {t('create.button')}
            </Button>
          </Flex>
        </Stack>
      </Flex>
    </Box>
  )
}
