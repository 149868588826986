/* eslint-disable camelcase */
import { useQuery } from 'react-query'
import { apiAuth } from '../../services/apiAuth'

export type Parcels = {
  id: number
  parcel_number: number
  tax_percentage: number | null
  tax_absolute: number | null
  interest_rate_percentage: number | null
  interest_rate_absolule: number | null
  is_active: boolean
}

export type WaysData = {
  id: number
  name: string
  day_to_receive: string
  observation: string | null
  way_to_receive_parcels: Array<Parcels>
  active_parcels: number
}

export type ResponseGetExam = {
  waysReceives: WaysData[]
  totalCountOfPage: number
}

export const getWaysReceives = async (
  clinicsId: string = '',
  page: number,
  search: string = '',
  isActive: boolean = true
): Promise<ResponseGetExam> => {
  try {
    const { data } = await apiAuth.get('clinics/ways-receives/', {
      params: {
        page,
        name: search,
        is_active: isActive
      }
    })

    const totalCountOfPage = data.count
    const waysReceives: WaysData[] = data.results

    return {
      waysReceives,
      totalCountOfPage
    }
  } catch (error: any) {
    console.log(error)

    const waysReceives: WaysData[] = []
    const totalCountOfPage = 0
    return {
      waysReceives,
      totalCountOfPage
    }
  }
}

export function useWaysReceives(
  page: number,
  functionLoading: Promise<ResponseGetExam>,
  search: string,
  isActive: boolean,
  options?: {}
) {
  return useQuery(
    ['waysReceives', page, search, isActive],
    () => functionLoading,
    {
      staleTime: 1000 * 2, // 2 seconds
      cacheTime: 1000 * 2, // 2 seconds
      ...options
    }
  )
}
