/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Flex,
  Stack,
  Text,
  useBreakpointValue
} from '@chakra-ui/react'
import { useState } from 'react'

import { SubmitHandler, useForm } from 'react-hook-form'

import { useTranslation } from 'react-i18next'

import { Header } from '../../components/Header'
import { CardContainer } from '../../components/molecules/CardContainer'
import { Sidebar } from '../../components/Sidebar'

import { Input } from '../../components/Form/Input'
import { Select } from '../../components/Form/Select'
import { SelectComponent } from '../../components/Form/SelectComponent'

type FormData = {
  is_active: boolean
  description: string
  is_special: boolean
}

type Data = {
  label: string
  value: string
}

type Specialist = {
  label: string
  value: number
}

type SpecialistData = Array<Specialist>

export function ReportsTransfer() {
  const [t] = useTranslation('pageReportsTransfer')
  const { register, handleSubmit, formState } = useForm<FormData>({})
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const optionsDefaultActions = [
    { value: 1, label: `${t('create.container1.title')}` },
    { value: 2, label: `${t('create.container2.title')}` }
  ]
  const [usersCaseSpecial, setUsersCaseSpecial] = useState<Data[]>([])
  const [users, setUsers] = useState<Data[]>([])
  const [transfers, setTransfers] = useState<Data[]>([])
  const [selectOption, setSelectOption] = useState(1)

  const handlerSelectUsers = (event: any) => {
    setUsers(event)
  }

  const handlerSelectUsersSpecial = (event: any) => {
    setUsersCaseSpecial(event)
  }

  const handlerSelectTransfer = (event: any) => {
    setTransfers(event)
  }

  const handleCreateRecipe: SubmitHandler<FormData> = async (values) => {
    await new Promise((resolve) => setTimeout(resolve, 2000))
  }

  return (
    <Box overflow="hidden" position="relative">
      <Header>
        <Flex
          mx="auto"
          w="100%"
          direction="row"
          d="flex"
          my="6"
          align="center"
          justify="space-around"
        >
          <Box mx="auto">
            <Text fontSize="24px" fontWeight="600" height="100%">
              {t('create.title')}
            </Text>
          </Box>
        </Flex>
      </Header>
      <Sidebar />
      <Flex
        flex="1"
        mx="auto"
        as="form"
        ml={isWideVersion ? '75px' : '2'}
        mt="15px"
        mb="8px"
        mr="2"
        bg="white"
        h="cal(100vh - 60px)"
        onSubmit={handleSubmit(handleCreateRecipe)}
      >
        <Stack spacing="4" w="100%">
          <Stack w="100%" spacing={6} direction="row">
            <Box w="320px">
              <Input label="Data inicial" name="inicialDate" type="date" />
            </Box>
            <Box w="320px">
              <Input label="Data final" name="finishDate" type="date" />
            </Box>
            <Select
              name="typeActionReport"
              options={optionsDefaultActions}
              value={selectOption}
              label="Tipo de relatório"
              onChange={(e) => setSelectOption(Number(e.target.value))}
            />
          </Stack>

          {selectOption === 1 ? (
            <CardContainer title={t('create.container1.title')}>
              <Stack direction="row" my="2" w="100%" spacing="6">
                <SelectComponent
                  options={[]}
                  name="users"
                  value={users}
                  onChange={handlerSelectUsers}
                  title={t('label.users')}
                  placeholder={t('placeholder.users')}
                />
              </Stack>
              <Flex direction="column">
                <Button bg="blue.300" color="white">
                  Gerar relatório
                </Button>
              </Flex>
            </CardContainer>
          ) : (
            <CardContainer title={t('create.container2.title')}>
              <Stack direction="row" my="2" spacing="6">
                <Stack direction="column" my="2" spacing="4" w="100%">
                  <SelectComponent
                    options={[]}
                    name="usersCaseSpecial"
                    value={usersCaseSpecial}
                    onChange={handlerSelectUsersSpecial}
                    title={t('label.users')}
                    placeholder={t('placeholder.users')}
                  />
                  <SelectComponent
                    options={[]}
                    name="transfer"
                    value={transfers}
                    onChange={handlerSelectTransfer}
                    title={t('label.transfer')}
                    placeholder={t('placeholder.transfer')}
                  />
                </Stack>
              </Stack>
              <Flex direction="column">
                <Button bg="blue.300" color="white">
                  Gerar relatório específico
                </Button>
              </Flex>
            </CardContainer>
          )}
          <Flex w="100%" h="300px" />
        </Stack>
      </Flex>
    </Box>
  )
}
