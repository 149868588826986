/* eslint-disable no-lonely-if */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Flex,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import { Input } from '../../components/Form/Input'
import { CardContainer } from '../../components/molecules/CardContainer'

import { ArrowBack } from '../../components/atoms/arrowBack'
import { Header } from '../../components/Header'
import { Sidebar } from '../../components/Sidebar'

import { RemoveBtnIcon } from '../../components/atoms/removeBtnIcon'
import { SelectComponent } from '../../components/Form/SelectComponent'
import { Switch } from '../../components/Form/Switch'
import { TextFieldControlled } from '../../components/Form/TextFieldControlled'
import { Draft } from '../../components/molecules/Draft'
import {
  formatCurrencyUSDDefault,
  remaskCaractersAll
} from '../../utils/fns/removeCaracters'
import { mask } from '../../utils/mask'
import { useLogicServicer } from './logic'
import { useService } from './service'

type FormData = {
  name: string
  price: string
  observation: string
  days_to_come_back: string
  is_active: boolean
  days_to_maturity: string
}

export function CreateAndUpdateService() {
  const [t] = useTranslation('pageServices')
  const service = useService()
  const toast = useToast()
  const logic = useLogicServicer()
  const history = useHistory()
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const tagsUpdate = service.tagsServices
  const docsUpdate = service.docServices
  const [localMethod, setLocal] = useState('')
  const [valueDraft, setValueDraft] = useState<any>()

  const { state }: any = history.location
  const { register, handleSubmit, formState } = useForm<FormData>({})
  const { errors } = formState

  function handleGetMethod() {
    const local = history.location.pathname

    if (local.indexOf('create') !== -1) {
      setLocal('create')
    } else {
      setLocal('update')

      // service defaults
      const formatServicesSessions = state.service_sessions.map((serv: any) => {
        return {
          ...serv,
          duration: String(serv.duration),
          time_to_be_there: String(serv.time_to_be_there),
          session_number: serv.session_number
        }
      })

      logic.setServiceSessios(formatServicesSessions)
    }
  }

  function formatSessionsServices(): any {
    const format = logic.serviceSessions.map((val: any) => {
      return {
        duration: Number(val.duration),
        time_to_be_there: Number(val.time_to_be_there),
        session_number: val.session_number
      }
    })

    return format
  }

  function formatTagsServices() {
    if (logic.tagsSelect !== null) {
      const serviceTags = {
        id: logic.tagsSelect.value
      }

      return serviceTags
    }

    return logic.tagsSelect
  }

  function formatDocsServices() {
    if (logic.docsSelect.length > 0) {
      const serviceDocs = logic.docsSelect.map((docs) => {
        return Number(docs.value)
      })

      return serviceDocs
    }

    return logic.docsSelect
  }

  const handleCreateService: SubmitHandler<FormData> = async (values) => {
    const formatsSessions = formatSessionsServices()
    const tag_service = formatTagsServices()
    const service_documents = formatDocsServices()

    if (logic.tagsSelect === null) {
      toast({
        title: 'O grupo de serviço é obrigatório',
        description: 'Selecione um grupo para o serviço',
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })

      return
    }

    const payload = {
      name: logic.nameService,
      price: Number(remaskCaractersAll(logic.price)) / 100,
      is_active: values.is_active,
      observation: values.observation,
      days_to_come_back: logic.daysToComeBack || 0,
      days_to_maturity: logic.daysToMaturity || 0,
      protocol: valueDraft === undefined ? '<p></br><p>' : String(valueDraft),
      service_sessions: formatsSessions,
      tag_service,
      service_documents
    }

    if (localMethod === 'create') {
      service.createRequest(payload)
    } else {
      const { id } = state

      service.updateRequest(payload, id)
    }
  }

  // Get values for update
  useEffect(() => {
    if (state?.price !== undefined) {
      if (state.price === '0.00') {
        logic.setPrice('')
      } else {
        logic.setPrice(formatCurrencyUSDDefault(state.price))
      }
    }

    if (state?.name !== undefined) {
      logic.setNameService(state.name)
    }

    if (state?.protocol !== undefined) {
      if (state.protocol === 'undefined') {
        setValueDraft('')
      } else {
        setValueDraft(state.protocol)
      }
    }

    if (state?.days_to_come_back !== undefined) {
      logic.setDaysToComeBack(state.days_to_come_back)
    }

    if (state?.days_to_maturity !== undefined) {
      logic.setDaysToMaturity(state.days_to_maturity)
    }
  }, [])
  // // Get values for update
  useEffect(() => {
    if (logic.getValuesSelectEdit === true) {
      return
    }

    if (state?.tag_service) {
      logic.setTagsSelect({
        value: state?.tag_service?.id,
        label: state?.tag_service?.name
      })
    } else {
      logic.setTagsSelect(null)
    }
  }, [tagsUpdate])

  useEffect(() => {
    if (logic.getValuesDocSelectEdit === true) {
      return
    }

    if (state?.service_documents !== null) {
      if (state?.service_documents.length > 0) {
        const docsDefault = state?.service_documents
        const docsValues = docsUpdate
        const docsSelect = docsDefault.map((id: any) =>
          docsValues.find((tag) => tag.id === id)
        )

        if (docsSelect[0] !== undefined) {
          logic.setDocsSelect(
            docsSelect.map((tag: any) => {
              return {
                value: String(tag.id),
                label: String(tag.name)
              }
            })
          )
        }
      }
    }
  }, [docsUpdate])

  useEffect(() => {
    handleGetMethod()
  }, [history.location.pathname])

  return (
    <Box overflow="hidden" position="relative">
      <Header>
        <Flex
          mx="auto"
          w="100%"
          direction="row"
          d="flex"
          my="6"
          align="center"
          justify="space-around"
        >
          <Box mx="auto">
            <Text fontSize="24px" fontWeight="600" height="100%">
              {localMethod === 'create'
                ? `${t('create.title')}`
                : `${t('updateTitle')}`}
            </Text>
          </Box>
          <Stack direction="row" align="center" justify="center">
            <ArrowBack href="/services">
              <Text fontSize="md" fontWeight="bold">
                Voltar
              </Text>
            </ArrowBack>
          </Stack>
        </Flex>
      </Header>
      <Sidebar />

      <Flex
        flex="1"
        mx="auto"
        as="form"
        ml={isWideVersion ? '75px' : '2'}
        mt="15px"
        mb="8px"
        mr="2"
        bg="white"
        h="cal(100vh - 60px)"
        onSubmit={handleSubmit(handleCreateService)}
      >
        <Stack spacing="4" w="100%">
          <CardContainer title={t('create.container1.title')}>
            <Stack direction="row" my="2" spacing="6">
              <Box w="70%">
                <Input
                  label={t('create.container1.i1')}
                  isRequired
                  name="name"
                  placeholder={t('create.container1.placeholder.name')}
                  value={logic.nameService}
                  maxLength={200}
                  onChange={(event) =>
                    logic.setNameService(event.target.value.toUpperCase())
                  }
                  error={errors.name}
                />
              </Box>
              <Box w="30%">
                <Switch
                  defaultChecked={
                    state?.is_active !== undefined ? state?.is_active : true
                  }
                  label={t('create.container1.i2')}
                  {...register('is_active')}
                />
              </Box>
            </Stack>
            <Stack direction="row" my="2" spacing="6">
              <Box w="20%">
                <TextFieldControlled
                  label={t('create.container1.i3')}
                  mask="monetary"
                  placeholder="0,00"
                  name="price"
                  initialValue={logic.price}
                  onKeyUp={(event) => logic.setPrice(event.currentTarget.value)}
                  error={errors.price}
                />
              </Box>
              <Box w="15%">
                <Input
                  label={t('create.container1.i4')}
                  masker="999"
                  placeholder="0"
                  value={logic.daysToComeBack}
                  onChange={(event) =>
                    logic.setDaysToComeBack(mask(event.target.value, '999'))
                  }
                  name="days_to_come_back"
                  error={errors.days_to_come_back}
                />
              </Box>
              <Box w="15%">
                <Input
                  label={t('create.container1.i5')}
                  placeholder="0"
                  value={logic.daysToMaturity}
                  onChange={(event) =>
                    logic.setDaysToMaturity(mask(event.target.value, '999'))
                  }
                  name="days_to_maturity"
                  error={errors.days_to_maturity}
                />
              </Box>
              <Box w="50%" h="100px">
                <SelectComponent
                  title="Grupo"
                  options={service.tagsServices.map((tag) => {
                    return {
                      value: String(tag.id),
                      label: tag.name
                    }
                  })}
                  isRequired
                  placeholder={t('placeholder.tags')}
                  noOptionsText={t('noOptions.tags')}
                  value={logic.tagsSelect}
                  onChange={(event: any) => logic.getEventMultiSelect(event)}
                />
              </Box>
            </Stack>
            <Text fontSize="sm" my="2">
              {t('label.observation')}
            </Text>
            <Textarea
              placeholder={t('placeholder.observation')}
              {...register('observation')}
              defaultValue={state?.observation}
            />
            <Flex />
          </CardContainer>

          <CardContainer title={t('create.container3.title')}>
            <Table>
              <Thead>
                <Tr>
                  <Th>{t('create.container3.i1')}</Th>
                  <Th>{t('create.container3.i2')}</Th>
                  <Th>{t('create.container3.i3')}</Th>
                  {logic.serviceSessions.length > 1 && <Th />}
                </Tr>
              </Thead>
              <Tbody>
                {logic.serviceSessions.map((serviceAux: any, index: number) => (
                  <Tr key={serviceAux.session_number}>
                    <Td>{serviceAux.session_number}</Td>
                    <Td>
                      <Input
                        value={serviceAux.duration}
                        placeholder="0"
                        onChange={(e) => logic.handleInputChange(e, index)}
                        name="duration"
                      />
                    </Td>
                    <Td>
                      <Input
                        value={serviceAux.time_to_be_there}
                        placeholder="0"
                        onChange={(e) => logic.handleInputChange(e, index)}
                        name="time_to_be_there"
                      />
                    </Td>
                    {logic.serviceSessions.length > 1 && (
                      <Td>
                        <RemoveBtnIcon
                          onClick={() =>
                            logic.onRemoveFieldServicesSessions(index)
                          }
                        />
                      </Td>
                    )}
                  </Tr>
                ))}
              </Tbody>
              <Box mt="5">
                <Button
                  size="md"
                  fontSize="sm"
                  bg="green.300"
                  color="#fff"
                  isLoading={formState.isSubmitting}
                  onClick={() => logic.handleAddFieldServicesSessions()}
                >
                  {t('create.container3.btnLabel')}
                </Button>
              </Box>
            </Table>
          </CardContainer>

          <CardContainer title={t('create.container2.title')}>
            <Draft
              defaultValue={state?.protocol}
              getValue={(value: any) => {
                setValueDraft(value)
              }}
            />
          </CardContainer>

          <CardContainer title={t('create.container4.title')}>
            <Box w="80%" h="100px">
              <SelectComponent
                title={t('create.container4.info')}
                options={service.docServices.map((doc) => {
                  return {
                    value: String(doc.id),
                    label: doc.name
                  }
                })}
                isMulti
                placeholder={t('placeholder.doc')}
                noOptionsText={t('noOptions.doc')}
                value={logic.docsSelect}
                onChange={(event: any) => logic.getEventMultiSelectDocs(event)}
              />
            </Box>
          </CardContainer>

          {/* <CardContainer title={t('create.container4.title')}>
            {logic.documents.map((doc: any, index: number) => (
              <>
                <Stack>
                  <Flex justify="space-between">
                    <Flex>
                      <Heading size="14px">Documento {index + 1}</Heading>
                    </Flex>
                    {logic.documents.length > 1 && (
                      <Box>
                        <Button
                          type="button"
                          colorScheme="red"
                          size="xs"
                          mb="2"
                          onClick={() =>
                            logic.onRemoveFieldDocumentsSessions(index)
                          }
                        >
                          X
                        </Button>
                      </Box>
                    )}
                  </Flex>
                  <Flex justify="space-between">
                    <Flex direction="column">
                      <Dropzone
                        getImg={(e: any) => {
                          logic.handleUploadDocument(e, index)
                        }}
                        endpoint="x"
                        handleSetImg={doc.document}
                      />
                    </Flex>

                    <Flex direction="column" w="40%">
                      <Box w="100%">
                        <SelectComponent
                          title="Ação para documento"
                          options={[
                            {
                              value: 'interno',
                              label: `${t('create.container4.options.op1')}`
                            },
                            {
                              value: 'paciente',
                              label: `${t('create.container4.options.op2')}`
                            }
                          ]}
                          value={doc.actionDoc}
                          onChange={(e: any) =>
                            logic.handleSelectOptionDocument(
                              e,
                              index,
                              'actionDoc'
                            )
                          }
                          name="actionDoc"
                        />
                      </Box>

                      {doc.actionDoc.value === 'paciente' && (
                        <Box mt="4">
                          <Checkbox
                            direction="row"
                            options={[
                              {
                                value: 'coletar',
                                label: 'Coletar assinatura'
                              }
                            ]}
                            defaultChecked={doc.checkColect !== ''}
                            onChange={(e) =>
                              logic.handleCheckboxDocument(
                                e,
                                index,
                                'checkColect'
                              )
                            }
                            name="checkColect"
                          />
                        </Box>
                      )}
                    </Flex>
                  </Flex>
                </Stack>
                <Divider my="6" />
              </>
            ))}

            <Box mt="5">
              <Button
                size="md"
                fontSize="sm"
                bg="green.300"
                color="#fff"
                isLoading={formState.isSubmitting}
                onClick={() => logic.handleAddFieldDocumentsSessions()}
              >
                {t('create.container4.btnLabel')}
              </Button>
            </Box>
          </CardContainer> */}

          <Flex mt="6" w="100%" align="flex-end" justifyContent="flex-end">
            <Button
              type="submit"
              size="md"
              fontSize="md"
              bg="blue.300"
              color="#fff"
              isLoading={formState.isSubmitting}
            >
              {t('create.button')}
            </Button>
          </Flex>

          <Stack w="100%" my="2" h="300px" />
        </Stack>
      </Flex>
    </Box>
  )
}
