/* eslint-disable camelcase */
import { useQuery } from 'react-query'
import { apiAuth } from '../../services/apiAuth'

export type FinancialSourcesData = {
  id: string
  name: string
  description: string
}

export type ResponseGetFinancialSourcesData = {
  financial: FinancialSourcesData[]
  totalCountOfPage: number
}

export const getFinancialSources = async (
  clinicsId: string = '',
  page: number,
  search: string = '',
  isActive: boolean = true
): Promise<ResponseGetFinancialSourcesData> => {
  try {
    const { data } = await apiAuth.get(
      `clinics/${clinicsId}/payment-sources/`,
      {
        params: {
          page,
          name__icontains: search,
          is_active: isActive
        }
      }
    )

    const totalCountOfPage = data.count
    const financial: FinancialSourcesData[] = data.results

    return {
      financial,
      totalCountOfPage
    }
  } catch (error: any) {
    console.log(error)

    const financial: FinancialSourcesData[] = []
    const totalCountOfPage = 0
    return {
      financial,
      totalCountOfPage
    }
  }
}

export function useFinancialSources(
  page: number,
  functionLoading: Promise<ResponseGetFinancialSourcesData>,
  search: string,
  isActive: boolean,
  options?: {}
) {
  return useQuery(['users', page, search, isActive], () => functionLoading, {
    staleTime: 1000 * 2, // 2 seconds
    cacheTime: 1000 * 2, // 2 seconds
    ...options
  })
}
