/* eslint-disable camelcase */
import { useQuery } from 'react-query'
import { apiAuth } from '../../services/apiAuth'

export type RecipesData = {
  id: string
  name: string
  prescription_detail: string
  status: number
  type: number
}

export type ResponseGetRecipes = {
  recipes: RecipesData[]
  totalCountOfPage: number
}

export const getRecipes = async (
  clinicsId: string = '',
  page: number,
  search: string = '',
  isActive: boolean = true
): Promise<ResponseGetRecipes> => {
  try {
    const { data } = await apiAuth.get(
      `clinics/${clinicsId}/prescriptions-requests/`,
      {
        params: {
          page,
          name__icontains: search,
          is_active: isActive
        }
      }
    )

    const totalCountOfPage = data.count
    const recipes: RecipesData[] = data.results
    console.log(data.results)

    return {
      recipes,
      totalCountOfPage
    }
  } catch (error: any) {
    console.log(error)

    const recipes: RecipesData[] = []
    const totalCountOfPage = 0
    return {
      recipes,
      totalCountOfPage
    }
  }
}

export function useRecipes(
  page: number,
  functionLoading: Promise<ResponseGetRecipes>,
  search: string,
  isActive: boolean,
  options?: {}
) {
  return useQuery(['recipes', page, search, isActive], () => functionLoading, {
    staleTime: 1000 * 2, // 2 seconds
    cacheTime: 1000 * 2, // 2 seconds
    ...options
  })
}
