/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  HStack,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue
} from '@chakra-ui/react'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaUserEdit } from 'react-icons/fa'
import { FiUsers } from 'react-icons/fi'
import { GrUserAdmin } from 'react-icons/gr'
import { Link } from 'react-router-dom'
import { Pagination } from '../../../components/PaginationNew'
import { useAuth } from '../../../hooks/auth'
import { getUsers, UserData, useUsers } from '../../../hooks/users/useUsers'
import { LayoutList } from '../../../layouts/List'

import { NotFound } from '../../../components/molecules/NotFound'
import { PermissionComponent } from '../../../components/Permissions'

export type OptionSelectData = {
  value: string
  string: string
}

// debounce
let timer: any = null

export default function Users() {
  const { clinicId } = useAuth()
  const { t } = useTranslation('pageUsers')
  const [searchState, setSearchState] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [isActive, setIsActive] = useState(true)
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const skeletonRepeat = [1, 2, 3, 4, 5, 6, 7]

  const getUsersData = useUsers(
    currentPage,
    getUsers(String(clinicId), currentPage, searchState, isActive),
    searchState,
    isActive
  )

  const SearchInputCallbackValue = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget

      clearTimeout(timer)

      timer = setTimeout(() => {
        setCurrentPage(1)
        setSearchState(value)
      }, 500)
    },
    []
  )

  const ActiveCheckboxCallbackValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsActive(event.target.checked)
    },
    []
  )

  return (
    <LayoutList
      title={t('home.title')}
      urlNew="/settings/users/create"
      refetch={getUsersData.refetch}
      placeholder={t('search')}
      capturaChange={(event: any) => SearchInputCallbackValue(event)}
      captureActiveChange={(event: any) => ActiveCheckboxCallbackValue(event)}
      spiCode="USERS"
    >
      <Box>
        <Table variant="striped" colorScheme="blue" size="sm">
          <Thead>
            <Tr>
              {isWideVersion && <Th>{t('home.tNome')}</Th>}
              <Th>{t('home.tSocialName')}</Th>
              <Th>{t('home.isSpecialist')}</Th>
              <Th>{t('home.tAcoes')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {getUsersData.isLoading ? (
              <>
                {skeletonRepeat.map((skeleton) => (
                  <Tr>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                  </Tr>
                ))}
              </>
            ) : (
              <>
                {getUsersData.data?.users.map((user: UserData) => (
                  <Tr key={String(user.id)}>
                    {isWideVersion && <Td>{user.user.name}</Td>}
                    <Td>
                      <Text fontSize="14px">{user?.social_name || ''}</Text>
                    </Td>

                    <Td>
                      <Text fontSize="sm">
                        {user.user.is_specialist === true
                          ? `${t('is_specialist')}`
                          : ''}
                      </Text>
                    </Td>

                    <Td>
                      <PermissionComponent spiCode="USERS" ipCode="EDIT">
                        <HStack>
                          <Link
                            to={{
                              pathname: `/settings/users/update/${user.id}`,
                              state: user
                            }}
                          >
                            <Tooltip
                              label={t('edit')}
                              aria-label={t('edit')}
                              hasArrow
                              placement="top-start"
                            >
                              <Box ml="1rem" w="24px">
                                <FaUserEdit
                                  color="black"
                                  size={16}
                                  style={{ cursor: 'pointer' }}
                                />
                              </Box>
                            </Tooltip>
                          </Link>
                          {user?.user?.is_superuser === false ? (
                            <Link
                              to={{
                                pathname: `/settings/users/config-permissions/${user.id}`,
                                state: user
                              }}
                            >
                              <Tooltip
                                label={t('permission')}
                                aria-label={t('permission')}
                                hasArrow
                                placement="top-start"
                              >
                                <Box ml="1rem" w="24px">
                                  <GrUserAdmin
                                    color="black"
                                    size={16}
                                    style={{ cursor: 'pointer' }}
                                  />
                                </Box>
                              </Tooltip>
                            </Link>
                          ) : (
                            <Box w="40px">
                              <Text fontSize="xs">
                                {user.user.is_superuser === true
                                  ? `${t('is_superuser')}`
                                  : ''}
                              </Text>
                            </Box>
                          )}
                          <Link
                            to={{
                              pathname: `/settings/users/config-specialists/${user.id}`,
                              state: user
                            }}
                          >
                            <Tooltip
                              label={t('specialistAccess')}
                              aria-label={t('specialistAccess')}
                              hasArrow
                              placement="top-start"
                            >
                              <Box ml="1rem" w="24px">
                                <FiUsers
                                  color="black"
                                  size={16}
                                  style={{ cursor: 'pointer' }}
                                />
                              </Box>
                            </Tooltip>
                          </Link>
                        </HStack>
                      </PermissionComponent>
                    </Td>
                  </Tr>
                ))}
              </>
            )}
          </Tbody>
        </Table>
        {getUsersData.data?.totalCountOfPage === 0 &&
          !getUsersData.isLoading && <NotFound />}
      </Box>
      {!(
        getUsersData.data?.totalCountOfPage === 0 && !getUsersData.isLoading
      ) && (
        <Pagination
          totalCountOfRegisters={getUsersData.data?.totalCountOfPage || 0}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      )}
    </LayoutList>
  )
}
